export const convertDateToString = (passedValue, type)=>{
  // console.log('convertDateToString called', passedValue, type)

  function convertTime(passedTime) {
    // console.log('convertTime called')

    let returnedTime = ''
    if (passedTime) {
      let hours = Number(passedTime.substring(0,2))
      if (hours > 12) {
        returnedTime = (hours - 12).toString() + ':' + passedValue.substring(14,16) + 'PM'
      } else if (hours === 12) {
        returnedTime = (hours).toString() + ':' + passedValue.substring(14,16) + 'PM'
      } else {
        returnedTime = passedTime + ' AM'
      }
    }

    return returnedTime
  }

  let returnedValue = ''
  if ((passedValue && passedValue !== '') || (passedValue === 0)) {
    if (type === 'first16') {

      returnedValue = passedValue.toString().substring(0,16)
    } else if (type === 'first10') {
      returnedValue = passedValue.toString().substring(0,10)
    } else if (type === 'last5') {
      // console.log('----last5:', passedValue, passedValue.length, passedValue[11])

      let adjustSubstring = 0
      if (passedValue[11] === 'T') {
        adjustSubstring = 1
      }

      returnedValue = passedValue.toString().substring(11 + adjustSubstring,16 + adjustSubstring)
      const hours = returnedValue.substring(0,2)
      // console.log('show hours', hours)
      if (Number(hours) === 12) {
        returnedValue = returnedValue + "PM"
      } else if (Number(hours) > 12) {
        const newHours = Number(hours) - 12
        if (newHours < 10) {
          returnedValue = '0' + newHours.toString() + returnedValue.substring(2,5) + 'PM'
        } else {
          returnedValue = newHours.toString() + returnedValue.substring(2,5) + 'PM'
        }
      } else {
        returnedValue = returnedValue + "AM"
      }
      // 2022-11-010T16:30, T16:3AM
    } else if (type === 'datetime') {
      // console.log('in datetime', passedValue)
      returnedValue = passedValue.toString().substring(0,16)
      const dateArray = returnedValue.split("T")

      const month = dateArray[0].substring(5,7)
      const day = dateArray[0].substring(8,10)
      const year = dateArray[0].substring(0,4)

      returnedValue = month + '/' + day + '/' + year + ' ' + convertTime(dateArray[1])
    } else if (type === 'datetime-2') {
      // console.log('in datetime-2', passedValue)
      let month = passedValue.getMonth()
      if ((month + 1) >= 10) {
        month = (month + 1).toString()
      } else {
        month = '0' + (month + 1).toString()
      }

      let day = passedValue.getDate()
      if (day < 10) {
        day = '0' + day.toString()
      } else {
        day = day.toString()
      }
      const year = passedValue.getFullYear()

      let hours = passedValue.getHours()
      let suffix = 'AM'
      // console.log('confirm hours: ', hours, ,typeof )
      if (hours > 12) {
        hours = (hours - 12).toString()
        suffix = 'PM'
      } else if (hours === 12) {
        hours = (hours).toString()
        suffix = 'PM'
      } else if (hours === 0) {
        hours = '12'
        suffix = 'AM'
      } else {
        hours = hours.toString()
      }

      let mins = passedValue.getMinutes().toString()
      if (mins < 10) {
        mins = '0' + mins.toString()
      } else {
        mins = mins.toString()
      }
      // console.log('gimme mins: ', mins)

      returnedValue = month + '/' + day + '/' + year + ' ' + hours + ':' + mins + suffix
    } else if (type === 'date') {

      returnedValue = passedValue.toString().substring(0,10)

      const month = returnedValue.substring(5,7)
      const day = returnedValue.substring(8,10)
      const year = returnedValue.substring(0,4)

      returnedValue = month + '/' + day + '/' + year
    } else if (type === 'date-2') {
      // console.log('in date-2', passedValue)
      let month = passedValue.getMonth()
      if ((month + 1) >= 10) {
        month = (month + 1).toString()
      } else {
        month = '0' + (month + 1).toString()
      }

      let day = passedValue.getDate()
      if (day >= 10) {
        day = day.toString()
      } else {
        day = '0' + day.toString()
      }
      const year = passedValue.getFullYear()

      returnedValue = month + '/' + day + '/' + year
    } else if (type === 'date-formal') {
      // console.log('in date-2', passedValue)
      let month = passedValue.getMonth()
      let formattedMonth = 'January'
      if (month === 0) {
        formattedMonth = 'January'
      } else if (month === 1) {
        formattedMonth = 'February'
      } else if (month === 2) {
        formattedMonth = 'March'
      } else if (month === 3) {
        formattedMonth = 'April'
      } else if (month === 4) {
        formattedMonth = 'May'
      } else if (month === 5) {
        formattedMonth = 'June'
      } else if (month === 6) {
        formattedMonth = 'July'
      } else if (month === 7) {
        formattedMonth = 'August'
      } else if (month === 8) {
        formattedMonth = 'September'
      } else if (month === 9) {
        formattedMonth = 'October'
      } else if (month === 10) {
        formattedMonth = 'November'
      } else if (month === 11) {
        formattedMonth = 'December'
      }

      let day = passedValue.getDate()
      if (day >= 10) {
        day = day.toString()
      } else {
        day = '0' + day.toString()
      }
      const year = passedValue.getFullYear()

      returnedValue = formattedMonth + ' ' + day + ', ' + year

    } else if (type === 'rawDateForInput') {
      // console.log('show passedValue: ', passedValue)

      // let adjustSubstring = 0
      // if (passedValue[11] === 'T') {
      //   adjustSubstring = 1
      // }

      let month = passedValue.getMonth()
      if ((month + 1) > 10) {
        month = (month + 1).toString()
      } else {
        month = '0' + (month + 1).toString()
      }
      let day = passedValue.getDate()
      if (day >= 10) {
        day = day.toString()
      } else {
        day = '0' + day.toString()
      }
      const year = passedValue.getFullYear()
      // console.log('dateForInput: ', month, day, year)
      // returnedValue = passedValue.toString().substring(0,10)
      //
      // const month = returnedValue.substring(5,7)
      // const day = returnedValue.substring(8,10)
      // const year = returnedValue.substring(0,4)

      returnedValue = year + '-' + month + '-' + day
      console.log('rv2: ', returnedValue)
    } else if (type === 'rawDateTimeForInput') {
      // console.log('show passedValue: ', passedValue)
      let month = passedValue.getMonth()
      if ((month + 1) >= 10) {
        month = (month + 1).toString()
      } else {
        month = '0' + (month + 1).toString()
      }
      let day = passedValue.getDate()
      if (day >= 10) {
        day = day.toString()
      } else {
        day = '0' + day.toString()
      }
      const year = passedValue.getFullYear()

      let hours = passedValue.getHours().toString()
      if (hours < 10) {
        hours = '0' + hours.toString()
      } else {
        hours = hours.toString()
      }

      let mins = passedValue.getMinutes().toString()
      if (mins < 10) {
        mins = '0' + mins.toString()
      } else {
        mins = mins.toString()
      }
      console.log('show hours: ', hours)
      console.log('show mins: ', mins)

      // console.log('dateForInput: ', month, day, year)
      // returnedValue = passedValue.toString().substring(0,10)
      //
      // const month = returnedValue.substring(5,7)
      // const day = returnedValue.substring(8,10)
      // const year = returnedValue.substring(0,4)
      // console.log('----rawDateTimeForInput:', passedValue, month, day, year, hours, mins)
      // 010
      returnedValue = year + '-' + month + '-' + day + 'T' + hours + ':' + mins

    } else if (type === 'daysAgo') {

      if (typeof passedValue === 'string') {
        // technically converting string to string
        passedValue = new Date(passedValue)
      }

      const diffTime = Math.abs(passedValue - new Date());
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      returnedValue = diffDays + ' days ago'

      if (diffDays === 0) {

        const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
        // console.log('diffDays is 0 so diffHours ', diffTime, diffDays, diffHours)
        returnedValue = diffHours + ' hours ago'
        if (diffHours === 0) {
          const diffMins = Math.floor(diffTime / (1000 * 60));
          // console.log('diffDays is 0 so diffMins ', diffTime, diffDays, diffHours, diffMins)
          returnedValue = diffMins + ' minutes ago'
        }
      }
    } else if (type === 'formatMonth') {
      // console.log('in formatMonth func', passedValue)
      returnedValue = 'January'
      if (passedValue === '01' || passedValue === 0) {
        returnedValue = 'January'
      } else if (passedValue === '02' || passedValue === 1) {
        returnedValue = 'February'
      } else if (passedValue === '03' || passedValue === 2) {
        returnedValue = 'March'
      } else if (passedValue === '04' || passedValue === 3) {
        returnedValue = 'April'
      } else if (passedValue === '05' || passedValue === 4) {
        returnedValue = 'May'
      } else if (passedValue === '06' || passedValue === 5) {
        returnedValue = 'June'
      } else if (passedValue === '07' || passedValue === 6) {
        returnedValue = 'July'
      } else if (passedValue === '08' || passedValue === 7) {
        returnedValue = 'August'
      } else if (passedValue === '09' || passedValue === 8) {
        returnedValue = 'September'
      } else if (passedValue === '10' || passedValue === 9) {
        returnedValue = 'October'
      } else if (passedValue === '11' || passedValue === 10) {
        returnedValue = 'November'
      } else if (passedValue === '12' || passedValue === 11) {
        returnedValue = 'December'
      }
      // console.log('returnedMonth 1: ', returnedValue)
    }
  }
  // console.log('returnedMonth 2: ', returnedValue)
  return returnedValue
}
