import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import Switch from 'react-switch';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import EditProject from './EditProject';
import SubSubmissions from '../Common/Submissions';
import SubComments from '../Common/Comments';
import SubConfigureProfileMatch from '../Common/ConfigureProfileMatch';
import SubBenchmarkDetails from '../Common/BenchmarkDetails';
import SubReferSomeone from '../Common/ReferSomeone';
import SubRenderOpportunityDetails from '../Common/RenderOpportunityDetails';
import SubAssistantWidget from '../Common/AssistantWidget';
import {convertDateToString} from '../Functions/ConvertDateToString';
import {signUp, signIn, signOut} from '../Services/AuthRoutes';
import withRouter from '../Functions/WithRouter';
import {subFavoriteItem} from '../Functions/FavoriteItem';

const favoritesIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-grey.png';
const favoritesIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-blue.png';
const gcLogo = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/full-guided-compass-logo.png';
const industryIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/industry-icon-dark.png";
const tagIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/tag-icon.png";
const skillsIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/skills-icon.png";
// const deadlineIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/deadline-icon.png";
// const prizeIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/prize-icon.png";
const profileIconBig = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-big.png';
const locationIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/location-icon.png';
const timeIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/time-icon-dark.png';
// const infoIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/info-icon.png';
// const infoIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/info-icon-dark.png';
const linkIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/link-icon.png';
const upvoteIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/upvote-icon-blue.png';
const upvoteIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/upvote-icon-grey.png';
const sendIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/send-icon.png';
const xIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/x-icon.png';
const timeRangeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/time-range-icon.png';
const calendarIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/calendar-icon-dark.png';
const subsidyIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/subsidy-icon-dark.png';
const moneyIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/money-icon-dark.png';
const checkmarkIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon-white.png';
const benchmarksIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/benchmarks-icon-dark.png';
const employersIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/employer-icon.png';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const opportunitiesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/opportunities-icon-dark.png';
const resumeIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/resume-icon-dark.png';
const referIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/refer-icon-dark.png';
const modalIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/modal-icon-dark.png';
const websiteIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/website-icon-dark.png';
const projectsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/projects-icon-dark.png';
const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';

class OpportunityDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
          prefix: '/app',
          selectedProject: {},

          showReferralText: true,
          disableSubmit: false,

          referralBonus: null,

          favorites: [],
          newFavorites: [],
          showSubFunction: true,

          collaborators: [],
          viewIndex: 0,
          subviewIndex: 0,
          selectedIndex: 0,

          myComment: '',
          comments: [],
          myReplies: [],
          mySubmissionComments: [],
          submissionComments: [],
          summarySplit: [],

          questionIndex: 0,
          survey: [],
          responses: [],
          employers: [],

          projectCategoryOptions: [],
          dateOptions: [],
          collaboratorOptions: [],
          functionOptions: [],
          industryOptions: [],
          projectOptions: [{}],
          gradeOptions: [],
          relationshipOptions: [],
          // roleNameOptions: ['Participant','Educator','Work-Based Learning Coordinator','Mentor','Other'],
          roleNameOptions: ['Student / Career Seeker','Teacher','Work-Based Learning Coordinator','Counselor','Employer Representative / Mentor','Other'],
          gradYearOptions: [],
          degreeOptions: [],
          studentActivityOptions: [],
          teacherActivityOptions: [],
          mentorActivityOptions: [],
          educationStatusOptions: [],

          startDate: '',
          endDate: '',

          editComments: [],
          editSubmissionComments: [],

          youTubeId: '92mBt-NFx50'

        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formatDate = this.formatDate.bind(this)
        this.favoriteItem = this.favoriteItem.bind(this)
        this.voteOnItem = this.voteOnItem.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.renderDetails = this.renderDetails.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.renderSkills = this.renderSkills.bind(this)
        this.renderInterests = this.renderInterests.bind(this)
        this.renderTraits = this.renderTraits.bind(this)
        this.renderTracks = this.renderTracks.bind(this)
        this.finishedSubmitting = this.finishedSubmitting.bind(this)
        this.itemClicked = this.itemClicked.bind(this)
        this.renderCollaborators = this.renderCollaborators.bind(this)
        this.removeItem = this.removeItem.bind(this)
        this.searchEmployers = this.searchEmployers.bind(this)
        this.employerClicked = this.employerClicked.bind(this)
        this.renderQuestions = this.renderQuestions.bind(this)
        this.optionClicked = this.optionClicked.bind(this)
        this.authenticate = this.authenticate.bind(this)
        this.populateInfo = this.populateInfo.bind(this)
        this.logout = this.logout.bind(this)
        this.renderSubmitProject = this.renderSubmitProject.bind(this)
        this.renderRSVPForm = this.renderRSVPForm.bind(this)
        this.pullCourses = this.pullCourses.bind(this)
        this.commandClicked = this.commandClicked.bind(this)
        this.checkWhetherDatePassed = this.checkWhetherDatePassed.bind(this)

    }

    static defaultProps = { selectedOpportunity: null, postings: [] }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const selectedOpportunity = this.props.selectedOpportunity
      this.retrieveData(selectedOpportunity)
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in SubOpportunityDetails ', this.props, prevProps)

      if (!prevProps.selectedOpportunity) {
        console.log('t1')
        if (this.props.selectedOpportunity) {
          const selectedOpportunity = this.props.selectedOpportunity
          this.setState({ selectedOpportunity })
          this.retrieveData(selectedOpportunity)
        }
      } else if (prevProps.opportunityId !== this.props.opportunityId) {
        console.log('t2')
        const opportunityId = this.props.params.opportunityId

        Axios.get('/api/postings/byid', { params: { _id: opportunityId } })
        .then((response) => {
           console.log('Posting detail by id query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved posting')

             const selectedOpportunity = response.data.posting
             this.setState({ selectedOpportunity })
             this.retrieveData(selectedOpportunity)
           }
        }).catch((error) => {
            console.log('Posting query did not work', error);
        });

      } else if (this.props.selectedOpportunity) {
        console.log('t3')
        if ( this.props.selectedOpportunity.name !== prevProps.selectedOpportunity.name ||  this.props.selectedOpportunity.title !== prevProps.selectedOpportunity.title) {
          console.log('t4')
          const selectedOpportunity = this.props.selectedOpportunity
          this.setState({ selectedOpportunity })
          this.retrieveData(selectedOpportunity)

        } else if (this.props.path !== prevProps.path) {
          console.log('t5')
          const selectedOpportunity = this.props.selectedOpportunity
          this.setState({ selectedOpportunity })
          this.retrieveData(selectedOpportunity)
        } else if (this.props.activeOrg !== prevProps.activeOrg) {
          console.log('t6')
          const selectedOpportunity = this.props.selectedOpportunity
          this.setState({ selectedOpportunity })
          this.retrieveData(selectedOpportunity)
        }
      } else {

      }
    }

    retrieveData(selectedOpportunity) {
      console.log('retrieveData called in SubOpportunityDetails', this.props)

      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let roleName = localStorage.getItem('roleName');
      let workMode = localStorage.getItem('workMode');
      let remoteAuth = localStorage.getItem('remoteAuth');

      if (workMode === 'true') {
        workMode = true
      } else {
        workMode = false
      }

      let activeOrg = this.props.activeOrg
      // 62e887486085208eaee1d4e3
      // const path = window.location.pathname
      const postings = this.props.postings

      if (selectedOpportunity) {

        // if (!activeOrg || activeOrg === '') {
        //   activeOrg = this.props.activeOrg
        // }

        const pageSource = this.props.pageSource

        let importedEmailId = false
        if (email) {
          importedEmailId = true
        }

        const referralCode = this.props.referralCode

        let prefix = '/app'
        if (window.location.pathname.includes('/advisor')) {
          prefix = '/advisor'
        }

        let opportunityCommands = []
        if (selectedOpportunity.postType === 'Work') {
          opportunityCommands.push('Create a resume based on my profile for ' + selectedOpportunity.title + ' @ ' + selectedOpportunity.employerName + '. {{profile}}')
          opportunityCommands.push('Create a cover letter based on my profile for ' + selectedOpportunity.title + ' @ ' + selectedOpportunity.employerName + '. {{profile}}')
          opportunityCommands.push('List 5 interview questions that would likely be asked in the first round for ' + selectedOpportunity.title + ' @ ' + selectedOpportunity.employerName)
          opportunityCommands.push('What are 3 example projects that I can work on to impress a hiring manager to hire me as a ' + selectedOpportunity.title + ' @ ' + selectedOpportunity.employerName)
          opportunityCommands.push('List 3 example projects that my supervisor may give me in first weeks of being a ' + selectedOpportunity.title + ' @ ' + selectedOpportunity.employerName)
          opportunityCommands.push('What are some strategies and tactics to reach my goal of being a ' + selectedOpportunity.title + ' @ ' + selectedOpportunity.employerName)
          opportunityCommands.push('How much pay can I expect, on average, for the next 1, 3, and 5 years as a ' + selectedOpportunity.title + ' @ ' + selectedOpportunity.employerName)
          opportunityCommands.push('What are the top competencies I need to be successful as a ' + selectedOpportunity.title + ' @ ' + selectedOpportunity.employerName)
          opportunityCommands.push('What are some indications that I would be a good fit for ' + selectedOpportunity.title + ' @ ' + selectedOpportunity.employerName)
        }

        this.setState({ emailId: email, importedEmailId, username, cuFirstName, cuLastName,
          firstName: cuFirstName, lastName: cuLastName,
          roleName, activeOrg, selectedOpportunity, postings, workMode, pageSource, remoteAuth,
          referralCode, prefix, opportunityCommands
        })

        if (window.innerWidth > 768) {
        } else {

          this.setState({ onMobile: true })
        }

        let dateOfBirth = null
        let gender = null
        let race = null
        let races = null
        let selfDescribedRace = null
        let address = null
        let zipcode = null
        let numberOfMembers = null
        let householdIncome = null
        let workAuthorization = null
        let adversityList = null
        let educationStatus = null

        let phoneNumber = null
        let alternativePhoneNumber = null
        let alternativeEmail = null
        let referrerName = null
        let referrerEmail = null
        let referrerOrg = null
        let schoolName = null
        let degree = null
        let gradDate = null
        let gradYear = null
        let major = null

        Axios.get('/api/users/profile/details', { params: { email } })
        .then((response) => {
          console.log('Profile query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved profile information')

             let pictureURL = response.data.user.pictureURL
             const jobTitle = response.data.user.jobTitle
             const employerName = response.data.user.employerName
             phoneNumber = response.data.user.phoneNumber
             const education = response.data.user.education
             const subscribedForReminders = response.data.user.subscribedForReminders
             const linkedInURL = response.data.user.linkedInURL

             // let schoolName = null
             // let gradYear = null
             // let degree = null
             // let major = null
             if (response.data.user.education && response.data.user.education.length > 0) {
               // schoolName = response.data.user.education[0].name
               // gradDate = response.data.user.education[0].endDate
               // if (response.data.user.education[0].endDate && response.data.user.education[0].endDate.split("|")) {
               //   gradYear = response.data.user.education[0].endDate.split(" ")[1]
               // }
               // degree = response.data.user.education[0].degree
               // major = response.data.user.education[0].major
               // // console.log('show me gradYeaar: ', gradYear, major, response.data.user.education)
               // if (response.data.user.education.length > 1) {
               //   for (let i = 1; i <= response.data.user.education.length; i++) {
               //     if (response.data.user.education[i - 1] && this.state.education[i - 1].isContinual) {
               //       schoolName = response.data.user.education[i - 1].name
               //       gradDate = response.data.user.education[i - 1].endDate
               //       if (response.data.user.education[i - 1].endDate && response.data.user.education[i - 1].endDate.split("|")) {
               //         gradYear = response.data.user.education[i - 1].endDate.split(" ")[1]
               //       }
               //       degree = response.data.user.education[i - 1].degree
               //       major = response.data.user.education[i - 1].major
               //     }
               //   }
               // }

               for (let i = 1; i <= response.data.user.education.length; i++) {
                 let edObject = response.data.user.education[i - 1]
                 if (edObject) {
                   let tempGradYear = null
                   if (edObject.endDate && edObject.endDate.split("|")) {
                     tempGradYear = edObject.endDate.split(" ")[1]
                   }

                   if (edObject.isContinual) {
                     schoolName = edObject.name
                     gradDate = edObject.endDate
                     gradYear = tempGradYear
                     degree = edObject.degree
                     major = edObject.major
                     break;
                   } else if ((tempGradYear && gradYear && tempGradYear > gradYear) || (tempGradYear && !gradYear)) {
                     schoolName = edObject.name
                     gradDate = edObject.endDate
                     gradYear = tempGradYear
                     degree = edObject.degree
                     major = edObject.major
                   }
                 }
               }
             }

             // console.log('gimmmmme gooods', schoolName, gradDate, gradYear, degree, major, education)

             dateOfBirth = response.data.user.dateOfBirth
             gender = response.data.user.gender
             race = response.data.user.race
             races = response.data.user.races
             selfDescribedRace = response.data.user.selfDescribedRace
             address = response.data.user.address
             zipcode = response.data.user.zipcode
             numberOfMembers = response.data.user.numberOfMembers
             householdIncome = response.data.user.householdIncome
             workAuthorization = response.data.user.workAuthorization
             adversityList = response.data.user.adversityList
             educationStatus = response.data.user.educationStatus

             alternativePhoneNumber = response.data.user.alternativePhoneNumber
             alternativeEmail = response.data.user.alternativeEmail
             referrerName = response.data.user.referrerName
             referrerEmail = response.data.user.referrerEmail
             referrerOrg = response.data.user.referrerOrg

             this.setState({ pictureURL, jobTitle, employerName, phoneNumber, education, linkedInURL,
               subscribedForReminders, schoolName, gradDate, gradYear, degree, major, dateOfBirth,
               gender, race,
               races, selfDescribedRace, address, zipcode, numberOfMembers, householdIncome,
               workAuthorization, adversityList, educationStatus,
               alternativePhoneNumber, alternativeEmail, referrerName, referrerEmail, referrerOrg
             })

           } else {
             console.log('no profile data found', response.data.message)
           }

        }).catch((error) => {
           console.log('Profile query did not work', error);
        });

        Axios.get('/api/projects', { params: { emailId: email, includeCollaborations: true } })
        .then((response) => {
          console.log('Projects query attempted', response.data);

          if (response.data.success && response.data.projects) {
            console.log('successfully retrieved projects')

            const projects = response.data.projects
            this.setState({ projects })

          } else {
            console.log('no project data found', response.data.message)
          }

        }).catch((error) => {
            console.log('Project query did not work', error);
        });

        Axios.get('/api/experience', { params: { emailId: email } })
        .then((response) => {
          console.log('Experience query attempted', response.data);

            if (response.data.success && response.data.experience) {
              console.log('successfully retrieved experience')

              const experience = response.data.experience
              this.setState({ experience})

            } else {
              console.log('no experience data found', response.data.message)
            }

        }).catch((error) => {
            console.log('Experience query did not work', error);
        });

        Axios.get('/api/favorites', { params: { emailId: email } })
        .then((response) => {
          console.log('Favorites query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved favorites')

             let favorites = []
             if (response.data.favorites) {
               favorites = response.data.favorites
             }

             let newFavorites = []
             if (response.data.newFavorites) {
               newFavorites = response.data.newFavorites
             }

             this.setState({ favorites, newFavorites })

           } else {
             console.log('no favorites data found', response.data.message)
           }

        }).catch((error) => {
           console.log('Favorites query did not work', error);
        });

        Axios.get('/api/workoptions')
        .then((response) => {
          console.log('Work options query tried', response.data);

          if (response.data.success) {
            console.log('Work options query succeeded')

            let functionOptions = [{value: 'I am not sure'}]
            let functionOptionsRaw = []
            for (let i = 1; i <= response.data.workOptions[0].functionOptions.length; i++) {
              if (i > 1) {
                functionOptions.push({ value: response.data.workOptions[0].functionOptions[i - 1]})
                functionOptionsRaw.push(response.data.workOptions[0].functionOptions[i - 1])
              }
            }

            let industryOptions = [{value: 'I am not sure'}]
            let industryOptionsRaw = []
            for (let i = 1; i <= response.data.workOptions[0].industryOptions.length; i++) {
              if (i > 1) {
                industryOptions.push({ value: response.data.workOptions[0].industryOptions[i - 1]})
                industryOptionsRaw.push(response.data.workOptions[0].industryOptions[i - 1])
              }
            }

            let workDistanceOptions = [{value: '0 miles'},{value: '10 miles'}]
            for (let i = 1; i <= response.data.workOptions[0].workDistanceOptions.length; i++) {
              if (i > 1) {
                workDistanceOptions.push({ value: response.data.workOptions[0].workDistanceOptions[i - 1]})
              }
            }

            let hoursPerWeekOptions = [{value: '~ 40 hours / week'}]
            for (let i = 1; i <= response.data.workOptions[0].hoursPerWeekOptions.length; i++) {
              if (i > 1) {
                hoursPerWeekOptions.push({ value: response.data.workOptions[0].hoursPerWeekOptions[i - 1]})
              }
            }

            let workTypeOptions = [{value: 'Internship'}]
            for (let i = 1; i <= response.data.workOptions[0].workTypeOptions.length; i++) {
              if (i > 1) {
                workTypeOptions.push({ value: response.data.workOptions[0].workTypeOptions[i - 1]})
              }
            }

            let hourlyPayOptions = [{value: 'Flexible'}]
            for (let i = 1; i <= response.data.workOptions[0].hourlyPayOptions.length; i++) {
              if (i > 1) {
                hourlyPayOptions.push({ value: response.data.workOptions[0].hourlyPayOptions[i - 1]})
              }
            }

            let annualPayOptions = [{value: 'I am not sure'}]
            for (let i = 1; i <= response.data.workOptions[0].annualPayOptions.length; i++) {
              if (i > 1) {
                annualPayOptions.push({ value: response.data.workOptions[0].annualPayOptions[i - 1]})
              }
            }

            let projectCategoryOptions = [{value: 'I am not sure'}]
            for (let i = 1; i <= response.data.workOptions[0].projectCategoryOptions.length; i++) {
              if (i > 1) {
                projectCategoryOptions.push({ value: response.data.workOptions[0].projectCategoryOptions[i - 1]})
              }
            }

            //strongarming this for now
            const gradeOptions = ['','A+','A','A-','B+','B','B-','C+','C','C-','D+','D','D-','F']
            const relationshipOptions = [''].concat(response.data.workOptions[0].relationshipOptions)

            const collaboratorOptions = [{value: '0'},{value: '1'},{value: '2'}, {value: '3'}, {value: '4'}, {value: '5'}]
            const hourOptions = [{value: ''}, {value: '< 10'},{value: '10 - 50'}, {value: '50 - 100'}, {value: '100 - 500'}, {value: '500 - 1000'}, {value: '1000 - 5000'}, {value: '5000 - 10000'}, {value: '10000+'}]

            let dateOptions = []

            const currentMonth = new Date().getMonth()
            const currentYear = new Date().getFullYear()

            let month = ''
            let year = currentYear - 10

            console.log('show me current stuff', currentMonth, currentYear)
            for (let i = 1; i <= 120 + currentMonth; i++) {
              console.log('show me stuff', i)
              if (i % 12 === 0) {
                month = 'January'
              } else if (i % 12 === 1) {
                month = 'February'
              } else if (i % 12 === 2) {
                month = 'March'
              } else if (i % 12 === 3) {
                month = 'April'
              } else if (i % 12 === 4) {
                month = 'May'
              } else if (i % 12 === 5) {
                month = 'June'
              } else if (i % 12 === 6) {
                month = 'July'
              } else if (i % 12 === 7) {
                month = 'August'
              } else if (i % 12 === 8) {
                month = 'September'
              } else if (i % 12 === 9) {
                month = 'October'
              } else if (i % 12 === 10) {
                month = 'November'
              } else if (i % 12 === 11) {
                month = 'December'
              }

              if (i > 12 && i <= 24) {
                if (i % 12 === 0) {
                  year = currentYear - 10 + 1 + 1
                } else {
                  year = currentYear - 10 + 1
                }
              } else if (i > 24 && i <= 36) {
                if (i % 12 === 0) {
                  year = currentYear - 10 + 2 + 1
                } else {
                  year = currentYear - 10 + 2
                }
              } else if (i > 36 && i <= 48) {
                if (i % 12 === 0) {
                  year = currentYear - 10 + 3 + 1
                } else {
                  year = currentYear - 10 + 3
                }
              } else if (i > 48 && i <= 60) {
                if (i % 12 === 0) {
                  year = currentYear - 10 + 4 + 1
                } else {
                  year = currentYear - 10 + 4
                }
              } else if (i > 60 && i <= 72) {
                if (i % 12 === 0) {
                  year = currentYear - 10 + 5 + 1
                } else {
                  year = currentYear - 10 + 5
                }
              } else if (i > 72 && i <= 84) {
                if (i % 12 === 0) {
                  year = currentYear - 10 + 6 + 1
                } else {
                  year = currentYear - 10 + 6
                }
              } else if (i > 84 && i <= 96) {
                if (i % 12 === 0) {
                  year = currentYear - 10 + 7 + 1
                } else {
                  year = currentYear - 10 + 7
                }
              } else if (i > 96 && i <= 108) {
                if (i % 12 === 0) {
                  year = currentYear - 10 + 8 + 1
                } else {
                  year = currentYear - 10 + 8
                }
              } else if (i > 108 && i <= 120) {
                if (i % 12 === 0) {
                  year = currentYear - 10 + 9 + 1
                } else {
                  year = currentYear - 10 + 9
                }
              }
              dateOptions.push({ value: month + ' ' + year})
            }

            // for events
            let gradYearOptions = ['']
            const startingPoint = new Date().getFullYear() - 25
            for (let i = 1; i <= 32; i++) {
              gradYearOptions.push(startingPoint + i)
            }
            const degreeOptions = response.data.workOptions[0].degreeOptions
            const educationStatusOptions = degreeOptions.concat(['Not currently enrolled in school'])

            let studentActivityOptions = response.data.workOptions[0].studentActivityOptions
            let teacherActivityOptions = response.data.workOptions[0].teacherActivityOptions
            let mentorActivityOptions = response.data.workOptions[0].mentorActivityOptions

            this.setState({ functionOptions, industryOptions, functionOptionsRaw, industryOptionsRaw,
              workDistanceOptions, hoursPerWeekOptions, workTypeOptions, hourlyPayOptions, payOptions: annualPayOptions,
              projectCategoryOptions, dateOptions, collaboratorOptions, hourOptions, gradeOptions, relationshipOptions,
              gradYearOptions, degreeOptions, educationStatusOptions,
              studentActivityOptions, teacherActivityOptions, mentorActivityOptions
            })

          } else {
            console.log('no jobFamilies data found', response.data.message)

          }
        }).catch((error) => {
            console.log('query for work options did not work', error);
        })

        if (selectedOpportunity.orgCode) {

          Axios.get('/api/org', { params: { orgCode: selectedOpportunity.orgCode } })
          .then((response) => {
            console.log('Org info query attempted', response.data);

              if (response.data.success) {
                console.log('org info query worked for post')

                const postingOrgCode = response.data.orgInfo.orgCode
                const postingOrgName = response.data.orgInfo.orgName
                const postingOrgContactEmail = response.data.orgInfo.contactEmail
                const orgContactEmail = response.data.orgInfo.contactEmail

                const orgName = response.data.orgInfo.orgName
                const webLogoURI = response.data.orgInfo.webLogoURI
                const orgLogo = response.data.orgInfo.webLogoURIColor
                const headerImageURL = response.data.orgInfo.headerImageURL
                const partnerLandingPage = response.data.orgInfo.partnerLandingPage
                const disableChatbotPrompts = response.data.orgInfo.disableChatbotPrompts

                // console.log('show hiu: ', this.props.passedTypes)
                this.setState({ postingOrgCode, postingOrgName, postingOrgContactEmail,
                  orgContactEmail, orgName, webLogoURI, orgLogo, headerImageURL, partnerLandingPage,
                  disableChatbotPrompts
                });

                let postType = null
                let postTypes = []
                if (this.props.passedTypes) {
                  postTypes = this.props.passedTypes
                }
                const placementPartners = response.data.orgInfo.placementPartners
                let accountCode = this.props.accountCode
                const recent = true
                const active = true
                const excludePastEvents = true
                const pullPartnerPosts = true
                let targetRole = this.props.targetRole
                let subPostType = this.props.passedSubPostType
                const projectPromptType = null
                const pathway = null
                const benchmarkId = selectedOpportunity.benchmarkId
                const excludeIds = [selectedOpportunity._id]
                const resLimit = 4

                //only schools see this screen
                Axios.get('/api/postings/user', { params: { orgCode: selectedOpportunity.orgCode, placementPartners,
                  postType, postTypes, projectPromptType, pathway, accountCode, recent, active, excludePastEvents,
                  pullPartnerPosts, csWorkflow: true,
                  roleName, targetRole, subPostType, benchmarkId, excludeIds, resLimit
                }})
                .then((response) => {
                  console.log('Posted postings query attempted within subcomponent', response.data);

                  if (response.data.success) {
                    console.log('posted postings query worked')

                    if (response.data.postings.length !== 0) {

                      // let useFunction = true

                      const relatedOpportunities = response.data.postings
                      this.setState({ relatedOpportunities })
                    }

                  } else {
                    console.log('posted postings query did not work', response.data.message)
                  }

                }).catch((error) => {
                    console.log('Posted postings query did not work for some reason', error);
                });

              } else {
                console.log('org info query did not work', response.data.message)
              }

          }).catch((error) => {
              console.log('Org info query did not work for some reason', error);
          });

          // Axios.get('/api/org', { params: { orgCode: activeOrg } })
          // .then((response) => {
          //   console.log('Org info query attempted', response.data);
          //
          //     if (response.data.success) {
          //       console.log('org info query worked')
          //
          //       const orgName = response.data.orgInfo.orgName
          //       const webLogoURI = response.data.orgInfo.webLogoURI
          //       const orgLogo = response.data.orgInfo.webLogoURIColor
          //
          //       this.setState({ orgName, webLogoURI, orgLogo });
          //
          //     } else {
          //       console.log('org info query did not work', response.data.message)
          //     }
          //
          // }).catch((error) => {
          //     console.log('Org info query did not work for some reason', error);
          // });
        }

        if (selectedOpportunity.postType === 'Individual' || selectedOpportunity.postType === 'Track' || selectedOpportunity.postType === 'Internship' || selectedOpportunity.postType === 'Scholarship' || selectedOpportunity.postType === 'Work' || selectedOpportunity.postType === 'Program') {

          let hasApplied = false
          if (selectedOpportunity.applicants) {
            if (selectedOpportunity.applicants.includes(email)) {
              hasApplied = true
            }
          }

          let startDateTitle = convertDateToString(selectedOpportunity.startDate,"date")
          if (selectedOpportunity.isPerpetual) {
            startDateTitle = startDateTitle + " - Continual"
          } else {
            startDateTitle = startDateTitle + " - " + convertDateToString(selectedOpportunity.endDate,"date")
          }

          let oppStats = [
            { icon: industryIconDark, title: selectedOpportunity.employerName, url: selectedOpportunity.employerURL, dataTip: 'Employer Name' },
            { icon: locationIcon, title: selectedOpportunity.location, dataTip: 'Employer Location' },
            { icon: calendarIconDark, title: 'Posted '+ convertDateToString(selectedOpportunity.createdAt,'date'), dataTip: 'Date Opportunity Was Posted' },
            { icon: timeRangeIcon, title: startDateTitle, dataTip: 'Work Time Range' },
            { icon: timeIconDark, title: selectedOpportunity.hoursPerWeek + " Per Week", dataTip: 'Hours per Week' }
          ]

          if (selectedOpportunity.field && selectedOpportunity.field.split("|") && selectedOpportunity.field.split("|")[0]) {
            oppStats.push({ icon: skillsIcon, title: selectedOpportunity.field.split("|")[0].trim(), dataTip: 'Work Function' })
          }

          if (selectedOpportunity.industry && selectedOpportunity.industry.split("|") && selectedOpportunity.field.split("|")[1]) {
            oppStats.push({ icon: industryIconDark, title: selectedOpportunity.field.split("|")[1].trim(), dataTip: 'Industry' })
          }

          if (selectedOpportunity.isSubsidized || selectedOpportunity.prioritizeSubsidized) {
            oppStats.push({ icon: subsidyIconDark, title: "This role is subsidized", subtitle: "***Only people 16 - 24 within Los Angeles county limits are eligible to receive subsidized internships***", dataTip: "Employer won't pay you directly" })
          }

          if (selectedOpportunity.workType) {
            oppStats.push({ icon: skillsIcon, title: selectedOpportunity.workType, dataTip: "Work Type" })
          }

          if (selectedOpportunity.payRange && selectedOpportunity.payRange !== '') {
            oppStats.push({ icon: moneyIconDark, title: selectedOpportunity.payRange, dataTip: "Pay Range" })
          }

          if (selectedOpportunity.supplementalPayArray && selectedOpportunity.supplementalPayArray.length > 0) {
            oppStats.push({ icon: tagIcon, title: selectedOpportunity.supplementalPayArray.toString(), dataTip: "Supplemental Pay" })
          }

          if (selectedOpportunity.benefits && selectedOpportunity.benefits.length > 0) {
            oppStats.push({ icon: tagIcon, title: selectedOpportunity.benefits.toString(), dataTip: "Benefits" })
          }

          this.setState({ selectedOpportunity, hasApplied, oppStats})

          if (selectedOpportunity.postType === 'Scholarship') {

            Axios.get('/api/applications', { params: { emailId: email } })
            .then((response) => {
              console.log('Applications query attempted 1', response.data);

                if (response.data.success) {
                  console.log('successfully retrieved applications')

                  if (response.data.applications.length > 0) {
                    console.log('the array is greater than 0')
                    for (let i = 1; i <= response.data.applications.length; i++) {
                      console.log(i, 'looping through applications', response.data.applications[i - 1]);

                      if (response.data.applications[i - 1].postingId === selectedOpportunity._id) {

                        const application = response.data.applications[i - 1]
                        let resumeURL = ''
                        console.log('application: ', application)
                        if (application.resumeURL) {

                          resumeURL = application.resumeURL
                        }
                        this.setState({ application, resumeURL })
                      }
                    }
                  }

                } else {
                  console.log('no applications data found', response.data.message)
                }

            }).catch((error) => {
                console.log('Applications query did not work', error);
            });
          } else {

            console.log('this is not a scholarship')

            Axios.get('/api/benchmarks/byid', { params: { _id: selectedOpportunity.benchmarkId } })
            .then((response) => {
               console.log('Benchmark query attempted', response.data);

               if (response.data.success) {
                 console.log('successfully retrieved benchmark')

                 const benchmark = response.data.benchmark
                 this.setState({ benchmark })

                 let searchTerm = selectedOpportunity.title
                 if (benchmark.knowledge && benchmark.knowledge.length) {
                   searchTerm = benchmark.knowledge[0].title
                 } else if (benchmark.skills && benchmark.skills.length) {
                   searchTerm = benchmark.skills[0].title
                 } else if (selectedOpportunity.name) {
                   searchTerm = selectedOpportunity.name
                 }
                 this.pullCourses(searchTerm, null, null, null)

                 let isApproved = false


                 if (selectedOpportunity.postType !== 'Track') {
                   console.log('testing 0', selectedOpportunity)
                   let parentPostId = ''
                   if (selectedOpportunity.orgName === 'Bixel Exchange' || selectedOpportunity.orgCode === 'bixel') {
                     parentPostId = '5c703cc7c29ab2400a036875'
                     console.log('testing 1')
                   } else if (selectedOpportunity.orgCode === 'unite-la') {
                     parentPostId = '5ee171c929bc432630ac7a95'
                   } else if (selectedOpportunity.orgName === 'LA Promise Fund') {
                     parentPostId = ''
                   }

                   console.log('testing 2', parentPostId)
                   if (selectedOpportunity.isChild) {
                     Axios.get('/api/postings/byid', { params: { _id: parentPostId } })
                     .then((response) => {
                        console.log('Posting detail by id query attempted', response.data);

                        if (response.data.success && !selectedOpportunity.isPromotional) {
                          console.log('successfully retrieved parent posting')

                          const parentPost = response.data.posting
                          this.setState({ parentPost })

                          let parentIndex = 0
                          for (let i = 1; i <= parentPost.tracks.length; i++) {
                            console.log('testing this: ', parentPost.tracks[i - 1].benchmark.jobFunction, benchmark.jobFunction)
                            if (parentPost.tracks[i - 1].benchmark.jobFunction === benchmark.jobFunction) {
                              parentIndex = i - 1
                            }
                          }
                          console.log('testing 30', parentPost.tracks[parentIndex])
                          if (parentPost.tracks[parentIndex].approvedApplicants) {
                            console.log('testing 4 ', email, parentPost.tracks[parentIndex])
                            if (parentPost.tracks[parentIndex].approvedApplicants.includes(email)) {
                              console.log('testing 5')
                              isApproved = true
                              this.setState({ isApproved, parentIndex })

                              Axios.get('/api/applications', { params: { emailId: email } })
                              .then((response) => {
                                console.log('Applications query attempted 2', response.data);

                                  if (response.data.success) {
                                    console.log('successfully retrieved applications')

                                    if (response.data.applications.length > 0) {
                                      console.log('the array is greater than 0')
                                      for (let i = 1; i <= response.data.applications.length; i++) {
                                        console.log(i, 'looping through applications', response.data.applications[i - 1], parentPost);

                                        if (response.data.applications[i - 1].postingId === parentPost._id) {
                                          const application = response.data.applications[i - 1]
                                          let resumeURL = ''
                                          console.log('application: ', application)
                                          if (application.resumeURL) {

                                            resumeURL = application.resumeURL
                                          }
                                          this.setState({ application, resumeURL })
                                        }
                                        // if (response.data.applications[i - 1].postingId === postingId) {
                                        //
                                        //   const application = response.data.applications[i - 1]
                                        //   let resumeURL = ''
                                        //   console.log('application: ', application)
                                        //   if (application.resumeURL) {
                                        //
                                        //     resumeURL = application.resumeURL
                                        //   }
                                        //   this.setState({ application, resumeURL })
                                        // }
                                      }
                                    }

                                  } else {
                                    console.log('no applications data found', response.data.message)
                                  }

                              }).catch((error) => {
                                  console.log('Applications query did not work', error);
                              });
                            }
                          }
                        } else {
                          console.log('no benchmark data found', response.data.message)

                          //this is the parentPost
                          Axios.get('/api/applications', { params: { emailId: email } })
                          .then((response) => {
                            console.log('Applications query attempted 3', response.data);

                              if (response.data.success) {
                                console.log('successfully retrieved applications',response.data.applications.length)

                                if (response.data.applications.length > 0) {
                                  console.log('the array is greater than 0')
                                  for (let i = 1; i <= response.data.applications.length; i++) {

                                    if (response.data.applications[i - 1].postingId === selectedOpportunity._id) {
                                      const application = response.data.applications[i - 1]
                                      console.log('application: ', application)
                                      if (application) {

                                        const resumeURL = application.resumeURL
                                        this.setState({ application, resumeURL })
                                      }
                                    }
                                  }
                                }

                              } else {
                                console.log('no applications data found', response.data.message)
                              }

                          }).catch((error) => {
                              console.log('Applications query did not work', error);
                          });
                        }

                     }).catch((error) => {
                          console.log('Benchmark query did not work', error);
                     });
                   } else {
                     //this is the parentPost
                     Axios.get('/api/applications', { params: { emailId: email } })
                     .then((response) => {
                       console.log('Applications query attempted 3', response.data);

                         if (response.data.success) {
                           console.log('successfully retrieved applications',response.data.applications.length)

                           if (response.data.applications.length > 0) {
                             console.log('the array is greater than 0')
                             for (let i = 1; i <= response.data.applications.length; i++) {
                               console.log('compare the two: ', i, response.data.applications[i - 1].postingId, selectedOpportunity._id)
                               if (response.data.applications[i - 1].postingId === selectedOpportunity._id) {
                                 const application = response.data.applications[i - 1]
                                 console.log('application: ', application)
                                 if (application) {

                                   const resumeURL = application.resumeURL
                                   this.setState({ application, resumeURL })
                                 }
                               }
                             }
                           }

                         } else {
                           console.log('no applications data found', response.data.message)
                         }

                     }).catch((error) => {
                         console.log('Applications query did not work', error);
                     });
                   }
                 }
               } else {
                 console.log('no benchmark data found: ', response.data.message)

                 //this is the parentPost
                 Axios.get('/api/applications', { params: { emailId: email } })
                 .then((response) => {
                   console.log('Applications query attempted 4', response.data);

                     if (response.data.success) {
                       console.log('successfully retrieved applications 1', response.data.applications.length)

                       if (response.data.applications.length > 0) {
                         console.log('the array is greater than 0')
                         for (let i = 1; i <= response.data.applications.length; i++) {

                           if (response.data.applications[i - 1].postingId === selectedOpportunity._id) {
                             console.log('test application: ', response.data.applications[i - 1])

                             const application = response.data.applications[i - 1]
                             console.log('application: ', application)
                             if (application.resumeURL) {
                               const resumeURL = application.resumeURL
                               this.setState({ application, resumeURL })
                             }
                           }
                         }
                       }

                     } else {
                       console.log('no applications data found', response.data.message)
                     }

                 }).catch((error) => {
                     console.log('Applications query did not work', error);
                 });
               }

            }).catch((error) => {
                 console.log('Benchmark query did not work', error);
            });
          }

        } else if (selectedOpportunity.postType === 'Event') {

          let roleNameLinked = null
          if (this.props.roleName) {
            roleName = this.props.roleName
            if (roleName.toLowerCase() === 'student' || roleName.toLowerCase() === 'career-seeker') {
              roleName = 'Student / Career Seeker'
              roleNameLinked = roleName
            } else if (roleName.toLowerCase() === 'volunteer' || roleName.toLowerCase() === 'mentor' || roleName.toLowerCase() === 'employer') {
              roleName = 'Employer Representative / Mentor'
              roleNameLinked = roleName
            }
          } else {
            if (roleName === 'Student') {
              roleName = "Student / Career Seeker"
            }
          }

          let gradYearOptions = ['']
          const startingPoint = new Date().getFullYear() - 6
          for (let i = 1; i <= 10; i++) {
            gradYearOptions.push(startingPoint + i)
          }

          let startDateString = null
          let endDateString = null
          let eventPassed = false

          startDateString = convertDateToString(new Date(selectedOpportunity.startDate),'datetime-2')
          endDateString = convertDateToString(new Date(selectedOpportunity.endDate),'datetime-2')

          let dateToTest = null
          if (selectedOpportunity.endDate && new Date(selectedOpportunity.endDate)) {
            dateToTest = selectedOpportunity.endDate
          } else if (selectedOpportunity.startDate && new Date(selectedOpportunity.startDate)) {
            dateToTest = selectedOpportunity.startDate
          }

          if (dateToTest) {
            if (new Date().getTime() > new Date(dateToTest).getTime()) {
              eventPassed = true
            }
          }

          this.setState({ startDateString, endDateString, eventPassed, roleName, roleNameLinked, gradYearOptions })

          if (this.props.pageSource === 'landingPage') {
            Axios.get('/api/emails', { params: { orgCode: activeOrg } })
            .then((response) => {
              console.log('Emails query attempted', response.data);

                if (response.data.success) {
                  console.log('emails query worked')

                  const studentBenefits = response.data.benefits[0].studentBenefits
                  const careerSeekerBenefits = response.data.benefits[0].studentBenefits
                  const teacherBenefits = response.data.benefits[0].teacherBenefits
                  const mentorBenefits = response.data.benefits[0].mentorBenefits
                  const employerBenefits = response.data.benefits[0].employerBenefits

                  this.setState({ studentBenefits, careerSeekerBenefits, teacherBenefits, mentorBenefits, employerBenefits });

                } else {
                  console.log('emails query did not work', response.data.message)
                }

            }).catch((error) => {
                console.log('Emails query did not work for some reason', error);
            });
          }

          Axios.get('/api/rsvps/bypost', { params: { postingId: selectedOpportunity._id } })
          .then((response) => {
            console.log('Rsvp query attempted', response.data);

            if (response.data.success) {
              console.log('rsvp query worked')

              let rsvps = response.data.rsvps
              let publicRsvps = []
              let alreadyRSVPd = false
              let makeRsvpsPublic = true
              let csRSVPs = []
              let mRSVPS = []
              for (let i = 1; i <= rsvps.length; i++) {
                if (rsvps[i - 1].email === email) {
                  alreadyRSVPd = true
                }

                if (!rsvps[i - 1].roleName || rsvps[i - 1].roleName === 'Student' || rsvps[i - 1].roleName === 'Career-Seeker') {
                  csRSVPs.push(rsvps[i - 1])

                  if (makeRsvpsPublic && rsvps[i - 1].username) {
                    if (rsvps[i - 1].pictureURL) {
                      publicRsvps.unshift(rsvps[i - 1])
                    } else {
                      publicRsvps.push(rsvps[i - 1])
                    }
                  }

                } else if (rsvps[i - 1].roleName === 'Mentor') {
                  mRSVPS.push(rsvps[i - 1])
                }
              }
              // console.log('show targetRole: ', this.props.targetRole)
              let rsvpLimitReached = false
              if ((window.location.pathname.includes('/opportunities/') && (this.props.roleName === 'career-seeker')) && selectedOpportunity.careerSeekerLimit && csRSVPs.length >= selectedOpportunity.careerSeekerLimit) {
                rsvpLimitReached = true
              } else if ((window.location.pathname.includes('/opportunities/') && this.props.roleName === 'volunteer') && selectedOpportunity.mentorLimit && mRSVPS.length >= selectedOpportunity.mentorLimit) {
                rsvpLimitReached = true
              }
              // alreadyRSVPd for events, hasRegistered for challenges
              console.log('my re: ', rsvpLimitReached, csRSVPs, mRSVPS)
              this.setState({ rsvps, publicRsvps, alreadyRSVPd, rsvpLimitReached, csRSVPs, mRSVPS })

            } else {
              console.log('rsvp query did not work', response.data.message)
              //there is no rsvp data
            }

          }).catch((error) => {
              console.log('Rsvp query did not work for some reason', error);
          });

          Axios.get('/api/comments', { params: { parentPostId: selectedOpportunity._id } })
          .then((response) => {
            console.log('Comments query attempted', response.data);

             if (response.data.success) {
               console.log('successfully retrieved comments')

               if (response.data.comments && response.data.comments.length > 0) {

                 this.setState({ comments: response.data.comments })
               }
             } else {
               console.log('no comments data found', response.data.message)
             }

          }).catch((error) => {
             console.log('Comments query did not work', error);
          });

          Axios.get('/api/customassessments/byid', { params: { _id: selectedOpportunity.surveyId } })
          .then((response) => {
            console.log('Survey id', response.data);

            if (response.data.success) {
              console.log('Survey successfully queried')

              let survey = response.data.assessment
              this.setState({ survey });

              if (selectedOpportunity.customAssessmentId) {
                Axios.get('/api/customassessments/byid', { params: { _id: selectedOpportunity.customAssessmentId } })
                .then((response) => {
                  console.log('Custom assessment id', response.data);

                  if (response.data.success) {
                    console.log('Assessment successfully queried')

                    let customAssessment = response.data.assessment
                    let responses = []
                    if (customAssessment && customAssessment.questions) {
                      for (let i = 1; i <= customAssessment.questions.length; i++) {
                        if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'dateOfBirth') || (customAssessment.questions[i - 1].name === 'Date of Birth' || customAssessment.questions[i - 1].name === 'Birth Date' || customAssessment.questions[i - 1].name === 'Birthdate')) {
                          responses[survey.questions.length + i - 1] = dateOfBirth
                        } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'gender') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'gender')) {
                          responses[survey.questions.length + i - 1] = gender
                        } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'race') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'race')) {
                          responses[survey.questions.length + i - 1] = race
                        } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'races') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'races')) {
                          responses[survey.questions.length + i - 1] = races
                        } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'selfDescribedRace') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'self-described race')) {
                          responses[survey.questions.length + i - 1] = selfDescribedRace
                        } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'address') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'home address')) {
                          responses[survey.questions.length + i - 1] = address
                        } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'zipcode') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'zip code')) {
                          responses[survey.questions.length + i - 1] = zipcode
                        } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'numberOfMembers') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'number of members in household')) {
                          responses[survey.questions.length + i - 1] = numberOfMembers
                        } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'householdIncome') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'estimated household income')) {
                          responses[survey.questions.length + i - 1] = householdIncome
                        } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'workAuthorization') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'work authorization')) {
                          responses[survey.questions.length + i - 1] = workAuthorization
                        } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'adversityList') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'background information')) {
                          responses[survey.questions.length + i - 1] = adversityList
                        } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'educationStatus') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'education status')) {
                          responses[survey.questions.length + i - 1] = educationStatus
                        } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'phoneNumber') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'phone number')) {
                          responses[survey.questions.length + i - 1] = phoneNumber
                        } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'alternativePhoneNumber') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'alternative phone number')) {
                          responses[survey.questions.length + i - 1] = alternativePhoneNumber
                        } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'alternativeEmail') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'alternative email')) {
                          responses[survey.questions.length + i - 1] = alternativeEmail
                        } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'referrerName') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'referrer name')) {
                          responses[survey.questions.length + i - 1] = referrerName
                        } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'referrerEmail') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'referrer email')) {
                          responses[survey.questions.length + i - 1] = referrerEmail
                        } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'referrerOrg') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'referrer org')) {
                          responses[survey.questions.length + i - 1] = referrerOrg
                        } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'education|name') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'education name')) {
                          responses[survey.questions.length + i - 1] = schoolName
                        } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'education|degree') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'education degree')) {
                          responses[survey.questions.length + i - 1] = degree
                        } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'education|endDate') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'education grad year')) {
                          responses[survey.questions.length + i - 1] = gradDate
                        } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'education|major') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'education major')) {
                          responses[survey.questions.length + i - 1] = major
                        }
                      }
                    }

                    this.setState({ customAssessment, responses });

                  } else {
                    console.log('Custom assessment query did not work', response.data.message)
                  }

                }).catch((error) => {
                    console.log('Org info query did not work for some reason', error);
                });
              }

            } else {
              console.log('Survey query did not work', response.data.message)
            }

          }).catch((error) => {
              console.log('Org info query did not work for some reason', error);
          });

        } else if (selectedOpportunity.postType === 'Project' || selectedOpportunity.postType === 'Assignment' || selectedOpportunity.postType === 'Problem' || selectedOpportunity.postType === 'Challenge') {

          let summarySplit = []
          if (selectedOpportunity.summary) {
            summarySplit = selectedOpportunity.summary.split('//n')
            console.log('show summarySplit: ', selectedOpportunity.summary.includes('//n'), selectedOpportunity.summary.substring(0,15), summarySplit)
          }

          //update submissions
          Axios.get('/api/postings/byid', { params: { _id: selectedOpportunity._id } })
          .then((response) => {
            console.log('Postings by id query attempted', response.data);

              if (response.data.success) {
                console.log('successfully retrieved postings')

                if (response.data.posting) {

                  const updatedOpportunity = response.data.posting
                  let registrationPassed = false
                  let deadlinePassed = false
                  let disableVoting = false

                  if (updatedOpportunity.submissionDeadline) {
                    if (new Date().getTime() > new Date(updatedOpportunity.submissionDeadline).getTime()) {
                      deadlinePassed = true
                    }
                  }

                  if (updatedOpportunity.startDate) {
                    if (new Date().getTime() > new Date(updatedOpportunity.startDate).getTime()) {
                      registrationPassed = true
                    }
                  }

                  let submitted = false

                  if (updatedOpportunity.submissions && updatedOpportunity.submissions.length > 0) {

                    let submissions = updatedOpportunity.submissions
                    for (let i = 1; i <= submissions.length; i++) {

                      //for students who already submitted
                      if (submissions[i - 1].userEmail === email) {
                        submitted = true
                      }
                    }
                    disableVoting = updatedOpportunity.disableVoting

                    submissions.sort(function(a,b) {
                      return b.upvotes.length - a.upvotes.length;
                    })

                    // console.log('show submissions: ', submissions)
                    updatedOpportunity['submissions'] = submissions
                  }

                  this.setState({
                    selectedOpportunity: updatedOpportunity, submitted,
                    summarySplit, deadlinePassed, registrationPassed, disableVoting
                  })
                }


              } else {
                console.log('no posting data found', response.data.message)
              }

          }).catch((error) => {
              console.log('Posting query did not work', error);
          });

          Axios.get('/api/projects', { params: { emailId: email } })
          .then((response) => {
            console.log('Projects query attempted in subcomponent', response.data);

            if (response.data.success) {
              console.log('successfully retrieved projects')

              if (response.data.projects) {

                let projectOptions = response.data.projects
                if (projectOptions.length > 0) {
                  console.log('the array is greater than 0')

                  projectOptions.unshift({})
                  this.setState({ projectOptions })
                  console.log('po: ', projectOptions)

                }
              }

            } else {
              console.log('no project data found', response.data.message)
            }

          }).catch((error) => {
              console.log('Project query did not work', error);
          });

          Axios.get('/api/rsvps/bypost', { params: { postingId: selectedOpportunity._id } })
          .then((response) => {
            console.log('Rsvp query attempted', response.data);

            if (response.data.success) {
              console.log('rsvp query worked')

              let rsvps = response.data.rsvps
              let hasRegistered = false
              for (let i = 1; i <= rsvps.length; i++) {
                if (rsvps[i - 1].email === email) {
                  hasRegistered = true
                }
              }

              this.setState({ hasRegistered })

            } else {
              console.log('rsvp query did not work', response.data.message)
              //there is no rsvp data
            }

          }).catch((error) => {
              console.log('Rsvp query did not work for some reason', error);
          });

          Axios.get('/api/comments', { params: { parentPostId: selectedOpportunity._id } })
          .then((response) => {
            console.log('Comments query attempted', response.data);

             if (response.data.success) {
               console.log('successfully retrieved comments')

               if (response.data.comments && response.data.comments.length > 0) {
                 let comments = []
                 let submissionComments = []
                 for (let i = 1; i <= response.data.comments.length; i++) {
                   console.log('test 1: ', response.data.comments[i - 1])
                   if (response.data.comments[i - 1].parentSubmissionId && response.data.comments[i - 1].parentSubmissionId !== '') {
                     submissionComments.push(response.data.comments[i - 1])
                   } else if (response.data.comments[i - 1].parentPostId && response.data.comments[i - 1].parentPostId !== '') {
                     comments.push(response.data.comments[i - 1])
                   }
                 }

                 console.log('test 2: ', comments, submissionComments)
                this.setState({ comments, submissionComments })
               }
             } else {
               console.log('no comments data found', response.data.message)
             }

          }).catch((error) => {
             console.log('Comments query did not work', error);
          });

        } else {
          console.log('spot check', selectedOpportunity.postType)
        }
      }
    }

    formatDate(passedDate) {
      console.log('formatDate called', passedDate)

      if (!passedDate) {
        return null
      } else {
        const startDate = new Date(passedDate)
        const startMonth = startDate.getMonth()
        const startNumDay = startDate.getDate()
        const startYear = startDate.getFullYear()
        // let startHour = startDate.getHours()
        // let startMin = startDate.getMinutes()
        let startHour = Number(passedDate.substring(11,13))
        let startMin = Number(passedDate.substring(14,16))

        console.log('show startHour and startMin ', startHour, typeof startHour, startMin, typeof startMin )

        let calcMonth = (startMonth + 1).toString()
        if (startMonth + 1 < 10) {
          calcMonth = '0' + calcMonth
        }

        let suffix = 'AM'
        let calcHour = startHour
        if (startHour > 12) {
          calcHour = startHour - 12
          suffix = 'PM'
        } else if (startHour === 12) {
          suffix = 'PM'
        }

        let calcMin = ''
        if (startMin > 10) {
          calcMin = startMin.toString()
        } else {
          calcMin = '0' + startMin.toString()
        }

        const finalStartMonth = calcMonth
        const finalStartDay = startNumDay.toString()
        const finalStartYear = startYear.toString()
        const finalHour = calcHour.toString()
        const finalMin = calcMin
        const startDateString = finalStartMonth + "/" + finalStartDay + "/" + finalStartYear + " " + finalHour + ":" + finalMin + suffix

        passedDate = startDateString

        return passedDate
      }
    }

    pullCourses(searchValue, priceValue, durationValue, difficultyLevelValue) {
      console.log('pullCourses called', searchValue, priceValue, durationValue, difficultyLevelValue)

      this.setState({ animating: true, errorMessage: null, successMessage: null })

      // const searchValue = 'Excel'
      if (!searchValue) {
        searchValue = this.state.selectedSkill
      }
      const categoryValue = null
      const subcategoryValue = null
      // let priceValue = this.state.priceValue
      if (!priceValue) {
        priceValue = this.state.priceValue
      }
      let ratingValue = null
      if (!ratingValue) {
        ratingValue = 3.0
      }
      // let durationValue = this.state.durationValue
      if (!durationValue) {
        durationValue = this.state.durationValue
      }

      if (difficultyLevelValue) {
        difficultyLevelValue = difficultyLevelValue.toLowerCase()
      }

      const resLimit = 4
      Axios.get('/api/courses/search', { params: { searchValue, categoryValue, subcategoryValue, priceValue, ratingValue, durationValue, difficultyLevelValue, queryUdemyCourses: true, resLimit } })
      .then((response) => {
        console.log('Courses query attempted', response.data);

          if (response.data.success) {
            console.log('successfully retrieved courses')

            if (response.data.responseData) {

              let relatedCourses = response.data.responseData.results
              if (relatedCourses && relatedCourses.length > resLimit) {
                relatedCourses = relatedCourses.slice(0,resLimit)

              }
              this.setState({ relatedCourses, animating: false })
            }

          } else {
            console.log('no course data found', response.data.message)
            this.setState({ animating: false })
          }

      }).catch((error) => {
          console.log('Course query did not work', error);
          this.setState({ animating: false, errorMessage: 'There was an unknown error retrieving the courses'})
      });
    }

    async favoriteItem(item, type) {
      console.log('favoriteItem called', item, type)

      if (window.location.pathname.includes('/problem-platform') && !this.state.emailId && !this.state.roleName && !this.state.activeOrg) {
        this.props.navigate('/problem-platform/' + item._id + '/checkout', { state: { selectedItem: item, sourceLink: window.location.pathname }})
      } else {

        if (this.state.showSubFunction) {

          this.setState({ isSaving: true, errorMessage: null, successMessage: null })

          type = type.toLowerCase()

          const returnedObject = await subFavoriteItem(item, this.state.favorites, this.state.emailId, this.state.newFavorites,type)

          if (returnedObject.success) {
            this.setState({ successMessage: returnedObject.message, favorites: returnedObject.favoritesArray, newFavorites: returnedObject.newFavorites, isSaving: false })
          } else {
            this.setState({ errorMessage: returnedObject.message, isSaving: false })
          }

        } else {

          let itemId = item._id

          let favoritesArray = this.state.favorites

          if (favoritesArray.includes(itemId)){

            let index = favoritesArray.indexOf(itemId)
            if (index > -1) {
              favoritesArray.splice(index, 1);
            }

            Axios.post('/api/favorites/save', {
              favoritesArray, emailId: this.state.emailId
            })
            .then((response) => {
              console.log('attempting to save favorites')
              if (response.data.success) {
                console.log('saved successfully', response.data)
                //clear values
                this.setState({
                  serverSuccessPlan: true,
                  serverSuccessMessagePlan: 'Favorite saved!'
                })
              } else {
                console.log('did not save successfully')
                this.setState({
                  serverSuccessPlan: false,
                  serverErrorMessagePlan: 'error:' + response.data.message
                })
              }
            }).catch((error) => {
                console.log('save did not work', error);
                this.setState({
                  serverSuccessPlan: false,
                  serverErrorMessagePlan: 'there was an error saving favorites'
                })
            });

          } else {
            console.log('adding item: ', favoritesArray, itemId)
            favoritesArray.push(itemId)
            Axios.post('/api/favorites/save', {
              favoritesArray, emailId: this.state.emailId
            })
            .then((response) => {
              console.log('attempting to save favorites')
              if (response.data.success) {
                console.log('saved successfully', response.data)
                //clear values
                this.setState({
                  serverSuccessPlan: true,
                  serverSuccessMessagePlan: 'Favorite saved!'
                })
              } else {
                console.log('did not save successfully')
                this.setState({
                  serverSuccessPlan: false,
                  serverErrorMessagePlan: 'error:' + response.data.message
                })
              }
            }).catch((error) => {
                console.log('save did not work', error);
                this.setState({
                  serverSuccessPlan: false,
                  serverErrorMessagePlan: 'there was an error saving favorites'
                })
            });
          }

          console.log('favorites', favoritesArray)
          this.setState({ favorites: favoritesArray })

        }
      }
    }

    voteOnItem(item, direction) {
      console.log('voteOnItem called', this.state.selectedOpportunity)

      if (window.location.pathname.includes('/problem-platform')) {
        this.props.navigate('/problem-platform/' + this.state.selectedOpportunity._id + '/checkout', { state: { selectedItem: this.state.selectedOpportunity, sourceLink: window.location.pathname }})
      } else {

        let selectedOpportunity = this.state.selectedOpportunity
        const _id = selectedOpportunity._id
        const emailId = this.state.emailId
        let changeUpvote = true
        const updatedAt = new Date()

        Axios.post('/api/problems', { _id, emailId, changeUpvote, updatedAt })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Problem save worked', response.data);

            const serverSuccessMessage = 'Problem successfully posted!'


            // console.log('show work: ', selectedOpportunity)

            let upvotes = selectedOpportunity.upvotes
            let downvotes = selectedOpportunity.downvotes

            if (direction === 'up') {
              console.log('in up')

              if (upvotes.includes(this.state.emailId)) {
                const removeIndex = upvotes.indexOf(this.state.emailId)
                if (removeIndex > -1) {
                  upvotes.splice(removeIndex, 1);
                  selectedOpportunity['upvotes'] = upvotes
                  this.setState({ selectedOpportunity, serverSuccessMessage })
                }
              } else {

                upvotes.push(this.state.emailId)

                if (downvotes.includes(this.state.emailId)) {
                  const removeIndex = downvotes.indexOf(this.state.emailId)
                  if (removeIndex > -1) {
                    downvotes.splice(removeIndex, 1);
                  }

                  selectedOpportunity['upvotes'] = upvotes
                  selectedOpportunity['downvotes'] = downvotes

                  this.setState({ selectedOpportunity, serverSuccessMessage })
                } else {

                  selectedOpportunity['upvotes'] = upvotes
                  this.setState({ selectedOpportunity, serverSuccessMessage })
                }
              }

            } else {

              if (downvotes.includes(this.state.emailId)) {
                console.log('un-downvoting')
                const removeIndex = downvotes.indexOf(this.state.emailId)
                if (removeIndex > -1) {
                  downvotes.splice(removeIndex, 1);
                  selectedOpportunity['downvotes'] = downvotes
                  this.setState({ selectedOpportunity, serverSuccessMessage })
                }
              } else {
                console.log('initial downvote')
                downvotes.push(this.state.emailId)

                if (upvotes.includes(this.state.emailId)) {
                  console.log('downvoting from previous upvote')
                  const removeIndex = upvotes.indexOf(this.state.emailId)
                  if (removeIndex > -1) {
                    upvotes.splice(removeIndex, 1);
                  }

                  selectedOpportunity['upvotes'] = upvotes
                  selectedOpportunity['downvotes'] = downvotes

                  this.setState({ selectedOpportunity, serverSuccessMessage })
                } else {
                  selectedOpportunity['downvotes'] = downvotes
                  this.setState({ selectedOpportunity, serverSuccessMessage })
                }
              }
            }

          } else {
            console.error('there was an error posting the talk / workshop');
            const serverErrorMessage = response.data.message
            this.setState({ serverErrorMessage })
          }
        }).catch((error) => {
            console.log('The talk post did not work', error);
        });
      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      if (event.target.name === 'selectProject') {
        let selectedProject = {}
        let projectOptions = this.state.projectOptions
        for (let i = 1; i <= projectOptions.length; i++) {
          if (projectOptions[i - 1].name === event.target.value) {
            selectedProject = projectOptions[i - 1]
          }
        }
        this.setState({ selectedProject })
      } else if (event.target.name === 'employerName') {
        this.setState({ [event.target.name]: event.target.value })
        this.searchEmployers(event.target.value)
      } else if (event.target.name.includes('responses|')) {
        const index = Number(event.target.name.split("|")[1])
        let responses = this.state.responses
        responses[index] = event.target.value
        console.log('show responses again: ', responses)
        this.setState({ responses })
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    searchEmployers(employerName) {
      console.log('searchEmployers ', employerName)

      Axios.get('/api/account/search', { params: { employerName } })
      .then((response) => {
        console.log('Employer search query attempted', response.data);

          if (response.data.success) {
            console.log('employer search query worked')

            const employers = response.data.employers
            if (employers && employers.length > 0) {
              console.log('we in')
              this.setState({ employers });
            }

          } else {
            console.log('employer search query did not work', response.data.message)
          }

      }).catch((error) => {
          console.log('Employer search query did not work for some reason', error);
      });
    }

    employerClicked(employer) {
      console.log('employerClicked called ', employer)

      const employers = []
      const accountCode = employer.accountCode
      const selectedAnswer = employer.employerName
      // let responses = this.state.responses
      let employerName = employer.employerName

      this.setState({ employers, accountCode, selectedAnswer, employerName })

    }

    handleSubmit(postType, register) {
      console.log('handleSubmit in subcomponent ', postType, register)

      this.setState({ serverErrorMessage: '', serverSuccessMessage: '', errorMessage: null, successMessage: null, disableSubmit: true })

      if (postType === 'Project' || postType === 'Assignment' || postType === 'Problem' || postType === 'Challenge') {

        if (register) {
          // register for challenge

          if (!this.state.roleName || this.state.roleName === '') {
            this.setState({ errorMessage: 'Please add your role name', disableSubmit: false })
          } else if (!this.state.firstName || this.state.firstName === '') {
            this.setState({ errorMessage: 'Please add your first name', disableSubmit: false})
          } else if (!this.state.lastName || this.state.lastName === '') {
            this.setState({ errorMessage: 'Please add your last name', disableSubmit: false})
          } else if (!this.state.emailId || this.state.emailId === '') {
            this.setState({ errorMessage: 'Please add your email', disableSubmit: false})
          } else if (!this.state.emailId.includes('@')) {
            this.setState({ errorMessage: 'Please add a valid email', disableSubmit: false})
          } else {

            const postingId = this.state.selectedOpportunity._id
            const postingTitle = this.state.selectedOpportunity.name
            const postType = this.state.selectedOpportunity.postType
            const projectPromptType = this.state.selectedOpportunity.projectPromptType

            let pictureURL = this.state.pictureURL
            let firstName = this.state.firstName
            let lastName = this.state.lastName
            let email = this.state.emailId
            const password = this.state.password
            const username = this.state.username
            const phoneNumber = this.state.phoneNumber

            let jobTitle = this.state.jobTitle
            let employerName = this.state.employerName

            const orgCode = this.state.activeOrg
            const accountCode = 'N/A'
            let roleName = "Student"
            if (window.location.pathname.includes('/app')) {
              roleName = 'Student'
            } else {
              roleName = this.state.roleName
            }
            const otherRoleName = this.state.otherRoleName

            let schoolName = this.state.school
            let gradYear = null
            let degree = null
            let major = null
            if (this.state.education && this.state.education.length > 0) {
              // schoolName = this.state.education[0].name
              // if (this.state.education[0].endDate && this.state.education[0].endDate.split(" ")) {
              //   gradYear = this.state.education[0].endDate.split(" ")[1]
              // }
              //
              // degree = this.state.education[0].degree
              // major = this.state.education[0].major
              // if (this.state.education.length > 1) {
              //   for (let i = 1; i <= this.state.education.length; i++) {
              //     if (this.state.education[i - 1] && this.state.education[i - 1].isContinual) {
              //       schoolName = this.state.education[i - 1].name
              //       if (this.state.education[i - 1].endDate && this.state.education[i - 1].endDate.split(" ")) {
              //         gradYear = this.state.education[i - 1].endDate.split(" ")[1]
              //       }
              //       degree = this.state.education[i - 1].degree
              //       major = this.state.education[i - 1].major
              //     }
              //   }
              // }

              for (let i = 1; i <= this.state.education.length; i++) {
                let edObject = this.state.education[i - 1]
                if (edObject) {
                  let tempGradYear = null
                  if (edObject.endDate && edObject.endDate.split("|")) {
                    tempGradYear = edObject.endDate.split(" ")[1]
                  }

                  if (edObject.isContinual) {
                    schoolName = edObject.name
                    gradYear = tempGradYear
                    degree = edObject.degree
                    major = edObject.major
                    break;
                  } else if ((tempGradYear && gradYear && tempGradYear > gradYear) || (tempGradYear && !gradYear)) {
                    schoolName = edObject.name
                    gradYear = tempGradYear
                    degree = edObject.degree
                    major = edObject.major
                  }
                }
              }
            }

            const createdAt = new Date()
            const updatedAt = new Date()

            let existingUser = true
            if (this.state.password) {
              existingUser = false
            }

            const collaborators = this.state.selectedOpportunity.collaborators
            const departments = this.state.selectedOpportunity.departments
            const pathways = this.state.selectedOpportunity.pathways
            const selectedPrograms = this.state.selectedOpportunity.selectedPrograms

            //stuff for email notification
            const orgName = this.state.selectedOpportunity.orgName
            const orgContactFirstName = this.state.selectedOpportunity.orgContactFirstName
            const orgContactLastName = this.state.selectedOpportunity.orgContactLastName
            const orgContactEmail = this.state.selectedOpportunity.orgContactEmail
            const rsvpConfirmationSubjectLine = this.state.selectedOpportunity.rsvpConfirmationSubjectLine
            const rsvpConfirmationMessage = this.state.selectedOpportunity.rsvpConfirmationMessage

            Axios.post('/api/rsvp', {
              postingId, postingTitle, postType, projectPromptType,
              pictureURL, firstName, lastName, email, password, username, phoneNumber, schoolName, jobTitle, employerName, orgCode,
              accountCode, roleName, otherRoleName, createdAt, updatedAt, existingUser, collaborators, departments, pathways, selectedPrograms,
              gradYear, degree, major,
              orgName, orgContactFirstName, orgContactLastName, orgContactEmail,
              rsvpConfirmationSubjectLine, rsvpConfirmationMessage })
            .then((response) => {

              if (response.data.success) {
                //save values
                console.log('Reserve worked', response.data);

                const message = 'You have successfully registered'
                this.setState({ serverPostSuccess: true, serverSuccessMessage: message, successMessage: message, disableSubmit: false, hasRegistered: true })

              } else {
                console.error('there was an error posting the wbl opportunity');
                const message = response.data.message
                this.setState({ serverPostSuccess: false, serverErrorMessage: message, errorMessage: message, disableSubmit: false
                })
              }
            }).catch((error) => {
                console.log('The postings post did not work', error);
                this.setState({ serverPostSuccess: false, serverErrorMessage: error, disableSubmit: false })
            });
          }

        } else {
          if (this.state.subviewIndex === 1 && !this.state.selectedProject.name) {
            //select a project
            this.setState({ serverErrorMessage: 'please add a project to submit', disableSubmit: false })
          } else if (this.state.subviewIndex === 1) {

            if (this.state.workMode) {
              if (!this.state.selectedProject.metrics) {
                this.setState({ serverErrorMessage: 'Please add metrics before submitting', disableSubmit: false })
                return
              } else if (!this.state.selectedProject.metrics.values) {
                this.setState({ serverErrorMessage: 'Please add metrics before submitting', disableSubmit: false })
                return
              } else if (!this.state.selectedProject.metrics.values.posts) {
                this.setState({ serverErrorMessage: 'Please add metrics before submitting', disableSubmit: false })
                return
              } else if (this.state.selectedProject.metrics.values.posts.length === 0) {
                this.setState({ serverErrorMessage: 'Please add metrics before submitting', disableSubmit: false })
                return
              }
            }

            //submit the selected project
            const emailId = this.state.emailId
            const cuFirstName = this.state.cuFirstName
            const cuLastName = this.state.cuLastName
            const contributorFirstName = this.state.selectedOpportunity.contributorFirstName
            const contributorLastName = this.state.selectedOpportunity.contributorLastName
            const contributorEmail = this.state.selectedOpportunity.contributorEmail
            const announcementDate = this.state.selectedOpportunity.announcementDate
            const projectPromptType = this.state.selectedOpportunity.projectPromptType
            const projectConfirmationSubjectLine = this.state.selectedOpportunity.projectConfirmationSubjectLine
            const projectConfirmationMessage = this.state.selectedOpportunity.projectConfirmationMessage

            const postingId = this.state.selectedOpportunity._id
            const postingName = this.state.selectedOpportunity.name
            const projectId = this.state.selectedProject._id
            const projectName = this.state.selectedProject.name
            const postType = this.state.selectedOpportunity.postType
            const orgContactEmail = this.state.orgContactEmail

            let orgName = this.state.selectedOpportunity.orgName
            if (this.state.postingOrgName) {
              orgName = this.state.postingOrgName
            }

            const orgCode = this.state.activeOrg
            const url = this.state.selectedProject.url
            const category = this.state.selectedProject.category
            const description = this.state.selectedProject.description
            const startDate = this.state.selectedProject.startDate
            const endDate = this.state.selectedProject.endDate
            const collaborators = this.state.selectedProject.collaborators
            const collaboratorCount = this.state.selectedProject.collaboratorCount
            const hours = this.state.selectedProject.hours

            const isContinual = this.state.isContinual
            const totalHours = this.state.projectTotalHours
            const focus = this.state.projectFocus

            const departments = this.state.selectedOpportunity.departments
            const pathways = this.state.selectedOpportunity.pathways
            const selectedPrograms = this.state.selectedOpportunity.selectedPrograms

            //save submission
            Axios.post('/api/projects/submit', {
              emailId, cuFirstName, cuLastName, contributorFirstName, contributorLastName, contributorEmail,
              postingId, postingName, projectId, projectName, orgContactEmail, orgName, announcementDate,
              projectPromptType, projectConfirmationSubjectLine, projectConfirmationMessage, postType,
              orgCode, url, category, description, startDate, endDate, collaborators, collaboratorCount, hours,
              isContinual, totalHours, focus, departments, pathways, selectedPrograms
            })
            .then((response) => {

              if (response.data.success) {
                //save values
                console.log('Project save worked ', response.data);
                //report whether values were successfully saved

                let selectedOpportunity = this.state.selectedOpportunity
                let submissions = this.state.selectedOpportunity.submissions
                if (this.state.submitted) {
                  const index = submissions.findIndex(submission => submission.userEmail === emailId )
                  submissions[index] = { projectId, name: projectName, url, category, description, startDate, endDate, collaborators,
                    collaboratorCount, hours, userEmail: this.state.emailId, userFirstName: cuFirstName, userLastName: cuLastName,
                    orgCode, grades: [], upvotes: [], downvotes: [], createdAt: new Date(), updatedAt: new Date()
                  }
                } else {
                  submissions.push({ projectId, name: projectName, url, category, description, startDate, endDate, collaborators,
                    collaboratorCount, hours, userEmail: this.state.emailId, userFirstName: cuFirstName, userLastName: cuLastName,
                    orgCode, grades: [], upvotes: [], downvotes: [], createdAt: new Date(), updatedAt: new Date()
                  })
                }

                selectedOpportunity['submissions'] = submissions

                // this.state.selectedOpportunity.submissions
                let serverSuccessMessage = 'Project submitted successfully!'
                if (this.state.submitted) {
                  serverSuccessMessage = 'Project updated successfully!'
                }

                this.setState({ serverSuccessMessage, submitted: true,
                  disableSubmit: false, selectedOpportunity })

              } else {
                console.log('project did not save successfully')
                this.setState({ serverErrorMessage: response.data.message, disableSubmit: false })
              }

            }).catch((error) => {
                console.log('Project save did not work', error);
                this.setState({ serverErrorMessage: error, disableSubmit: false })
            });

          } else if (this.state.subviewIndex === 0) {

            //save project first
            if (!this.state.projectTitle || this.state.projectTitle === '') {
              this.setState({ serverErrorMessage: 'please add a project title', disableSubmit: false})
            } else if (!this.state.projectURL || this.state.projectURL === '') {
              this.setState({ serverErrorMessage: 'please add a url / web link for this project', disableSubmit: false})
            } else if (!this.state.projectURL.includes("http")) {
              this.setState({ serverErrorMessage: 'please add a valid url', disableSubmit: false})
            } else if (!this.state.projectCategory || this.state.projectCategory === '') {
              this.setState({ serverErrorMessage: 'please add a category for this project', disableSubmit: false})
            } else if (!this.state.startDate || this.state.startDate === '') {
              this.setState({ serverErrorMessage: 'please indicate when you started working on this project', disableSubmit: false})
            } else if (!this.state.isContinual && this.state.endDate !== '') {
              this.setState({ serverErrorMessage: 'please indicate when you stopped working on this project', disableSubmit: false})
            } else if (!this.state.projectHours || this.state.projectHours === '') {
              this.setState({ serverErrorMessage: 'please add the number of hours you worked on this project', disableSubmit: false})
            } else {
              //
              // if (this.state.collaboratorCount > 0) {
              //   for (let i = 1; i <= this.state.collaboratorCount; i++) {
              //     if (this.state.collaborators[i - 1]) {
              //       if (this.state.collaborators[i - 1] === '') {
              //         return this.setState({ serverErrorMessage: 'please add collaborator emails for each collaborator', disableSubmit: false })
              //       }
              //     } else {
              //       return this.setState({ serverErrorMessage: 'please add collaborator emails for each collaborator', disableSubmit: false })
              //     }
              //   }
              // }

              const emailId = this.state.emailId
              const userFirstName = this.state.cuFirstName
              const userLastName = this.state.cuLastName

              const name = this.state.projectTitle
              const url = this.state.projectURL
              const category = this.state.projectCategory
              const description = this.state.projectDescription
              const startDate = this.state.startDate
              const endDate = this.state.endDate
              const collaborators = this.state.collaborators
              const collaboratorCount = this.state.collaboratorCount
              const hours = this.state.projectHours
              const skillTags = this.state.skillTags
              const jobFunction = this.state.projectFunction
              const industry = this.state.projectIndustry

              const isContinual = this.state.isContinual
              const totalHours = this.state.projectTotalHours
              const focus = this.state.projectFocus

              const orgCode = this.state.activeOrg

              const createdAt = new Date()
              const updatedAt = new Date()

              Axios.post('/api/projects', {
                emailId, userFirstName, userLastName, name, url, category, description, startDate, endDate, collaborators, collaboratorCount,
                isContinual, totalHours, focus,
                hours, skillTags, jobFunction, industry, orgCode, createdAt, updatedAt
              })
              .then((response) => {

                if (response.data.success) {
                  //save values
                  console.log('Project save worked ', response.data);
                  //report whether values were successfully saved

                  // this.setState({ serverSuccessMessage: 'Projects saved successfully!', disableSubmit: false })

                  const cuFirstName = this.state.cuFirstName
                  const cuLastName = this.state.cuLastName
                  const contributorFirstName = this.state.selectedOpportunity.contributorFirstName
                  const contributorLastName = this.state.selectedOpportunity.contributorLastName
                  const contributorEmail = this.state.selectedOpportunity.contributorEmail
                  const postingId = this.state.selectedOpportunity._id
                  const postingName = this.state.selectedOpportunity.name
                  const projectPromptType = this.state.selectedOpportunity.projectPromptType
                  const projectConfirmationSubjectLine = this.state.selectedOpportunity.projectConfirmationSubjectLine
                  const projectConfirmationMessage = this.state.selectedOpportunity.projectConfirmationMessage
                  const projectId = response.data._id
                  const projectName = name
                  const orgContactEmail = this.state.orgContactEmail

                  const orgCode = this.state.activeOrg
                  const url = this.state.projectURL
                  const category = this.state.projectCategory
                  const description = this.state.projectDescription
                  const startDate = this.state.startDate
                  const endDate = this.state.endDate
                  const collaborators = this.state.collaborators
                  const collaboratorCount = this.state.collaboratorCount
                  const hours = this.state.projectHours

                  //save submission
                  Axios.post('/api/projects/submit', {
                    emailId, cuFirstName, cuLastName, contributorFirstName, contributorLastName, contributorEmail,
                    postingId, postingName, projectPromptType, projectConfirmationSubjectLine, projectConfirmationMessage,
                    projectId, projectName, orgContactEmail, totalHours, focus,
                    orgCode, url, category, description, startDate, endDate, collaborators, collaboratorCount, hours
                  })
                  .then((response) => {

                    if (response.data.success) {
                      //save values
                      console.log('Project submit worked here ', response.data);
                      //report whether values were successfully saved

                      let selectedOpportunity = this.state.selectedOpportunity
                      let submissions = this.state.selectedOpportunity.submissions

                      if (this.state.submitted) {
                        const index = submissions.findIndex(submission => submission.userEmail === emailId )
                        submissions[index] = { projectId, name: projectName, url, category, description, startDate, endDate, collaborators,
                          collaboratorCount, hours, userEmail: this.state.emailId, userFirstName: cuFirstName, userLastName: cuLastName,
                          orgCode, grades: [], upvotes: [], downvotes: [], createdAt: new Date(), updatedAt: new Date()
                        }
                      } else {
                        submissions.push({ projectId, name: projectName, url, category, description, startDate, endDate, collaborators,
                          collaboratorCount, hours, userEmail: this.state.emailId, userFirstName: cuFirstName, userLastName: cuLastName,
                          orgCode, grades: [], upvotes: [], downvotes: [], createdAt: new Date(), updatedAt: new Date()
                        })
                      }

                      selectedOpportunity['submissions'] = submissions

                      let serverSuccessMessage = 'Project submitted successfully!'
                      if (this.state.submitted) {
                        serverSuccessMessage = 'Project updated successfully!'
                      }

                      this.setState({ serverSuccessMessage,
                        submitted: true, disableSubmit: false, selectedOpportunity,

                        projectTitle: '', projectURL: '',
                        projectCategory: '', projectDescription: '', startDate: '', endDate: '', collaborators: [],
                        collaboratorCount: undefined, projectHours: '', skillTags: '',
                        isContinual: undefined, totalHours: '', focus: '', projectFunction: '', projectIndustry: ''

                      })

                    } else {
                      console.log('project did not save successfully')
                      this.setState({ serverErrorMessage: response.data.message, disableSubmit: false })
                    }

                  }).catch((error) => {
                      console.log('Project save did not work', error);
                      this.setState({ serverErrorMessage: error, disableSubmit: false })
                  });

                } else {
                  console.log('project did not save successfully')
                  this.setState({ serverErrorMessage: response.data.message, disableSubmit: false })
                }

              }).catch((error) => {
                  console.log('Project save did not work', error);
                  this.setState({ serverErrorMessage: error, disableSubmit: false })
              });
            }
          }
        }

      } else if (postType === 'Event') {
        console.log("DSDFSDF!")
        if (!this.state.cuFirstName || this.state.cuFirstName === '') {
          this.setState({ errorMessage: 'Please add your first name', disableSubmit: false })
        } else if (!this.state.cuLastName || this.state.cuLastName === '') {
          this.setState({ errorMessage: 'Please add your last name ', disableSubmit: false})
        } else if (!this.state.emailId || this.state.emailId === '') {
          this.setState({ errorMessage: 'Please add your email', disableSubmit: false})
        } else if (!this.state.emailId.includes('@')) {
          this.setState({ errorMessage: 'Please add a valid email', disableSubmit: false})
        } else if (!this.state.roleName || this.state.roleName === '') {
          this.setState({ errorMessage: 'Please add a role that best describes you', disableSubmit: false})
        } else if (this.state.roleName === 'Other' && (!this.state.otherRoleName || this.state.otherRoleName === '')) {
          this.setState({ errorMessage: 'Please specify your role (in the field below other)', disableSubmit: false})
        // } else if ((this.state.roleName === 'Student' || this.state.roleName === 'Career-Seeker' || this.state.roleName === 'Student / Career Seeker')  && (!this.state.schoolName || this.state.schoolName === '')) {
        //   this.setState({ errorMessage: 'Please add your current school or the last school that you attended', disableSubmit: false})
        // } else if ((this.state.roleName === 'Student' || this.state.roleName === 'Career-Seeker' || this.state.roleName === 'Student / Career Seeker') && (!this.state.gradYear || this.state.gradYear === '')) {
        //   this.setState({ errorMessage: 'Please add your graduation year', disableSubmit: false})
        // } else if ((this.state.roleName === 'Student' || this.state.roleName === 'Career-Seeker' || this.state.roleName === 'Student / Career Seeker') && (!this.state.degree || this.state.degree === '')) {
        //   this.setState({ errorMessage: 'Please add the degree type', disableSubmit: false})
        } else if (this.state.roleName === 'Teacher' && (!this.state.schoolName || this.state.schoolName === '')) {
          this.setState({ errorMessage: 'Please add the name of the school that employs you', disableSubmit: false})
        } else if (this.state.roleName === 'Work-Based Learning Coordinator' && (!this.state.schoolName || this.state.schoolName === '')) {
          this.setState({ errorMessage: 'Please add the name of the school that employs you', disableSubmit: false})
        } else if (this.state.roleName === 'Counselor' && (!this.state.schoolName || this.state.schoolName === '')) {
          this.setState({ errorMessage: 'Please add the name of the school that employs you', disableSubmit: false})
        } else if (this.state.roleName === 'Teacher' && (!this.state.degree || this.state.degree === '')) {
          this.setState({ errorMessage: 'Please add the degree type of the school that employs you', disableSubmit: false})
        } else if (this.state.roleName === 'Work-Based Learning Coordinator' && (!this.state.degree || this.state.degree === '')) {
          this.setState({ errorMessage: 'Please add the degree type of the school that employs you', disableSubmit: false})
        } else if (this.state.roleName === 'Counselor' && (!this.state.degree || this.state.degree === '')) {
          this.setState({ errorMessage: 'Please add the degree type of the school that employs you', disableSubmit: false})
        } else if (this.state.roleName === 'Employer Representative / Mentor' && (!this.state.jobTitle || this.state.jobTitle === '')) {
          this.setState({ errorMessage: 'Please add your job title', disableSubmit: false})
        } else if (this.state.roleName === 'Employer Representative / Mentor' && (!this.state.employerName || this.state.employerName === '')) {
          this.setState({ errorMessage: 'Please add your employer name', disableSubmit: false})
        } else {
          console.log('passed the initial check')

          const postingId = this.state.selectedOpportunity._id
          const postingTitle = this.state.selectedOpportunity.title

          let questions = []
          const pictureURL = this.state.pictureURL
          let firstName = this.state.cuFirstName
          let lastName = this.state.cuLastName
          let email = this.state.emailId
          let username = this.state.username
          let phoneNumber = this.state.phoneNumber

          // if ((this.state.roleName === 'Student' || this.state.roleName === 'Career-Seeker' || this.state.roleName === 'Student / Career Seeker') && this.state.selectedOpportunity && this.state.selectedOpportunity.rsvpComponents.some(comp => comp.name === 'Phone Number')) {
          //   const pIndex = this.state.selectedOpportunity.rsvpComponents.findIndex(comp => comp.name === 'Phone Number')
          //   if (this.state.selectedOpportunity.rsvpComponents[pIndex].required) {
          //     if (!phoneNumber || phoneNumber === '' || phoneNumber.length < 7 || phoneNumber.length > 14)
          //     return this.setState({ errorMessage: 'Please add a valid phone number', disableSubmit: false})
          //   }
          // }

          let employerName = this.state.employerName
          const orgCode = this.state.activeOrg
          let accountCode = 'N/A'
          if (this.state.accountCode && this.state.accountCode !== '') {
            accountCode = this.state.accountCode
          } else {
            if (employerName) {
              accountCode = employerName.replace('"','').replace("<","").replace(">","").replace("%","").replace("{","").replace("}","").replace("|","").replace("^","").replace("~","").replace("[","").replace("]","").replace("`","").replace(/ /g,"").replace(/,/g,"").toLowerCase()
            }
          }

          let roleName = "Student"
          let rsvpType = ''
          if (window.location.pathname.includes('/app')) {
            roleName = 'Student'
          } else {
            if (this.props.pageSource === 'landingPage') {
              if (this.state.roleName === 'Other') {
                roleName = this.state.otherRoleName
                rsvpType = roleName
              } else {
                if (this.state.roleName === 'Student / Career Seeker') {
                  roleName = 'Student'
                  rsvpType = roleName
                } else if (this.state.roleName === 'Employer Representative / Mentor') {
                  roleName = 'Mentor'
                  rsvpType = roleName
                } else {
                  roleName = this.state.roleName
                  rsvpType = roleName
                }
              }
            } else {
              roleName = this.state.roleName
            }
          }

          const otherRoleName = this.state.otherRoleName

          let schoolName = this.state.school
          let gradDate = null
          let gradYear = null
          let degree = null
          let major = null
          if (this.props.pageSource === 'landingPage' && !this.state.customAssessment) {
            schoolName = this.state.schoolName
            gradYear = this.state.gradYear
            gradDate = this.state.gradDate

            degree = this.state.degree
            major = this.state.major
          } else {
            if (this.state.education && this.state.education.length > 0) {
              // gradDate = this.state.education[0].endDate
              // if (gradDate && gradDate.split(" ") && gradDate.split(" ")[1]) {
              //   gradYear = gradDate.split(" ")[1]
              // }
              //
              // degree = this.state.education[0].degree
              // major = this.state.education[0].major
              // if (this.state.education.length > 1) {
              //   for (let i = 1; i <= this.state.education.length; i++) {
              //     if (this.state.education[i - 1] && this.state.education[i - 1].isContinual) {
              //       gradDate = this.state.education[i - 1].endDate
              //       if (gradDate && gradDate.split(" ") && gradDate.split(" ")[1]) {
              //         gradYear = gradDate.split(" ")[1]
              //       }
              //
              //       degree = this.state.education[i - 1].degree
              //       major = this.state.education[i - 1].major
              //     }
              //   }
              // }

              for (let i = 1; i <= this.state.education.length; i++) {
                let edObject = this.state.education[i - 1]
                if (edObject) {
                  let tempGradYear = null
                  if (edObject.endDate && edObject.endDate.split("|")) {
                    tempGradYear = edObject.endDate.split(" ")[1]
                  }

                  if (edObject.isContinual) {
                    schoolName = edObject.name
                    gradDate = edObject.endDate
                    gradYear = tempGradYear
                    degree = edObject.degree
                    major = edObject.major
                    break;
                  } else if ((tempGradYear && gradYear && tempGradYear > gradYear) || (tempGradYear && !gradYear)) {
                    schoolName = edObject.name
                    gradDate = edObject.endDate
                    gradYear = tempGradYear
                    degree = edObject.degree
                    major = edObject.major
                  }
                }
              }
            }
          }

          // let activities = []
          // let otherAnswers = []
          const studentActivityInterests = this.state.studentActivityInterests
          let teacherActivityInterests = this.state.teacherActivityInterests
          let mentorActivityInterests = this.state.mentorActivityInterests

          let jobTitle = 'N/A'
          if (this.props.landingPage) {
            jobTitle = this.state.jobTitle
            employerName = this.state.employerName

            if (roleName === 'Student') {
              teacherActivityInterests = null
              jobTitle = null
              employerName = null
              mentorActivityInterests = null
            }
          }

          const industries = this.state.industries
          const functions = this.state.functions

          const departments = this.state.selectedOpportunity.departments
          const pathways = this.state.selectedOpportunity.pathways
          const selectedPrograms = this.state.selectedOpportunity.selectedPrograms
          const subscribedForReminders = this.state.subscribedForReminders

          const createdAt = new Date()
          const updatedAt = new Date()

          let existingUser = true
          if (this.props.pageSource === 'landingPage') {
            existingUser = false
          }

          //stuff for email notification
          const orgName = this.state.selectedOpportunity.orgName
          const orgContactFirstName = this.state.selectedOpportunity.orgContactFirstName
          const orgContactLastName = this.state.selectedOpportunity.orgContactLastName
          const orgContactEmail = this.state.selectedOpportunity.orgContactEmail
          const location = this.state.selectedOpportunity.location
          const rsvpConfirmationSubjectLine = this.state.selectedOpportunity.rsvpConfirmationSubjectLine
          const rsvpConfirmationMessage = this.state.selectedOpportunity.rsvpConfirmationMessage
          const startDate = this.state.startDateString
          const endDate = this.state.endDateString

          // for new sign ups
          let dateOfBirth = this.state.dateOfBirth
          let gender = this.state.gender
          let race = this.state.race
          let races = this.state.races
          let selfDescribedRace = this.state.selfDescribedRace
          let address = this.state.address
          let zipcode = this.state.zipcode
          let numberOfMembers = this.state.numberOfMembers
          let householdIncome = this.state.householdIncome
          let workAuthorization = this.state.workAuthorization
          let adversityList = this.state.adversityList
          let educationStatus = this.state.educationStatus
          let education = this.state.education

          let alternativePhoneNumber = this.state.alternativePhoneNumber
          let alternativeEmail = this.state.alternativeEmail
          let referrerName = this.state.referrerName
          let referrerEmail = this.state.referrerEmail
          let referrerOrg = this.state.referrerOrg

          let customAssessmentId = null

          let rsvpResponses = []
          if (roleName === 'Student' || this.state.roleName === 'Student / Career Seeker') {

            rsvpResponses.push({ question: 'First Name', answer: firstName })
            rsvpResponses.push({ question: 'Last Name', answer: lastName })
            rsvpResponses.push({ question: 'Email', answer: email })
            rsvpResponses.push({ question: 'Role Name', answer: roleName })
            rsvpResponses.push({ question: 'Other Role Name', answer: otherRoleName})

            // verify that all required fields are filled out
            if (this.state.customAssessment && this.state.customAssessment.questions) {
              let customAssessment = this.state.customAssessment
              customAssessmentId = this.state.customAssessment._id

              // if (item.questionType === 'Multiple Answer' && (!this.state[item.shortname] || this.state[item.shortname].length === 0)) {
              //   return this.setState({ serverErrorMessage: 'Please add answer(s) for ' + item.name })
              // } else if (!item.shortname.includes("|") && (!this.state[item.shortname] || !this.state[item.shortname] === '')) {
              //   return this.setState({ serverErrorMessage: 'Please add an answer for ' + item.name })
              // } else if (item.shortname.includes("|") && (!this.state[item.shortname.split("|")[0]] || this.state[item.shortname.split("|")[0]].length === 0)) {
              //   return this.setState({ serverErrorMessage: 'Please add answer(s) for the education fields' })
              // }

              for (let i = 1; i <= this.state.customAssessment.questions.length; i++) {
                if (this.state.customAssessment.questions[i - 1].required && !this.state.responses[this.state.survey.questions.length + i - 1]) {
                  return this.setState({ errorMessage: this.state.customAssessment.questions[i - 1].name + ' is a required field.', disableSubmit: false})
                } else if (this.state.customAssessment.questions[i - 1].required && this.state.customAssessment.questions[i - 1].questionType === 'Checkbox' && this.state.responses[this.state.survey.questions.length + i - 1].length === 0) {
                  return this.setState({ errorMessage: this.state.customAssessment.questions[i - 1].name + ' is a required field.', disableSubmit: false})
                } else {
                  rsvpResponses.push({
                    question: this.state.customAssessment.questions[i - 1].name,
                    questionType: this.state.customAssessment.questions[i - 1].questionType,
                    answerChoices: this.state.customAssessment.questions[i - 1].answerChoices,
                    shortname: this.state.customAssessment.questions[i - 1].shortname,
                    mapToProfile: this.state.customAssessment.questions[i - 1].mapToProfile,
                    answer: this.state.responses[this.state.survey.questions.length + i - 1]
                  })
                }

                if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'dateOfBirth') || (customAssessment.questions[i - 1].name === 'Date of Birth' || customAssessment.questions[i - 1].name === 'Birthdate')) {
                  dateOfBirth = this.state.responses[this.state.survey.questions.length + i - 1]
                } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'gender') || (this.state.customAssessment.questions[i - 1].name === 'Gender')) {
                  gender = this.state.responses[this.state.survey.questions.length + i - 1]
                } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'race') || (this.state.customAssessment.questions[i - 1].name === 'Race')) {
                  race = this.state.responses[this.state.survey.questions.length + i - 1]
                } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'races') || (this.state.customAssessment.questions[i - 1].name === 'Races')) {
                  races = this.state.responses[this.state.survey.questions.length + i - 1]
                } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'selfDescribedRace') || (this.state.customAssessment.questions[i - 1].name === 'Self Described Race (Optional - If You Answered Self-Describe)')) {
                  selfDescribedRace = this.state.responses[this.state.survey.questions.length + i - 1]
                } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'address') || (this.state.customAssessment.questions[i - 1].name === 'Home Address')) {
                  address = this.state.responses[this.state.survey.questions.length + i - 1]
                } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'zipcode') || (this.state.customAssessment.questions[i - 1].name === 'Zip Code')) {
                  zipcode = this.state.responses[this.state.survey.questions.length + i - 1]
                } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'numberOfMembers') || (this.state.customAssessment.questions[i - 1].name === 'Number of Members in Household')) {
                  numberOfMembers = this.state.responses[this.state.survey.questions.length + i - 1]
                } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'householdIncome') || (this.state.customAssessment.questions[i - 1].name === 'Estimated Household Income')) {
                  householdIncome = this.state.responses[this.state.survey.questions.length + i - 1]
                } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'workAuthorization') || (this.state.customAssessment.questions[i - 1].name === 'Work Authorization')) {
                  workAuthorization = this.state.responses[this.state.survey.questions.length + i - 1]
                } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'adversityList') || (this.state.customAssessment.questions[i - 1].name === 'Background Information')) {
                  adversityList = this.state.responses[this.state.survey.questions.length + i - 1]
                } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'educationStatus') || (this.state.customAssessment.questions[i - 1].name === 'Education Status')) {
                  educationStatus = this.state.responses[this.state.survey.questions.length + i - 1]
                } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'phoneNumber') || (this.state.customAssessment.questions[i - 1].name === 'Phone Number')) {
                  phoneNumber = this.state.responses[this.state.survey.questions.length + i - 1]
                } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'alternativePhoneNumber') || (this.state.customAssessment.questions[i - 1].name === 'Alternative Phone Number')) {
                  alternativePhoneNumber = this.state.responses[this.state.survey.questions.length + i - 1]
                } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'alternativeEmail') || (this.state.customAssessment.questions[i - 1].name === 'Alternative Email')) {
                  alternativeEmail = this.state.responses[this.state.survey.questions.length + i - 1]
                } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'referrerName') || (customAssessment.questions[i - 1].name === 'Referrer Name')) {
                  referrerName = this.state.responses[this.state.survey.questions.length + i - 1]
                } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'referrerEmail') || (customAssessment.questions[i - 1].name === 'Referrer Email')) {
                  referrerEmail = this.state.responses[this.state.survey.questions.length + i - 1]
                } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'referrerOrg') || (customAssessment.questions[i - 1].name === 'Referrer Org')) {
                  referrerOrg = this.state.responses[this.state.survey.questions.length + i - 1]
                } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'education|name') || (customAssessment.questions[i - 1].name === 'Education Name')) {
                  schoolName = this.state.responses[this.state.survey.questions.length + i - 1]
                } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'education|degree') || (customAssessment.questions[i - 1].name === 'Education Degree')) {
                  degree = this.state.responses[this.state.survey.questions.length + i - 1]
                } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'education|endDate') || (customAssessment.questions[i - 1].name === 'Education Grad Year')) {
                  gradDate = this.state.responses[this.state.survey.questions.length + i - 1]
                  if (gradDate && gradDate.split(" ") && gradDate.split(" ")[1]) {
                    gradYear = gradDate.split(" ")[1]
                  }
                } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'education|major') || (customAssessment.questions[i - 1].name === 'Referrer Major')) {
                  major = this.state.responses[this.state.survey.questions.length + i - 1]
                }
              }
            } else {
              rsvpResponses.push({ question: 'Education Status', answer: educationStatus })
              rsvpResponses.push({ question: 'School Name', answer: schoolName })
              rsvpResponses.push({ question: 'Grad Date', answer: gradDate})
              rsvpResponses.push({ question: 'Degree Type', answer: degree })
              rsvpResponses.push({ question: 'Major / Pathway', answer: major})
              rsvpResponses.push({ question: 'Activity Interests', answer: studentActivityInterests })
            }
          }

          const self = this
          function postRSVP(passedUsername) {
            console.log('postRSVP called')

            if (passedUsername) {
              username = passedUsername
            }

            Axios.post('/api/rsvp', {
              postingId, postingTitle, rsvpType, questions, pictureURL, firstName, lastName, email, username, phoneNumber,
              alternativePhoneNumber, alternativeEmail,
              studentActivityInterests, teacherActivityInterests, mentorActivityInterests,
              schoolName, jobTitle, employerName, orgCode,
              accountCode, roleName, otherRoleName, createdAt, updatedAt, existingUser,
              gradDate, gradYear, degree, major, industries, functions, departments, pathways, selectedPrograms,
              subscribedForReminders,
              orgName, orgContactFirstName, orgContactLastName, orgContactEmail, location, startDate, endDate,
              rsvpResponses, customAssessmentId,
              rsvpConfirmationSubjectLine, rsvpConfirmationMessage
            })
            .then((response) => {
              console.log('')
              if (response.data.success) {
                //save values
                console.log('Reserve worked', response.data);

                window.scrollTo(0, 0)

                let successMessage = response.data.message
                let serverSuccessMessage = successMessage

                self.setState({ serverPostSuccess: true, serverSuccessMessage, successMessage,
                  disableSubmit: false, alreadyRSVPd: true, done: true
                })

              } else {
                console.error('there was an error: ', response.data.message);

                let errorMessage = response.data.message
                let serverErrorMessage = errorMessage
                self.setState({
                  serverPostSuccess: false, serverErrorMessage, errorMessage,
                  alreadyRSVPd: true, disableSubmit: false
                })
              }
            }).catch((error) => {
                console.log('The postings post did not work', error);
                self.setState({ serverPostSuccess: false, serverErrorMessage: error, disableSubmit: false })
            });
          }

          if (this.state.importedEmailId) {

            // if (schoolName && gradDate && degree && major) {
            //   education = [{
            //     name: schoolName,
            //     endDate: gradDate,
            //     degree, major,
            //   }]
            // }

            const updatedAt = new Date()

            const userObject = {
              emailId: email, firstName, lastName,
              gradYear, school: schoolName, phoneNumber,alternativePhoneNumber,alternativeEmail,
              subscribedForReminders,degree,major,dateOfBirth,gender,race,races,selfDescribedRace,
              address,zipcode,numberOfMembers, householdIncome, workAuthorization, adversityList,
              educationStatus,education, referrerName, referrerEmail, referrerOrg,
              updatedAt
            }

            Axios.post('/api/users/profile/details', userObject)
            .then((response) => {

              if (response.data.success) {
                console.log('successfully saved profile')

                localStorage.setItem('firstName', firstName)
                localStorage.setItem('lastName', lastName)
                // localStorage.setItem('pictureURL', pictureURL)
                // console.log('saved lastName: ', lastNameValue)

                postRSVP()

              } else {
                console.log('profile save was not successful')

                postRSVP()

              }
            }).catch((error) => {
                console.log('Saving the info did not work', error);
            });
          } else {
            if (!this.state.password || this.state.password === '') {
              this.setState({ errorMessage: 'Please add your password', disableSubmit: false})
            } else {

              const password = this.state.password
              const studentBenefits = this.state.studentBenefits
              const teacherBenefits = this.state.teacherBenefits
              const mentorBenefits = this.state.mentorBenefits
              const employerBenefits = this.state.employerBenefits
              const confirmEmail = false

              education = []
              if (schoolName && gradDate && degree && major) {
                education = [{
                  name: schoolName,
                  endDate: gradDate,
                  degree, major,
                }]
              }

              if (roleName === 'Work-Based Learning Coordinator' || roleName === 'WBLC') {
                roleName = "Mentor"
              }

              async function startSignUp() {
                const returnedValue = await signUp(firstName, lastName, email, password, gradYear, jobTitle, employerName, orgCode, roleName, otherRoleName, null, null, schoolName, null, accountCode, studentBenefits, teacherBenefits, mentorBenefits, employerBenefits, confirmEmail, null,null,null,null,null,null,null,phoneNumber,alternativePhoneNumber,alternativeEmail, subscribedForReminders,degree,major,dateOfBirth,gender,races,selfDescribedRace,address,zipcode,numberOfMembers, householdIncome, workAuthorization, adversityList, educationStatus,education, referrerName, referrerEmail, referrerOrg)
                console.log('was sign up successful? ', returnedValue)
                if (returnedValue && returnedValue.success) {
                  console.log('sign up was successful: ', returnedValue)
                  // this.queryInfo(this.state.email)

                  postRSVP(returnedValue.username)

                } else if (returnedValue && returnedValue.message) {
                  console.log('sign up error: ', returnedValue)
                  self.setState({ isSavingModal: false, disableSubmit: false, errorMessage: returnedValue.message })
                } else {
                  console.log('unknown sign up response: ', returnedValue)
                  self.setState({ isSavingModal: false, disableSubmit: false, errorMessage: returnedValue.error.message })
                }
              }

              startSignUp()

            }
          }
        }
      }
    }

    finishedSubmitting = (selectedProject) => {
      console.log('finishedSubmitting called', selectedProject)

      let selectedOpportunity = this.state.selectedOpportunity
      let submissions = this.state.selectedOpportunity.submissions

      if (this.state.submitted) {
        const index = submissions.findIndex(selectedProject => selectedProject.userEmail === this.state.emailId )
        submissions[index] = selectedProject
      } else {
        submissions.push(selectedProject)
      }

      selectedOpportunity['submissions'] = submissions

      this.setState({ serverSuccessMessage: 'Project submitted successfully!',
        submitted: true, disableSubmit: false, selectedOpportunity,

        projectTitle: '', projectURL: '',
        projectCategory: '', projectDescription: '', startDate: '', endDate: '', collaborators: [],
        collaboratorCount: undefined, projectHours: '', skillTags: '',
        isContinual: undefined, totalHours: '', focus: '', projectFunction: '', projectIndustry: ''

      })
    }

    renderDetails(type) {
      console.log('renderDetails ', type)

      let rows = []

      let items = []

      if (type === 'section') {
        if (this.state.selectedOpportunity.sections) {
          items = this.state.selectedOpportunity.sections
        }
      } else if (type === 'exhibit') {
        if (this.state.selectedOpportunity.exhibits) {
          items = this.state.selectedOpportunity.exhibits
        }
      }

      const alphabet = 'abcdefghijklmnopqrstuvwxyz'

      for (let i = 1; i <= items.length; i++) {

        const index = i - 1

        rows.push(
          <div key={type + i}>
            {type === 'section' && (
              <div>
                <p className="heading-text-4">{items[index].title}</p>
                <p className="description-text-1">{items[index].body}</p>
              </div>
            )}
            {type === 'exhibit' && (
              <div className="bottom-padding">
                <p className="heading-text-4">Exhibit {alphabet[index].toUpperCase()}</p>
                {(items[index].link && items[index].link !== '') && (
                  <a href={items[index].link} target="_blank">{items[index].link}</a>
                )}
              </div>
            )}
          </div>
        )
      }

      return rows

    }

    closeModal() {
      this.setState({ modalIsOpen: false, showReferSomeone: false, showIdealProfile: false, showMatch: false,
        login: false,
        showSubmitProject: false, showRSVPForm: false, showApplyViaEmail: false, viewRSVPs: false
      });
    }

    renderSkills() {
      console.log('renderSkills called')

      let rows = []

      if (this.state.benchmark) {
        if (this.state.benchmark.skills) {
          for (let i = 1; i <= this.state.benchmark.skills.length; i++) {
            console.log('skills ', i)

            const rowKey = "skills" + i.toString()

            let rawSkillWeight = this.state.benchmark.skills[i - 1].weight * 50
            if (rawSkillWeight > 100) {
              rawSkillWeight = 100
            }
            const skillWeight = rawSkillWeight.toString() + '%'

            let borderClass = "primary-border"
            let backgroundClass = "primary-background"
            if (this.state.benchmark.skills[i - 1].skillType === 'Soft Skill') {
              borderClass = "secondary-border"
              backgroundClass = "secondary-background"
            }

            rows.push(
              <div key={rowKey}>
                <div className="relative-column-30" >
                  <p className="description-text-2">{this.state.benchmark.skills[i - 1].title} ({this.state.benchmark.skills[i - 1].skillType})</p>
                </div>
                <div className="relative-column-70" >
                  <div className="half-spacer"/>

                  <div className={"progress-bar " + borderClass}>
                    <div className={"filler " + backgroundClass} style={{ width: skillWeight }} />
                  </div>
                </div>
                <div className="clear" />
                <div className="spacer"/>
              </div>
            )
          }
        }
      }

      return rows
    }

    renderInterests() {
      console.log('renderInterests called')

      let rows = []

      if (this.state.benchmark) {
        if (this.state.benchmark.interests) {
          for (let i = 1; i <= this.state.benchmark.interests.length; i++) {
            console.log('interests ', i)

            const rowKey = "interests" + i.toString()
            const interestScore = (this.state.benchmark.interests[i - 1].score * 20).toString() + '%'

            rows.push(
              <div key={rowKey}>
                <div className="relative-column-30" >
                  <p className="description-text-2">{this.state.benchmark.interests[i - 1].title}</p>
                </div>
                <div className="relative-column-70" >
                  <div className="half-spacer"/>
                  <div className={"progress-bar tertiary-border"}>
                    <div className={"filler tertiary-background"} style={{ width: interestScore }} />
                  </div>
                </div>
                <div className="clear" />
                <div className="spacer"/>
              </div>
            )
          }
        }
      }

      return rows
    }

    renderTraits() {
      console.log('renderTraits called')

      let rows = []

      if (this.state.benchmark) {
        if (this.state.benchmark.traits) {
          for (let i = 1; i <= this.state.benchmark.traits.length; i++) {
            console.log('traits ', i)

            const rowKey = "traits" + i.toString()
            const traitScore = (this.state.benchmark.traits[i - 1].score * 20).toString() + '%'

            rows.push(
              <div key={rowKey}>
                <div className="relative-column-30" >
                  <p className="description-text-2">{this.state.benchmark.traits[i - 1].title}</p>
                </div>
                <div className="relative-column-70" >
                  <div className="half-spacer"/>
                  <div className={"progress-bar quaternary-border"}>
                    <div className={"filler quaternary-background"} style={{ width: traitScore }} />
                  </div>
                </div>
                <div className="clear" />
                <div className="spacer"/>
              </div>
            )
          }
        }
      }

      return rows
    }

    renderTracks() {
      console.log('renderTracks called')

      let rows = []

      if (this.state.selectedOpportunity.tracks) {
        for (let i = 1; i <= this.state.selectedOpportunity.tracks.length; i++) {
          console.log('tracks ', i)

          const rowKey = "tracks" + i.toString()

          rows.push(
            <div key={rowKey}>
              <div className="relative-column-80">
               <p className="heading-text-5">{this.state.selectedOpportunity.tracks[i - 1].name}</p>
                <p>{this.state.selectedOpportunity.tracks[i - 1].jobs.length} Internship Postings</p>
              </div>
              <div className="clear" />
              <div className="spacer"/><div className="half-spacer"/>
            </div>
          )
        }
      }

      return rows
    }

    itemClicked(value, type) {
      console.log('itemClicked', value, type)

      if (type === 'roleName') {
        // console.log('compare me: ')

        let rsvpLimitReachedLate = false
        if ((window.location.pathname.includes('/opportunities/') && value === 'Student / Career Seeker') && (this.state.selectedOpportunity.careerSeekerLimit && this.state.csRSVPs && this.state.csRSVPs.length >= this.state.selectedOpportunity.careerSeekerLimit)) {
          rsvpLimitReachedLate = true
        } else if ((window.location.pathname.includes('/opportunities/') && value === 'Employer Representative / Mentor') && (this.state.selectedOpportunity.mentorLimit && this.state.mRSVPS && this.state.mRSVPS.length >= this.state.selectedOpportunity.mentorLimit)) {
          rsvpLimitReachedLate = true
        }

        let roleName = value
        this.setState({ roleName, rsvpLimitReachedLate })
      } else if (type === 'studentActivity') {
        let studentActivityInterests = this.state.studentActivityInterests
        if (studentActivityInterests) {
          if (studentActivityInterests.includes(value)) {
            let index = studentActivityInterests.indexOf(value)
            studentActivityInterests.splice(index, 1)

          } else {
            studentActivityInterests.push(value)
          }
        } else {
          studentActivityInterests = [value]
        }
        this.setState({ studentActivityInterests })
      } else if (type === 'industry') {
        let industries = this.state.industries
        if (industries) {
          if (industries.includes(value)) {
            let index = industries.indexOf(value)
            industries.splice(index, 1)

          } else {
            industries.push(value)
          }
        } else {
          industries = [value]
        }
        this.setState({ industries })
      } else if (type === 'function') {
        let functions = this.state.functions
        if (functions) {
          if (functions.includes(value)) {
            let index = functions.indexOf(value)
            functions.splice(index, 1)

          } else {
            functions.push(value)
          }
        } else {
          functions = [value]
        }
        this.setState({ functions })
      } else if (type === 'teacherActivity') {
        let teacherActivityInterests = this.state.teacherActivityInterests
        if (teacherActivityInterests) {
          if (teacherActivityInterests.includes(value)) {
            let index = teacherActivityInterests.indexOf(value)
            teacherActivityInterests.splice(index, 1)

          } else {
            teacherActivityInterests.push(value)
          }
        } else {
          teacherActivityInterests = [value]
        }
        this.setState({ teacherActivityInterests })
      } else if (type === 'mentorActivity') {
        let mentorActivityInterests = this.state.mentorActivityInterests
        if (mentorActivityInterests) {
          if (mentorActivityInterests.includes(value)) {
            let index = mentorActivityInterests.indexOf(value)
            mentorActivityInterests.splice(index, 1)

          } else {
            mentorActivityInterests.push(value)
          }
        } else {
          mentorActivityInterests = [value]
        }
        this.setState({ mentorActivityInterests })
      }
    }

    optionClicked(index, optionIndex, value, value2) {
      console.log('optionClicked called', index, optionIndex, value, value2)

      let responses = this.state.responses
      if (responses[index]) {
        if (responses[index].includes(value2)) {
          const deleteIndex = responses[index].indexOf(value2)
          responses[index].splice(deleteIndex, 1)
        } else {
          responses[index].push(value2)
        }

      } else {
        responses[index] = [value2]
      }

      this.setState({ responses })

    }

    renderCollaborators() {
      console.log('renderCollaborators called')

      let rows = []
      let collaborators = this.state.selectedOpportunity.collaborators
      if (collaborators) {
        for (let i = 1; i <= collaborators.length; i++) {

          const index = i - 1

          rows.push(
            <div key={"collaborator" + i.toString()}>
              <div className="spacer" /><div className="half-spacer" />

              <div className="fixed-column-50">
                <img src={collaborators[i - 1].pictureURL ? collaborators[i - 1].pictureURL : profileIconBig} alt="pic" className="profile-thumbnail-2"/>
              </div>
              <div className="calc-column-offset-100 left-padding">
                <p>{collaborators[i - 1].firstName} {collaborators[i - 1].lastName} ({collaborators[i - 1].email})</p>
                <div className="half-spacer" />
                {(collaborators[i - 1].joined) ? (
                  <p className="description-text-2">{collaborators[i - 1].roleName}</p>
                ) : (
                  <p className="description-text-2">(This user has not joined Guided Compass)</p>
                )}
              </div>
              <div className="fixed-column-50">
                <div className="spacer" />
                <a className="background-link" onClick={() => this.removeItem(index)}>
                  <img src={xIcon} alt="pic" className="image-auto-20"/>
                </a>
              </div>
              <div className="clear" />
            </div>
          )
        }
      }

      return rows
    }

    renderQuestions(roleName) {
      console.log('renderQuestions called', roleName)

      if (roleName === 'Student / Career Seeker' || roleName === 'Student' || roleName === 'Career-Seeker' || (roleName === 'Admin' && window.location.pathname.startsWith('/app/opportunities'))) {
        console.log('in student')

        return (
          <div key="student">
            {(this.state.customAssessment && this.state.customAssessment.questions) ? (
              <div>
                {this.state.customAssessment.questions.map((value, index) =>
                  <div key={value}>
                    {((!value.conditional) || (value.conditional && (value.conditionNumber - 1 === (index + this.state.survey.questions.length)) && value.conditionValue === this.state.responses[index - 1])) && (
                      <div key={value} className="row-10">
                        <label className="profile-label">{value.name}{(value.required) && <label className="error-color">*</label>}</label>
                        {(value.questionType === 'Date' || value.questionType === 'Birthdate') && (
                          <input type="date" className="date-picker white-background" name={'responses|' + (index + this.state.survey.questions.length)} value={this.state.responses[index + this.state.survey.questions.length]} onChange={this.formChangeHandler}></input>
                        )}
                        {(value.questionType === 'Short Answer') && (
                          <input type="text" className="text-field" placeholder={(value.placeholder) ? value.placeholder : "Your answer..."} name={'responses|' + (index + this.state.survey.questions.length)} value={this.state.responses[index + this.state.survey.questions.length]} onChange={this.formChangeHandler}></input>
                        )}
                        {(value.questionType === 'Long Answer') && (
                          <textarea type="text" className="text-field" placeholder={(value.placeholder) ? value.placeholder : "Your answer..."} name={'responses|' + (index + this.state.survey.questions.length)} value={this.state.responses[index + this.state.survey.questions.length]} onChange={this.formChangeHandler}></textarea>
                        )}
                        {(value.questionType === 'Multiple Choice') && (
                          <select name={'responses|' + (index + this.state.survey.questions.length)} className="dropdown" value={this.state.responses[index + this.state.survey.questions.length]} onChange={this.formChangeHandler}>
                            {[''].concat(value.answerChoices).map(value => <option key={value} value={value}>{value}</option>)}
                          </select>
                        )}
                        {/*
                        {(value.questionType === 'Linear Scale') && (
                          <div className="top-margin bottom-margin-20">
                            <div className="flex-container flex-space-between row-direction description-text-2">
                              <button className="background-button" onClick={() => this.formChangeHandler({ target: { name: 'responses|' + (index + this.state.survey.questions.length), value: this.state.responses[index + this.state.survey.questions.length]}}) }>
                                <div className="width-20 height-20 circle-radius white-background dark-border">
                                  {(this.state.responses[index + this.state.survey.questions.length] === value.answerChoices[0]) && <div className="width-10 height-10 circle-radius primary-background left-margin-4 top-margin-4" />}
                                </div>
                                <p>{(value.answerChoices[0]) ? value.answerChoices[0] : "Least/most value"}</p>
                              </button>
                              <button className="background-button" onClick={() => this.formChangeHandler({ target: { name: "listedAnswer|multipleChoice|" + passedIndex, value: value.answerChoices[1]}}) }>
                                <div className="width-20 height-20 circle-radius white-background dark-border center-item">
                                  {(this.state.responses[index + this.state.survey.questions.length] === value.answerChoices[1]) && <div className="width-10 height-10 circle-radius primary-background left-margin-4 top-margin-4" />}
                                </div>
                                <p className="center-text">{(value.answerChoices[1]) ? value.nswerChoices[1] : "Lower Mid value"}</p>
                              </button>
                              <button className="background-button" onClick={() => this.formChangeHandler({ target: { name: "listedAnswer|multipleChoice|" + passedIndex, value: value.answerChoices[2]}}) }>
                                <div className="width-20 height-20 circle-radius white-background dark-border center-item">
                                  {(this.state.responses[index + this.state.survey.questions.length] === value.answerChoices[2]) && <div className="width-10 height-10 circle-radius primary-background left-margin-4 top-margin-4" />}
                                </div>
                                <p className="center-text">{(value.answerChoices[2]) ? value.answerChoices[2] : "Mid value"}</p>
                              </button>
                              <button className="background-button" onClick={() => this.formChangeHandler({ target: { name: "listedAnswer|multipleChoice|" + passedIndex, value: value.answerChoices[3]}}) }>
                                <div className="width-20 height-20 circle-radius white-background dark-border center-item">
                                  {(this.state.responses[index + this.state.survey.questions.length] === value.answerChoices[3]) && <div className="width-10 height-10 circle-radius primary-background left-margin-4 top-margin-4" />}
                                </div>
                                <p className="center-text">{(value.answerChoices[3]) ? value.answerChoices[3] : "Higher Mid value"}</p>
                              </button>
                              <button className="background-button" onClick={() => this.formChangeHandler({ target: { name: "listedAnswer|multipleChoice|" + passedIndex, value: value.answerChoices[4]}}) }>
                                <div className="width-20 height-20 circle-radius white-background dark-border pin-right">
                                  {(this.state.responses[index + this.state.survey.questions.length] === value.answerChoices[4]) && <div className="width-10 height-10 circle-radius primary-background left-margin-4 top-margin-4" />}
                                </div>
                                <p className="right-text">{(value.answerChoices[4]) ? value.answerChoices[4] : "Least/most value"}</p>
                              </button>
                            </div>
                            <div className="top-margin-negative-40">
                              <div className="dark-border-bottom top-margin height-1 full-width" />
                            </div>
                          </div>
                        )}*/}
                        {(value.questionType === 'Multiple Answer' || value.questionType === 'Checkbox') && (
                          <div>
                            {/*
                            <div key={value} className="float-left">
                              <div className="float-left row-5 right-padding">
                                <button className="background-button" onClick={() => this.itemClicked(value,'teacherActivity')}>
                                  {(this.state.teacherActivityInterests && this.state.teacherActivityInterests.includes(value)) ? (
                                    <div className="tag-container-4">
                                      <p className="description-text-2 white-text half-bold-text">{value}</p>
                                    </div>
                                  ) : (
                                    <div className="tag-container-inactive-2">
                                      <p className="description-text-2 cta-color half-bold-text">{value}</p>
                                    </div>
                                  )}
                                </button>
                              </div>
                            </div>*/}
                            {value.answerChoices.map((value2, optionIndex) =>
                              <div key={value2 + optionIndex} className="float-left">
                                <div className="float-left row-5 right-padding">
                                  <button className="background-button" onClick={() => this.optionClicked((index + this.state.survey.questions.length), optionIndex, value, value2)}>
                                    {(this.state.responses[index + this.state.survey.questions.length] && this.state.responses[index + this.state.survey.questions.length].includes(value2)) ? (
                                      <div className="tag-container-4">
                                        <p className="description-text-2 white-text half-bold-text">{value2}</p>
                                      </div>
                                    ) : (
                                      <div className="tag-container-inactive-2">
                                        <p className="description-text-2 cta-color half-bold-text">{value2}</p>
                                      </div>
                                    )}
                                  </button>
                                </div>
                              </div>
                            )}
                            {/*
                            {value.answerChoices.map((value2, optionIndex) =>
                              <div key={value2 + optionIndex}>
                                <div className="top-margin-5 right-padding dark-background">
                                  {(this.state.responses[index + this.state.survey.questions.length] && this.state.responses[index + this.state.survey.questions.length].includes(value2)) ? (
                                    <button className="background-button tag-container-6 float-left cta-background-color bottom-margin right-margin" onClick={() => this.optionClicked((index + this.state.survey.questions.length), optionIndex, value, value2)}>
                                      <div>
                                        <div className="float-left left-text">
                                          <p className="description-text-2 white-text">{value2}</p>
                                        </div>
                                      </div>
                                    </button>
                                  ) : (
                                    <button className="background-button tag-container-6 float-left bottom-margin right-margin" onClick={() => this.optionClicked((index + this.state.survey.questions.length), optionIndex, value, value2)}>
                                      <div>
                                        <div className="float-left left-text">
                                          <p className="description-text-2">{value2}</p>
                                        </div>
                                      </div>
                                    </button>
                                  )}
                                </div>
                              </div>
                            )}*/}
                            <div className="clear" />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div>
                {(this.state.selectedOpportunity && this.state.selectedOpportunity.rsvpComponents && this.state.selectedOpportunity.rsvpComponents.some(comp => comp.name === 'Phone Number')) && (
                  <div className="row-10">
                    <div className="container-left">
                      <label className="profile-label">Cell Phone Number<label className="error-color">*</label></label>
                      <input className="text-field" type="text" placeholder="(555) 555-5555" name="phoneNumber" value={this.state.phoneNumber} onChange={this.formChangeHandler}/>
                    </div>
                    {(this.state.selectedOpportunity && this.state.selectedOpportunity.sendReminderText) && (
                      <div className="container-right">
                        <label className="profile-label">Text Reminder</label>
                        <Switch
                          onChange={(change) => this.setState({ subscribedForReminders: change })}
                          checked={this.state.subscribedForReminders}
                          id="normal-switch"
                        />
                        <p className="profile-descriptor top-margin-5">[If enabled, you will receive a text reminder before the event.]</p>
                      </div>
                    )}
                    <div className="clear" />
                  </div>
                )}

                <div className="row-10">
                  <label className="profile-label">Education Status: I am currently earning a<label className="error-color bold-text">*</label></label>
                  <select name={"educationStatus"} className="dropdown" value={this.state.educationStatus} onChange={this.formChangeHandler}>
                      {this.state.educationStatusOptions.map(value => <option key={value} value={value}>{value}</option>)}
                  </select>
                </div>

                <div className="row-10">
                  <div className="container-left">
                    <label className="profile-label">School Name (Current or Last)<label className="error-color">*</label></label>
                    <input className="text-field" type="text" placeholder="School name" name="schoolName" value={this.state.schoolName} onChange={this.formChangeHandler} />
                  </div>
                  <div className="container-right">
                    <label className="profile-label">Grad Year<label className="error-color">*</label></label>
                    <select name="gradYear" className="dropdown" value={this.state.gradYear} onChange={this.formChangeHandler}>
                        {this.state.gradYearOptions.map(value => <option key={value} value={value}>{value}</option>)}
                    </select>
                  </div>

                  <div className="clear" />
                </div>

                <div className="edit-profile-row">
                  <div className="container-left">
                    <label className="profile-label">Degree Type<label className="error-color">*</label></label>
                    <select name="degree" className="dropdown" value={this.state.degree} onChange={this.formChangeHandler}>
                        {this.state.degreeOptions.map(value => <option key={value} value={value}>{value}</option>)}
                    </select>
                  </div>
                  <div className="container-right">
                    <label className="profile-label">Major / Pathway</label>
                    <input className="text-field" type="text" placeholder="Major / pathway..." name="major" value={this.state.major} onChange={this.formChangeHandler} />
                  </div>
                  <div className="clear" />
                </div>

                {(this.state.pageSource === 'landingPage') && (
                  <div className="edit-profile-row">
                    <label className="profile-label">Would you be interested in any of the following?</label>
                    {this.state.studentActivityOptions.map((value, index) =>
                      <div key={value} className="float-left">
                        <div className="float-left row-5 right-padding">
                          <button className="background-button" onClick={() => this.itemClicked(value,'studentActivity')}>
                            {(this.state.studentActivityInterests && this.state.studentActivityInterests.includes(value)) ? (
                              <div className="tag-container-4">
                                <p className="description-text-2 white-text half-bold-text">{value}</p>
                              </div>
                            ) : (
                              <div className="tag-container-inactive-2">
                                <p className="description-text-2 cta-color half-bold-text">{value}</p>
                              </div>
                            )}
                          </button>
                        </div>
                      </div>
                    )}
                    <div className="clear" />
                  </div>
                )}
              </div>
            )}
          </div>
        )

      } else if (roleName === 'Teacher' || roleName === 'Work-Based Learning Coordinator' || roleName === 'Counselor') {

        return (
          <div key="WBLC">
            <div className="edit-profile-row">
              <div className="container-left">
                <label className="profile-label">School Name<label className="error-color">*</label></label>
                <input className="text-field" type="text" placeholder="School name" name="schoolName" value={this.state.schoolName} onChange={this.formChangeHandler} />
              </div>
              <div className="container-right">
                <label className="profile-label">School Type<label className="error-color">*</label></label>
                <select name="degree" className="dropdown" value={this.state.degree} onChange={this.formChangeHandler}>
                    {this.state.degreeOptions.map(value => <option key={value} value={value}>{value}</option>)}
                </select>
              </div>

              <div className="clear" />
            </div>

            <div className="edit-profile-row">
              <label className="profile-label">Do you focus on any of the below industries?</label>
              {this.state.industryOptionsRaw.map((value, index) =>
                <div key={value} className="float-left">
                  <div className="float-left row-5 right-padding">
                    <button className="background-button" onClick={() => this.itemClicked(value,'industry')}>
                      {(this.state.industries && this.state.industries.includes(value)) ? (
                        <div className="tag-container-4">
                          <p className="description-text-2 white-text half-bold-text">{value}</p>
                        </div>
                      ) : (
                        <div className="tag-container-inactive-2">
                          <p className="description-text-2 cta-color half-bold-text">{value}</p>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              )}
              <div className="clear" />
            </div>

            <div className="edit-profile-row">
              <label className="profile-label">Do you focus on any of the below work functions?</label>
              {this.state.functionOptionsRaw.map((value, index) =>
                <div key={value} className="float-left">
                  <div className="float-left row-5 right-padding">
                    <button className="background-button" onClick={() => this.itemClicked(value,'function')}>
                      {(this.state.functions && this.state.functions.includes(value)) ? (
                        <div className="tag-container-4">
                          <p className="description-text-2 white-text half-bold-text">{value}</p>
                        </div>
                      ) : (
                        <div className="tag-container-inactive-2">
                          <p className="description-text-2 cta-color half-bold-text">{value}</p>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              )}
              <div className="clear" />
            </div>

            <div className="edit-profile-row">
              <label className="profile-label">Would you be interested in any of the following?</label>
              {this.state.teacherActivityOptions.map((value, index) =>
                <div key={value} className="float-left">
                  <div className="float-left row-5 right-padding">
                    <button className="background-button" onClick={() => this.itemClicked(value,'teacherActivity')}>
                      {(this.state.teacherActivityInterests && this.state.teacherActivityInterests.includes(value)) ? (
                        <div className="tag-container-4">
                          <p className="description-text-2 white-text half-bold-text">{value}</p>
                        </div>
                      ) : (
                        <div className="tag-container-inactive-2">
                          <p className="description-text-2 cta-color half-bold-text">{value}</p>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              )}
              <div className="clear" />
            </div>
          </div>
        )
      } else if (roleName === 'Employer Representative / Mentor') {
        return (
          <div key="employer">
            <div className="edit-profile-row">
              <div className="container-left">
                <label className="profile-label">Job Title<label className="error-color">*</label></label>
                <input className="text-field" type="text" placeholder="Job Title" name="jobTitle" value={this.state.jobTitle} onChange={this.formChangeHandler} />
              </div>
              <div className="container-right">
                <label className="profile-label">Employer Name<label className="error-color">*</label></label>
                <input className="text-field" type="text" placeholder="(e.g., Nike)" name="employerName" value={this.state.employerName} onChange={this.formChangeHandler} />

                {(this.state.employers.length > 0) && (
                  <div>
                    <div className="spacer"/>
                    {this.state.employers.map(value =>
                      <div key={value._id} className="left-text bottom-margin-5">
                        <button className="background-button full-width left-text" onClick={() => this.employerClicked(value)}>
                          <div>
                            <div className="fixed-column-30">
                              <img src={employersIconBlue} alt="GC" className="image-auto-22" />
                            </div>
                            <div className="calc-column-offset-30">
                              <p className="cta-color">{value.employerName}</p>
                            </div>
                          </div>
                        </button>
                      </div>)}
                  </div>
                )}
              </div>

              <div className="clear" />
            </div>

            <div className="edit-profile-row">
              <label className="profile-label">Would you be interested in any of the following?</label>
              {this.state.mentorActivityOptions.map((value, index) =>
                <div key={value} className="float-left">
                  <div className="float-left row-5 right-padding">
                    <button className="background-button" onClick={() => this.itemClicked(value,'mentorActivity')}>
                      {(this.state.mentorActivityInterests && this.state.mentorActivityInterests.includes(value)) ? (
                        <div className="tag-container-4">
                          <p className="description-text-2 white-text half-bold-text">{value}</p>
                        </div>
                      ) : (
                        <div className="tag-container-inactive-2">
                          <p className="description-text-2 cta-color half-bold-text">{value}</p>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              )}
              <div className="clear" />
            </div>
          </div>
        )
      }
    }

    removeItem(index) {
      console.log('removeItem called', index)

      // collaborators
      let selectedOpportunity = this.state.selectedOpportunity
      let collaborators = this.state.selectedOpportunity.collaborators
      collaborators.splice(index, 1)
      selectedOpportunity['collaborators'] = collaborators
      this.setState({ selectedOpportunity })

    }

    inviteCollaborators() {
      console.log('inviteCollaborators called')

      if (this.state.collaboratorEmail && this.state.collaboratorEmail !== '') {

        this.setState({ collaboratorErrorMessage: null })

        if (!this.state.collaboratorEmail.includes('@')) {
          this.setState({ collaboratorErrorMessage: 'Please add a valid email' })
        } else {
          //check if user is on GC

          const email = this.state.collaboratorEmail
          let collaboratorEmail = ''

          Axios.get('/api/users/profile/details/' + email)
          .then((response) => {
            if (response.data.success) {

              const responseData = response.data

              const pictureURL = responseData.user.pictureURL
              const firstName = responseData.user.firstName
              const lastName = responseData.user.lastName
              const roleName = responseData.user.roleName

              let collaborators = this.state.selectedOpportunity.collaborators
              if (collaborators) {
                collaborators.push({ pictureURL, firstName, lastName, email, roleName, joined: true })
              } else {
                collaborators = [{ pictureURL, firstName, lastName, email, roleName, joined: true }]
              }

              let selectedOpportunity = this.state.selectedOpportunity
              selectedOpportunity['collaborators'] = collaborators

              this.setState({ selectedOpportunity, collaboratorEmail })

            } else {
              let collaborators = this.state.selectedOpportunity.collaborators
              if (collaborators) {
                collaborators.push({ pictureURL: '', firstName: 'Collaborator', lastName: '#' + collaborators.length, email, roleName: 'Student', joined: false })
              } else {
                collaborators = [{ pictureURL: '', firstName: 'Collaborator', lastName: '#1', email, roleName: 'Student', joined: false }]
              }

              let selectedOpportunity = this.state.selectedOpportunity
              selectedOpportunity['collaborators'] = collaborators

              this.setState({ selectedOpportunity, collaboratorEmail })
            }
          }).catch((error) => {
              console.log('Profile details fetch did not work', error);
              let collaborators = this.state.selectedOpportunity.collaborators
              if (collaborators) {
                collaborators.push({ pictureURL: '', firstName: 'Collaborator', lastName: '#' + collaborators.length, email, roleName: 'Student', joined: false })
              } else {
                collaborators = [{ pictureURL: '', firstName: 'Collaborator', lastName: '#1', email, roleName: 'Student', joined: false }]
              }

              let selectedOpportunity = this.state.selectedOpportunity
              selectedOpportunity['collaborators'] = collaborators

              this.setState({ selectedOpportunity, collaboratorEmail })
          });
        }
      }
    }

    async authenticate() {
      console.log('authenticate called')

      this.setState({ isSavingModal: true, errorMessage: null, successMessage: null })

      if (!this.state.emailId || this.state.emailId === '') {
        this.setState({ errorMessage: 'Please enter an email', isSavingModal: false })
      } else if (!this.state.password || this.state.password === '') {
        this.setState({ errorMessage: 'Please enter your password', isSavingModal: false })
      } else {

        const email = this.state.emailId
        const password = this.state.password
        const orgFocus = null

        const returnedValue = await signIn(email, password, orgFocus)
        if (returnedValue && returnedValue.success) {
          console.log('show returnedValue 1: ', returnedValue)
          // this.queryInfo(this.state.email)

          this.setState({ modalIsOpen: false })
          this.populateInfo(returnedValue.user.email, returnedValue.user, returnedValue.accountInfo)

        } else if (returnedValue && returnedValue.message) {
          this.setState({ isSavingModal: false, errorMessage: returnedValue.message })
        } else {
          console.log('show returnedValue 2: ', returnedValue)
        }
      }
    }

    populateInfo(emailId, user, account) {
      console.log('populateInfo called', emailId, user, account)

      if (emailId && user) {
        // localStorage.setItem('email', emailId)
        // localStorage.setItem('username', user.username)
        // localStorage.setItem('firstName', user.firstName)
        // localStorage.setItem('lastName', user.lastName)
        // localStorage.setItem('roleName', user.roleName)
        // if (user.roleName === 'Employer') {
        //   localStorage.setItem('isEmployer', 'true')
        //   localStorage.setItem('emp', account.accountCode)
        // }
        //
        // localStorage.setItem('activeOrg', account.activeOrg)
        // localStorage.setItem('sharePartners', JSON.stringify([account.sharePartners]))
        // // localStorage.setItem('orgFocus', orgFocus)
        //
        // const contactFirstName = user.firstName
        // const contactLastName = user.lastName
        // const employerName = account.employerName
        // const contactTitle = account.contactTitle
        // const employerLocation = account.employerLocation
        // const employerURL = account.employerURL
        // const employerType = account.employerType
        // const employeeCount = account.employeeCount
        // const industries = account.industries
        // const employerDescription = account.description
        // const employerLogoURI = account.employerLogoURI
        // const contactEmail = emailId
        // const referrerName = account.referrerName
        // let orgContacts = account.orgContacts
        // let contactPhone = ''
        // if (orgContacts) {
        //   for (let i = 1; i <= orgContacts.length; i++) {
        //     if (orgContacts[i - 1].contactEmail === emailId) {
        //       contactPhone = orgContacts[i - 1].contactPhone
        //     }
        //   }
        // }

        // this.setState({ isSavingModal: false,
        //   contactFirstName, contactLastName, employerName, contactTitle, employerLocation, employerURL,
        //   employerType, employeeCount, industries, employerDescription, employerLogoURI,
        //   contactEmail, contactPhone, referrerName
        // })

        const cuFirstName = user.firstName
        const cuLastName = user.lastName
        const emailId = user.email

        let roleName = user.roleName
        let roleNameLinked = null
        if (roleName) {
          if (roleName.toLowerCase() === 'student' || roleName.toLowerCase() === 'career-seeker') {
            roleName = 'Student / Career Seeker'
            roleNameLinked = roleName
          } else if (roleName.toLowerCase() === 'volunteer' || roleName.toLowerCase() === 'mentor' || roleName.toLowerCase() === 'employer') {
            roleName = 'Employer Representative / Mentor'
            roleNameLinked = roleName
          }
        } else {
          if (roleName === 'Student') {
            roleName = "Student / Career Seeker"
          }
        }

        let pictureURL = user.pictureURL
        const jobTitle = user.jobTitle
        const employerName = user.employerName
        const phoneNumber = user.phoneNumber
        const alternativePhoneNumber = user.alternativePhoneNumber
        const alternativeEmail = user.alternativeEmail
        const education = user.education
        const subscribedForReminders = user.subscribedForReminders
        const referrerName = user.referrerName
        const referrerEmail = user.referrerEmail
        const referrerOrg = user.referrerOrg

        let schoolName = null
        let gradYear = null
        let degree = null
        let major = null
        if (user.education && user.education.length > 0) {
          // schoolName = user.education[0].name
          // if (user.education[0].endDate && user.education[0].endDate.split("|")) {
          //   gradYear = user.education[0].endDate.split(" ")[1]
          // }
          // degree = user.education[0].degree
          // major = user.education[0].major
          // // console.log('show me gradYeaar: ', gradYear, major, user.education)
          // if (user.education.length > 1) {
          //   for (let i = 1; i <= user.education.length; i++) {
          //     if (user.education[i - 1] && this.state.education[i - 1].isContinual) {
          //       schoolName = user.education[i - 1].name
          //       if (user.education[i - 1].endDate && user.education[i - 1].endDate.split("|")) {
          //         gradYear = user.education[i - 1].endDate.split(" ")[1]
          //       }
          //       degree = user.education[i - 1].degree
          //       major = user.education[i - 1].major
          //     }
          //   }
          // }

          for (let i = 1; i <= user.education.length; i++) {
            let edObject = user.education[i - 1]
            if (edObject) {
              let tempGradYear = null
              if (edObject.endDate && edObject.endDate.split("|")) {
                tempGradYear = edObject.endDate.split(" ")[1]
              }

              if (edObject.isContinual) {
                schoolName = edObject.name
                gradYear = tempGradYear
                degree = edObject.degree
                major = edObject.major
                break;
              } else if ((tempGradYear && gradYear && tempGradYear > gradYear) || (tempGradYear && !gradYear)) {
                schoolName = edObject.name
                gradYear = tempGradYear
                degree = edObject.degree
                major = edObject.major
              }
            }
          }
        }

        const dateOfBirth = user.dateOfBirth
        const gender = user.gender
        const race = user.race
        const races = user.races
        const selfDescribedRace = user.selfDescribedRace
        const address = user.address
        const zipcode = user.zipcode
        const numberOfMembers = user.numberOfMembers
        const householdIncome = user.householdIncome
        const workAuthorization = user.workAuthorization
        const adversityList = user.adversityList
        const educationStatus = user.educationStatus

        let customAssessment = this.state.customAssessment
        let responses = this.state.responses

        if (customAssessment && customAssessment.questions) {
          let survey = this.state.survey
          for (let i = 1; i <= customAssessment.questions.length; i++) {
            if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'dateOfBirth') || (customAssessment.questions[i - 1].name === 'Date of Birth' || customAssessment.questions[i - 1].name === 'Birth Date' || customAssessment.questions[i - 1].name === 'Birthdate')) {
              responses[survey.questions.length + i - 1] = dateOfBirth
            } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'gender') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'gender')) {
              responses[survey.questions.length + i - 1] = gender
            } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'race') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'race')) {
              responses[survey.questions.length + i - 1] = race
            } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'races') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'races')) {
              responses[survey.questions.length + i - 1] = races
            } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'selfDescribedRace') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'self-described race')) {
              responses[survey.questions.length + i - 1] = selfDescribedRace
            } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'address') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'home address')) {
              responses[survey.questions.length + i - 1] = address
            } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'zipcode') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'zip code')) {
              responses[survey.questions.length + i - 1] = zipcode
            } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'numberOfMembers') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'number of members in household')) {
              responses[survey.questions.length + i - 1] = numberOfMembers
            } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'householdIncome') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'estimated household income')) {
              responses[survey.questions.length + i - 1] = householdIncome
            } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'workAuthorization') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'work authorization')) {
              responses[survey.questions.length + i - 1] = workAuthorization
            } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'adversityList') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'background information')) {
              responses[survey.questions.length + i - 1] = adversityList
            } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'educationStatus') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'education status')) {
              responses[survey.questions.length + i - 1] = educationStatus
            } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'phoneNumber') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'phone number')) {
              responses[survey.questions.length + i - 1] = phoneNumber
            } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'alternativePhoneNumber') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'alternative phone number')) {
              responses[survey.questions.length + i - 1] = alternativePhoneNumber
            } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'alternativeEmail') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'alternative email')) {
              responses[survey.questions.length + i - 1] = alternativeEmail
            } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'referrerName') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'referrer name')) {
              responses[survey.questions.length + i - 1] = referrerName
            } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'referrerEmail') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'referrer email')) {
              responses[survey.questions.length + i - 1] = referrerEmail
            } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'referrerOrg') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'referrer org')) {
              responses[survey.questions.length + i - 1] = referrerOrg
            } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'education|name') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'education name')) {
              responses[survey.questions.length + i - 1] = schoolName
            } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'education|degree') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'education degree')) {
              responses[survey.questions.length + i - 1] = degree
            } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'education|endDate') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'education grad year')) {
              responses[survey.questions.length + i - 1] = gradYear
            } else if ((customAssessment.questions[i - 1].mapToProfile && customAssessment.questions[i - 1].shortname === 'education|major') || (customAssessment.questions[i - 1].name && customAssessment.questions[i - 1].name.toLowerCase() === 'education major')) {
              responses[survey.questions.length + i - 1] = major
            }
          }
        }


        this.setState({
          cuFirstName, cuLastName, emailId, importedEmailId: true, roleName, roleNameLinked,
          pictureURL, jobTitle, employerName, phoneNumber, education,
          subscribedForReminders, schoolName, gradYear, degree, major,
          dateOfBirth, gender, races, address, zipcode, numberOfMembers, householdIncome,
          workAuthorization, adversityList, educationStatus, responses
        })
      }
    }

    async logout() {
      console.log('logout called')

      const returnedValue = await signOut(
        this.state.emailId, this.state.activeOrg, this.state.orgFocus,
        this.state.accountCode, this.state.roleName, this.props.navigate, true
      )

      if (returnedValue && returnedValue.success) {
        console.log('show returnedValue 1: ', returnedValue)
        // this.queryInfo(this.state.email)

        let importedEmailId = null
        let cuFirstName = ''
        let cuLastName = ''
        let emailId = ''
        let password = ''
        let roleName = null
        let roleNameLinked = null
        const otherRoleName = null

        let phoneNumber = null
        let accountCode = null

        let schoolName = null
        let gradYear = null
        let degree = null
        let major = null

        const studentActivityInterests = null
        const teacherActivityInterests = null
        const mentorActivityInterests = null

        let jobTitle = null
        let employerName = null

        const industries = null
        const functions = null
        const subscribedForReminders = null

        let responses = []
        let alreadyRSVPd = false
        console.log('show me cuFirstName', cuFirstName)

        this.setState({ isSavingModal: false, modalIsOpen: false, successMessage: returnedValue.message,
          importedEmailId, cuFirstName, cuLastName, emailId, password, roleName, roleNameLinked, otherRoleName,
          phoneNumber, accountCode, schoolName, gradYear, degree, major, studentActivityInterests,
          teacherActivityInterests, mentorActivityInterests, jobTitle, employerName,
          industries, functions, subscribedForReminders, responses, alreadyRSVPd
        })

      } else if (returnedValue && returnedValue.message) {
        this.setState({ isSavingModal: false, errorMessage: returnedValue.message })
      } else {
        console.log('show returnedValue 2: ', returnedValue)
      }
    }

    renderSubmitProject() {
      console.log('renderSubmitProject called')

      return (
        <div key="renderSubmitProject">
          {(this.state.deadlinePassed || (this.state.registrationPassed && !this.state.hasRegistered)) ? (
            <div>
              <p className="error-message">The deadline has passed for {this.state.selectedOpportunity.name}</p>
              <div className="spacer"/><div className="spacer"/><div className="spacer"/>
            </div>
          ) : (
            <div>
              <div className="bottom-padding">
                <div className={(this.state.subviewIndex === 0) ? "app-title-container-1-of-2-1 selected-item-container-1" : "app-title-container-1-of-2-1 unselected-item-container-1"}>
                  <p className={(this.state.subviewIndex === 0) ? "heading-text-6 cta-color" : "heading-text-6 unselected-color"}><a className="background-button" onClick={() => this.setState({ subviewIndex: 0 })}>Submit New Project</a></p>
                </div>
                <div className={(this.state.subviewIndex === 1) ? "app-title-container-1-of-2-1 margin-five-percent-left selected-item-container-1" : "app-title-container-1-of-2-1 margin-five-percent-left unselected-item-container-1"}>
                  <p className={(this.state.subviewIndex === 1) ? "heading-text-6 cta-color" : "heading-text-6 unselected-color"}><a className="background-button" onClick={() => this.setState({ subviewIndex: 1 })}>Submit Project from Profile</a></p>
                </div>
                <div className="clear" />
              </div>

              {(this.state.subviewIndex === 0) ? (
                <div>
                  <EditProject selectedProject={{ startDate: this.state.dateOptions[this.state.dateOptions.length - 1].value, endDate: this.state.dateOptions[this.state.dateOptions.length - 1].value}}
                    selectedIndex={undefined} selectedOpportunity={this.state.selectedOpportunity} submitted={this.state.submitted}
                    projectCategoryOptions={this.state.projectCategoryOptions} dateOptions={this.state.dateOptions}
                    collaboratorOptions={this.state.collaboratorOptions}  hourOptions={this.state.hourOptions}
                    functionOptions={this.state.functionOptions}  industryOptions={this.state.industryOptions}
                    finishedSubmitting={this.finishedSubmitting} userPic={this.state.pictureURL}
                  />
                </div>
              ) : (
                <div>
                  {(this.state.projectOptions.length > 1) ? (
                    <div>

                      <div className="spacer"/><div className="spacer"/>
                      <select name="selectProject" className="dropdown" value={this.state.selectedProject.name} onChange={this.formChangeHandler}>
                          {this.state.projectOptions.map(value => <option key={value.name} value={value.name}>{value.name}</option>)}
                      </select>
                    </div>
                  ) : (
                    <div>
                      <div className="spacer"/><div className="spacer"/>
                      <p className="error-message">You current don't have projects saved to your profile. Toggle to "Submit New Project" to submit.</p>
                    </div>
                  )}
                </div>
              )}

              {(this.state.subviewIndex === 1) ? (
                <div>
                  {(this.state.subviewIndex === 0) ? (
                    <div>

                    </div>
                  ) : (
                    <div>
                      {this.state.serverSuccessMessage !== '' && <p className="success-message">{this.state.serverSuccessMessage}</p>}
                      {this.state.serverErrorMessage !== '' && <p className="error-message">{this.state.serverErrorMessage}</p>}
                      <button className={(this.state.disableSubmit) ? "btn btn-primary disabled-background disabled-border" : "btn btn-primary"} disabled={this.state.disableSubmit} onClick={() => this.handleSubmit(this.state.selectedOpportunity.postType)}>{this.state.submitted ? "Update Your Solution" : "Submit Your Solution"}</button>
                    </div>
                  )}
                </div>
              ) : (
                <div>

                </div>
              )}
            </div>
          )}
        </div>
      )
    }

    renderRSVPForm(inModal) {
      console.log('renderRSVPForm called', inModal)

      return (
        <div key={'renderRSVPForm'}>
          {(this.state.alreadyRSVPd === true && !this.state.editRSVP) ? (
            <div className="row-10">
              <div className="spacer"/><div className="half-spacer"/>

              {(this.props.pageSource === 'landingPage') ? (
                <div>
                  <p className="error-color row-10">You have already RSVPd for this event and you are registered with the Guided Compass platform.</p>

                  <div className="row-25">
                    <Link className="btn btn-primary cta-background-color white-text" to={this.state.prefix + "/opportunities"}>Navigate to Portal and View Other Opportunities</Link>
                  </div>

                  <p className="row-10 description-text-2 row-10">Is this not you? <button className="background-button cta-color bold-text" onClick={() => this.logout()} disabled={this.state.isSavingModal} >Log out</button></p>

                </div>

              ) : (
                <p className="error-message">You have already RSVPd for this event.</p>
              )}
            </div>
          ) : (
            <div>
              <div>
                <div className="spacer"/><div className="spacer"/><div className="spacer"/>

                {(this.props.pageSource === 'landingPage') && (
                  <div>
                    {(this.state.importedEmailId) ? (
                      <div className="bottom-margin">
                        <p className="description-text-3 top-padding-5">Is this not you? <button className="background-button cta-color bold-text" onClick={() => this.logout()} disabled={this.state.isSavingModal} >Log out</button></p>
                      </div>
                    ) : (
                      <div className="bottom-margin">
                        <p className="description-text-3 top-padding-5">Already have an account? Login to <button className="background-button cta-color bold-text" onClick={() => this.setState({ modalIsOpen: true, login: true })} disabled={this.state.isSavingModal} >import your information</button></p>
                      </div>
                    )}
                  </div>
                )}

                <div className="edit-profile-row">
                  <div className="container-left">
                    <label className="profile-label">First Name<label className="error-color">*</label></label>
                    <input className="text-field" type="text" placeholder="First Name" name="cuFirstName" value={this.state.cuFirstName} onChange={this.formChangeHandler} />
                  </div>
                  <div className="container-right">
                      <label className="profile-label">Last Name<label className="error-color">*</label></label>
                      <input className="text-field" type="text" placeholder="Last Name" name="cuLastName" value={this.state.cuLastName} onChange={this.formChangeHandler}/>
                  </div>
                  <div className="clear" />
                </div>

                {(this.props.pageSource === 'landingPage' && !this.state.importedEmailId) && (
                  <div>
                    <div className="row-10">
                      <div className="container-left">
                        <label className="profile-label">Email<label className="error-color">*</label></label>
                        <input className="text-field" type="text" placeholder="Email" name="emailId" value={this.state.emailId} onChange={this.formChangeHandler} />
                      </div>
                      <div className="container-right">
                        <label className="profile-label">Password<label className="error-color">*</label></label>
                        <input className="text-field date-picker" type="password" placeholder="Password" name="password" value={this.state.password} onChange={this.formChangeHandler} />
                      </div>
                      <div className="clear" />
                    </div>
                    {(!this.state.roleNameLinked) && (
                      <div className="edit-profile-row">
                        <label className="profile-label">Which best describes you?<label className="error-color">*</label></label>
                        {this.state.roleNameOptions.map((value, index) =>
                          <div key={value} className="float-left">
                            <div className="float-left row-5 right-padding">
                              <button className="background-button" onClick={() => this.itemClicked(value,'roleName')}>
                                {(this.state.roleName === value) ? (
                                  <div className="tag-container-4">
                                    <p className="description-text-2 white-text half-bold-text">{value}</p>
                                  </div>
                                ) : (
                                  <div className="tag-container-inactive-2">
                                    <p className="description-text-2 cta-color half-bold-text">{value}</p>
                                  </div>
                                )}
                              </button>
                            </div>
                          </div>
                        )}
                        <div className="clear" />
                      </div>
                    )}

                    {(this.state.roleName && this.state.roleName === 'Other') && (
                      <div className="edit-profile-row">
                        <label className="profile-label">Other - Role Name (Please Specify)<label className="error-color">*</label></label>
                        <input className="text-field" type="text" placeholder="Role Name" name="otherRoleName" value={this.state.responses[4]} onChange={this.formChangeHandler} />
                      </div>
                    )}
                  </div>
                )}

                {(this.state.rsvpLimitReachedLate) ? (
                  <div className="top-margin-30">
                    <p className="error-color">This posting has reached its RSVP limit.</p>
                  </div>
                ) : (
                  <div>
                    {this.renderQuestions(this.state.roleName)}

                    <div className="half-spacer"/>

                    {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color row-5 description-text-2">{this.state.errorMessage}</p>}
                    {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color row-5 description-text-2">{this.state.successMessage}</p>}

                    <button className={(this.state.disableSubmit) ? "btn btn-primary disabled-background disabled-border" : "btn btn-primary"} disabled={this.state.disableSubmit} onClick={() => this.handleSubmit(this.state.selectedOpportunity.postType)}>Register</button>
                    {(inModal) && (
                      <button className="btn btn-secondary left-margin" onClick={() => this.closeModal()}>Close Modal</button>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )
    }

    commandClicked(command) {
      console.log('commandClicked called')

      if (command) {
        let resumeContent = ''
        if (this.state.education && this.state.education.length > 0) {
          resumeContent = resumeContent + 'My education: ' + JSON.stringify(this.state.education)
        }

        if (this.state.projects && this.state.projects.length > 0) {
          resumeContent = resumeContent + 'My projects: ' + JSON.stringify(this.state.projects)
        }

        if (this.state.experience && this.state.experience.length > 0) {
          resumeContent = resumeContent + 'My experience: ' + JSON.stringify(this.state.experience)
        }

        resumeContent = resumeContent + '.'

        // education, experience, goals
        command = command.replace(/{{profile}}/g,resumeContent)
      }

      this.props.commandClicked(command)

    }

    checkWhetherDatePassed(passedOpportunity) {
      console.log('checkWhetherDatePassed called', passedOpportunity.endDate)

      let deadlinePassed = false
      if (passedOpportunity.endDate) {
        if (new Date().getTime() > new Date(passedOpportunity.endDate).getTime()) {
          deadlinePassed = true
        }
      }

      return deadlinePassed
    }

    render() {

      let registerLink = '/organizations/' + this.state.activeOrg + '/career-seeker/join'
      if (!this.state.activeOrg || this.state.activeOrg === '') {
        registerLink = '/join'
      }

      let showApply = true
      let alreadyRSVPd = false
      // let isActive = true
      let applyText = 'Apply'
      let applyLink = this.state.prefix + '/opportunities'
      let applyState = null
      let applyButton = null
      let showApplyTwo = false
      let applyText2 = null
      let applyLink2 = ''
      let applyButton2 = null
      if (this.state.selectedOpportunity) {
        if (this.state.selectedOpportunity.submissionDeadline && new Date(this.state.selectedOpportunity.submissionDeadline) < new Date()) {
          showApply = false
          applyText = "The deadline has passed for this opportunity"
          // applyText = "Submit Project"
          // applyButton = { modalIsOpen: true, showSubmitProject: true }
        } else if (!this.state.selectedOpportunity.isActive) {
          showApply = false
        } else if (this.state.selectedOpportunity.postType === 'Event' && this.checkWhetherDatePassed(this.state.selectedOpportunity)) {
          showApply = false
          applyText = "The date for this event has passed"
        } else {

          if (this.state.selectedOpportunity.postType === 'Work' || this.state.selectedOpportunity.postType === 'Internship' || this.state.selectedOpportunity.postType === 'Individual' || this.state.selectedOpportunity.postType === 'Program') {
            if (this.props.path && this.props.path.includes('/advisor')) {
              applyText = 'Endorse'
              applyLink = '/advisor/endorsements/new'
              applyState = { benchmark: this.state.benchmark }
            } else {

              if (this.props.pageSource === 'landingPage' && !this.state.emailId && !this.state.selectedOpportunity.isPromotional && (this.state.roleName !== 'Student' && this.state.roleName !== 'Career-Seeker')) {

                applyText = "Register to Apply"
                applyLink = registerLink
                applyState = { opportunityId: this.state.selectedOpportunity._id, opportunityOrg: this.state.activeOrg }

                if (this.state.selectedOpportunity.applicationMethod === "Applicants may be referred, but they must also submit to our posting" || this.state.selectedOpportunity.applicationMethod === "Applicants may be referred, but they must also apply via our website posting" || this.state.selectedOpportunity.applicationMethod === "Applicants may be referred, but they must also apply via email") {
                  showApplyTwo = true
                  if (this.state.selectedOpportunity.applicationMethod === "Applicants may be referred, but they must also apply via email") {
                    // applyText = "Apply via Guided Compass"
                    applyText2 = "Also Apply via Email"
                    applyButton2 = { modalIsOpen: true, showApplyViaEmail: true }
                  } else {
                    // applyText = "Apply via Guided Compass"
                    applyText2 = "Also Apply via Their Website"
                    applyLink2 = this.state.selectedOpportunity.jobLink
                  }
                }
              } else {
                applyLink = this.state.prefix + '/postings/' + this.state.selectedOpportunity._id + '/apply'
                applyState = { selectedPosting: this.state.selectedOpportunity, application: this.state.application, referralCode: this.state.referralCode }
                if (this.state.selectedOpportunity.isPromotional && !window.location.pathname.includes('/app')) {
                  applyLink = '/opportunities/organizations/' + this.state.activeOrg + '/' + this.state.selectedOpportunity._id + '/apply'
                  applyState = { selectedOpportunity: this.state.selectedOpportunity, referralCode: this.state.referralCode }
                }
                if (this.state.hasApplied) {
                  applyText = "Update"
                } else {
                  applyText = "Apply"
                }

                if (this.state.selectedOpportunity.applicationMethod === "Applicants may be referred, but they must also submit to our posting" || this.state.selectedOpportunity.applicationMethod === "Applicants may be referred, but they must also apply via our website posting" || this.state.selectedOpportunity.applicationMethod === "Applicants may be referred, but they must also apply via email") {
                  showApplyTwo = true
                  if (this.state.selectedOpportunity.applicationMethod === "Applicants may be referred, but they must also apply via email") {
                    applyText = "Apply via Guided Compass"
                    applyText2 = "Also Apply via Email"
                    applyButton2 = { modalIsOpen: true, showApplyViaEmail: true }
                  } else {
                    applyText = "Apply via Guided Compass"
                    applyText2 = "Also Apply via Their Website"
                    applyLink2 = this.state.selectedOpportunity.jobLink
                  }
                }
              }
            }
          } else if (this.state.selectedOpportunity.postType === "Project" || this.state.selectedOpportunity.postType === "Assignment" || this.state.selectedOpportunity.postType === "Problem" || this.state.selectedOpportunity.postType === "Challenge") {
            if (this.props.pageSource === 'landingPage' && !this.state.emailId && (this.state.roleName !== 'Student' && this.state.roleName !== 'Career-Seeker')) {
              applyText = "Register to Submit"
              applyLink = registerLink
              applyState = { opportunityId: this.state.selectedOpportunity._id, opportunityOrg: this.state.activeOrg }
            } else {
              applyText = "Submit Project"
              if (this.state.submitted) {
                applyText = "Update Project"
              }
              applyButton = { modalIsOpen: true, showSubmitProject: true }
            }
          } else if (this.state.selectedOpportunity.postType === "Event") {
            applyText = "RSVP"
            applyButton = { modalIsOpen: true, showRSVPForm: true }
            if (this.state.alreadyRSVPd === true && !this.state.editRSVP) {
              alreadyRSVPd = true
            }
          }
        }
      }

      let relatedOpportunitiesPathPrefix = '/app/opportunities/'
      if (window.location.pathname.includes('/problem-platform')) {
        relatedOpportunitiesPathPrefix = '/problem-platform/'
      } else if (this.props.pageSource === 'landingPage') {
        relatedOpportunitiesPathPrefix = '/opportunities/organizations/' + this.state.activeOrg + '/'
      } else if (window.location.pathname.includes('/advisor')) {
        relatedOpportunitiesPathPrefix = '/advisor/opportunities/'
      }

      // let mainClass = "standard-container-2"
      // let mainStyle = {}
      // let mainClass2 = "width-70-percent center-horizontally max-width-1400"
      // let containerClass = ""
      // let subClass = "full-width"
      // if (window.innerWidth > 768 && this.state.showCompassAssistant && !this.state.disableChatbotPrompts && window.location.pathname.startsWith('/app') && this.state.endorsements && this.state.endorsements.length > 0) {
      //   containerClass = "relative-column-70 padding-five-percent-left"
      //   mainClass = ""
      //   mainClass2 = ""
      //   subClass = "card-clear-padding slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-right"
      // }

      return (
          <div>
            {(this.state.isLoading) ? (
              <div className="flex-container flex-center full-space">
                <div>
                  <div className="super-spacer" />

                  <img src={loadingGIF} alt="GC" className="image-auto-80 center-horizontally"/>
                  <div className="spacer" /><div className="spacer" /><div className="spacer" />
                  <p className="center-text cta-color bold-text">Loading...</p>

                </div>
              </div>
            ) : (
              <div>
                <div className={(window.innerWidth > 768 && !this.props.inModal) ? "relative-column-70 padding-five-percent-left" : "full-width"}>
                  {(this.state.selectedOpportunity && this.state.selectedOpportunity.isActive !== false) ? (
                    <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally top-margin-40"}>
                      <div className="full-width height-5 primary-background" />
                      <div className="padding-40">
                        <div className="spacer"/><div className="spacer"/><div className="spacer"/>

                        {(this.state.selectedOpportunity.postType === 'Internship' || this.state.selectedOpportunity.postType === 'Individual' || this.state.selectedOpportunity.postType === 'Track' || this.state.selectedOpportunity.postType === 'Work' || this.state.selectedOpportunity.postType === 'Program') && (
                          <div>
                            <div>
                              <div className="flex-container">

                                {(!this.state.selectedOpportunity.useImageTitle) && (
                                  <div className="flex-10 height-40">
                                    <div>
                                      {(this.state.selectedOpportunity.imageURL) && (
                                        <img src={this.state.selectedOpportunity.imageURL} alt="GC" className={(window.innerWidth > 768) ? "image-100-fit" : "image-30-fit"}/>
                                      )}
                                    </div>
                                  </div>
                                )}

                                <div className={(this.state.selectedOpportunity.useImageTitle) ? "flex-90" : "flex-80"}>
                                  {(this.state.selectedOpportunity.useImageTitle) ? (
                                    <div className="bottom-margin-20">
                                      {(this.state.selectedOpportunity.imageTitleURL) ? (
                                        <img src={this.state.selectedOpportunity.imageTitleURL} alt="GC" className={(window.innerWidth > 768) ? "image-half-auto" : "image-full-auto"} />
                                      ) : (
                                        <img src={this.state.selectedOpportunity.imageURL} alt="GC" className={(window.innerWidth > 768) ? "image-half-auto" : "image-full-auto"} />
                                      )}
                                      {(!this.state.selectedOpportunity.hideTextTitle) && (
                                        <div className="top-margin-20">
                                          <h3>{this.state.selectedOpportunity.title}{(this.state.selectedOpportunity.employerName && this.state.selectedOpportunity.employerName !== 'No Employer Selected') && " @ " + this.state.selectedOpportunity.employerName}</h3>
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <div>
                                      {(this.state.selectedOpportunity.postType === 'Track' || this.state.selectedOpportunity.subPostType === 'Track') ? (
                                        <h2>{this.state.selectedOpportunity.title}</h2>
                                      ) : (
                                        <h2>{this.state.selectedOpportunity.title}{(this.state.selectedOpportunity.employerName && this.state.selectedOpportunity.employerName !== 'No Employer Selected') && " @ " + this.state.selectedOpportunity.employerName}</h2>
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="flex-10 right-text">
                                  {(this.state.pageSource === 'landingPage' && !this.state.selectedOpportunity.isPromotional) ? (
                                    <div>
                                      {(this.state.selectedOpportunity.orgCode !== 'guidedcompass') && (
                                        <div>
                                          <Link className="background-button" to={"/partners/" + this.state.selectedOpportunity.orgCode}>
                                            <img src={this.state.orgLogo} alt="GC" className="image-full-auto" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Learn more about our partnership" />
                                          </Link>
                                          <ReactTooltip id="tooltip-placeholder-id" />
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <button className="btn background-button" onClick={() => this.favoriteItem(this.state.selectedOpportunity,this.state.selectedOpportunity.postType.toLowerCase()) }>
                                      <img src={(this.state.favorites.includes(this.state.selectedOpportunity._id)) ? favoritesIconBlue : favoritesIconGrey} alt="GC" className="image-auto-20"/>
                                    </button>
                                  )}
                                </div>
                                <div className="clear" />
                              </div>

                              {this.state.hasApplied ? (
                                <div className="horizontal-padding-3">
                                  <div className="half-spacer"/>
                                  <p className="error-color center-text bold-text">***You have already applied to this posting. If the recipient is interested, they will contact you. Look for updates via email. You can update your application at any time by resubmitting. If you have any technical questions, email help@guidedcompass.com.***</p>
                                  <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>
                                </div>
                              ) : (
                                <div>
                                  {(this.state.selectedOpportunity.orgCode === 'unite-la') && (
                                    <div>
                                      {(this.state.selectedOpportunity.postType === 'Track' || this.state.selectedOpportunity.subPostType === 'Track') ? (
                                        <div>
                                          <p className="center-text cta-color bold-color">***This is a common application. You must apply to this to apply to any roles attached to this program.***</p>
                                          <div className="spacer"/><div className="spacer"/>
                                        </div>
                                      ) : (
                                        <div>
                                          {(!this.state.isApproved && this.state.pageSource !== 'landingPage' && this.state.selectedOpportunity.isChild) && (
                                            <div>
                                              <p className="error-color center-text bold-text">***This can only be applied to after you have been approved to this track attached to the {(this.state.parentPost) ? <Link className="secondary-link cta-color clear-border" to={this.state.prefix + '/opportunities/' + this.state.parentPost._id} state={{ selectedOpportunity: this.state.parentPost }}>{this.state.selectedOpportunity.orgName} posting</Link> : this.state.selectedOpportunity.orgName + " posting"}. Apply then await approval from their team administrator.***</p>
                                              <div className="spacer"/><div className="spacer"/>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}

                              <div className="spacer"/><div className="spacer"/>

                              <SubRenderOpportunityDetails history={this.props.navigate} activeOrg={this.state.activeOrg} favorites={this.state.favorites} selectedOpportunity={this.state.selectedOpportunity} oppStats={this.state.oppStats} />

                              {(!this.state.selectedOpportunity.isPromotional && this.state.benchmark && this.state.selectedOpportunity.workflowType !== 'Common App') && (
                                <div>
                                  <div className="spacer"/><div className="spacer"/>
                                  <hr />
                                  <div className="spacer"/><div className="spacer"/><div className="spacer"/>
                                </div>
                              )}

                              <div className="left-padding-5">
                                <div className="spacer"/>
                                { (this.state.sessionErrorMessage!== '') && <p className="error-message">{this.state.sessionErrorMessage}</p> }
                                { (this.state.serverPostSuccess) ? (
                                  <p className="success-message">{this.state.serverSuccessMessage}</p>
                                ) : (
                                  <p className="error-message">{this.state.serverErrorMessage}</p>
                                )}
                                {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color top-padding full-width">{this.state.errorMessage}</p>}
                                {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color top-padding full-width">{this.state.successMessage}</p>}

                                {(window.location.pathname.includes('/advisor')) ? (
                                  <div>
                                    <div className="left-padding-5 right-padding-5">
                                      <p className="heading-text-3">Endorse a Student for this Posting</p>
                                      <div className="spacer"/><div className="spacer"/>
                                    </div>

                                    <div>
                                      <div className="spacer"/><div className="spacer"/>
                                      <p>Endorsing a student allows him / her to automatically import that endorsement into scholarship and internship applications.{(this.state.benchmark) && " Clicking the button below will auto-populate the skills and traits information relevant to this posting so that you provide a relevant endorsement."}</p>
                                      <div className="spacer"/><div className="spacer"/><div className="spacer"/><div className="spacer"/>
                                    </div>

                                    <Link className="clear-border" to={'/advisor/endorsements/new'} state={{ benchmark: this.state.benchmark }}><button className="btn btn-primary">Endorse a Student</button></Link>
                                  </div>
                                ) : (
                                  <div>
                                    {this.state.hasApplied ? (
                                      <div>
                                        <p className="error-color center-text bold-text">***You have already applied to this posting. If the recipient is interested, they will contact you. Look for updates via email. You can update your application at any time by resubmitting. If you have any technical questions, email help@guidedcompass.com.***</p>
                                        <div className="spacer"/><div className="spacer"/>
                                        <div>
                                          {this.state.selectedOpportunity.isChild ? (
                                            <div className="flex-container flex-center">
                                              <Link className="clear-border" to={this.state.prefix + '/postings/' + this.state.selectedOpportunity._id + '/apply'} state={{ selectedPosting: this.state.selectedOpportunity, application: this.state.application, referralCode: this.state.referralCode }}><button className="btn btn-primary">Update</button></Link>
                                            </div>
                                          ) : (
                                            <div className="flex-container flex-center">
                                              {(this.props.pageSource === 'landingPage' && this.state.selectedOpportunity.isPromotional) ? (
                                                <Link className="clear-border" to={'/opportunities/organizations/' + this.state.activeOrg + '/' + this.state.selectedOpportunity._id + '/apply'} state={{ selectedPosting: this.state.selectedOpportunity, application: this.state.application, referralCode: this.state.referralCode }}><button className="btn btn-primary">Update</button></Link>
                                              ) : (
                                                <Link className="clear-border" to={this.state.prefix + '/postings/' + this.state.selectedOpportunity._id + '/apply'} state={{ selectedPosting: this.state.selectedOpportunity, application: this.state.application, referralCode: this.state.referralCode }}><button className="btn btn-primary">Update</button></Link>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    ):(
                                      <div>
                                        {(this.state.selectedOpportunity.isActive === false) ? (
                                          <div>
                                            <p className="error-color">This posting has been marked inactive (i.e., it is not taking any new applications).</p>
                                          </div>
                                        ) : (
                                          <div>
                                            {(this.state.pageSource === 'landingPage' && !this.state.emailId && (this.state.roleName !== 'Student' && this.state.roleName !== 'Career-Seeker')) ? (
                                              <div>
                                                {(this.state.selectedOpportunity.isPromotional) ? (
                                                  <Link className="clear-border" to={'/opportunities/organizations/' + this.state.activeOrg + '/' + this.state.selectedOpportunity._id + '/apply'} state={{ selectedOpportunity: this.state.selectedOpportunity, referralCode: this.state.referralCode }}>
                                                    <button className="btn btn-primary">
                                                      Apply Now
                                                    </button>
                                                  </Link>
                                                ) : (
                                                  <Link className="clear-border" to={registerLink} state={{ opportunityId: this.state.selectedOpportunity._id, opportunityOrg: this.state.activeOrg }}>
                                                    <button className="btn btn-primary">
                                                      Register to Apply
                                                    </button>
                                                  </Link>
                                                )}

                                                {(!this.state.remoteAuth && this.state.selectedOpportunity.allowReferrals !== false) && (
                                                  <button className={(window.innerWidth > 768) ? "btn btn-tertiary left-margin" : "btn btn-tertiary top-margin-15"} onClick={() => this.setState({ modalIsOpen: true, showReferSomeone: true })}>Refer Someone</button>
                                                )}
                                              </div>
                                            ) : (
                                              <div>
                                                {this.state.selectedOpportunity.isChild ? (
                                                  <div>
                                                    {this.state.isApproved ? (
                                                      <div>
                                                        <Link className="clear-border" to={this.state.prefix + '/postings/' + this.state.selectedOpportunity._id + '/apply'} state={{ selectedPosting: this.state.selectedOpportunity, referralCode: this.state.referralCode }}><button className="btn btn-primary">Submit Application for Referral</button></Link>
                                                      </div>
                                                    ) : (
                                                      <div>
                                                        <p className="error-color center-text bold-text">***You must apply to the {this.state.orgName} general posting and then get approved before applying for this specific posting.***</p>
                                                        {(this.state.parentPost) && (
                                                          <div className="center-text">
                                                            <div className="spacer"/><div className="spacer"/>
                                                            <Link className="secondary-link clear-border" to={this.state.prefix + '/opportunities/' + this.state.parentPost._id} state={{ selectedOpportunity: this.state.parentPost }}><button className="btn btn-primary">View {this.state.selectedOpportunity.orgName} General Posting</button></Link>
                                                          </div>
                                                        )}
                                                      </div>
                                                    )}
                                                  </div>
                                                ) : (
                                                  <div>
                                                    {(this.state.selectedOpportunity.postType === 'Track' || this.state.selectedOpportunity.subPostType === 'Track') ? (
                                                      <Link className="clear-border" to={this.state.prefix + '/postings/' + this.state.selectedOpportunity._id + '/apply'} state={{ selectedPosting: this.state.selectedOpportunity, application: this.state.application, referralCode: this.state.referralCode }}><button className="btn btn-primary">Apply</button></Link>
                                                    ) : (
                                                      <div>
                                                        {(this.state.selectedOpportunity.submissionDeadline && new Date(this.state.selectedOpportunity.submissionDeadline) < new Date()) ? (
                                                          <div>
                                                            <p className="error-color">The deadline has passed for this opportunity</p>
                                                          </div>
                                                        ) : (
                                                          <div>

                                                            {(this.state.selectedOpportunity.direct) ? (
                                                              <Link className="clear-border float-left" to={this.state.prefix + '/postings/' + this.state.selectedOpportunity._id + '/apply'} state={{ selectedPosting: this.state.selectedOpportunity, application: this.state.application, referralCode: this.state.referralCode }}><button className="btn btn-primary">Apply</button></Link>
                                                            ) : (
                                                              <div>

                                                                {(this.state.selectedOpportunity.applicationMethod === "Applicants may be referred, but they must also submit to our posting" || this.state.selectedOpportunity.applicationMethod === "Applicants may be referred, but they must also apply via our website posting" || this.state.selectedOpportunity.applicationMethod === "Applicants may be referred, but they must also apply via email") ? (
                                                                  <Link className="clear-border float-left" to={this.state.prefix + '/postings/' + this.state.selectedOpportunity._id + '/apply'} state={{ selectedPosting: this.state.selectedOpportunity, application: this.state.application, referralCode: this.state.referralCode }}><button className="btn btn-primary">{(this.state.showReferralText) ? "Request a " + this.state.selectedOpportunity.orgName + " Referral" : "Apply"}</button></Link>
                                                                ) : (
                                                                  <Link className="clear-border float-left" to={this.state.prefix + '/postings/' + this.state.selectedOpportunity._id + '/apply'} state={{ selectedPosting: this.state.selectedOpportunity, application: this.state.application, referralCode: this.state.referralCode }}><button className="btn btn-primary">Apply</button></Link>
                                                                )}
                                                              </div>
                                                            )}

                                                            {((this.state.selectedOpportunity.applicationMethod === "Applicants may be referred, but they must also submit to our posting" || this.state.selectedOpportunity.applicationMethod === "Applicants may be referred, but they must also apply via our website posting") && (this.state.selectedOpportunity.jobLink)) && (
                                                              <div>
                                                                <button className={(window.innerWidth > 768) ? "btn btn-secondary left-margin-15 float-left" : "btn btn-secondary top-margin-15"} onClick={() => window.open(this.state.selectedOpportunity.jobLink)}>
                                                                  <div className="float-left">
                                                                    <div className='mini-spacer' /><div className='mini-spacer' />
                                                                    <img src={websiteIconDark} alt="GC" className="image-auto-19" />
                                                                  </div>
                                                                  <div className="float-left left-padding">Apply via their Website</div>
                                                                  <div className="clear" />
                                                                </button>
                                                              </div>
                                                            )}

                                                            {((this.state.selectedOpportunity.applicationMethod === "Applicants may be referred, but they must also apply via email") && (this.state.selectedOpportunity.employerContactEmail)) && (
                                                              <div>
                                                                <button className={(window.innerWidth > 768) ? "btn btn-secondary left-margin-15 float-left" : "btn btn-secondary top-margin-15"} onClick={() => this.setState({ modalIsOpen: true, showApplyViaEmail: true })}>
                                                                  <div className="float-left">
                                                                    <div className='mini-spacer' /><div className='mini-spacer' />
                                                                    <img src={sendIcon} alt="GC" className="image-auto-19" />
                                                                  </div>
                                                                  <div className="float-left left-padding">Apply via Email</div>
                                                                  <div className="clear" />
                                                                </button>
                                                              </div>
                                                            )}


                                                            {/*
                                                            {(this.state.selectedOpportunity.benchmarkId && window.location.pathname.includes(this.state.prefix + '/opportunities') && !this.state.selectedOpportunity.hideViewMatch) && (
                                                              <button className={(window.innerWidth > 768) ? "btn btn-primary senary-background cta-border cta-color left-margin-15 float-left" : "btn btn-primary senary-background cta-border cta-color top-margin-15"} onClick={() => this.setState({ modalIsOpen: true, showMatch: true })}>
                                                                <div className="float-left">
                                                                  <div className='mini-spacer' /><div className='mini-spacer' />
                                                                  <img src={benchmarksIconDark} alt="GC" className="image-auto-19" />
                                                                </div>
                                                                <div className="float-left left-padding">View Match</div>
                                                                <div className="clear" />
                                                              </button>
                                                            )}

                                                            {(!this.state.remoteAuth && this.state.selectedOpportunity.allowReferrals !== false) && (
                                                              <button className={(window.innerWidth > 768) ? "btn btn-tertiary left-margin-15 float-left" : "btn btn-tertiary top-margin-15"} onClick={() => this.setState({ modalIsOpen: true, showReferSomeone: true })}>Refer Someone</button>
                                                            )}*/}
                                                            <div className="clear" />
                                                          </div>
                                                        )}
                                                      </div>
                                                    )}

                                                  </div>
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        )}

                                      </div>
                                    )}
                                  </div>
                                )}

                                <div className="spacer"/><div className="spacer"/><div className="spacer"/>

                              </div>
                              {this.state.serverSuccessMessage !== '' && <p className="success-message">{this.state.serverSuccessMessage}</p>}
                              {this.state.serverErrorMessage !== '' && <p className="error-message">{this.state.serverErrorMessage}</p>}

                            </div>
                          </div>
                        )}

                        {(this.state.selectedOpportunity.postType === 'Project' || this.state.selectedOpportunity.postType === 'Assignment' || this.state.selectedOpportunity.postType === 'Problem' || this.state.selectedOpportunity.postType === 'Challenge') && (
                          <div>

                            <div className="spacer"/><div className="spacer"/><div className="spacer"/>

                            <div>
                              <div className="relative-column-80">
                                <label className="heading-text-2">{this.state.selectedOpportunity.name}</label>

                                <div className="clear" />
                                <div className="spacer" />

                                <div className="float-left">
                                  <label className="description-text-1">{this.state.selectedOpportunity.contributorFirstName} {this.state.selectedOpportunity.contributorLastName}, {this.state.selectedOpportunity.contributorTitle}</label>
                                </div>

                                {(this.state.selectedOpportunity.employerName) && (
                                  <div className="float-left left-padding-5">
                                    {(this.state.selectedOpportunity.employerURL && this.state.selectedOpportunity.employerURL.includes('http')) ? (
                                      <label className="description-text-1">@ <a href={this.state.selectedOpportunity.employerURL} target="_blank">{this.state.selectedOpportunity.employerName}</a></label>
                                    ) : (
                                      <label className="description-text-1">@ {this.state.selectedOpportunity.employerName}</label>
                                    )}
                                  </div>
                                )}

                                <div className="clear" />
                              </div>

                              <div className="relative-column-20 right-text">
                                {(!this.props.inModal) && (
                                  <div>
                                    {(this.state.selectedOpportunity.postType === 'Challenge' && this.state.selectedOpportunity.prizes && this.state.selectedOpportunity.prizes[0]) ? (
                                      <div>
                                        <label className="heading-text-2 cta-color">${this.state.selectedOpportunity.prizes[0]}</label>
                                      </div>
                                    ) : (
                                      <div className="float-right full-width">
                                        {(this.state.pageSource === 'landingPage') ? (
                                          <Link className="background-button" to={registerLink} state={{ opportunityId: this.state.selectedOpportunity._id, opportunityOrg: this.state.activeOrg }}>
                                            <div className="standard-border rounded-corners">
                                              <div className="float-left padding-7">
                                                <img src={(this.state.selectedOpportunity.upvotes.includes(this.state.emailId)) ? upvoteIconBlue : upvoteIconGrey} alt="GC" className="image-auto-15"/>
                                              </div>
                                              <div className="vertical-separator-4" />
                                              <div className="float-left horizontal-padding-10">
                                                <div className="half-spacer" />
                                                <p className="description-text-2 half-bold-text">{this.state.selectedOpportunity.upvotes.length}</p>
                                              </div>
                                              <div className="clear" />
                                            </div>
                                          </Link>
                                        ) : (
                                          <button className="background-button clear-padding display-block pin-right" onClick={() => this.voteOnItem(this.state.selectedOpportunity, 'up', 0) }>
                                            <div className="standard-border rounded-corners">
                                              <div className="float-left padding-7">
                                                <img src={(this.state.selectedOpportunity.upvotes.includes(this.state.emailId)) ? upvoteIconBlue : upvoteIconGrey} alt="GC" className="image-auto-15"/>
                                              </div>
                                              <div className="vertical-separator-4" />
                                              <div className="float-left horizontal-padding-10">
                                                <div className="half-spacer" />
                                                <p className="description-text-2 half-bold-text">{this.state.selectedOpportunity.upvotes.length}</p>
                                              </div>
                                              <div className="clear" />
                                            </div>
                                          </button>
                                        )}
                                      </div>
                                    )}

                                    {(this.state.pageSource === 'landingPage') ? (
                                      <Link className="background-button" to={registerLink} state={{ opportunityId: this.state.selectedOpportunity._id, opportunityOrg: this.state.activeOrg }}>
                                        {(this.state.emailId) && (
                                          <div className="standard-border rounded-corners">
                                            <div className="float-left row-5 horizontal-padding-10 center-text">
                                              <p className="description-text-3 half-bold-text">Follow</p>
                                            </div>
                                            <div className="clear" />
                                          </div>
                                        )}
                                      </Link>
                                    ) : (
                                      <button className="background-button clear-padding display-block float-right top-margin" onClick={() => this.favoriteItem(this.state.selectedOpportunity, "project") }>
                                        {(this.state.favorites.includes(this.state.selectedOpportunity._id)) ? (
                                          <div className="cta-border cta-background-color rounded-corners">
                                            <div className="float-left row-7 left-padding-5 right-padding-5">
                                              <img src={checkmarkIconWhite} alt="GC" className="image-auto-12"/>
                                            </div>
                                            <div className="float-left row-5 right-padding-10 center-text">
                                              <p className="description-text-3 half-bold-text white-text">Followed</p>
                                            </div>
                                            <div className="clear" />
                                          </div>
                                        ) : (
                                          <div className="standard-border rounded-corners">
                                            {(this.state.emailId) && (
                                              <div className="float-left row-5 horizontal-padding-10 center-text">
                                                <p className="description-text-3 half-bold-text">{(this.state.emailId) ? "Follow" : "Register"}</p>
                                              </div>
                                            )}

                                            <div className="clear" />
                                          </div>
                                        )}
                                      </button>
                                    )}
                                  </div>
                                )}
                              </div>

                              <div className="clear" />
                            </div>

                            <div className="super-spacer"/>

                            <SubRenderOpportunityDetails history={this.props.navigate} activeOrg={this.state.activeOrg} favorites={this.state.favorites} selectedOpportunity={this.state.selectedOpportunity} oppStats={this.state.oppStats} showHeader={true} showBody={null} />

                            <div>
                              <div>
                                <div className={(this.state.viewIndex === 0) ? "app-title-container-1-of-2-1 selected-item-container-1" : "app-title-container-1-of-2-1 unselected-item-container-1"}>
                                  <p className={(this.state.viewIndex === 0) ? "heading-text-4 cta-color" : "heading-text-4 unselected-color"}><a className="background-button" onClick={() => this.setState({ viewIndex: 0 })}>Details</a></p>
                                </div>

                                {(window.location.pathname.includes('/app') || this.state.roleName === 'Student') && (
                                  <div className={(this.state.viewIndex === 1) ? "app-title-container-1-of-2-1 margin-five-percent-left selected-item-container-1" : "app-title-container-1-of-2-1 margin-five-percent-left unselected-item-container-1"}>
                                    <p className={(this.state.viewIndex === 1) ? "heading-text-4 cta-color" : "heading-text-4 unselected-color"}><a className="background-button" onClick={() => this.setState({ viewIndex: 1 })}>{(this.state.submitted) ? "Update your" : "Submit a"} Project</a></p>
                                  </div>
                                )}

                                {(this.state.onMobile) && (
                                  <div className="clear" />
                                )}
                                <div className={(this.state.viewIndex === 2) ? "app-title-container-1-of-2-1 margin-five-percent-left selected-item-container-1" : "app-title-container-1-of-2-1 margin-five-percent-left unselected-item-container-1"}>

                                  { ( !this.state.selectedOpportunity.submissions || this.state.selectedOpportunity.submissions.length === 0 ) ? (
                                    <p className={(this.state.viewIndex === 2) ? "heading-text-4 cta-color" : "heading-text-4 unselected-color"}><a className="background-button" onClick={() => this.setState({ viewIndex: 2 })}>Submissions</a></p>
                                  ) : (
                                    <div>
                                      <p className={(this.state.viewIndex === 2) ? "heading-text-4 cta-color float-left margin-right-3" : "heading-text-4 unselected-color float-left margin-right-3"}><a className="background-button" onClick={() => this.setState({ viewIndex: 2 })}>Submissions</a></p>
                                      {(this.state.viewIndex === 2) ? (
                                        <div className="noti-bubble selected-background description-text-4 float-left">{this.state.selectedOpportunity.submissions.length}</div>
                                      ) : (
                                        <div className="noti-bubble unselected-background description-text-4 float-left">{this.state.selectedOpportunity.submissions.length}</div>
                                      )}
                                    </div>
                                  )}
                                </div>

                                <div className={(this.state.viewIndex === 3) ? "app-title-container-1-of-2-1 margin-five-percent-left selected-item-container-1" : "app-title-container-1-of-2-1 margin-five-percent-left unselected-item-container-1"}>
                                  { ( this.state.comments.length === 0 ) ? (
                                    <p className={(this.state.viewIndex === 3) ? "heading-text-4 cta-color" : "heading-text-4 unselected-color"}><a className="background-button" onClick={() => this.setState({ viewIndex: 3 })}>Comments</a></p>
                                  ) : (
                                    <div>
                                      <p className={(this.state.viewIndex === 3) ? "heading-text-4 cta-color float-left margin-right-3" : "heading-text-4 unselected-color float-left margin-right-3"}><a className="background-button" onClick={() => this.setState({ viewIndex: 3 })}>Comments</a></p>
                                      {(this.state.viewIndex === 3) ? (
                                        <div className="noti-bubble selected-background description-text-4 float-left">{this.state.comments.length}</div>
                                      ) : (
                                        <div className="noti-bubble unselected-background description-text-4 float-left">{this.state.comments.length}</div>
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="clear" />
                              </div>

                              <div className="spacer"/><div className="half-spacer"/>

                              {(this.state.viewIndex === 0) && (
                                <div>

                                  <SubRenderOpportunityDetails history={this.props.navigate} activeOrg={this.state.activeOrg} favorites={this.state.favorites} selectedOpportunity={this.state.selectedOpportunity} oppStats={this.state.oppStats} showHeader={null} showBody={true} />

                                  <div className="spacer"/><div className="spacer"/><div className="spacer"/>
                                  <hr className="cta-border-color" />

                                  <div className="spacer"/><div className="half-spacer"/>

                                  {(this.state.selectedOpportunity.postType === 'Challenge') && (
                                    <div>
                                      {(this.state.registrationPassed || this.state.hasRegistered) ? (
                                        <div>

                                          {(this.state.hasRegistered) ? (
                                            <p className="cta-color">You have already registered for this challenge.</p>
                                          ) : (
                                            <p className="error-color">Registration has passed for this challenge.</p>
                                          )}

                                          <div className="spacer"/><div className="spacer"/>
                                          <hr className="cta-border-color" />
                                          <div className="spacer"/><div className="spacer"/>
                                        </div>
                                      ) : (
                                        <div>
                                          {(this.state.emailId || (this.state.selectedOpportunity.isPublic && !this.state.emailId)) && (
                                            <div>
                                              <div className="top-padding-20">
                                                <p className="heading-text-3">Register for Challenge</p>
                                              </div>

                                              <div className="row-5">
                                                <div className="row-10">
                                                  <div>
                                                    <div className={(window.innerWidth > 768) ? "float-left" : "calc-column-offset-45"}>
                                                      <label className="profile-label">Which best describes you?<label className="error-color">*</label></label>
                                                    </div>
                                                    <div className={(window.innerWidth > 768) ? "float-left left-padding" : "fixed-column-45 left-padding"}>
                                                      <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                                      {/*
                                                      <div className="noti-bubble-info-7-9">
                                                        <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showServiceDefinitions: false, showPricingChart: false, showRoleDefinitions: true })}>
                                                          <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                                                        </a>
                                                      </div>*/}
                                                    </div>
                                                    <div className="clear" />
                                                  </div>

                                                  {this.state.roleNameOptions.map((value, index) =>
                                                    <div key={value} className="float-left">
                                                      <div className="float-left row-5 right-padding">
                                                        <button className="background-button" onClick={() => this.itemClicked(value,'roleName')}>
                                                          {(this.state.roleName === value) ? (
                                                            <div className="tag-container-4">
                                                              <p className="description-text-2 white-text half-bold-text">{value}</p>
                                                            </div>
                                                          ) : (
                                                            <div className="tag-container-inactive-2">
                                                              <p className="description-text-2 cta-color half-bold-text">{value}</p>
                                                            </div>
                                                          )}
                                                        </button>
                                                      </div>
                                                    </div>
                                                  )}
                                                  <div className="clear" />
                                                </div>

                                                {(this.state.roleName === 'Other') && (
                                                  <div className="row-10">
                                                    <label className="profile-label">You selected "other". Please write your role:<label className="error-color">*</label></label>
                                                    <input className="text-field" type="text" placeholder="Other Role Name..." name="otherRoleName" value={this.state.otherRoleName} onChange={this.formChangeHandler} />
                                                  </div>
                                                )}

                                                <div className="row-10">
                                                  <div className="container-left">
                                                    <label className="profile-label">First Name<label className="error-color">*</label></label>
                                                    <input className="text-field" type="text" placeholder="First Name" name="firstName" value={this.state.firstName} onChange={this.formChangeHandler} />
                                                  </div>
                                                  <div className="container-right">
                                                    <label className="profile-label">Last Name<label className="error-color">*</label></label>
                                                    <input className="text-field" type="text" placeholder="Last Name" name="lastName" value={this.state.lastName} onChange={this.formChangeHandler} />
                                                  </div>
                                                  <div className="clear" />
                                                </div>

                                                {(this.state.roleName && this.state.roleNameOptions.includes(this.state.roleName)) && (
                                                  <div>
                                                    <div className="row-10">
                                                      <div className="container-left">
                                                        {(this.state.roleName === 'Participant' || this.state.roleName === 'Student / Career Seeker') ? (
                                                          <div>
                                                            <label className="profile-label">Current / Latest School Name<label className="error-color">*</label></label>
                                                            <input className="text-field" type="text" placeholder="School Name" name="school" value={this.state.school} onChange={this.formChangeHandler} />
                                                          </div>
                                                        ) : (
                                                          <div>
                                                            <label className="profile-label">Employer Name<label className="error-color">*</label></label>
                                                            <input className="text-field" type="text" placeholder="Employer Name" name="employerName" value={this.state.employerName} onChange={this.formChangeHandler} />
                                                          </div>
                                                        )}
                                                      </div>
                                                      <div className="container-right">
                                                        {(this.state.roleName !== 'Participant' && this.state.roleName !== 'Student / Career Seeker') && (
                                                          <div>
                                                            <label className="profile-label">Job Title<label className="error-color">*</label></label>
                                                            <input className="text-field" type="text" placeholder="Job Title" name="jobTitle" value={this.state.jobTitle} onChange={this.formChangeHandler} />
                                                          </div>
                                                        )}
                                                      </div>
                                                      <div className="clear" />
                                                    </div>

                                                    {(this.state.roleName === 'Educator' || this.state.roleName === 'Teacher') && (
                                                      <div className="row-10">
                                                        <div className="container-left">
                                                          <label className="profile-label">School Name<label className="error-color">*</label></label>
                                                          <input className="text-field" type="text" placeholder="School Name" name="school" value={this.state.school} onChange={this.formChangeHandler} />
                                                        </div>
                                                        <div className="clear" />
                                                      </div>
                                                    )}

                                                    <div className="row-10">
                                                      <div className="container-left">
                                                        <label className="profile-label">Email<label className="error-color">*</label></label>
                                                        <input className="text-field" type="text" placeholder="Email" name="emailId" value={this.state.emailId} onChange={this.formChangeHandler} />
                                                      </div>
                                                      {/*
                                                      <div className="container-right">
                                                        <label className="profile-label">Password<label className="error-color">*</label></label>
                                                        <input className="text-field date-picker" type="password" placeholder="Password" name="password" value={this.state.password} onChange={this.formChangeHandler} />
                                                      </div>*/}
                                                      <div className="clear" />
                                                    </div>

                                                    {(this.state.roleName === 'Participant' || this.state.roleName === 'Student / Career Seeker') && (
                                                      <div>
                                                        <label className="profile-label">Add Teammates (Optional)</label>
                                                        <div>
                                                          <div className="container-left">
                                                            <div className="calc-column-offset-80">
                                                              <input className="text-field" type="text" placeholder="Add email..." name={"collaboratorEmail"} value={this.state.collaboratorEmail} onChange={this.formChangeHandler} />
                                                            </div>
                                                            <div className="fixed-column-80 left-padding">
                                                              <button type="button" className={(this.state.collaboratorEmail && this.state.collaboratorEmail !== '') ? "btn btn-squarish" : "btn btn-squarish clear-border unselected-background"} onClick={() => this.inviteCollaborators()}>Add</button>
                                                            </div>
                                                          </div>


                                                          <div className="clear" />
                                                        </div>

                                                        {(this.state.collaboratorErrorMessage) && <p className="error-message">{this.state.collaboratorErrorMessage}</p>}

                                                        <div>
                                                          {this.renderCollaborators()}
                                                        </div>
                                                      </div>
                                                    )}

                                                  </div>
                                                )}

                                                <div className="top-padding-20 bottom-padding full-width">
                                                  <button className={(this.state.disableSubmit) ? "btn btn-primary disabled-background disabled-border" : "btn btn-primary"} disabled={this.state.disableSubmit} onClick={() => this.handleSubmit(this.state.selectedOpportunity.postType, 'register')}>Register</button>

                                                  <div className="clear" />
                                                  {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color top-padding full-width">{this.state.errorMessage}</p>}
                                                  {(this.state.successMessage && this.state.successMessage !== '') && <p className="error-color top-padding full-width">{this.state.successMessage}</p>}

                                                </div>
                                              </div>
                                            </div>
                                          )}

                                          <div className="spacer"/><div className="spacer"/>
                                          <hr className="cta-border-color" />
                                          <div className="spacer"/><div className="spacer"/>
                                        </div>
                                      )}
                                    </div>
                                  )}

                                  {(!this.state.emailId) ? (
                                    <div>
                                      <div className="spacer"/><div className="half-spacer"/>
                                      <Link className="clear-border" to={registerLink} state={{ opportunityId: this.state.selectedOpportunity._id, opportunityOrg: this.state.activeOrg }}>
                                        <button className="btn btn-primary">
                                          Sign In to GC to Submit
                                        </button>
                                      </Link>
                                    </div>
                                  ) : (
                                    <div>
                                      {(window.location.pathname.includes('/app/opportunities') && ((!this.state.registrationPassed) || (this.state.registrationPassed && !this.state.deadlinePassed && this.state.hasRegistered))) && (
                                        <div>
                                          <div className="spacer"/><div className="half-spacer"/>
                                          <button className="btn btn-primary" onClick={() => this.setState({ viewIndex: 1 })}>
                                            {(this.state.submitted) ? "Update your" : "Submit a"} Project
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}

                              {(this.state.viewIndex === 1) && (
                                <div>
                                  {this.renderSubmitProject()}
                                </div>
                              )}

                              {(this.state.viewIndex === 2) && (
                                <div>
                                  <SubSubmissions selectedOpportunity={this.state.selectedOpportunity} activeOrg={this.state.activeOrg} accountCode={this.state.accountCode} submissionComments={this.state.submissionComments} postingOrgCode={this.state.postingOrgCode} postingOrgName={this.state.postingOrgName} orgContactEmail={this.state.orgContactEmail} pictureURL={this.state.pictureURL} gradeOptions={this.state.gradeOptions} history={this.props.navigate} pageSource={this.state.pageSource}/>
                                </div>
                              )}

                              {(this.state.viewIndex === 3) && (
                                <div>
                                  <SubComments selectedOpportunity={this.state.selectedOpportunity} activeOrg={this.state.activeOrg} accountCode={this.state.accountCode} comments={this.state.comments} postingOrgCode={this.state.postingOrgCode} postingOrgName={this.state.postingOrgName} orgContactEmail={this.state.orgContactEmail} pictureURL={this.state.pictureURL} history={this.props.navigate} pageSource={this.state.pageSource} />
                                </div>
                              )}

                            </div>

                          </div>
                        )}

                        {(this.state.selectedOpportunity.postType === 'Event') && (
                          <div>
                            <div>
                              {(this.state.selectedOpportunity.useImageTitle) ? (
                                <div className="calc-column-offset-40">
                                  {(this.state.selectedOpportunity.imageTitleURL) ? (
                                    <img src={this.state.selectedOpportunity.imageTitleURL} alt="GC" className={(window.innerWidth > 768) ? "image-half-auto" : "image-full-auto"} />
                                  ) : (
                                    <div>
                                      <img src={this.state.selectedOpportunity.imageURL} alt="GC" className={(window.innerWidth > 768) ? "image-half-auto" : "image-full-auto"} />
                                    </div>
                                  )}
                                  <div className="spacer" />

                                  {(this.state.selectedOpportunity.useImageTitle && !this.state.selectedOpportunity.hideTextTitle) && (
                                    <div className="top-margin-20">
                                      <h3>{this.state.selectedOpportunity.title}{(this.state.selectedOpportunity.employerName) && " @ " + this.state.selectedOpportunity.employerName}</h3>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div className="calc-column-offset-40">
                                  <p className="heading-text-2">{this.state.selectedOpportunity.title} RSVP Form</p>
                                  <div className="spacer" />
                                </div>
                              )}
                              <div className="fixed-column-40 right-text">
                                {(this.state.pageSource === 'landingPage') ? (
                                  <div className="height-40" />
                                ) : (
                                  <button className="btn background-button" onClick={() => this.favoriteItem(this.state.selectedOpportunity, "event") }>
                                    <img src={(this.state.favorites.includes(this.state.selectedOpportunity._id)) ? favoritesIconBlue : favoritesIconGrey} alt="GC" className="image-auto-20"/>
                                  </button>
                                )}
                              </div>
                              <div className="clear" />
                            </div>

                            {(this.state.pageSource !== 'landingPage') && (
                              <div>
                                <div className={(this.state.viewIndex === 0) ? "app-title-container-1-of-2-1 selected-item-container-1" : "app-title-container-1-of-2-1 unselected-item-container-1"}>
                                  <p className={(this.state.viewIndex === 0) ? "heading-text-4 cta-color" : "heading-text-4 unselected-color"}><a className="background-button" onClick={() => this.setState({ viewIndex: 0 })}>Details</a></p>
                                </div>
                                <div className={(this.state.viewIndex === 1) ? "app-title-container-1-of-2-1 margin-five-percent-left selected-item-container-1" : "app-title-container-1-of-2-1 margin-five-percent-left unselected-item-container-1"}>
                                  { ( this.state.comments.length === 0 ) ? (
                                    <p className={(this.state.viewIndex === 1) ? "heading-text-4 cta-color" : "heading-text-4 unselected-color"}><a className="background-button" onClick={() => this.setState({ viewIndex: 1 })}>Comments</a></p>
                                  ) : (
                                    <div>
                                      <p className={(this.state.viewIndex === 1) ? "heading-text-4 cta-color float-left margin-right-3" : "heading-text-4 unselected-color float-left margin-right-3"}><a className="background-button" onClick={() => this.setState({ viewIndex: 1 })}>Comments</a></p>
                                      {(this.state.viewIndex === 1) ? (
                                        <div className="noti-bubble selected-background description-text-4 float-left">{this.state.comments.length}</div>
                                      ) : (
                                        <div className="noti-bubble unselected-background description-text-4 float-left">{this.state.comments.length}</div>
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="clear" />
                              </div>
                            )}

                            <div className="spacer"/><div className="spacer"/>

                            {(this.state.viewIndex === 0) ? (
                              <div>
                                <SubRenderOpportunityDetails history={this.props.navigate} activeOrg={this.state.activeOrg} favorites={this.state.favorites} selectedOpportunity={this.state.selectedOpportunity} oppStats={this.state.oppStats} />

                                <div className="spacer"/><div className="spacer"/><div className="spacer"/>
                                <hr className="cta-border-color" />

                                {(this.state.selectedOpportunity.isActive === false || this.state.rsvpLimitReached || this.checkWhetherDatePassed(this.state.selectedOpportunity)) ? (
                                  <div className="top-margin-30">
                                    {(this.state.selectedOpportunity.isActive === false) ? (
                                      <p className="error-color">This posting has been marked inactive (i.e., it is not taking any new rsvps).</p>
                                    ) : (
                                      <div>
                                        <p className="error-color">{(this.checkWhetherDatePassed(this.state.selectedOpportunity)) ? "The date for this event has passed." : "This posting has reached its RSVP limit."}</p>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div>
                                    {this.renderRSVPForm(false)}
                                  </div>
                                )}

                              </div>
                            ) : (
                              <div>
                                <SubComments selectedOpportunity={this.state.selectedOpportunity} activeOrg={this.state.activeOrg} accountCode={this.state.accountCode} comments={this.state.comments} postingOrgCode={this.state.postingOrgCode} postingOrgName={this.state.postingOrgName} orgContactEmail={this.state.orgContactEmail} pictureURL={this.state.pictureURL} pageSource={this.state.pageSource}/>
                              </div>
                            )}

                          </div>
                        )}

                        {(this.state.selectedOpportunity.postType === 'Scholarship') && (
                          <div>
                            <div className="flex-container">
                              <div className="flex-90">
                                <div className="float-left right-padding">
                                  <div className="spacer" />
                                  <img src={gcLogo} alt="GC" className="image-auto-48" />
                                </div>
                              </div>

                              <div className="flex-10 right-text">
                                <button className="btn background-button" onClick={() => this.favoriteItem(this.state.selectedOpportunity, "scholarship") }>
                                  <img src={(this.state.favorites.includes(this.state.selectedOpportunity._id)) ? favoritesIconBlue : favoritesIconGrey} alt="GC" className="image-auto-20"/>
                                </button>
                              </div>

                              <div className="clear" />
                            </div>

                            <div className="super-spacer"/>

                            <div>
                              <p className="heading-text-2">{this.state.selectedOpportunity.title}</p>
                              <div className="spacer" />

                              <p className="description-text-1">{this.state.selectedOpportunity.orgName}</p>
                              <div className="clear" />
                              <div className="spacer"/><div className="spacer"/>

                              <div className="edit-profile-row">
                                <p className="heading-text-4">Description</p>
                                <p className="keep-line-breaks">{this.state.selectedOpportunity.description}</p>
                                <div className="spacer"/><div className="spacer"/>
                              </div>
                            </div>

                            {(this.props.path && this.props.path.includes('/advisor')) ? (
                              <div>
                                <div>
                                  <div className="edit-profile-row">
                                    <p className="heading-text-4">Endorse a Student for this Posting</p>
                                    <p>Endorsing a student allows him / her to automatically import that endorsement into scholarship and internship applications.{(this.state.benchmark) && " Clicking the button below will auto-populate the skills and traits information relevant to this posting so that you provide a relevant endorsement."}</p>
                                  </div>

                                  <div className="spacer"/><div className="spacer"/>

                                  <Link className="clear-border" to={'/advisor/endorsements/new'} state={{ benchmark: this.state.benchmark }}><button className="btn btn-primary">Endorse a Student</button></Link>
                                </div>
                              </div>
                            ) : (
                              <div>
                                {this.state.serverSuccessMessage !== '' && <p className="success-message">{this.state.serverSuccessMessage}</p>}
                                {this.state.serverErrorMessage !== '' && <p className="error-message">{this.state.serverErrorMessage}</p>}

                                {this.state.hasApplied ? (
                                  <Link className="clear-border" to={this.state.prefix + '/postings/' + this.state.selectedOpportunity._id + '/apply'} state={{ selectedPosting: this.state.selectedOpportunity, application: this.state.application, referralCode: this.state.referralCode }}><button className="btn btn-primary">Update</button></Link>
                                ) : (
                                  <Link className="clear-border" to={this.state.prefix + '/postings/' + this.state.selectedOpportunity._id + '/apply'} state={{ selectedPosting: this.state.selectedOpportunity, application: this.state.application, referralCode: this.state.referralCode }}><button className="btn btn-primary">Apply</button></Link>
                                )}
                              </div>
                            )}

                          </div>
                        )}

                      </div>
                    </div>
                  ) : (
                    <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally top-margin-40"}>
                      <div className="full-width height-5 primary-background" />
                      <div className="padding-40">

                        {(this.state.selectedOpportunity && this.state.selectedOpportunity.isActive === false) ? (
                          <p className="error-color">This posting has been marked inactive (i.e., it is not taking any new applications).</p>
                        ) : (
                          <p className="error-color">No opportunity found with that ID</p>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                {(window.innerWidth > 768 && !this.props.inModal) && (
                  <div className="relative-column-30 horizontal-padding top-margin-40">
                    <div className="left-padding-20">
                      {(showApply && !alreadyRSVPd) && (
                        <div className="card-clear-padding min-width-100 max-width-310 bottom-margin-20">
                          <div className="full-width height-5 senary-background" />
                          <div className="padding-20">
                            <p className="heading-text-5">Actions</p>

                            <div className="spacer" /><div className="spacer" />

                            <div className="row-10">
                              {(applyButton) ? (
                                <button onClick={() => this.setState(applyButton)} className="background-button full-width left-text cta-color">
                                  <div className="fixed-column-35">
                                    <img src={resumeIconDark} alt="GC" className="image-auto-25" />
                                  </div>
                                  <div className="calc-column-offset-70 left-padding-5">
                                    <p>{applyText}</p>
                                  </div>
                                  <div className="float-left left-padding">
                                    <span className="float-right top-margin-5">
                                      <Link to={'/opportunities/organizations/'} className="background-link">
                                        <img src={arrowIndicatorIcon} alt="GC" className="image-auto-15"/>
                                      </Link>
                                    </span>
                                  </div>
                                </button>
                              ) : (
                                <div>
                                  <Link to={applyLink} state={applyState} className="background-link">
                                    <div className="fixed-column-35">
                                      <img src={resumeIconDark} alt="GC" className="image-auto-25" />
                                    </div>
                                    <div className="calc-column-offset-70 left-padding-5">
                                      <p>{applyText}</p>
                                    </div>
                                  </Link>
                                  <div className="float-left left-padding">
                                    <span className="float-right top-margin-5">
                                      <Link to={'/opportunities/organizations/'} className="background-link">
                                        <img src={arrowIndicatorIcon} alt="GC" className="image-auto-15"/>
                                      </Link>
                                    </span>
                                  </div>
                                </div>
                              )}

                              <div className="clear"/>

                              <div className="spacer" /><div className="spacer" />
                              <hr />
                              <div className="clear"/>
                              <div className="spacer" />
                            </div>

                            {(showApplyTwo && (applyButton2 || applyLink2)) && (
                              <div className="bottom-padding">
                                <div className="row-10">
                                  {(applyButton2) ? (
                                    <button onClick={() => this.setState(applyButton2)} className="background-button full-width left-text cta-color">
                                      <div className="fixed-column-35">
                                        <img src={sendIcon} alt="GC" className="image-auto-25" />
                                      </div>
                                      <div className="calc-column-offset-70 left-padding-5">
                                        <p>{applyText2}</p>
                                      </div>
                                      <div className="float-left left-padding">
                                        <span className="float-right top-margin-5">
                                          <Link to={'/opportunities/organizations/'} className="background-link">
                                            <img src={arrowIndicatorIcon} alt="GC" className="image-auto-15"/>
                                          </Link>
                                        </span>
                                      </div>
                                    </button>
                                  ) : (
                                    <div>
                                      <a href={applyLink2} className="background-link" target="_blank" rel="noopener noreferrer">
                                        <div className="fixed-column-35">
                                          <img src={websiteIconDark} alt="GC" className="image-auto-25" />
                                        </div>
                                        <div className="calc-column-offset-70 left-padding-5">
                                          <p>{applyText2}</p>
                                        </div>
                                      </a>
                                      <div className="float-left left-padding">
                                        <span className="float-right top-margin-5">
                                          <a href={applyLink2} to={'/opportunities/organizations/'} className="background-link" target="_blank" rel="noopener noreferrer">
                                            <img src={linkIcon} alt="GC" className="image-auto-15"/>
                                          </a>
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>

                                <div className="clear"/>

                                <div className="spacer" /><div className="spacer" />
                                <hr />
                                <div className="clear"/>
                                <div className="spacer" />
                              </div>
                            )}

                            {(this.state.selectedOpportunity && this.state.selectedOpportunity.benchmarkId && !this.state.selectedOpportunity.hideIdealCandidateProfile) && (
                              <div className="bottom-padding">
                                <button className="background-button clear-margin full-width left-text cta-color" onClick={() => this.setState({ modalIsOpen: true, showIdealProfile: true })}>
                                  <div className="fixed-column-35">
                                    <img src={profileIconDark} alt="GC" className="image-auto-25" />
                                  </div>
                                  <div className="calc-column-offset-70 left-padding-5">
                                    <p>Ideal Candidate Profile</p>
                                  </div>
                                  <div className="float-left left-padding">
                                    <span className="float-right top-margin-5">
                                      <img src={modalIconDark} alt="GC" className="image-auto-15"/>
                                    </span>
                                  </div>
                                </button>

                                <div className="clear"/>

                                <div className="spacer" /><div className="spacer" />
                                <hr />
                                <div className="clear"/>
                                <div className="spacer" />
                              </div>
                            )}

                            {(this.props.pageSource !== 'landingPage' && this.state.selectedOpportunity && this.state.selectedOpportunity.benchmarkId && window.location.pathname.includes(this.state.prefix + '/opportunities') && !this.state.selectedOpportunity.hideViewMatch) && (
                              <div className="bottom-padding">
                                <button className="background-button clear-margin full-width left-text cta-color" onClick={() => this.setState({ modalIsOpen: true, showMatch: true })}>
                                  <div className="fixed-column-35">
                                    <img src={benchmarksIconDark} alt="GC" className="image-auto-25 standard-border" />
                                  </div>
                                  <div className="calc-column-offset-70 left-padding-5">
                                    <p>View Match</p>
                                  </div>
                                  <div className="float-left left-padding">
                                    <span className="float-right top-margin-5">
                                      <img src={modalIconDark} alt="GC" className="image-auto-15"/>
                                    </span>
                                  </div>
                                </button>

                                <div className="clear"/>

                                <div className="spacer" /><div className="spacer" />
                                <hr />
                                <div className="clear"/>
                                <div className="spacer" />
                              </div>
                            )}



                            {(!this.state.remoteAuth && this.state.selectedOpportunity && this.state.selectedOpportunity.allowReferrals !== false && (this.state.selectedOpportunity.postType === 'Work' || this.state.selectedOpportunity.postType === 'Internship')) && (
                              <div className="bottom-padding">
                                <button onClick={() => this.setState({ modalIsOpen: true, showReferSomeone: true })} className="background-button full-width left-text cta-color">
                                  <div className="fixed-column-35">
                                    <img src={referIconDark} alt="GC" className="image-auto-25" />
                                  </div>
                                  <div className="calc-column-offset-70 left-padding-5">
                                    <p>Refer Someone</p>
                                  </div>
                                  <div className="float-left left-padding">
                                    <span className="float-right top-margin-5">
                                      <img src={modalIconDark} alt="GC" className="image-auto-15"/>
                                    </span>
                                  </div>
                                </button>
                                <div className="clear"/>
                              </div>
                            )}

                            <div className="spacer" />
                          </div>
                        </div>
                      )}

                      {(this.state.publicRsvps && this.state.publicRsvps.length > 0 && window.location.pathname.includes(this.state.prefix)) && (
                        <div className="card-clear-padding min-width-100 max-width-310 bottom-margin-20">
                          <div className="full-width height-5 senary-background" />
                          <div className="padding-20">
                            <p className="heading-text-5">{this.state.csRSVPs.length} People {(this.checkWhetherDatePassed(this.state.selectedOpportunity)) ? "Attended" : "Attending"}</p>

                            <div className="spacer" /><div className="half-spacer" />

                            {this.state.publicRsvps.map((item, index) =>
                              <div key={item}>
                                {(index < 3) && (
                                  <div className="bottom-padding">
                                    <div className="spacer" />
                                    <Link to={(this.state.pageSource === 'landingPage') ? '/' + item.username + '/profile' : this.state.prefix + '/profile/' + item.username} className="background-link">
                                      <div className="fixed-column-50">
                                        <img src={(item.pictureURL) ? item.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-1" />
                                      </div>
                                      <div className="calc-column-offset-90 left-padding-5">
                                        <p>{item.firstName} {item.lastName}</p>
                                        <p className="description-text-2 standard-color">{(item.schoolName) && item.schoolName}{(item.jobTitle && item.jobTitle !== 'N/A') && item.jobTitle}</p>
                                      </div>
                                    </Link>
                                    <div className="float-left left-padding">
                                      <span className="float-right">
                                        <div className="spacer"/>
                                        <Link to={(this.state.pageSource === 'landingPage') ? '/opportunities/organizations/' + this.state.activeOrg + '/' + item._id : this.state.prefix + '/opportunities/' + item._id} state={{ selectedOpportunity: item }} className="background-link">
                                          <img src={arrowIndicatorIcon} alt="GC" className="image-auto-22"/>
                                        </Link>
                                      </span>
                                    </div>
                                    <div className="clear"/>

                                    <div className="spacer" /><div className="spacer" />
                                    <hr />
                                    <div className="clear"/>
                                    <div className="spacer" />
                                  </div>
                                )}
                              </div>
                            )}

                            {(this.state.publicRsvps.length > 0) && (
                              <div>
                                <button className="background-button full-width right-text" onClick={() => this.setState({ modalIsOpen: true, viewRSVPs: true })}>
                                  <p className="description-text-2">See More <label className="description-text-2 left-padding-5">></label></p>
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      {(this.state.relatedOpportunities && this.state.relatedOpportunities.length > 0) && (
                        <div className="card-clear-padding min-width-100 max-width-310 bottom-margin-20">
                          <div className="full-width height-5 senary-background" />
                          <div className="padding-20">
                            <p className="heading-text-5">Related Opportunities</p>

                            <div className="spacer" /><div className="half-spacer" />

                            {this.state.relatedOpportunities.map((item, index) =>
                              <div key={item}>
                                {(index < 3) && (
                                  <div className="bottom-padding">
                                    <div className="spacer" />
                                    <Link to={relatedOpportunitiesPathPrefix + item._id} state={{ selectedOpportunity: item }} className="background-link">
                                      <div className="fixed-column-50">
                                        <img src={(item.imageURL) ? item.imageURL : (item.postType === 'Work' || item.postType === 'Internship') ? opportunitiesIconDark : (item.postType === "Project") ? projectsIconDark : calendarIconDark} alt="GC" className="image-35-fit" />
                                      </div>
                                      <div className="calc-column-offset-90 left-padding-5">
                                        <p>{(item.name) ? item.name : item.title}</p>
                                        <p className="description-text-2 standard-color">{item.postType}{(item.employerName) && " @ " + item.employerName}</p>
                                      </div>
                                    </Link>
                                    <div className="float-left left-padding">
                                      <span className="float-right">
                                        <div className="spacer"/>
                                        <Link to={relatedOpportunitiesPathPrefix + item._id} state={{ selectedOpportunity: item }} className="background-link">
                                          <img src={arrowIndicatorIcon} alt="GC" className="image-auto-22"/>
                                        </Link>
                                      </span>
                                    </div>
                                    <div className="clear"/>

                                    <div className="spacer" /><div className="spacer" />
                                    <hr />
                                    <div className="clear"/>
                                    <div className="spacer" />
                                  </div>
                                )}
                              </div>
                            )}

                            {(this.state.relatedOpportunities.length > 0) && (
                              <div>
                                <Link className="background-button full-width right-text" to={(this.state.pageSource === 'landingPage') ? '/opportunities/organizations/' + this.state.activeOrg : this.state.prefix + '/opportunities' + this.state.activeOrg}>
                                  <p className="description-text-2">See More <label className="description-text-2 left-padding-5">></label></p>
                                </Link>
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      {(!this.state.disableChatbotPrompts && this.state.opportunityCommands && this.state.opportunityCommands.length > 0 && this.state.pageSource !== 'landingPage') && (
                        <div>
                          <SubAssistantWidget commands={this.state.opportunityCommands} commandClicked={this.commandClicked} />
                        </div>
                      )}

                      {(this.state.relatedCourses && this.state.relatedCourses.length > 0 && !this.state.selectedOpportunity.useImageTitle) && (
                        <div className="card-clear-padding min-width-100 max-width-310 bottom-margin-20">
                          <div className="full-width height-5 senary-background" />
                          <div className="padding-20">
                            <p className="heading-text-5">Related Courses</p>

                            <div className="spacer" /><div className="half-spacer" />

                            {this.state.relatedCourses.map((item, index) =>
                              <div key={item}>

                                {(index < 3) && (
                                  <div className="bottom-padding">
                                    <div className="spacer" />
                                    <a href={'https://www.udemy.com' + item.url} target="_blank" rel="noopener noreferrer">
                                      <div>
                                        <div className="calc-column-offset-40">
                                          <img src={item.image_125_H} alt="GC" className="image-70-auto" />
                                        </div>
                                        <div className="fixed-column-40">
                                          <span className="float-right">
                                            <div className="spacer"/>
                                            <img src={linkIcon} alt="GC" className="image-auto-22"/>
                                          </span>
                                        </div>
                                        <div className="clear"/>
                                      </div>

                                      <div className="full-width top-padding">
                                        <p>{item.title}</p>
                                        <p className="description-text-2 standard-color">{item.headline}</p>
                                      </div>
                                    </a>

                                    <div className="spacer" /><div className="spacer" />
                                    <hr />
                                    <div className="clear"/>
                                    <div className="spacer" />
                                  </div>
                                )}
                              </div>
                            )}

                            {(this.state.relatedCourses.length > 0) && (
                              <div>
                                <Link className="background-button full-width right-text" to={(this.state.pageSource === 'landingPage') ? '/courses' : this.state.prefix + '/courses'} state={{ subNavSelected: 'Courses' }}>
                                  <p className="description-text-2">See More <label className="description-text-2 left-padding-5">></label></p>
                                </Link>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="clear" />
              </div>
            )}

            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
            >
              {(this.state.showReferSomeone) && (
                <div key="showReferSomeone" className="full-width padding-20">
                  <SubReferSomeone history={this.props.navigate} selectedOpportunity={this.state.selectedOpportunity}
                    referralBonus={this.state.referralBonus} pageSource={this.state.pageSource} linkedInURL={this.state.linkedInURL}
                    benchmark={this.state.benchmark} relationshipOptions={this.state.relationshipOptions}
                    jobTitle={this.state.jobTitle} employerName={this.state.employerName}
                    orgCode={this.state.activeOrg} orgName={this.state.postingOrgName}
                    orgProgramName={this.state.orgName} orgContactEmail={this.state.postingOrgContactEmail}
                    closeModal={this.closeModal} headerImageURL={this.state.headerImageURL}
                    partnerLandingPage={this.state.partnerLandingPage}
                  />
                </div>
              )}

              {(this.state.showIdealProfile) && (
                <div key="showIdealProfile" className="full-width">
                   <SubBenchmarkDetails title={this.state.selectedOpportunity.title} selectedBenchmark={this.state.benchmark} closeModal={this.closeModal} />
                 </div>
              )}

              {(this.state.showMatch) && (
                <div key="showDescription" className="full-width">
                   <SubConfigureProfileMatch title={this.state.selectedOpportunity.title} workFunction={(this.state.selectedOpportunity.field) && this.state.selectedOpportunity.field.split(" | ")[0]} selectedBenchmark={this.state.benchmark} closeModal={this.closeModal} matchType="Opportunity"/>
                 </div>
              )}

              {(this.state.viewRSVPs) && (
                <div key="showReferSomeone" className="full-width padding-20">
                  <p className="heading-text-2">{this.state.selectedOpportunity.title} RSVPs</p>
                  <div className="spacer" />

                  {this.state.csRSVPs.map((item, index) =>
                    <div key={item}>
                    <div className="bottom-padding">
                      <div className="spacer" />
                        <Link to={(this.state.pageSource === 'landingPage') ? '/' + item.username + '/profile' : this.state.prefix + '/profile/' + item.username} className={(item.username) ? "background-link" : "background-link no-pointers standard-color"}>
                          <div className="fixed-column-50">
                            <img src={(item.pictureURL) ? item.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-1" />
                          </div>
                          <div className="calc-column-offset-90 left-padding-5">
                            <p>{item.firstName} {item.lastName}</p>
                            <p className="description-text-2 standard-color">{(item.schoolName) && item.schoolName}{(item.jobTitle && item.jobTitle !== 'N/A') && item.jobTitle}</p>
                          </div>
                        </Link>
                        <div className="float-left left-padding">
                          <span className="float-right">
                            <div className="spacer"/>
                            <Link to={(this.state.pageSource === 'landingPage') ? '/opportunities/organizations/' + this.state.activeOrg + '/' + item._id : this.state.prefix + '/opportunities/' + item._id} state={{ selectedOpportunity: item }} className="background-link">
                              <img src={arrowIndicatorIcon} alt="GC" className="image-auto-22"/>
                            </Link>
                          </span>
                        </div>
                        <div className="clear"/>

                        <div className="spacer" /><div className="spacer" />
                        <hr />
                        <div className="clear"/>
                        <div className="spacer" />
                      </div>
                    </div>
                  )}
                </div>
              )}

              {(this.state.login) && (
                <div>
                  <p className="heading-text-2">Login</p>

                  {(this.state.isSavingModal) ? (
                    <div>
                      <div className="flex-container flex-center full-space">
                        <div>
                          <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                          <div className="spacer" /><div className="spacer" /><div className="spacer" />
                          <p className="center-text cta-color bold-text">Authenticating...</p>

                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="row-20">
                        <div className="row-10">
                          <input className="text-field standard-border standard-color" type="text" name="emailId" placeholder="Email" value={this.state.emailId} onChange={this.formChangeHandler} />
                        </div>

                        <div className="row-10">
                          <input className="text-field height-40 full-width standard-border standard-color" type="password" name="password" placeholder="Password" value={this.state.password} onChange={this.formChangeHandler} />
                        </div>
                      </div>

                      {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
                      {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}

                      <div>
                        <div className="float-left right-padding">
                          <button className="btn btn-primary" disabled={this.state.isSavingModal} onClick={() => this.authenticate()}>Sign In & Import</button>
                        </div>
                        <div className="float-left">
                          <button className="btn btn-secondary" disabled={this.state.isSavingModal} onClick={() => this.closeModal()}>Close View</button>
                        </div>
                        <div className="clear" />
                      </div>
                    </div>
                  )}
                </div>
              )}

              {(this.state.showSubmitProject) && (
                <div>
                  {this.renderSubmitProject()}
                </div>
              )}

              {(this.state.showRSVPForm) && (
                <div>
                  <p className="heading-text-2 top-padding">Register for {this.state.selectedOpportunity.title}</p>

                  {this.renderRSVPForm(true)}
                </div>
              )}

              {(this.state.showApplyViaEmail) && (
                <div>

                  <div className="fixed-column-33 right-padding">
                    <div className="spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                    <img src={sendIcon} alt="Compass logo" className="image-auto-23" />
                  </div>
                  <div className="calc-column-offset-33">
                    <p className="heading-text-4">Apply via Email</p>
                    <p><label className="half-bold-text">Address to:</label> {this.state.selectedOpportunity.employerContactFirstName} {this.state.selectedOpportunity.employerContactLastName}</p>
                    <p><label className="half-bold-text">Email:</label> {this.state.selectedOpportunity.employerContactEmail}</p>
                    <p><label className="half-bold-text">Subject Line:</label> {this.state.selectedOpportunity.subjectLine}</p>
                    {(this.state.selectedOpportunity.appRequirements && this.state.selectedOpportunity.appRequirements !== '') && (
                      <div>
                        <p><label className="half-bold-text">Please include:</label> {this.state.selectedOpportunity.appRequirements}</p>
                      </div>
                    )}
                    {(this.state.selectedOpportunity.submissionDeadline && this.state.selectedOpportunity.submissionDeadline !== '') && (
                      <div>
                        <p><label className="half-bold-text">Deadline:</label> {convertDateToString(this.state.selectedOpportunity.submissionDeadline,"date")}</p>
                      </div>
                    )}
                  </div>

                </div>
              )}

            </Modal>

          </div>
      )
    }
}

export default withRouter(OpportunityDetails);
