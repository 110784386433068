import React, {Component} from 'react';
import Axios from 'axios';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubEditPosting from './Subcomponents/EditPosting';
import SubOpportunityDetails from './Subcomponents/OpportunityDetails';
import withRouter from './Functions/WithRouter';

class AdvEditPosting extends Component {
    constructor(props) {
      super(props)

      this.state = {
          readMode: false,
          editMode: false
      }

      this.retrieveData = this.retrieveData.bind(this)
      this.commandClicked = this.commandClicked.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');

      let selectedPosting = null
      let postings = null
      let duplicate = null
      if (this.props.location && this.props.location.state) {
        selectedPosting = this.props.location.state.selectedPosting
        postings = this.props.location.state.postings
        duplicate = this.props.location.state.duplicate
      }

      let postingId = null
      if (this.props.params) {
        postingId = this.props.params
      }

      if (this.props.params) {
        console.log('went directly to posting', postingId)
        console.log('show opportunityId: ', postingId)

        Axios.get('/api/postings/byid', { params: { _id: postingId } })
        .then((response) => {
           console.log('Posting query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved posting')

             selectedPosting = response.data.posting
             this.retrieveData(selectedPosting, null, email, postingId, duplicate)
           } else {
             console.log('no posting data found', response.data.message)
             this.retrieveData(null, null, email, postingId)
           }

        }).catch((error) => {
             console.log('Posting query did not work', error);
        });

      } else {

        // let { selectedPosting, postings, duplicate } = this.props.location.state;
        // console.log('got selectedPosting: ', selectedPosting)

        if (selectedPosting) {
          if (selectedPosting.postType) {
            this.retrieveData(selectedPosting, postings, email, duplicate)
          } else {

            Axios.get('/api/postings/byid', { params: { _id: selectedPosting._id } })
            .then((response) => {
               console.log('Posting query attempted', response.data);

               if (response.data.success) {
                 console.log('successfully retrieved posting')

                 const selectedPosting = response.data.posting
                 this.retrieveData(selectedPosting, postings, email, null, duplicate)
               } else {
                 console.log('no posting data found', response.data.message)
               }

            }).catch((error) => {
                 console.log('Posting query did not work', error);
            });
          }
        } else {

          let { postingId } = this.props.params

          if (postingId) {
            Axios.get('/api/postings/byid', { params: { _id: postingId } })
            .then((response) => {
               console.log('Posting query attempted', response.data);

               if (response.data.success) {
                 console.log('successfully retrieved posting')

                 const selectedPosting = response.data.posting
                 this.retrieveData(selectedPosting, postings, email, null, duplicate)
               } else {
                 console.log('no posting data found', response.data.message)
               }

            }).catch((error) => {
                 console.log('Posting query did not work', error);
            });
          } else {
            console.log('couldnt find any information')
          }
        }
      }
    }

    retrieveData(selectedPosting, postings, email, postingId, duplicate) {
      console.log('retrieveData called in AdvEditPosting', selectedPosting, postings, email, postingId, duplicate)

      const username = localStorage.getItem('username');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const activeOrg = localStorage.getItem('activeOrg');
      const orgFocus = localStorage.getItem('orgFocus');
      const roleName = localStorage.getItem('roleName');
      const orgLogo = localStorage.getItem('orgLogo');

      let readMode = true
      let editMode = false

      if (!selectedPosting || postingId === 'add' || selectedPosting.orgCode === activeOrg) {
        readMode = false
        editMode = true
      }
      // console.log('show requestMode: ', selectedPosting.requestMode, selectedPosting.orgCode, email, selectedPosting)
      // if (selectedPosting.postType === 'Event') {
      //   console.log('contributorRoleName 1: ', selectedPosting.contributorRoleName, selectedPosting.contributorRoleName === 'Employer')
      //   if (email === selectedPosting.orgContactEmail || email === selectedPosting.contributorEmail) {
      //     readMode = false
      //     editMode = true
      //   } else if ((selectedPosting.requestMode === true && selectedPosting.orgCode === activeOrg) || (selectedPosting.contributorRoleName === 'Employer' && selectedPosting.orgCode === activeOrg)) {
      //     console.log('contributorRoleName 2: ', selectedPosting.contributorRoleName, selectedPosting.contributorRoleName === 'Employer', this.state.roleName, roleName)
      //     if (roleName === 'Teacher' || roleName === 'School Support') {
      //       readMode = false
      //       editMode = true
      //     }
      //   }
      // } else if (selectedPosting.postType === 'Assignment' || selectedPosting.postType === 'Problem' || selectedPosting.postType === 'Challenge') {
      //   console.log('test 1 now', email, selectedPosting.contributorEmail)
      //   if (email === selectedPosting.contributorEmail) {
      //     readMode = false
      //     editMode = true
      //   } else if ((selectedPosting.requestMode === true && selectedPosting.orgCode === activeOrg) || (selectedPosting.contributorRoleName === 'Employer' && selectedPosting.orgCode === activeOrg)) {
      //     if (roleName === 'Teacher' || roleName === 'School Support') {
      //       readMode = false
      //       editMode = true
      //     }
      //   }
      // } else if (selectedPosting.postType === 'Internship' || selectedPosting.postType === 'Individual' || selectedPosting.postType === 'Track') {
      //   if (email === selectedPosting.posterEmail) {
      //     readMode = false
      //     editMode = true
      //   }
      // } else if (selectedPosting.postType === 'Scholarship') {
      //   if (email === selectedPosting.orgContactEmail) {
      //     readMode = false
      //     editMode = true
      //   } else if ((selectedPosting.requestMode === true && selectedPosting.orgCode === activeOrg) || (selectedPosting.contributorRoleName === 'Employer' && selectedPosting.orgCode === activeOrg)) {
      //     readMode = false
      //     editMode = true
      //   }
      // }
      // console.log('show mode: ', readMode, editMode)
      const path = window.location.pathname
      const jobs = []

      this.setState({ readMode, editMode, selectedPosting, postings, jobs, path,
        roleName, emailId: email, username, orgLogo,
        cuFirstName, cuLastName, activeOrg, orgFocus, duplicate
      })
    }

    commandClicked(passedCommand) {
      console.log('commandClicked called')

      this.setState({ passedCommand })

    }

    render() {
      console.log('show editMode 2: ', this.state.editMode, this.state.readMode)
      return (
        <div>
          <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} roleName={this.state.roleName} history={this.props.navigate} />

          {(this.state.readMode) && (
            <div>
              <SubOpportunityDetails selectedOpportunity={this.state.selectedPosting} path={this.state.path} postings={this.state.postings} commandClicked={this.commandClicked} />
            </div>
          )}
          {(this.state.editMode) && (
            <div>
              <SubEditPosting org={this.state.activeOrg} selectedOpportunity={this.state.selectedPosting} editMode={this.state.editMode} jobs={this.state.jobs} roleName={this.state.roleName} path={this.state.path} history={this.props.navigate} duplicate={this.state.duplicate} />
            </div>
          )}

          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo, this.state.passedCommand)}
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(AdvEditPosting);
