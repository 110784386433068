import Axios from 'axios';
import {convertDateToString} from '../Functions/ConvertDateToString';

export const signUp = async(firstName, lastName, email, password, gradYear, jobTitle, employerName, orgCode, roleName, otherRoleName, courseId, workId, school, schoolDistrict, accountCode, studentBenefits, teacherBenefits, mentorBenefits, employerBenefits, mfaEnabled, passedOrgName, passedOrgURL, passedOrgRegion, passedOrgDescription, employerURL, employerLocation, employerDescription, phoneNumber, alternativePhoneNumber, alternativeEmail, subscribedForReminders,degree,major,dateOfBirth,gender,races,selfDescribedRace,address,zipcode,numberOfMembers, householdIncome, workAuthorization, adversityList, educationStatus, education, referrerName, referrerEmail, referrerOrg, requestAccess, pathway)=>{
  console.log('signUp called', firstName, lastName, email, password, gradYear, jobTitle, employerName, orgCode, roleName, otherRoleName, courseId, workId, school, schoolDistrict, accountCode, studentBenefits, teacherBenefits, mentorBenefits, employerBenefits, mfaEnabled, passedOrgName, passedOrgURL, passedOrgRegion, passedOrgDescription, employerURL, employerLocation, employerDescription, phoneNumber, subscribedForReminders,degree,major,dateOfBirth,gender,races,selfDescribedRace,address,zipcode,numberOfMembers, householdIncome, workAuthorization, adversityList, educationStatus, education, requestAccess,pathway)

  const pathVar = window.location.pathname.toLowerCase()

  let createdAt = new Date();
  let updatedAt = new Date();
  let platform = 'web'

  if (!firstName || firstName === '') {
    return { error: { message: 'please enter your first name' }}
  } else if (!lastName || lastName === '') {
    return { error: { message: 'please enter your last name' }}
  } else if (!email || email === '') {
    return { error: { message: 'please enter your email' }}
  } else if (!email.includes('@')) {
    return { error: { message: 'email invalid. please enter a valid email' }}
  } else if (!password || password === '') {
    return { error: { message: 'please enter a password' }}
  } else if (!orgCode || orgCode === '') {
    return { error: { message: 'please add the code affiliated with a Guided Compass partner' }}
  } else if (!roleName) {
    return { error: { message: 'please indicate the closest answer to your role' }}
  // } else if (roleName.toLowerCase() === 'student' && gradYear === '') {
  //   return { error: { message: 'please enter your expected graduation year' }}
  } else if (password.length < 7) {
    return { error: { message: 'please enter a password over 6 characters' }}
  } else if (roleName.toLowerCase() === 'mentor' && jobTitle === '') {
    return { error: { message: 'please enter your job title' }}
  } else if (roleName.toLowerCase() === 'mentor' && employerName === '') {
    return { error: { message: 'please enter the name of your employer' }}
  } else {

    console.log('about to try to save')

    firstName = firstName.trim()
    lastName = lastName.trim()
    let combinedNames = firstName + lastName
    let username = combinedNames.toLowerCase().replace(/\s/g, "");

    let activeOrg = orgCode.toLowerCase()
    if ((roleName === 'Teacher' || roleName === 'WBLC' || roleName === 'Admin') && activeOrg === 'guidedcompass') {
      activeOrg = 'defaultsandbox'
      if (roleName === 'WBLC') {
        roleName = 'Admin'
      }
    }

    return await Axios.get('/api/org', { params: { orgCode: activeOrg } })
    .then(async(response) => {
      console.log('Org info query attempted for sign up', response.data);

        if (response.data.success) {
          console.log('org info query worked', email)

          email = email.toLowerCase()
          email = email.trim()
          const domain = email.split("@")[1]
          const applicableRoles = ['Student','Worker','Teacher','Admin','WBLC']
          const enableCheckDomain = false

          if (response.data.orgInfo.requiredDomains && response.data.orgInfo.requiredDomains.length > 0 && !response.data.orgInfo.requiredDomains.some(rd => rd.domain === domain) && applicableRoles.includes(roleName) && enableCheckDomain) {
            // const rdIndex = response.data.orgInfo.requiredDomains.findIndex(rd => rd.domain === domain)
            const requiredDomain = response.data.orgInfo.requiredDomains[0].domain

            return { error: { message: 'please enter a valid email with a ' + requiredDomain + ' domain', isSaving: false }}
          } else {
            let orgName = response.data.orgInfo.orgName
            if (passedOrgName) {
              orgName = passedOrgName
            }
            const orgFocus = response.data.orgInfo.orgFocus
            const orgContactFirstName = response.data.orgInfo.contactFirstName
            const orgContactLastName = response.data.orgInfo.contactLastName
            const orgContactEmail = response.data.orgInfo.contactEmail
            let orgChatEmail = response.data.orgInfo.chatEmail
            if (!orgChatEmail) {
              orgChatEmail = orgContactEmail
            }
            const orgLogo = response.data.orgInfo.webLogoURIColor
            const studentAliasSingular = response.data.orgInfo.studentAliasSingular
            const studentAliasPlural = response.data.orgInfo.studentAliasPlural
            const mentorAliasSingular = response.data.orgInfo.mentorAliasSingular
            const mentorAliasPlural = response.data.orgInfo.mentorAliasPlural
            const headerImageURL = response.data.orgInfo.headerImageURL
            const publicOrg = response.data.orgInfo.isPublic
            const placementPartners = response.data.orgInfo.placementPartners
            const skipWalkthrough = response.data.orgInfo.skipWalkthrough

            const myOrgs = [activeOrg]
            const courseIds = [courseId]
            const workIds = [workId]

            let workMode = false
            if (roleName && roleName.toLowerCase() === 'worker') {
              roleName = 'Student'
              workMode = true
            }

            if (orgFocus === 'Placement') {
              if (response.data.orgInfo.pathwaysTiedToSchools) {
                if (pathway && pathway.split("|")) {
                  school = pathway.split("|")[1].trim()
                }
              } else {
                if (!school) {
                  school = ''
                }
              }
            } else {
              if (response.data.orgInfo.pathwaysTiedToSchools) {
                if (pathway && pathway.split("|")) {
                  school = pathway.split("|")[1].trim()
                }
              }
            }

            let isAdvisor = false
            let isOrganization = false
            let isEmployer = false

            let benefits = undefined
            if (roleName === 'Student') {
              jobTitle = 'Student'
              employerName = 'None'
              benefits = studentBenefits
            } else if (roleName === 'Teacher') {
              isAdvisor = true
              jobTitle = 'Teacher'
              employerName = school
              benefits = teacherBenefits
            } else if (roleName === 'Admin' || roleName === 'admin') {
              isOrganization = true
            } else if (roleName === 'Mentor') {
              isAdvisor = true
              if (!accountCode) {
                accountCode = employerName.replace('"','').replace("<","").replace(">","").replace("%","").replace("{","").replace("}","").replace("|","").replace("^","").replace("~","").replace("[","").replace("]","").replace("`","").replace(/ /g,"").replace(/,/g,"").toLowerCase()
              }
              benefits = mentorBenefits
            } else if (roleName === 'Employer') {
              isEmployer = true
              if (!accountCode) {
                accountCode = employerName.replace('"','').replace("<","").replace(">","").replace("%","").replace("{","").replace("}","").replace("|","").replace("^","").replace("~","").replace("[","").replace("]","").replace("`","").replace(/ /g,"").replace(/,/g,"").toLowerCase()
              }
              benefits = employerBenefits
            }

            if (benefits) {
              for (let i = 1; i <= benefits.length; i++) {
                benefits[i - 1]['detail'] = benefits[i - 1].detail.replace(/{{orgName}}/g,orgName)
              }
            }

            const openToMentoring = true
            const orgURL = passedOrgURL
            const orgRegion = passedOrgRegion
            const orgDescription = passedOrgDescription

            if (response.data.orgInfo.requiredDomains && response.data.orgInfo.requiredDomains.length > 0 && response.data.orgInfo.requiredDomains.some(rd => rd.category === 'school') && (roleName === 'Student' || roleName === 'Career-Seeker')) {
              // console.log('are we in education?')
              const startDate = convertDateToString(new Date().getMonth(),'formatMonth') + ' ' + new Date().getFullYear().toString()
              const endDate = startDate
              education = [{
                name: response.data.orgInfo.requiredDomains[0].name,
                startDate, endDate, isContinual: true,
                degree: response.data.orgInfo.requiredDomains[0].degree,
                location: response.data.orgInfo.requiredDomains[0].location,
              }]
            } else {
              // console.log('not in education: ', response.data.orgInfo.requiredDomains, school)
            }

            const publicProfileExtent = 'Only Connections'
            const publicPreferences = [
              { name: 'Post', value: 'All', publicItems: []},
              { name: 'Project', value: 'All', publicItems: []},
              { name: 'Goal', value: 'All', publicItems: []},
              { name: 'Passion', value: 'All', publicItems: []},
              { name: 'Assessment', value: 'All', publicItems: []},
              { name: 'Endorsement', value: 'All', publicItems: []},
            ]

            let sessionUserId = null
            if (localStorage.getItem('sessionUserId')) {
              sessionUserId = localStorage.getItem('sessionUserId');
            }

            const verificationCode = Math.floor(Math.random() * 100000)

            return await Axios.post('/api/users/register', {
              firstName,lastName, username, email, password, gradYear, orgName, courseIds, workIds,
              orgContactFirstName, orgContactLastName, orgContactEmail,
              activeOrg, myOrgs, roleName, otherRoleName, school, schoolDistrict, jobTitle, employerName, accountCode,
              createdAt, updatedAt, platform, openToMentoring, benefits, headerImageURL,
              isAdvisor, isOrganization, isEmployer, mfaEnabled, workMode, requestAccess,
              orgURL, orgRegion, orgDescription,
              phoneNumber, alternativePhoneNumber, alternativeEmail, subscribedForReminders,degree,major,dateOfBirth,
              gender,races,selfDescribedRace,
              address,zipcode,numberOfMembers, householdIncome, workAuthorization, adversityList,
              educationStatus, education, referrerName, referrerEmail, referrerOrg,
              employerURL, employerLocation, employerDescription, verificationCode,
              publicProfileExtent, publicPreferences, sessionUserId
            })
            .then(async(response) => {
                console.log('Register users', response.data);

                if (response.data.success) {
                  console.log('Register query worked')

                  const confirmUsernameParams = { emailId: email, username }
                  // const confirmUsernameParams = { emailId: email, username, doNotPublicize: true }

                  return await Axios.get('/api/profile/confirm-unique-username', { params: confirmUsernameParams })
                  .then(async(response2) => {
                    console.log('Confirm unique username query attempted', response2.data);

                      if (response.data.success) {
                        console.log('unique username query worked')

                        const newUsername = response2.data.username
                        // localStorage.setItem('username', username)
                        localStorage.setItem('email', email)//this.props.auth.email
                        localStorage.setItem('username', newUsername)
                        localStorage.setItem('firstName', firstName)
                        localStorage.setItem('lastName', lastName)
                        // localStorage.setItem('isAdvisor', 'false')
                        // localStorage.setItem('isAdvisee', 'true')
                        localStorage.setItem('unreadNotificationsCount', 0)
                        localStorage.setItem('orgAffiliation', '')
                        localStorage.setItem('activeOrg', activeOrg)
                        localStorage.setItem('orgFocus', orgFocus)
                        localStorage.setItem('myOrgs', JSON.stringify(myOrgs))
                        localStorage.setItem('orgName', orgName)
                        localStorage.setItem('orgContactEmail', orgContactEmail)
                        localStorage.setItem('orgChatEmail', orgChatEmail)
                        localStorage.setItem('orgLogo', orgLogo)
                        localStorage.setItem('roleName', roleName)
                        localStorage.setItem('publicOrg', publicOrg)
                        if (placementPartners) {
                          localStorage.setItem('placementPartners', JSON.stringify(placementPartners))
                        }

                        if (accountCode) {
                          localStorage.setItem('emp', accountCode)
                        }

                        if (roleName.toLowerCase() === 'worker') {
                          localStorage.setItem('workMode', 'true')
                        }

                        if (studentAliasSingular) {
                          localStorage.setItem('studentAliasSingular', studentAliasSingular)
                        }

                        if (studentAliasPlural) {
                          localStorage.setItem('studentAliasPlural', studentAliasPlural)
                        }

                        if (mentorAliasSingular) {
                          localStorage.setItem('mentorAliasSingular', mentorAliasSingular)
                        }

                        if (mentorAliasPlural) {
                          localStorage.setItem('mentorAliasPlural', mentorAliasPlural)
                        }

                        localStorage.setItem('promptWelcomeMessage','true')

                        if (roleName === 'Employer' && accountCode) {
                          return await Axios.get('/api/account', { params: { accountCode } })
                          .then((response2) => {
                            console.log('Account info query attempted', response2.data);

                            if (response2.data.success) {
                              console.log('account info query worked')

                              // localStorage.setItem('activeOrg', response2.data.accountInfo.activeOrg)
                              localStorage.setItem('sharePartners', JSON.stringify(response2.data.accountInfo.sharePartners))
                              localStorage.setItem('employerName', response2.data.accountInfo.employerName)
                              localStorage.setItem('employerLogoURL', response2.data.accountInfo.employerLogoURI)
                              if (response2.data.accountInfo.internalToolEnabled) {
                                localStorage.setItem('internalToolEnabled', 'true')
                              }

                              return { success: true, message: 'successfully logged in as employer', user: response.data.user, skipWalkthrough }

                            } else {
                              return { success: false, message: response.data.message, error: { message: response2.data.message } }
                            }

                          }).catch((error) => {
                            console.log('Account info query did not work for some reason', error);
                            return { success: false, message: error.toString(), error: { message: error.toString() } }
                          });
                        } else {
                          return { success: true, message: 'New user created', user: response.data.user, skipWalkthrough }
                        }

                      } else {
                        console.log('there was an error')
                        return { error: { message: "Unable to find unique username" }}
                      }
                  })

                } else {
                  console.log('register query did not work', response.data.message)
                  return { error: { message: response.data.message }}

                }

            }).catch((error) => {
                console.log('Register query did not work for some reason', error);
                return { error: { message: error }}
            });
          }

        } else {
          console.log('org info query did not work', response.data.message)
          //don't allow signups without an org affiliation
          return { error: { message: response.data.message }}

        }

    }).catch((error) => {
        console.log('Org info query did not work for some reason', error);
        return { error: { message: error.toString() }}
    });
  }
}

export const signIn = async(email, password, orgFocus, passedOrgCode)=>{
  console.log('signIn called', email, password, orgFocus, passedOrgCode)

  const pathVar = window.location.pathname.toLowerCase()

  if (email === '') {
    return { error: { message: 'please enter your email' }}
  } else if (password === '') {
    return { error: { message: 'please enter your password' }}
  } else {

    if (email) {
      email = email.toLowerCase()
      email = email.trim()
    }

    const vanillaSignInRedirects = true
    let logInCredentials = { email, password }
    if (passedOrgCode && !pathVar.startsWith('/employers')) {
      logInCredentials = { email, password, orgCode: passedOrgCode }
    }

    return await Axios.post('/api/users/login', logInCredentials)
    .then(async(response) => {
      console.log('Login attempted', response.data);

        if (response.data.success) {

          const roleName = response.data.user.roleName

          // deny if you don't have permissions
          if ((pathVar.includes('/student') || pathVar.includes('/career-seeker')) && (roleName !== 'Student' && roleName !== 'Career-Seeker' && roleName !== 'Admin')) {
            console.log('in the career one', roleName, pathVar)
            return { success: false, message: "This account has " + roleName + " permissions. It doesn't have student / career-seeker permissions." }
          } else if ((pathVar.includes('/advisor') || pathVar.includes('/teacher') || pathVar.includes('/mentor')) && (roleName !== 'Mentor' && roleName !== 'Teacher' && roleName !== 'School Support' && roleName !== 'Counselor' && roleName !== 'Admin')) {
            return { success: false, message: "This account has " + roleName + " permissions. It doesn't have advisor permissions." }
          } else if (pathVar.includes('/admin') && (roleName !== 'Admin' && roleName !== 'Work-Based Learning Coordinator' && roleName !== 'WBLC')) {
            return { success: false, message: "This account has " + roleName + " permissions. It doesn't have admin permissions." }
          } else if (pathVar.includes('/employer') && (roleName !== 'Employer' && roleName !== 'Admin')) {
            return { success: false, message: "This account has " + roleName + " permissions. It doesn't have employer permissions." }
          }

          // console.log('in the career two', pathVar, roleName)
          const orgCode = response.data.user.activeOrg

          return await Axios.get('/api/org', { params: { orgCode} })
          .then(async(response2) => {
            console.log('Org info query attempted for orgFocus on login', response2.data);

              if (response2.data.success) {
                console.log('org info query worked for after sign in')

                const orgFocus = response2.data.orgInfo.orgFocus
                let publicOrg = response2.data.orgInfo.isPublic
                const orgName = response2.data.orgInfo.orgName
                const orgContactEmail = response2.data.orgInfo.contactEmail
                let orgChatEmail = response2.data.orgInfo.chatEmail
                if (!orgChatEmail) {
                  orgChatEmail = orgContactEmail
                }
                const orgLogo = response2.data.orgInfo.webLogoURIColor
                // const placementPartners = response2.data.orgInfo.placementPartners
                const requirePersonalInfo = response2.data.orgInfo.requirePersonalInfo
                const signUpFieldsRequired = response2.data.orgInfo.signUpFieldsRequired

                if (response2.data.orgInfo.studentAliasSingular) {
                  localStorage.setItem('studentAliasSingular', response2.data.orgInfo.studentAliasSingular)
                }

                if (response2.data.orgInfo.studentAliasPlural) {
                  localStorage.setItem('studentAliasPlural', response2.data.orgInfo.studentAliasPlural)
                }

                if (response2.data.orgInfo.mentorAliasSingular) {
                  localStorage.setItem('mentorAliasSingular', response2.data.orgInfo.mentorAliasSingular)
                }

                if (response2.data.orgInfo.mentorAliasPlural) {
                  localStorage.setItem('mentorAliasPlural', response2.data.orgInfo.mentorAliasPlural)
                }

                localStorage.setItem('email', email)
                localStorage.setItem('username', response.data.user.username)
                localStorage.setItem('firstName', response.data.user.firstName)
                localStorage.setItem('lastName', response.data.user.lastName)
                localStorage.setItem('pictureURL', response.data.user.pictureURL)
                localStorage.setItem('unreadNotificationsCount', 0)

                if (response.data.user.workMode === true) {
                  localStorage.setItem('workMode', 'true')
                } else {
                  localStorage.setItem('workMode', 'false')
                }

                if (response.data.user.isAdvisor) {
                  localStorage.setItem('isAdvisor', 'true')
                } else {
                  localStorage.setItem('isAdvisor', 'false')
                  localStorage.setItem('isAdvisee', 'true')
                }

                if (response.data.user.orgAffiliation) {
                  if (response.data.user.orgAffiliation === 'admin') {
                    console.log('user is an admin')
                    localStorage.setItem('orgAffiliation', 'admin')
                  } else {
                    console.log('user is not an admin')
                    localStorage.setItem('orgAffiliation', '')
                  }
                } else {
                  console.log('no orgAffiliation found')
                  localStorage.setItem('orgAffiliation', '')
                }

                if (response.data.user.myOrgs) {
                  localStorage.setItem('myOrgs', JSON.stringify(response.data.user.myOrgs))
                }

                if (response.data.user.activeOrg) {
                  localStorage.setItem('activeOrg', response.data.user.activeOrg)
                  localStorage.setItem('orgFocus', orgFocus)
                  localStorage.setItem('orgName', orgName)
                  localStorage.setItem('orgContactEmail', orgContactEmail)
                  localStorage.setItem('orgChatEmail', orgChatEmail)
                  localStorage.setItem('orgLogo', orgLogo)
                }

                if (response2.data.orgInfo.placementPartners) {
                  localStorage.setItem('placementPartners', JSON.stringify(response2.data.orgInfo.placementPartners))
                }

                if (response.data.user.temporaryPassword) {
                  localStorage.setItem('temporaryPassword', 'true')
                }

                // console.log('show roleName on signin: ', response.data.user.roleName)
                if (roleName) {
                  localStorage.setItem('roleName', roleName)
                }

                // let publicOrg = this.state.publicOrg
                if (response.data.user.activeOrg === 'guidedcompass') {
                  publicOrg = true
                }
                localStorage.setItem('publicOrg', publicOrg)

                let orgToJoin = null
                if (passedOrgCode && response.data.user.myOrgs && !response.data.user.myOrgs.includes(passedOrgCode)) {
                  orgToJoin = passedOrgCode
                }

                if (response.data.user.accountCode) {
                  localStorage.setItem('accountCode', response.data.user.accountCode)
                }
                localStorage.setItem('promptWelcomeMessage','true')
                // console.log('does it start ---', pathVar)
                if (pathVar.startsWith('/employers') || (vanillaSignInRedirects && pathVar === '/signin' && roleName === 'Employer') || (pathVar.startsWith('/subscribe') && roleName === 'Employer')) {
                  console.log('in it')
                  const accountCode = response.data.user.accountCode
                  console.log('show accountCode: ', accountCode)
                  if (accountCode && accountCode !== '') {
                    return await Axios.get('/api/account', { params: { accountCode } })
                    .then((response3) => {
                      console.log('Account info query attempted', response3.data);

                      if (response3.data.success) {
                        console.log('account info query worked')

                        localStorage.setItem('jobTitle', response.data.user.jobTitle)
                        localStorage.setItem('isEmployer', 'true')
                        localStorage.setItem('emp', accountCode)
                        localStorage.setItem('activeOrg', response3.data.accountInfo.activeOrg)
                        localStorage.setItem('sharePartners', JSON.stringify(response3.data.accountInfo.sharePartners))
                        localStorage.setItem('employerName', response3.data.accountInfo.employerName)
                        localStorage.setItem('employerLogoURL', response3.data.accountInfo.employerLogoURI)

                        if (response3.data.accountInfo.internalToolEnabled) {
                          localStorage.setItem('internalToolEnabled', 'true')
                        }

                        return { success: true, message: 'successfully logged in as employer', user: response.data.user }

                      } else {
                        return { success: false, message: response3.data.message, error: { message: response3.data.message } }
                      }

                    }).catch((error) => {
                      console.log('Account info query did not work for some reason', error);
                      return { success: false, message: error.toString(), error: { message: error.toString() } }
                    });
                  } else {

                    return { success: false, message: 'No employer associated with this account. Please email help@guidedcompass.com' }
                  }
                } else {
                  if ((!vanillaSignInRedirects && pathVar === '/signin' || pathVar.includes('student') || roleName === 'Student' || roleName === 'Career-Seeker' || roleName === 'Employee') || ((vanillaSignInRedirects && pathVar === '/signin' && (roleName === 'Student' || roleName === 'Career-Seeker')))) {
                    // this.props.navigate('/app')
                    // console.log('-----? ! yeah', signUpFieldsRequired)
                    if (requirePersonalInfo && signUpFieldsRequired && signUpFieldsRequired.length > 0) {
                      for (let i = 1; i <= signUpFieldsRequired.length; i++) {
                        // console.log('do it', i)
                        const item = signUpFieldsRequired[i - 1]
                        const userObject = response.data.user

                        if (item.required) {
                          // console.log('do it', i, item)
                          // multiple answer is array
                          if (item.questionType === 'Multiple Answer' && (!userObject[item.shorthand] || userObject[item.shorthand].length === 0)) {
                            return { success: true, message: 'successfully logged in as student', user: response.data.user, orgToJoin, redirectToOnboarding: true }
                          } else if (!item.shorthand.includes("|") && (!userObject[item.shorthand] || !userObject[item.shorthand] === '')) {
                            return { success: true, message: 'successfully logged in as student', user: response.data.user, orgToJoin, redirectToOnboarding: true }
                          } else if (item.shorthand.includes("|") && (!userObject[item.shorthand.split("|")[0]] || userObject[item.shorthand.split("|")[0]].length === 0)) {
                            return { success: true, message: 'successfully logged in as student', user: response.data.user, orgToJoin, redirectToOnboarding: true }
                          }
                        }
                      }
                    }

                    return { success: true, message: 'successfully logged in as student', user: response.data.user, orgToJoin }
                  } else if (pathVar.includes('/admin') || (vanillaSignInRedirects && pathVar === '/signin' && (roleName === 'Admin' || roleName === 'Work-Based Leaning Coordinator' || roleName === 'WBLC'))) {

                    return { success: true, message: 'successfully logged in as admin', user: response.data.user }

                  } else if (pathVar.includes('/advisor') || pathVar.includes('/teacher') || pathVar.includes('/mentor') || roleName === 'Teacher' || roleName === 'Mentor' || roleName === 'School Support' || (vanillaSignInRedirects && pathVar === '/signin' && (roleName === 'Teacher' || roleName === 'Counselor' || roleName === 'School Support' || roleName === 'Mentor'))) {
                    // mentor or teacher
                    return { success: true, message: 'successfully logged in as an advisor', user: response.data.user, orgToJoin }

                  } else if (roleName === 'Admin' || roleName === 'Work-Based Learning Coordinator') {
                    return { success: true, message: 'successfully logged in as admin', user: response.data.user }
                  } else {
                    console.log('Something went very wrong with identifying the role: ', response.data.user.activeOrg, roleName, pathVar)
                    return { error: { message: "Something went wrong identifying your role or you don't have the correct for this sign in permissions. Here's some relevant information attached to this account: " + roleName }}
                  }
                }

              } else {
                console.log('org info query did not work', response2.data.message)
                return { error: { message: "We had an issue retrieving the org information"}}
              }

          }).catch((error) => {
              console.log('Org info query did not work for some reason', error);
          });
        } else {
          console.log('login did not work', response.data.message)
          //don't allow signups without an org affiliation
          // return { error: { message: response.data.message }}
          return { success: false, message: response.data.message }

        }

    }).catch((error) => {
        console.log('Login did not work for some reason', error);
        return { error: { message: error }}
    });

  }
}

export const signOut = async(email, activeOrg, orgFocus, accountCode, roleName, navigate, returnStatus)=>{
  console.log('signOut called', email, activeOrg, orgFocus, accountCode, roleName, navigate, returnStatus)

  const pathVar = window.location.pathname.toLowerCase()

  return await Axios.post('/api/users/logout', { email })
  .then((response) => {
    console.log('Logout attempted', response.data);

    if (response.data.success) {
      console.log('Logout worked', email)

      localStorage.removeItem('email')//this.props.auth.email
      localStorage.removeItem('pictureURL')
      localStorage.removeItem('username')
      localStorage.removeItem('firstName')
      localStorage.removeItem('lastName')
      localStorage.removeItem('isAdvisor')
      localStorage.removeItem('unreadNotificationsCount')
      localStorage.removeItem('orgAffiliation')
      localStorage.removeItem('myOrgs')
      localStorage.removeItem('activeOrg')
      localStorage.removeItem('placementPartners')
      localStorage.removeItem('roleName')
      localStorage.removeItem('orgFocus')
      localStorage.removeItem('pathway')
      localStorage.removeItem('studentAliasSingular')
      localStorage.removeItem('studentAliasPlural')
      localStorage.removeItem('mentorAliasSingular')
      localStorage.removeItem('mentorAliasPlural')
      localStorage.removeItem('workMode')
      localStorage.removeItem('isOrganization')
      localStorage.removeItem('org')
      localStorage.removeItem('isEmployer')
      localStorage.removeItem('emp')
      localStorage.removeItem('accountCode')
      localStorage.removeItem('isAdvisee')
      localStorage.removeItem('remoteAuth')
      localStorage.removeItem('authType')
      localStorage.removeItem('publicOrg')
      localStorage.removeItem('orgName')
      localStorage.removeItem('orgContactEmail')
      localStorage.removeItem('orgChatEmail')
      localStorage.removeItem('orgLogo')
      localStorage.removeItem('orgURL')
      localStorage.removeItem('orgRegion')
      localStorage.removeItem('orgDescription')
      localStorage.removeItem('jobTitle')
      localStorage.removeItem('employerName')
      localStorage.removeItem('employerURL')
      localStorage.removeItem('employerRegion')
      localStorage.removeItem('employerDescription')
      localStorage.removeItem('employerLogoURL')
      localStorage.removeItem('sharePartners')
      localStorage.removeItem('internalToolEnabled')
      localStorage.removeItem('promptWelcomeMessage')
      localStorage.removeItem('temporaryPassword')

      if (returnStatus) {
        return { success: true, message: response.data.message }
      } else {
        let logoutLink = '/signin'
        console.log('a1: ', roleName, pathVar, window.location.pathname)
        if (pathVar.includes('/advisor')) {
          console.log('a3')
          logoutLink = '/advisor/signin'
          if (orgFocus && orgFocus === 'Placement' && roleName) {
              //organizations
              logoutLink = '/organizations/' + activeOrg + '/' + roleName.toLowerCase() + '/signin'
          } else {
            // schools
            if (roleName) {
              logoutLink = '/schools/' + activeOrg + '/' + roleName.toLowerCase() + '/signin'
            }
          }
        } else if (pathVar.includes('/organizations')) {
          logoutLink = '/organizations/' + activeOrg + '/admin/signin'
        } else if (pathVar.includes('/employers') && !pathVar.includes('/app/employers')) {
          logoutLink = '/employers/' + accountCode + '/signin'
        } else if (pathVar.includes('/problem-platform')) {
          logoutLink = '/problem-platform/signin'
        } else if (pathVar.includes('/app')) {
          logoutLink = '/signin'
        } else {
          // student
          if (orgFocus && orgFocus === 'Placement') {
              //organizations
              logoutLink = '/organizations/' + activeOrg + '/student/signin'
          } else {
            // schools
            logoutLink = '/schools/' + activeOrg + '/student/signin'
          }
        }

        document.body.style.backgroundColor = "#fff";
        navigate(logoutLink)
      }

    } else {
      console.log('logout did not work', response.data.message)
      //don't allow signups without an org affiliation
      return { success: false, message: response.data.message }

    }

  }).catch((error) => {
      console.log('Logout did not work for some reason', error);
      return { error: { message: error }}
  });
}
