import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubTakeAssessment from './Subcomponents/TakeAssessment';
import withRouter from './Functions/WithRouter';

class AdvTakeAssessment extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let { assessmentTitle } = this.props.params
      let { assessments, index, assessment, resultsData } = this.props.location.state

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      let type = ''
      let assessmentDescription = ''
      let isValidAssessment = true
      if (assessmentTitle === 'work-preferences') {
        type = 'work preferences'
        assessmentTitle = 'Work Preferences'
        assessmentDescription = 'Self-assesses your fit to current and similar work'
      } else if (assessmentTitle === 'personal-goals-and-preferences') {
        type = 'personal'
        assessmentTitle = 'Personal Goals & Preferences'
        assessmentDescription = 'Share your personal goals, frustrations, brands, and influencers'
      } else if (assessmentTitle === 'interest-assessment') {
        type = 'interests'
        assessmentTitle = 'Interest Assessment'
        assessmentDescription = 'Assesses what category of work you may be most interested in'
      } else if (assessmentTitle === 'skill-assessment') {
        type = 'skills'
        assessmentTitle = 'Skill Assessment'
        assessmentDescription = 'Self-assessment of hard and soft skills associated with different career paths'
      } else if (assessmentTitle === 'personality-assessment') {
        type = 'personality'
        assessmentTitle = 'Personality Assessment'
        assessmentDescription = 'Assesses your personality type along axis relevant to different career paths'
      } else if (assessmentTitle === 'values-assessment') {
        type = 'values'
        assessmentTitle = 'Values Assessment'
        assessmentDescription = 'Assesses your values and the values of your ideal employer'
      } else {

      }

      if (isValidAssessment) {

        this.setState({ emailId: email, username, cuFirstName, cuLastName, activeOrg, orgFocus,
          roleName, assessmentTitle, orgLogo,
          assessmentDescription, type, assessments, index, assessment, resultsData
        })
      }
    }

    render() {

      return (
        <div>
          <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} roleName={this.state.roleName} history={this.props.navigate}/>
          <SubTakeAssessment history={this.props.navigate} type={this.state.type} assessmentTitle={this.state.assessmentTitle} assessments={this.state.assessments} index={this.state.index} assessment={this.state.assessment} resultsData={this.state.resultsData} assessmentDescription={this.state.assessmentDescription} />

          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(AdvTakeAssessment)
