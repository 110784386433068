import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Switch from 'react-switch';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { CircularProgressbar } from 'react-circular-progressbar';;
import Modal from 'react-modal';
import MyEditor from '../Subcomponents/MyEditor';
import EditCourse from './EditCourse';
import ProjectDetails from '../Subcomponents/ProjectDetails';
import SubEditPlacement from '../Subcomponents/EditPlacement';
import SubAddWorkspaces from '../Subcomponents/AddWorkspaces';
import SubSubmissions from '../Common/Submissions';
import SubComments from '../Common/Comments';
import SubRenderOpportunityDetails from '../Common/RenderOpportunityDetails';
import withRouter from '../Functions/WithRouter';
import {convertDateToString} from '../Functions/ConvertDateToString';
import {convertStringToDate} from '../Functions/ConvertStringToDate';
import {configureDefaultReports} from '../Functions/ConfigureDefaultReports';
import {subExportStandardCSV} from '../Functions/ExportStandardCSV';
import {subExportCSV} from '../Functions/ExportCSV';

const addCircleOutlineIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-circle-outline-icon.png';
const profileIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-grey.png';
const profileIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-blue.png';
const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
const employersIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/employer-icon.png';
const exportIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/export-icon-blue.png';
const xIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/x-icon.png';
const addIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-blue.png";
const convertIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/convert-icon.png";
const skillsIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/skills-icon.png";
const questionMarkBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/question-mark-blue.png';
const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";
const caseIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/case-icon-blue.png";
const challengeIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/challenge-icon-blue.png";
const assignmentsIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/assignments-icon-blue.png";
const dollarSign = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/dollar-sign.png';
const challengesProcess = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/challenges-process.png';
const checkboxEmpty = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkbox-empty.png';
const checkboxChecked = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkbox-checked.png';
const checkmarkIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png';
const industryIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/industry-icon-dark.png';
const trashIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/trash-icon-dark.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const closeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png';
const addPeopleIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-people-icon-dark.png';
const careerMatchesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/career-matches-icon-dark.png';
const imageIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/image-icon-dark.png';

class EditPosting extends Component {
    constructor(props) {
        super(props)

        this.state = {
          showModule: true,
          path: '',
          disableSomeRequirements: true,

          useNewSubmissionDeadline: true,
          showCustomConfirmationMessage: true,
          hideTextTitle: true,
          isPromotional: true,

          showRich: false,
          showCompetencyTags: true,
          showEventReminder: true,
          showApplicationMethodDetails: true,
          allowReferrals: true,

          hideIdealCandidateProfile: true,
          hideViewMatch: true,

          applicationMethod: 'Only receive candidates who are referred via Guided Compass',

          appComponents: [],
          rsvpComponents: [],
          postTypeOptions: [],
          subPostTypeOptions: [],
          projectPromptTypeOptions: ['','Assignment','Problem','Challenge'],
          typeOptions: ['','For-Profit','Non-Profit','Public Sector'],
          hourlyPayRangeOptions: [],
          annualPayRangeOptions: [],
          functionOptions: [],
          customAssessmentOptions: [{ title : 'No Custom Assessments Saved'}],
          benchmarkOptions: [{ title: 'No Benchmarks Saved'}],
          hoursPerWeekOptions: [],
          hireCountOptions: [],
          employerOptions: [{ name: 'No Employers Saved'}],
          courseOptions: [{name: ''},{name: 'Add New Course'}],
          programOptions: [{ name: 'No Employers Saved'}],
          pathwayOptions: [],
          departmentOptions: [],
          sectionTemplateOptions: [],
          evaluationMethodOptions: [],
          prizeOptions: [],
          employers: [],
          organizations: [],
          dateOptions: [],
          gradeLevelOptions: [],
          knowledgeLevelOptions: [],
          difficultyOptions: ['','Very Easy','Easy','Medium','Hard','Challenger'],
          assessmentOptions: [{ title: 'Pick an RSVP Survey'}],
          attendanceOptions: [],
          employerTypeOptions: [],
          employerSizeOptions: [],
          industryOptions: [],
          eventTypeOptions: [],
          locationTypeOptions: [],
          binaryOptions: ['','Yes','No'],
          employerHostOptions: ['','One Employer Will Lead','Multiple Employers Will Lead','No Employers Will Lead'],
          postCountOptions: [],
          applicationMethodOptions: [],
          locationOptions: [],
          statusOptions: [],
          assigneeGroupOptions: ['Everyone'],
          roleNameOptions: [],
          durationOptions: [],
          politicalPartyOptions: [],
          gradeOptions: [],
          announcementMethodOptions: ['','Automated Email','At Event'],
          pathwayPlacementTypeOptions: ['','CTE','Non-CTE','IEP'],
          referrerOptions: [],
          workTypeOptions: ['','Full-Time','Part-Time','Internship','Apprenticeship'],
          tagTypeOptions: ['','Hard Skill','Soft Skill','Knowledge'],
          payTypeOptions: ['','Hourly','Salary','Custom'],
          hourlyPayOptions: [],
          annualPayOptions: [],
          supplementalPayOptions: [],
          benefitOptions: [],
          questionTypeOptions: ['Short Answer','Long Answer','Multiple Choice','Checkbox','Ranking','Email','Birthdate'],
          hourOptions: ['','01','02','03','04','05','06','07','08','09','10','11','12'],
          minuteOptions: ['','00','30'],
          meridiemOptions: ['','AM','PM'],
          reminderTimeOptions: ['','At time of event','30 minutes before','1 hour before','2 hours before'],
          targetRoleOptions: ['Career-Seekers','Volunteers'],
          stageOptions: [],
          typeTagOptions: [],
          yearsOfExperienceOptions: [],

          status: 'Undecided',
          problemType: 'Exploratory',
          jobTitle: '',
          employerName: '',
          employerType: '',
          employerSize: '',
          difficultyLevel: '',
          industry: '',
          workFunctions: [],
          videoLink: '',
          summary: '',
          employerInfo: '',
          guidelines: '',
          solution: '',
          selectedAssessment: { title: 'Pick an RSVP Survey'},
          course: { name: ''},
          selectedCourses: [],
          assigneeGroup: '',
          pathways: [],
          departments: [],
          industries: [],
          targetRoles: [],

          supplementalPayArray: [],
          benefits: [],

          payType: 'Hourly',
          payRange: '$10 - $20 / Hour',
          pay: 0,

          viewIndex: 0,

          resources: [],
          links: [],
          assignments: [],
          checked: [],

          exhibits: [],
          sectionTemplate: 'No',
          functions: [],

          submissionDeadline: '',
          announcementDate: '',
          evaluationMethod: '',
          prizes: ['','',''],
          rules: '',
          calculatedHours: 15,

          org: '',
          isActive: true,

          postType: '',
          title: '',
          location: '',
          type: 'Internship',
          subPostType: 'Individual',
          function: '',

          startDate: '',
          isPerpetual: false,
          endDate: '',
          hoursPerWeek: '',
          hireCount: '1',

          orgTransactionNoti: true,
          usePrimaryOrgContact: true,
          orgContactEmail: '',

          orgName: '',
          orgURL: '',
          orgMission: '',

          customAssessment: { title : 'No Custom Assessments Saved'},
          benchmark: { title: 'GC: Other Benchmark', _id: '6084f5fdeeeee99c010eaf08' },

          selectedEmployer: { employerName: 'No Employers Saved'},

          description: '',

          includeBasicInfo: true,
          includeResume: true,
          includeCoverLetter: false,
          includeInterests: true,
          includeSkills: true,
          includeTraits: true,
          includeGoals: false,
          includeEndorsements: true,
          includeCustomAssessment: false,

          includeInNotifications: true,

          disableInterests: false,
          disableSkills: false,
          disableTraits: false,

          topInterests: [],
          topSkills: [],
          topTraits: [],
          rsvps: [],
          attendees: [],

          employerURL: '',
          employeeCount: '',
          employeeGrowth: '',

          applications: [],

          formHasChanged: false,
          childrenAdded: [],

          animating: false,

          includeVotingRound: true,

          selectedIndex: 0,
          myComment: '',
          comments: [],
          myReplies: [],
          submissionComments: [],
          mySubmissionComments: [],

          assignees: [],
          suggestedAssignees: [],
          workers: [],
          suggestedWorkers: [],

          editComments: [],
          editSubmissionComments: [],

          clientErrorMessage: '',
          serverPostSuccess: true,
          serverSuccessMessage: '',
          serverErrorMessage: '',

        }

        this.retrieveData = this.retrieveData.bind(this)
        this.pullConditionalInfo = this.pullConditionalInfo.bind(this)
        this.pullOppData = this.pullOppData.bind(this)

        this.deletePost = this.deletePost.bind(this)
        this.calculateMatches = this.calculateMatches.bind(this)

        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.exportInfo = this.exportInfo.bind(this)
        this.child = React.createRef();
        this.indicateFormChange = this.indicateFormChange.bind(this)

        this.editJob = this.editJob.bind(this)
        this.saveCustomAssessment = this.saveCustomAssessment.bind(this)
        this.saveCustomBenchmark = this.saveCustomBenchmark.bind(this)
        this.finishPosting = this.finishPosting.bind(this)
        this.saveAttendee = this.saveAttendee.bind(this)

        this.bulkConvert = this.bulkConvert.bind(this)

        // duplicates
        this.employerClicked = this.employerClicked.bind(this)
        this.orgClicked = this.orgClicked.bind(this)
        this.assigneeClicked = this.assigneeClicked.bind(this)
        this.workerClicked = this.workerClicked.bind(this)
        this.itemClicked = this.itemClicked.bind(this)
        this.competencyClicked = this.competencyClicked.bind(this)
        this.buttonClicked = this.buttonClicked.bind(this)
        this.optionClicked = this.optionClicked.bind(this)
        this.switchPreference = this.switchPreference.bind(this)

        this.searchEmployers = this.searchEmployers.bind(this)
        this.searchOrganizations = this.searchOrganizations.bind(this)
        this.searchAssignees = this.searchAssignees.bind(this)
        this.searchItems = this.searchItems.bind(this)
        this.searchWorkers = this.searchWorkers.bind(this)
        this.searchCompetencies = this.searchCompetencies.bind(this)

        this.renderApplications = this.renderApplications.bind(this)
        this.renderTags = this.renderTags.bind(this)
        this.renderNotiTags = this.renderNotiTags.bind(this)
        this.renderResources = this.renderResources.bind(this)
        this.renderExhibits = this.renderExhibits.bind(this)
        this.renderLinks = this.renderLinks.bind(this)
        this.renderRAndA = this.renderRAndA.bind(this)
        this.renderRSVPs = this.renderRSVPs.bind(this)
        this.renderProspects = this.renderProspects.bind(this)
        this.renderQuestions = this.renderQuestions.bind(this)
        this.renderAnswerChoices = this.renderAnswerChoices.bind(this)
        this.renderReadOnlyContent = this.renderReadOnlyContent.bind(this)
        this.renderPathways = this.renderPathways.bind(this)
        this.renderDepartments = this.renderDepartments.bind(this)
        this.renderTargetRoles = this.renderTargetRoles.bind(this)
        this.renderPrograms = this.renderPrograms.bind(this)
        this.renderSharePartners = this.renderSharePartners.bind(this)
        this.renderTaggedCandidatesOfInterest = this.renderTaggedCandidatesOfInterest.bind(this)
        this.renderTaggedPostings = this.renderTaggedPostings.bind(this)
        this.renderConfirmDelete = this.renderConfirmDelete.bind(this)
        this.renderConfirmationMessage = this.renderConfirmationMessage.bind(this)
        this.renderApplicationMethodDetails = this.renderApplicationMethodDetails.bind(this)
        this.renderApplicationComponents = this.renderApplicationComponents.bind(this)
        this.renderImageAsTitle = this.renderImageAsTitle.bind(this)
        this.renderWorkFunctions = this.renderWorkFunctions.bind(this)
        this.renderVisibilityOptions = this.renderVisibilityOptions.bind(this)
        this.renderDateRange = this.renderDateRange.bind(this)
        this.renderDescription = this.renderDescription.bind(this)
        this.renderOrgContactInformation = this.renderOrgContactInformation.bind(this)
        this.renderCustomAssessment = this.renderCustomAssessment.bind(this)

        this.addResource = this.addResource.bind(this)
        this.addLink = this.addLink.bind(this)
        this.addExhibit = this.addExhibit.bind(this)
        this.addTag = this.addTag.bind(this)
        this.addAnswerChoice = this.addAnswerChoice.bind(this)
        this.addItem = this.addItem.bind(this)
        this.addNewCourse = this.addNewCourse.bind(this)
        this.addOrgToPosting = this.addOrgToPosting.bind(this)

        this.removeExhibit = this.removeExhibit.bind(this)
        this.removeTag = this.removeTag.bind(this)
        this.removeOrg = this.removeOrg.bind(this)
        this.deleteItem = this.deleteItem.bind(this)

    }

    static defaultProps = { org: null, accountCode: null, selectedOpportunity: null, editMode: false, jobs: [], roleName: null, path: '' }

    componentDidMount() {
      console.log('editPosting called in subcomponent');

      const org = this.props.org
      const selectedOpportunity = this.props.selectedOpportunity
      const editMode = this.props.editMode
      const jobs = this.props.jobs
      const roleName = this.props.roleName
      const path = this.props.path

      if (window.location.pathname.includes('/problem-platform')) {
        this.retrieveData(org, selectedOpportunity, editMode, jobs, roleName, path)
      } else if (window.location.pathname.includes('/advisor')) {
        this.retrieveData(org, selectedOpportunity, editMode, jobs, roleName, path)
      } else if (window.location.pathname.includes('/walkthrough')) {
        this.retrieveData(org, selectedOpportunity, editMode, jobs, roleName, path)
      }
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in editPosting')

      const org = this.props.org
      const selectedOpportunity = this.props.selectedOpportunity
      const editMode = this.props.editMode
      const jobs = this.props.jobs
      const roleName = this.props.roleName
      const path = this.props.path

      if (this.props.selectedOpportunity) {
        if (!prevProps.selectedOpportunity) {
          this.retrieveData(org, selectedOpportunity, editMode, jobs, roleName, path)
        } else {
          if (this.props.selectedOpportunity.postType !== prevProps.selectedOpportunity.postType) {

            console.log('t4', selectedOpportunity)
            this.retrieveData(org, selectedOpportunity, editMode, jobs, roleName, path)

          } else if (this.props.postingId !== prevProps.postingId) {
            // !this.state.selectedOpportunity && this.props.selectedOpportunity ||

            console.log('t4', selectedOpportunity)
            this.retrieveData(org, selectedOpportunity, editMode, jobs, roleName, path)

          } else {
            console.log('t90', this.state.selectedOpportunity)
          }
        }
      } else if (this.props.path !== prevProps.path) {
        console.log('t8', selectedOpportunity)
        this.retrieveData(org, selectedOpportunity, editMode, jobs, roleName, path)
      } else if (this.props.org !== prevProps.org) {
        console.log('t5',selectedOpportunity)
        this.retrieveData(org, selectedOpportunity, editMode, jobs, roleName, path)

      } else {
        console.log('t7')
      }
    }

    retrieveData(org, selectedOpportunity, editMode, jobs, roleName, path) {
      console.log('retrieveData called in shared editPosting component', selectedOpportunity)

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      // let roleName = localStorage.getItem('roleName')
      let orgFocus = localStorage.getItem('orgFocus');
      let placementPartners = localStorage.getItem('placementPartners');
      // let activeOrg = localStorage.getItem('activeOrg')

      const sectionTemplateOptions = [
        { title: 'Custom', sectionTitles: [] },
        { title: 'Exploratory', sectionTitles: ['Major Pain Points','Problem Size','How I Discovered','Why I Posted','My Background','Employer Background'] },
        { title: 'Technical', sectionTitles: ['Technical Requirements','Required Skills'] },
      ]

      const problemTypeOptions = ['','Exploratory','Technical']

      const evaluationMethodOptions = ['','Judges Decide','Community Voting']
      const prizeOptions = ['','None','$10','$100','$1,000','$10,000']

      const startMonth = new Date().getMonth() + 2
      // const currentDay = new Date().getDate()
      const currentYear = new Date().getFullYear()
      const nextYear = currentYear + 1

      let dateOptions = ['']

      for (let i = 1; i <= 12; i++) {

        let givenMonth = startMonth + i - 1
        if (givenMonth > 12) {
          givenMonth = givenMonth - 12

          dateOptions.push(givenMonth + '/01/' + nextYear )
        } else {
          dateOptions.push(givenMonth + '/01/' + currentYear)
        }
      }

      const gradeLevelOptions = ['','6th - 8th','9th - 10th','11th - 12th','College', 'All Grade Levels']
      const knowledgeLevelOptions = ['','Beginner','1-2 Years of Familiarity','3+ Years of Familiarity']
      const locationTypeOptions = ['','My Organization / School', 'Employer', 'Remote']
      let postCountOptions = ['']
      for (let i = 1; i <= 6; i++) {
        postCountOptions.push(i.toString())
      }

      let postTypeOptions = ['Event','Project','Work']
      if (this.state.showModule && window.location.pathname.startsWith('/organizations')) {
        postTypeOptions = ['Event','Project','Program','Work']
      }

      let postType = ''

      if (path) {
        if (path.includes('problem-platform')) {
          postTypeOptions = ['Assignment','Problem','Challenge']
          postType = 'Assignment'
        } else if (path.includes('/employers/')) {
          if (this.props.postType) {
            postType = this.props.postType
          } else {
            // if (!org || org === '' || org === 'guidedcompass') {
            //   postType = 'Work'
            // } else {
            //   postType = 'Internship'
            // }
            postType = 'Work'
          }
        }
      }

      let eventTypeOptions = []
      let requestMode = false
      let isExternalPost = false
      const statusOptions = ['Undecided','Approved','Denied']

      let showFeatured = true

      let willEmployerLead = this.state.willEmployerLead

      if (path) {
        if (path.includes('/advisor') && roleName === 'Teacher') {
          eventTypeOptions = ["","Talk","Workshop","Mock Interview","Field Trip","Job Shadow","Project Showcase","Networking / Career Fair","Conference","Other"]
        } else if (path.includes('/advisor') && roleName === 'School Support') {
          eventTypeOptions = ["","Talk","Workshop","Mock Interview","Workshop","Field Trip","Job Shadow","Project Showcase","Networking / Career Fair","Conference","Other"]
        } else if (path.includes('/advisor') && roleName === 'Mentor') {
          eventTypeOptions = ["","Talk","Workshop","Mock Interview","Workshop","Job Shadow","Other"]
          requestMode = true
        } else if (path.includes('/organizations')) {
          eventTypeOptions = ["","Talk","Workshop","Mock Interview","Field Trip","Job Shadow","Project Showcase","Networking / Career Fair","Conference","Other"]
        } else if (path.includes('/employers')) {
          eventTypeOptions = ["","Talk","Workshop","Mock Interview","Field Trip","Job Shadow","Conference","Other"]
          requestMode = true
          showFeatured = false
          willEmployerLead = 'One Employer Will Lead'
        } else if (path.includes('/problem-platform')) {
          if (roleName === 'Admin' || roleName === 'Work-Based Learning Coordinator') {
            requestMode = false
          } else {
            requestMode = true
          }

          isExternalPost = true
          showFeatured = false
        }
      }

      const roleNameOptions = ['','Employer Representative','Mentor','Teacher','Work-Based Learning Coordinator','Principal / President','Student','Other']

      let showRich = this.state.showRich
      if (org === 'c2c') {
        showRich = true
      }

      const gradeOptions = ['','A+','A','A-','B+','B','B-','C+','C','C-','D+','D','D-','F']

      let appComponents = [
        { name: 'Basic Profile Info', include: true, required: true, disabled: true },
        { name: 'Profile Details', include: true, required: false, disabled: false },
        { name: 'Resume', include: true, required: true, disabled: false },
        { name: 'Cover Letter', include: false, required: false, disabled: false },
        { name: 'Academic Transcript', include: false, required: false, disabled: false },
        { name: 'Letter of Recommendation', include: false, required: false, disabled: false },
        { name: 'Identification', include: false, required: false, disabled: false },
        { name: 'Career Assessments', include: true, required: false, disabled: false },
        { name: 'Endorsements', include: true, required: false, disabled: false },
        { name: 'Vaccinations', include: false, required: false, disabled: false },
        { name: 'Referral Information', include: false, required: false, disabled: false },
        { name: 'Custom Assessment', include: false, required: false, disabled: false },
      ]

      let rsvpComponents = [
        // { name: 'Phone Number', include: false, required: false, disabled: false },
        { name: 'Custom Assessment', include: false, required: false, disabled: false },
      ]

      this.setState({
          emailId: email, username,cuFirstName, cuLastName, org, orgFocus, placementPartners,
          sectionTemplateOptions, path, postType, showFeatured,
          problemTypeOptions, evaluationMethodOptions, prizeOptions, dateOptions, gradeLevelOptions, knowledgeLevelOptions, locationTypeOptions, postCountOptions,
          roleName, postTypeOptions, eventTypeOptions, statusOptions, roleNameOptions, requestMode, editMode, isExternalPost,
          willEmployerLead, showRich, gradeOptions, appComponents, rsvpComponents
      });

      Axios.get('/api/courses', { params: { orgCode: org } })
      .then((response) => {

          if (response.data.success) {
            console.log('Course details query worked', response.data);

            if (response.data.courses) {
              let courseOptions = response.data.courses
              courseOptions.unshift({ name: ''})
              courseOptions.push({ name: 'Add New Course'})
              this.setState({ courseOptions })
            }
          } else {
            console.log('no courses found', response.data.message)
            let courseOptions = [{ name: ''},{ name: 'Add New Course'}]
            this.setState({ courseOptions })
          }

      }).catch((error) => {
          console.log('Course query did not work', error);
          let courseOptions = [{ name: ''},{ name: 'Add New Course'}]
          this.setState({ courseOptions })
      });

      if (this.props.postingId) {
        Axios.get('/api/postings/byid', { params: { _id: this.props.postingId } })
        .then((response) => {
           console.log('Posting query attempted, does this work:', response.data);

           if (response.data.success) {
             console.log('successfully retrieved posting')

             selectedOpportunity = response.data.posting
             this.pullConditionalInfo(org, selectedOpportunity, editMode, jobs, roleName, path, email, cuFirstName, cuLastName, placementPartners)
             this.pullOppData(org, selectedOpportunity, editMode, jobs, roleName, path, email, cuFirstName, cuLastName, username, placementPartners, orgFocus, appComponents, rsvpComponents)
           } else {
             console.log('no posting data found', response.data.message)
           }

        }).catch((error) => {
             console.log('Posting query did not work', error);
        });
      } else if (selectedOpportunity) {
        if (this.props.duplicate) {
          console.log('in duplicate')
          delete selectedOpportunity._id
          selectedOpportunity['orgCode'] = org
          selectedOpportunity['title'] = selectedOpportunity.title + " (Duplicate)"
          selectedOpportunity['candidatesOfInterest'] = []
          selectedOpportunity['applications'] = []
          selectedOpportunity['applicants'] = []
        }

        this.pullConditionalInfo(org, selectedOpportunity, editMode, jobs, roleName, path, email, cuFirstName, cuLastName, placementPartners)
        this.pullOppData(org, selectedOpportunity, editMode, jobs, roleName, path, email, cuFirstName, cuLastName, username, placementPartners, orgFocus, appComponents, rsvpComponents)

      } else {
        this.pullConditionalInfo(org, null, editMode, jobs, roleName, path, email, cuFirstName, cuLastName, placementPartners)
      }
    }

    pullConditionalInfo(org, selectedOpportunity, editMode, jobs, roleName, path, email, cuFirstName, cuLastName, placementPartners) {
      console.log('pullConditionalInfo called')

      let reportOptions = configureDefaultReports(true)

      Axios.get('/api/reports', { params: { orgCode: org } })
      .then((response) => {
         console.log('Reports query attempted', response.data);
          if (response.data.success && response.data.reports) {
            console.log('reports query worked')

            reportOptions.shift()
            reportOptions = response.data.reports.concat(reportOptions)
            reportOptions.unshift({ name: '' })
            // reportOptions = reportOptions.splice(1,0,response.data.reports)
            this.setState({ reportOptions })

          } else {
            console.log('reports query did not work', response.data.message)
            this.setState({ reportOptions })
          }
      }).catch((error) => {
          console.log('Reports query did not work for some reason', error);
          this.setState({ reportOptions })
      });

      if (path && path.includes('/employers/')) {
        //if an employer, the selectedEmployer is that employer

        if (this.props.accountCode) {

          const accountCode = this.props.accountCode

          Axios.get('/api/account', { params: { accountCode } })
          .then((response) => {
            console.log('Account info query attempted', response.data);

            if (response.data.success) {
              console.log('account info query worked')

              // const accountCode = response.data.accountInfo.accountCode
              const employerName = response.data.accountInfo.employerName
              const employerType = response.data.accountInfo.employerType
              const employerURL = response.data.accountInfo.employerURL
              const employerEmployees = response.data.accountInfo.employeeCount
              const employerGrowth = response.data.accountInfo.employeeGrowth
              const employerIndustry = response.data.accountInfo.employerIndustry

              let employerContactFirstName = ''
              let employerContactLastName = ''
              let employerContactEmail = ''
              if (selectedOpportunity) {
                if (selectedOpportunity.employerContactEmail) {
                  employerContactFirstName = selectedOpportunity.employerContactFirstName
                  employerContactLastName = selectedOpportunity.employerContactLastName
                  employerContactEmail = selectedOpportunity.employerContactEmail
                }
              } else {
                employerContactFirstName = cuFirstName
                employerContactLastName = cuLastName
                employerContactEmail = email
                // employerContactFirstName = response.data.accountInfo.contactFirstName
                // employerContactLastName = response.data.accountInfo.contactLastName
                // employerContactEmail = response.data.accountInfo.contactEmail
              }

              let pictureURL = null
              let employerLogoURI = response.data.accountInfo.employerLogoURI
              if (response.data.accountInfo.employerLogoURI) {
                pictureURL = response.data.accountInfo.employerLogoURI
              }

              let sharePartners = response.data.accountInfo.sharePartners

              const selectedEmployer = {
                accountCode, employerName, employerType, employerURL, employerEmployees, employerGrowth, employerIndustry, employerLogoURI,
              }

              this.setState({
                selectedEmployer, employerContactFirstName, employerContactLastName, employerContactEmail,
                pictureURL, employerLogoURI,
                employerName, employerURL, industry: employerIndustry, accountCode, sharePartners
              });

              // test if there is current work
              Axios.get('/api/work', { params: { accountCode } })
              .then((response) => {
                console.log('Work query attempted within employer dashboard', response.data);

                if (response.data.success && response.data.workArray && response.data.workArray.length > 0) {
                  console.log('account info query worked in sub settings')

                  const workArray = response.data.workArray
                  this.setState({ workArray })

                  // delete this if possible
                  Axios.get('/api/org', { params: { orgCode: org } })
                  .then((response) => {
                    console.log('Org info query attempted one', response.data);

                    if (response.data.success) {
                      console.log('org info query worked', path)

                      const orgName = response.data.orgInfo.orgName
                      const assigneeGroupOptions = ['All of ' + orgName, 'All ' + employerName + ' Workers', 'Specific Individuals']

                      this.setState({ orgName, assigneeGroupOptions });

                    } else {
                      console.log('org info query did not work', response.data.message)
                    }

                  }).catch((error) => {
                      console.log('Org info query did not work for some reason', error);
                  });

                } else {
                  // no need to update assigneeGroupOptions
                }

              }).catch((error) => {
                console.log('Account info query did not work for some reason', error);
              });

              if (selectedOpportunity) {
                sharePartners = []
                if (selectedOpportunity.placementPartners) {
                  sharePartners = selectedOpportunity.placementPartners
                }
              } else {
                if (sharePartners && sharePartners.includes('guidedcompass')) {
                  const gcIndex = sharePartners.indexOf('guidedcompass')
                  sharePartners.splice(gcIndex,1)
                  // console.log('show sharePartners: ', sharePartners)
                }
              }

              if (sharePartners.length > 0) {
                Axios.get('/api/orgs', { params: { orgCodes: sharePartners } })
                .then((response) => {
                  console.log('Org training providers query attempted', response.data);

                  if (response.data.success) {
                    console.log('org training providers query worked in sub settings')

                    const trainingProviders = response.data.orgs
                    this.setState({ trainingProviders })

                  }

                }).catch((error) => {
                  console.log('Org training providers query did not work for some reason', error);
                });
              }
            }

          }).catch((error) => {
            console.log('Account info query did not work for some reason', error);
          });
        }

      } else {

        // pull information only required when creating a new posting
        if (window.location.pathname.startsWith('/organizations')) {
          Axios.get('/api/programs', { params: { orgCode: org } })
          .then((response) => {

              if (response.data.success) {
                console.log('Programs query worked', response.data);

                let programOptions = response.data.programs
                programOptions.unshift({ title: ''})
                // programOptions.push({ name: 'Add New Program'})
                this.setState({ programOptions })
              } else {
                console.log('no programs found', response.data.message)
              }
          }).catch((error) => {
              console.log('Program query did not work', error);
          });
        }

        Axios.get('/api/account/partners', { params: { org, placementPartners } })
        .then((response) => {
          console.log('Posted employer query attempted!', response.data);

          if (response.data.success) {
            console.log('posted employer query worked')

            if (response.data.employers.length !== 0) {

              let employerOptions = response.data.employers
              // employerOptions.sort(function(a,b) {
              //   return b.employerName - a.employerName;
              // })
              employerOptions.sort(function(a, b){
                  if(a.employerName < b.employerName) { return -1; }
                  if(a.employerName > b.employerName) { return 1; }
                  return 0;
              })

              let selectedEmployer = { employerName: ''}
              employerOptions.unshift(selectedEmployer)


              console.log('show employerOptions: ', selectedOpportunity)

              if (selectedOpportunity) {

                if (selectedOpportunity.accountCode) {
                  for (let i = 1; i <= employerOptions.length; i++) {
                    console.log('did we get in here for sure: ', selectedOpportunity.accountCode)
                    if (selectedOpportunity.accountCode === employerOptions[i - 1].accountCode) {
                      console.log('testers', employerOptions[i - 1])
                      selectedEmployer = employerOptions[i - 1]
                    }
                  }
                }
              }
              console.log('show accountCode: ', selectedEmployer, selectedOpportunity)
              this.setState({ employerOptions, selectedEmployer });
            }

          } else {
            console.log('query for employers query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Employer query did not work for some reason', error);
        });
      }

      Axios.get('/api/workoptions')
      .then((response) => {
        console.log('Work options query tried', response.data);

        if (response.data.success) {
          console.log('Work options query succeeded')

          let functionOptions = response.data.workOptions[0].functionOptions
          const durationOptions = response.data.workOptions[0].durationOptions

          let politicalPartyOptions = response.data.workOptions[0].politicalAlignmentOptions
          if (politicalPartyOptions) {
            politicalPartyOptions.unshift('')
          }

          let hoursPerWeekOptions = response.data.workOptions[0].hoursPerWeekOptions
          let subPostTypeOptions = response.data.workOptions[0].workTypeOptions
          let locationOptions = response.data.workOptions[0].locationOptions

          let applicationMethodOptions = []
          if (org === 'guidedcompass') {
            applicationMethodOptions = [
              '',
              'Only receive candidates who are referred via Guided Compass',
              'Applicants may be referred, but they must also apply via email',
              'Applicants may be referred, but they must also apply via our website posting'
            ]
          }

          const workTypeOptions = response.data.workOptions[0].workTypeOptions
          const hourlyPayOptions = response.data.workOptions[0].hourlyPayOptions
          const annualPayOptions = response.data.workOptions[0].annualPayOptions
          const supplementalPayOptions = response.data.workOptions[0].supplementalPayOptions
          const benefitOptions = response.data.workOptions[0].benefitOptions

          let targetRoleOptions = []
          if (response.data.workOptions[0].targetRoleOptions) {
            targetRoleOptions = response.data.workOptions[0].targetRoleOptions
          }

          let rsvpConfirmationSubjectLine = "Thank You for Registering for {{ Posting Title }}, {{ First Name }}!"
          if (selectedOpportunity && selectedOpportunity.rsvpConfirmationSubjectLine) {
            rsvpConfirmationSubjectLine = selectedOpportunity.rsvpConfirmationSubjectLine
          }

          let rsvpConfirmationMessage = "Hi {{ First Name }},\n\nThank you for rsvping to {{ Posting Title }} on {{ Start Date }} hosted by {{ Org Name }}! We hope that you can join us then at:\n\n{{ Location }}\n\nWe look forward to seeing you!\n\nBest,\n{{ Org Name }}"
          if (selectedOpportunity && selectedOpportunity.rsvpConfirmationMessage) {
            rsvpConfirmationMessage = selectedOpportunity.rsvpConfirmationMessage
          }

          let projectConfirmationSubjectLine = "Thank You for Submitting Your Project to {{ Posting Title }}, {{ First Name }}!"
          if (selectedOpportunity && selectedOpportunity.projectConfirmationSubjectLine) {
            projectConfirmationSubjectLine = selectedOpportunity.projectConfirmationSubjectLine
          }

          let projectConfirmationMessage = "Hi {{ First Name }},\n\nThank you for submitting your project to {{ Posting Title }}!\n\nBest,\n{{ Org Name }}"
          if (selectedOpportunity && selectedOpportunity.projectConfirmationMessage) {
            projectConfirmationMessage = selectedOpportunity.projectConfirmationMessage
          }

          let applicationConfirmationSubjectLine = "{{ Posting Title }} Application Successfully Submitted, {{ First Name }}!"
          if (selectedOpportunity && selectedOpportunity.applicationConfirmationSubjectLine) {
            applicationConfirmationSubjectLine = selectedOpportunity.applicationConfirmationSubjectLine
          }

          let applicationConfirmationMessage = 'Hi {{ First Name }},\n\nYour application for the {{ Posting Title }} position with {{ Employer Name }} was successfully submitted.\n\nGot questions? Email {{ Org Contact Email }}.\n\nBest,\n{{ Org Name }}'
          if (selectedOpportunity && selectedOpportunity.applicationConfirmationMessage) {
            applicationConfirmationMessage = selectedOpportunity.applicationConfirmationMessage
          }

          const stageOptions = response.data.workOptions[0].postingStageOptions
          // rsvpConfirmationMessage = 'df/n\nsdf'
          // console.log('rsvpConfirmationMessage? ', rsvpConfirmationMessage)
          let typeTagOptions = response.data.workOptions[0].typeTagOptions.slice(1,response.data.workOptions[0].typeTagOptions.length)
          let adversityTagOptions = response.data.workOptions[0].adversityTagOptions.slice(1,response.data.workOptions[0].adversityTagOptions.length)
          let educationTagOptions = response.data.workOptions[0].educationTagOptions.slice(1,response.data.workOptions[0].educationTagOptions.length)
          let yearsOfExperienceOptions = [''].concat(response.data.workOptions[0].yearsOfExperienceOptions)

          this.setState({
            functionOptions ,
            industryOptions: response.data.workOptions[0].industryOptions,
            hoursPerWeekOptions,
            hourlyPayRangeOptions: response.data.workOptions[0].hourlyPayOptions,
            annualPayRangeOptions: response.data.workOptions[0].annualPayOptions,
            countOptions: response.data.workOptions[0].employeeCountOptions,
            growthOptions: response.data.workOptions[0].employeeGrowthOptions,
            hireCountOptions: response.data.workOptions[0].projectedInternOptions,
            employerSizeOptions: response.data.workOptions[0].employeeCountOptions,
            employerTypeOptions: response.data.workOptions[0].employerTypeOptions,
            durationOptions, politicalPartyOptions, workTypeOptions, subPostTypeOptions, locationOptions,
            applicationMethodOptions,
            hourlyPayOptions, annualPayOptions, supplementalPayOptions, benefitOptions, targetRoleOptions,
            stageOptions,
            rsvpConfirmationSubjectLine, rsvpConfirmationMessage,
            projectConfirmationSubjectLine, projectConfirmationMessage,
            applicationConfirmationSubjectLine, applicationConfirmationMessage,
            typeTagOptions, adversityTagOptions, educationTagOptions, yearsOfExperienceOptions
          })

          Axios.get('/api/org', { params: { orgCode: org } })
          .then((response) => {
            console.log('Org info query attempted one', response.data);

            if (response.data.success) {
              console.log('org info query worked', path)

              const orgName = response.data.orgInfo.orgName
              const orgURL = response.data.orgInfo.orgURL
              const orgMission = response.data.orgInfo.orgMission

              let orgContactFirstName = response.data.orgInfo.contactFirstName
              let orgContactLastName = response.data.orgInfo.contactLastName
              let orgContactEmail = response.data.orgInfo.contactEmail
              if (selectedOpportunity) {
                orgContactFirstName = selectedOpportunity.orgContactFirstName
                orgContactLastName = selectedOpportunity.orgContactLastName
                orgContactEmail = selectedOpportunity.orgContactEmail
              }

              let pictureURL = null
              if (path.includes('/organizations') && response.data.orgInfo.webLogoURI && org === 'c2c') {
                pictureURL = response.data.orgInfo.webLogoURI
              } else if (path.includes('/organizations') && response.data.orgInfo.webLogoURIColor) {
                pictureURL = response.data.orgInfo.webLogoURIColor
              }

              const placementPartners = response.data.orgInfo.placementPartners

              const webLogoURIColor = response.data.orgInfo.webLogoURIColor

              const automaticallyAttach = response.data.orgInfo.automaticallyAttach
              const parentPostId = response.data.orgInfo.parentPostId

              if (org && org !== '' && org !== 'guidedcompass') {
                applicationMethodOptions = [
                  '',
                  'Only receive candidates who are referred via Guided Compass',
                  'Applicants may be referred, but they must also apply via email',
                  'Applicants may be referred, but they must also apply via our website posting'
                ]
              }

              const assigneeGroupOptions = ['All of ' + orgName]

              const customVirtualTerm = response.data.orgInfo.customVirtualTerm

              let preSetStartDate = undefined
              if (response.data.orgInfo.preSetStartDate) {
                preSetStartDate = convertDateToString(response.data.orgInfo.preSetStartDate,"first10")
              }

              let preSetEndDate = undefined
              if (response.data.orgInfo.preSetEndDate) {
                preSetEndDate = convertDateToString(response.data.orgInfo.preSetEndDate,"first10")
              }

              let preSetSubmissionDeadline = undefined
              if (response.data.orgInfo.preSetSubmissionDeadline) {
                preSetSubmissionDeadline = convertDateToString(response.data.orgInfo.preSetSubmissionDeadline,"first10")
              }

              // let hoursPerWeekOptions = ['']
              if (response.data.orgInfo.hoursPerWeekOptions) {
                hoursPerWeekOptions = ['']
                hoursPerWeekOptions = hoursPerWeekOptions.concat(response.data.orgInfo.hoursPerWeekOptions)
              }
              // console.log('show hpwo: ', hoursPerWeekOptions)

              let referrerOptions = [{ contactFirstName: '' }]
              let referrerTracker = []
              if (response.data.orgInfo.pathways && response.data.orgInfo.pathways.length > 0) {
                for (let i = 1; i <= response.data.orgInfo.pathways.length; i++) {
                  if (response.data.orgInfo.pathways[i - 1].contactFirstName && response.data.orgInfo.pathways[i - 1].contactLastName) {
                    if (!referrerTracker.includes(response.data.orgInfo.pathways[i - 1].contactEmail)) {
                      referrerTracker.push(response.data.orgInfo.pathways[i - 1].contactEmail)
                      referrerOptions.push(response.data.orgInfo.pathways[i - 1])
                    }
                  }
                }
                referrerOptions.push({ contactFirstName: 'Other', contactLastName: '' })
              }

              const infoCollected = ['Resume','Basic Info','Interests','Personality','Values','Skills','Knowledge','Work Style','Diversity Info','Awards']

              // let typeTagOptions = response.data.orgInfo.typeTagOptions
              // let adversityTagOptions = response.data.orgInfo.adversityTagOptions
              // let educationTagOptions = response.data.orgInfo.educationTagOptions

              this.setState({ orgName, orgURL, orgMission, orgContactFirstName, orgContactLastName, orgContactEmail, pictureURL,
                placementPartners,
                webLogoURIColor, automaticallyAttach, parentPostId, parentId: parentPostId,
                applicationMethodOptions, assigneeGroupOptions,
                customVirtualTerm, preSetStartDate, preSetEndDate, preSetSubmissionDeadline, hoursPerWeekOptions, referrerOptions,
                infoCollected
              });

            } else {
              console.log('org info query did not work', response.data.message)
            }

          }).catch((error) => {
              console.log('Org info query did not work for some reason', error);
          });

          const accountCode = this.props.accountCode
          console.log('show accountCode: ', accountCode)

          Axios.get('/api/benchmarks', { params: { orgCodes: [org, 'general'], accountCode } })
          .then((response) => {
            console.log('Benchmarks query attempted no 1', response.data);

            if (response.data.success) {
              console.log('benchmark query worked no 1')

              if (response.data.benchmarks.length !== 0) {
                console.log('show fo1: ', functionOptions)
                let benchmarkOptions = response.data.benchmarks
                functionOptions = ['']
                for (let i = 1; i <= benchmarkOptions.length; i++) {
                  if (benchmarkOptions[i - 1].jobFunction && !functionOptions.includes(benchmarkOptions[i - 1].jobFunction)) {
                    functionOptions.push(benchmarkOptions[i - 1].jobFunction)
                  }
                }
                console.log('show fo2: ', functionOptions)
                benchmarkOptions.unshift({ title: 'No Benchmark Added'})
                functionOptions.sort()

                if (org === 'dpscd' && !functionOptions.includes('Other')) {
                  functionOptions.push('Other')
                }

                // this.setState({ benchmarkOptions, functionOptions });
                this.setState({ benchmarkOptions });
              }

            } else {
              console.log('benchmark query did not work', response.data.message)
            }

          }).catch((error) => {
              console.log('Benchmark query did not work for some reason', error);
          });
        }
      });

      if (selectedOpportunity && selectedOpportunity.customAssessmentId) {
        // pull custom assessment
        Axios.get('/api/customassessments/byid', { params: { _id: selectedOpportunity.customAssessmentId } })
        .then((response) => {
          console.log('Custom assessment query attempted in subeditposting', response.data);

          if (response.data.success) {
            console.log('custom assessment query worked')

            if (response.data.assessment && response.data.assessment.questions) {
              const customAssessment = response.data.assessment
              const screeningQuestions = customAssessment.questions
              this.setState({ customAssessment, screeningQuestions })
            }

          } else {
            console.log('custom assessment query did not work', response.data.message)
          }

        }).catch((error) => {
          console.log('Posted jobs query did not work for some reason', error);
        });
      }

      if (window.location.pathname.includes('/organizations') || window.location.pathname.includes('/advisor')) {
        const orgCodes = [org]
        Axios.get('/api/customassessments', { params: { orgCodes } })
        .then((response) => {
          console.log('Custom assessment query attempted', response.data);

          if (response.data.success) {
            console.log('custom assessment query worked')

            if (response.data.assessments.length !== 0) {

              let customAssessments = response.data.assessments
              customAssessments.unshift({ title: 'No Assessment Selected'})
              // console.log('gimme customAssessments again', customAssessments)
              let customAssessment = { title: 'No Assessment Selected'}
              let adversityListOptions = null
              for (let i = 1; i <= response.data.assessments.length; i++) {
                console.log(i);

                if (selectedOpportunity) {
                  if (response.data.assessments[i - 1]._id === selectedOpportunity.customAssessmentId) {
                    customAssessment = { title: response.data.assessments[i - 1].title}
                  }
                }

                if (response.data.assessments[i - 1].questions && response.data.assessments[i - 1].questions.length > 0) {
                  for (let j = 1; j <= response.data.assessments[i - 1].questions.length; j++) {
                    if (response.data.assessments[i - 1].questions[j - 1].shortname === 'adversityList') {
                      adversityListOptions = response.data.assessments[i - 1].questions[j - 1].answerChoices
                    }
                  }
                }
              }


              this.setState({ customAssessment, customAssessmentOptions: customAssessments, adversityListOptions });
            }

          } else {
            console.log('custom assessment query did not work', response.data.message)
          }

        }).catch((error) => {
          console.log('Posted jobs query did not work for some reason', error);
        });

        Axios.get('/api/org', { params: { orgCode: org } })
        .then((response) => {
          console.log('Org info query attempted two oh', response.data);

          if (response.data.success) {
            console.log('org info query worked')

            let pathwayOptions = []
            if (response.data.orgInfo.pathways) {
              pathwayOptions = response.data.orgInfo.pathways
            }

            let departmentOptions = []
            if (response.data.orgInfo.departments) {
              departmentOptions = response.data.orgInfo.departments
            }

            this.setState({
                orgName: response.data.orgInfo.orgName,
                orgURL: response.data.orgInfo.orgURL,
                orgMission: response.data.orgInfo.orgMission,
                orgContactEmail: response.data.orgInfo.contactEmail,
                pathwayOptions, departmentOptions
            });

            Axios.get('/api/pathways', { params: { orgCode: org } })
            .then((response) => {
              console.log('Pathways query attempted two oh', response.data);

              if (response.data.success && response.data.pathways && response.data.pathways.length > 0) {
                console.log('pathways query worked')

                pathwayOptions = response.data.pathways
                this.setState({ pathwayOptions});

              } else {
                console.log('org info query did not work', response.data.message)
              }

            }).catch((error) => {
                console.log('Org info query did not work for some reason', error);
            });

          } else {
            console.log('org info query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });

      }

      Axios.get('/api/users/profile/details', { params: { email } })
      .then((response) => {
        console.log('Profile query attempted', response.data);

        if (response.data.success) {
          console.log('profile query worked')

          if (!selectedOpportunity) {
            //profile
            const jobTitle = response.data.user.jobTitle

            let pictureURL = this.state.pictureURL
            if (path.includes('/add') || path.includes('/advisor')) {
              pictureURL = response.data.user.pictureURL
            }
            this.setState({ jobTitle, pictureURL })
          }

        } else {
          console.log('profile query did not work', response.data.message)
        }

      }).catch((error) => {
        console.log('Profile query did not work for some reason', error);
      });
    }

    pullOppData(org, selectedOpportunity, editMode, jobs, roleName, path, email, cuFirstName, cuLastName, username, placementPartners, orgFocus, appComponents, rsvpComponents) {
      console.log('pullOppData called')

      let readOnly = false
      if (selectedOpportunity.placementPartners && selectedOpportunity.placementPartners.includes(org)) {
        readOnly = true
      }

      this.setState({ selectedOpportunity, readOnly, placementPartners: selectedOpportunity.placementPartners })

      Axios.get('/api/org', { params: { orgCode: selectedOpportunity.orgCode } })
      .then((response) => {
        console.log('Posting org info query attempted', response.data);

          if (response.data.success) {
            console.log('org info query worked')

            const postingOrgCode = response.data.orgInfo.orgCode
            const postingOrgName = response.data.orgInfo.orgName
            const postingOrgContactEmail = response.data.orgInfo.contactEmail

            this.setState({ postingOrgCode, postingOrgName, postingOrgContactEmail });

          } else {
            console.log('posting org info query did not work', response.data.message)
          }

      }).catch((error) => {
          console.log('Posting org info query did not work for some reason', error);
      });

      if (selectedOpportunity.postType === 'Internship' || selectedOpportunity.postType === 'Individual' || selectedOpportunity.postType === 'Work') {
        console.log('in internship')

        let subPostType = selectedOpportunity.subPostType
        if (selectedOpportunity.postType === 'Individual') {
          subPostType = selectedOpportunity.postType
        }
        const workflowType = selectedOpportunity.workflowType

        let includeCoverLetter = false
        let includeInterests = true
        let includeSkills = true
        let includeTraits = true
        let includeEndorsements = true
        let includeCustomAssessment = false

        let applicationComponents = selectedOpportunity.applicationComponents

        // if (applicationComponents.includes('coverLetter')) {
        //   includeCoverLetter = true
        // }

        if (!applicationComponents.includes('interests')) {
          includeInterests = false
        }

        if (!applicationComponents.includes('skills')) {
          includeSkills = false
        }

        if (!applicationComponents.includes('personality')) {
          includeTraits = false
        }

        if (!applicationComponents.includes('endorsements')) {
          includeEndorsements = false
        }

        // if (applicationComponents.includes('customAssessment')) {
        //   includeCustomAssessment = true
        // }

        if (selectedOpportunity.appComponents && selectedOpportunity.appComponents.length > 0) {
          appComponents = selectedOpportunity.appComponents

          for (let i = 1; i <= selectedOpportunity.appComponents.length; i++) {
            if (selectedOpportunity.appComponents[i - 1].name === 'Basic Profile Info' && selectedOpportunity.appComponents[i - 1].include) {

            } else if (selectedOpportunity.appComponents[i - 1].name === 'Profile Details' && selectedOpportunity.appComponents[i - 1].include) {
            } else if (selectedOpportunity.appComponents[i - 1].name === 'Resume' && selectedOpportunity.appComponents[i - 1].include) {
            } else if (selectedOpportunity.appComponents[i - 1].name === 'Cover Letter' && selectedOpportunity.appComponents[i - 1].include) {
              includeCoverLetter = true
            } else if (selectedOpportunity.appComponents[i - 1].name === 'Academic Transcript' && selectedOpportunity.appComponents[i - 1].include) {
            } else if (selectedOpportunity.appComponents[i - 1].name === 'Letter of Recommendation' && selectedOpportunity.appComponents[i - 1].include) {
            } else if (selectedOpportunity.appComponents[i - 1].name === 'Identification' && selectedOpportunity.appComponents[i - 1].include) {
            } else if (selectedOpportunity.appComponents[i - 1].name === 'Career Assessments' && selectedOpportunity.appComponents[i - 1].include) {
            } else if (selectedOpportunity.appComponents[i - 1].name === 'Endorsements' && selectedOpportunity.appComponents[i - 1].include) {
            } else if (selectedOpportunity.appComponents[i - 1].name === 'Vaccinations' && selectedOpportunity.appComponents[i - 1].include) {
            } else if (selectedOpportunity.appComponents[i - 1].name === 'Referral Information' && selectedOpportunity.appComponents[i - 1].include) {
            } else if (selectedOpportunity.appComponents[i - 1].name === 'Basic Profile Info' && selectedOpportunity.appComponents[i - 1].include) {
            } else if (selectedOpportunity.appComponents[i - 1].name === 'Custom Assessment' && selectedOpportunity.appComponents[i - 1].include) {
              includeCustomAssessment = true
            }
          }
        }

        let fieldArray = selectedOpportunity.field.split("|")
        let functionValue = fieldArray[0].trim()
        let industryValue = fieldArray[1].trim()
        let pathways = []
        if (selectedOpportunity.pathways) {
          pathways = selectedOpportunity.pathways
        }

        let departments = []
        if (selectedOpportunity.departments) {
          departments = selectedOpportunity.departments
        }

        let targetRoles = []
        if (selectedOpportunity.targetRoles) {
          targetRoles = selectedOpportunity.targetRoles
        }

        let selectedPrograms = []
        if (selectedOpportunity.selectedPrograms) {
          selectedPrograms = selectedOpportunity.selectedPrograms
        }

        let usePrimaryOrgContact = true
        if (selectedOpportunity.usePrimaryOrgContact) {
            usePrimaryOrgContact = selectedOpportunity.usePrimaryOrgContact
        }

        let employerTransactionNoti = true
        if (selectedOpportunity.employerTransactionNoti) {
          employerTransactionNoti = selectedOpportunity.employerTransactionNoti
        }

        this.setState({ animating: true })

        let orgCodes = [org, 'general']
        if (org === 'lausd') {
          orgCodes.push('bixel')
        }

        let showCompetencyTags = this.state.showCompetencyTags
        if (org === 'guidedcompass') {
          showCompetencyTags = true
        }

        if (window.location.pathname.includes('/employers') && showCompetencyTags) {
          // console.log('?!', selectedOpportunity.benchmarkId)

          if (selectedOpportunity && selectedOpportunity.benchmarkId) {
            Axios.get('/api/benchmarks/byid', { params: { _id: selectedOpportunity.benchmarkId } })
            .then((response) => {
              console.log('Benchmark query attempted in employer', response.data);

              if (response.data.success) {
                console.log('benchmark query worked')

                const ogBenchmark = response.data.benchmark
                let competencyTags = []
                if (ogBenchmark.skills) {
                  for (let i = 1; i <= ogBenchmark.skills.length; i++) {
                    console.log('show value: ', ogBenchmark.skills[i - 1])
                    if (ogBenchmark.skills[i - 1]) {
                      competencyTags.push({
                        name: ogBenchmark.skills[i - 1].title,
                        category: ogBenchmark.skills[i - 1].skillType,
                        description: ogBenchmark.skills[i - 1].description,
                        score: ogBenchmark.skills[i - 1].score,
                        weight: ogBenchmark.skills[i - 1].weight
                      })
                    }
                  }
                }
                if (ogBenchmark.abilities) {
                  for (let i = 1; i <= ogBenchmark.abilities.length; i++) {
                    console.log('show abilities value: ', ogBenchmark.abilities[i - 1])
                    if (ogBenchmark.abilities[i - 1]) {
                      competencyTags.push({
                        name: ogBenchmark.abilities[i - 1].title,
                        category: ogBenchmark.abilities[i - 1].category,
                        description: ogBenchmark.abilities[i - 1].description,
                        score: ogBenchmark.abilities[i - 1].score,
                        weight: ogBenchmark.abilities[i - 1].weight
                      })
                    }
                  }
                }
                if (ogBenchmark.knowledge) {
                  for (let i = 1; i <= ogBenchmark.knowledge.length; i++) {
                    console.log('show knowledge value: ', ogBenchmark.knowledge[i - 1])
                    if (ogBenchmark.knowledge[i - 1]) {
                      competencyTags.push({
                        name: ogBenchmark.knowledge[i - 1].title,
                        category: ogBenchmark.knowledge[i - 1].category,
                        description: ogBenchmark.knowledge[i - 1].description,
                        score: ogBenchmark.knowledge[i - 1].score,
                        weight: ogBenchmark.knowledge[i - 1].weight
                      })
                    }
                  }
                }

                this.setState({ ogBenchmark, competencyTags })

                if (org === 'guidedcompass') {
                  Axios.get('/api/applications/bypost', { params: { postingId: selectedOpportunity._id } })
                  .then((response) => {
                    console.log('Applications query attempted', response.data);

                    if (response.data.success) {
                      console.log('applications query worked')

                      if (response.data.applications.length !== 0) {

                        const applications = response.data.applications
                        this.setState({ applications, selectedOpportunity, org, animating: false })
                        this.calculateMatches(response.data.applications, selectedOpportunity, ogBenchmark, null, org)

                      }

                    } else {
                      console.log('applications query did not work', response.data.message)
                      this.setState({ animating: false });
                    }
                  }).catch((error) => {
                      console.log('Application query did not work for some reason', error);

                      this.setState({ animating: false });
                  });
                }

              } else {
                console.log('benchmark query did not work', response.data.message)
              }

            }).catch((error) => {
                console.log('Benchmark query did not work for some reason', error);
                this.setState({ animating: false, serverPostSuccess: false, serverErrorMessage: error })
            });
          } else {
            this.setState({ animating: false, serverPostSuccess: false, serverErrorMessage: "Please attach a benchmark or change the function to view applicants", applicationErrorMessage: "Please attach a benchmark or change the function to view applicants" })
          }
        } else {

          if (selectedOpportunity.placementPartners && selectedOpportunity.placementPartners.includes(org)) {
            console.log("this org doesn't own this posting")

            Axios.get('/api/benchmarks/byid', { params: { _id: selectedOpportunity.benchmarkId } })
            .then((response) => {
              console.log('Benchmark query by id attempted', response.data);

              if (response.data.success) {
                console.log('benchmark query worked')

                let benchmark = response.data.benchmark
                const ogBenchmark = benchmark
                const benchmarkOptions = []
                // console.log('benchmark: ', benchmark.title)
                this.setState({ benchmarkOptions, benchmark, ogBenchmark })

                Axios.get('/api/applications/bypost', { params: { postingId: selectedOpportunity._id } })
                .then((response) => {
                  console.log('Applications query attempted', response.data);

                  if (response.data.success) {
                    console.log('applications query worked')

                    if (response.data.applications.length !== 0) {

                      const applications = response.data.applications
                      this.setState({ applications, selectedOpportunity, benchmark, benchmarkOptions, org, animating: false })
                      this.calculateMatches(response.data.applications, selectedOpportunity, benchmark, benchmarkOptions, org, 'test')

                    }

                  } else {
                    console.log('applications query did not work', response.data.message)
                    this.setState({ benchmark, benchmarkOptions, animating: false });
                  }
                }).catch((error) => {
                    console.log('Application query did not work for some reason', error);

                    this.setState({ benchmark, benchmarkOptions, animating: false });
                });

              } else {
                console.log('benchmark query did not work', response.data.message)
                // let benchmark = { title: 'No Benchmark Added'}
                // let benchmark = { title: 'GC: Other Benchmark', _id: '6084f5fdeeeee99c010eaf08' }
                let benchmark = this.state.benchmark
                this.setState({ benchmark, animating: false, serverPostSuccess: false, serverErrorMessage: response.data.message })
              }

            }).catch((error) => {
                console.log('Benchmark query did not work for some reason', error);
                this.setState({ animating: false, serverPostSuccess: false, serverErrorMessage: error })
            });

          } else {

            Axios.get('/api/benchmarks', { params: { orgCodes } })
            .then((response) => {
              console.log('Benchmarks query attempted', response.data);

              if (response.data.success) {
                console.log('benchmark query worked')

                if (response.data.benchmarks.length !== 0) {

                  let benchmarkOptions = response.data.benchmarks
                  benchmarkOptions.unshift({ title: 'No Benchmark Added'})

                  // let benchmark = { title: 'No Benchmark Added' }
                  // let benchmark = { title: 'GC: Other Benchmark', _id: '6084f5fdeeeee99c010eaf08' }
                  let benchmark = this.state.benchmark

                  for (let i = 1; i <= response.data.benchmarks.length; i++) {
                    console.log(i);

                    if (response.data.benchmarks[i - 1]._id === selectedOpportunity.benchmarkId) {

                      benchmark = response.data.benchmarks[i - 1]

                    }
                  }

                  const ogBenchmark = benchmark
                  if (benchmark.thirdPartyAssessmentWeight && benchmark.thirdPartyAssessments && benchmark.thirdPartyAssessments.length > 0) {
                    if (appComponents) {
                      let alreadyIncluded = false
                      for (let i = 1; i <= appComponents.length; i++) {
                        if (appComponents[i - 1].name === 'Third Party Assessments') {
                          alreadyIncluded = true
                        }
                      }
                      if (!alreadyIncluded) {
                        const thirdPartyAssessmentComponent = { name: 'Third Party Assessments', include: false, required: true, disabled: false }
                        appComponents.splice(8,0,thirdPartyAssessmentComponent)
                      }
                    }
                  }
                  if (benchmark.dealBreakers && benchmark.dealBreakers.length > 0) {
                    if (appComponents) {
                      let alreadyIncluded = false
                      for (let i = 1; i <= appComponents.length; i++) {
                        if (appComponents[i - 1].name === 'Screening Questions (Deal Breakers)') {
                          alreadyIncluded = true
                        }
                      }
                      if (!alreadyIncluded) {
                        const dealBreakersComponent = { name: 'Screening Questions (Deal Breakers)', include: false, required: true, disabled: false }
                        appComponents.splice(10,0,dealBreakersComponent)
                      }
                    }
                  }

                  this.setState({ benchmarkOptions, benchmark, ogBenchmark, appComponents })

                  Axios.get('/api/applications/bypost', { params: { postingId: selectedOpportunity._id } })
                  .then((response) => {
                    console.log('Applications query attempted', response.data);

                    if (response.data.success) {
                      console.log('applications query worked')

                      if (response.data.applications.length !== 0) {

                        const applications = response.data.applications
                        this.setState({ applications, selectedOpportunity, benchmark, benchmarkOptions, org, animating: false })
                        this.calculateMatches(response.data.applications, selectedOpportunity, benchmark, benchmarkOptions, org)

                      }

                    } else {
                      console.log('applications query did not work', response.data.message)
                      this.setState({ benchmark, benchmarkOptions, animating: false });
                    }
                  }).catch((error) => {
                      console.log('Application query did not work for some reason', error);

                      this.setState({ benchmark, benchmarkOptions, animating: false });
                  });
                }

              } else {
                console.log('benchmark query did not work', response.data.message)
                // let benchmark = { title: 'No Benchmark Added'}
                // let benchmark = { title: 'GC: Other Benchmark', _id: '6084f5fdeeeee99c010eaf08' }
                let benchmark = this.state.benchmark

                this.setState({ benchmark, animating: false, serverPostSuccess: false, serverErrorMessage: response.data.message })
              }

            }).catch((error) => {
                console.log('Benchmark query did not work for some reason', error);
                this.setState({ animating: false, serverPostSuccess: false, serverErrorMessage: error })
            });
          }
        }

        let submissionDeadline = ''
        if (selectedOpportunity.submissionDeadline) {
          if (this.state.useNewSubmissionDeadline) {
            // console.log('show the sd 1: ', selectedOpportunity.submissionDeadline)
            // submissionDeadline = convertDateToString(selectedOpportunity.submissionDeadline,"first16")
            submissionDeadline = convertDateToString(new Date(selectedOpportunity.submissionDeadline), "rawDateTimeForInput")
            // console.log('show the sd: ', submissionDeadline, selectedOpportunity.submissionDeadline, new Date(selectedOpportunity.submissionDeadline), new Date(selectedOpportunity.submissionDeadline).toString())
          } else {
            submissionDeadline = convertDateToString(selectedOpportunity.submissionDeadline,"first10")
          }
        }

        let startDate = ''
        if (selectedOpportunity.startDate) {
          startDate = convertDateToString(selectedOpportunity.startDate,"first10")
        }

        let endDate = ''
        if (selectedOpportunity.endDate) {
          endDate = convertDateToString(selectedOpportunity.endDate,"first10")
        }

        let isFilled = false
        if (selectedOpportunity.placed) {
          isFilled = true
        }

        let includeInNotifications = selectedOpportunity.includeInNotifications
        if (!selectedOpportunity.includeInNotifications) {
          includeInNotifications = false
        }
        const typeTags = selectedOpportunity.typeTags
        const educationTags = selectedOpportunity.educationTags
        const adversityTags = selectedOpportunity.adversityTags
        const yearsOfExperience = selectedOpportunity.yearsOfExperience
        const allowReferrals = selectedOpportunity.allowReferrals
        const hideIdealCandidateProfile = selectedOpportunity.hideIdealCandidateProfile
        const hideViewMatch = selectedOpportunity.hideViewMatch

        const imageTitleURL = selectedOpportunity.imageTitleURL
        const useImageTitle = selectedOpportunity.useImageTitle
        const hideTextTitle = selectedOpportunity.hideTextTitle

        this.setState({
            emailId: email, username, cuFirstName, cuLastName,
            org, orgFocus,

            selectedOpportunity, subPostType, workflowType,

            _id: selectedOpportunity._id,
            postType: selectedOpportunity.postType,
            title: selectedOpportunity.title,
            location: selectedOpportunity.location,
            type: selectedOpportunity.type,
            function: functionValue, pathways, departments, targetRoles, selectedPrograms,
            payType: selectedOpportunity.payType,
            payRange: selectedOpportunity.payRange,
            pay: selectedOpportunity.pay,

            hoursPerWeek: selectedOpportunity.hoursPerWeek,
            hireCount: selectedOpportunity.hireCount,
            isPerpetual: selectedOpportunity.isPerpetual,
            startDate, endDate,

            orgTransactionNoti: selectedOpportunity.orgTransactionNoti,
            usePrimaryOrgContact, employerTransactionNoti,
            employerContactFirstName: selectedOpportunity.employerContactFirstName,
            employerContactLastName: selectedOpportunity.employerContactLastName,
            employerContactEmail: selectedOpportunity.employerContactEmail,
            employerContactPhone: selectedOpportunity.employerContactPhone,
            accountCode: selectedOpportunity.accountCode,
            postingOrgCode: selectedOpportunity.postingOrgCode,

            description: selectedOpportunity.description,

            appComponents,
            includeBasicInfo: true,
            includeResume: true,
            includeCoverLetter, includeInterests,
            includeSkills, includeTraits,
            includeGoals: false,
            includeEndorsements, includeCustomAssessment,

            employerName: selectedOpportunity.employerName,
            employerURL: selectedOpportunity.employerURL,
            employerType: selectedOpportunity.employerType,
            industry: industryValue,
            employeeCount: selectedOpportunity.employerEmployees,
            employeeGrowth: selectedOpportunity.employerGrowth,
            isActive: selectedOpportunity.isActive,
            isUnlisted: selectedOpportunity.isUnlisted,
            featured: selectedOpportunity.featured,
            isSubsidized: selectedOpportunity.isSubsidized,
            submissionDeadline,
            politicalParty: selectedOpportunity.politicalParty,

            appRequirements: selectedOpportunity.appRequirements,
            applicationMethod: selectedOpportunity.applicationMethod,
            subjectLine: selectedOpportunity.subjectLine,
            jobLink: selectedOpportunity.jobLink,
            isFilled,
            referrerName: selectedOpportunity.referrerName,
            referralExplanation: selectedOpportunity.referralExplanation,
            supplementalPayArray: selectedOpportunity.supplementalPayArray,
            benefits: selectedOpportunity.benefits,
            zipcode: selectedOpportunity.zipcode,

            prioritizeSubsidized: selectedOpportunity.prioritizeSubsidized,
            prioritizeSchoolCredit: selectedOpportunity.prioritizeSchoolCredit,
            hostUndocumented: selectedOpportunity.hostUndocumented,
            customAssessmentId: selectedOpportunity.customAssessmentId,
            benchmarkId: selectedOpportunity.benchmarkId,
            candidatesOfInterest: selectedOpportunity.candidatesOfInterest,
            taggedPostings: selectedOpportunity.taggedPostings,
            includeInNotifications, typeTags, educationTags, adversityTags, yearsOfExperience,
            allowReferrals, imageTitleURL, useImageTitle, hideTextTitle, hideIdealCandidateProfile,
            hideViewMatch
        });
      } else if (selectedOpportunity.postType === 'Program') {

        console.log('in program')

        let subPostType = selectedOpportunity.subPostType
        if (selectedOpportunity.postType === 'Individual') {
          subPostType = selectedOpportunity.postType
        }
        const workflowType = selectedOpportunity.workflowType

        let includeCoverLetter = false
        let includeInterests = true
        let includeSkills = true
        let includeTraits = true
        let includeEndorsements = true
        let includeCustomAssessment = false

        let applicationComponents = selectedOpportunity.applicationComponents

        // if (applicationComponents.includes('coverLetter')) {
        //   includeCoverLetter = true
        // }

        if (!applicationComponents.includes('interests')) {
          includeInterests = false
        }

        if (!applicationComponents.includes('skills')) {
          includeSkills = false
        }

        if (!applicationComponents.includes('personality')) {
          includeTraits = false
        }

        if (!applicationComponents.includes('endorsements')) {
          includeEndorsements = false
        }

        // if (applicationComponents.includes('customAssessment')) {
        //   includeCustomAssessment = true
        // }

        if (selectedOpportunity.appComponents && selectedOpportunity.appComponents.length > 0) {
          appComponents = selectedOpportunity.appComponents

          for (let i = 1; i <= selectedOpportunity.appComponents.length; i++) {
            if (selectedOpportunity.appComponents[i - 1].name === 'Basic Profile Info' && selectedOpportunity.appComponents[i - 1].include) {

            } else if (selectedOpportunity.appComponents[i - 1].name === 'Profile Details' && selectedOpportunity.appComponents[i - 1].include) {
            } else if (selectedOpportunity.appComponents[i - 1].name === 'Resume' && selectedOpportunity.appComponents[i - 1].include) {
            } else if (selectedOpportunity.appComponents[i - 1].name === 'Cover Letter' && selectedOpportunity.appComponents[i - 1].include) {
              includeCoverLetter = true
            } else if (selectedOpportunity.appComponents[i - 1].name === 'Academic Transcript' && selectedOpportunity.appComponents[i - 1].include) {
            } else if (selectedOpportunity.appComponents[i - 1].name === 'Letter of Recommendation' && selectedOpportunity.appComponents[i - 1].include) {
            } else if (selectedOpportunity.appComponents[i - 1].name === 'Identification' && selectedOpportunity.appComponents[i - 1].include) {
            } else if (selectedOpportunity.appComponents[i - 1].name === 'Career Assessments' && selectedOpportunity.appComponents[i - 1].include) {
            } else if (selectedOpportunity.appComponents[i - 1].name === 'Endorsements' && selectedOpportunity.appComponents[i - 1].include) {
            } else if (selectedOpportunity.appComponents[i - 1].name === 'Vaccinations' && selectedOpportunity.appComponents[i - 1].include) {
            } else if (selectedOpportunity.appComponents[i - 1].name === 'Referral Information' && selectedOpportunity.appComponents[i - 1].include) {
            } else if (selectedOpportunity.appComponents[i - 1].name === 'Basic Profile Info' && selectedOpportunity.appComponents[i - 1].include) {
            } else if (selectedOpportunity.appComponents[i - 1].name === 'Custom Assessment' && selectedOpportunity.appComponents[i - 1].include) {
              includeCustomAssessment = true
            }
          }
        }

        let fieldArray = selectedOpportunity.field.split("|")
        let functionValue = fieldArray[0].trim()
        let industryValue = fieldArray[1].trim()
        let pathways = []
        if (selectedOpportunity.pathways) {
          pathways = selectedOpportunity.pathways
        }

        let departments = []
        if (selectedOpportunity.departments) {
          departments = selectedOpportunity.departments
        }

        let targetRoles = []
        if (selectedOpportunity.targetRoles) {
          targetRoles = selectedOpportunity.targetRoles
        }

        let selectedPrograms = []
        if (selectedOpportunity.selectedPrograms) {
          selectedPrograms = selectedOpportunity.selectedPrograms
        }

        let usePrimaryOrgContact = true
        if (selectedOpportunity.usePrimaryOrgContact) {
            usePrimaryOrgContact = selectedOpportunity.usePrimaryOrgContact
        }

        let employerTransactionNoti = true
        if (selectedOpportunity.employerTransactionNoti) {
          employerTransactionNoti = selectedOpportunity.employerTransactionNoti
        }

        this.setState({ animating: true })

        let orgCodes = [org, 'general']
        if (org === 'lausd') {
          orgCodes.push('bixel')
        }

        let showCompetencyTags = this.state.showCompetencyTags
        if (org === 'guidedcompass') {
          showCompetencyTags = true
        }

        if (window.location.pathname.includes('/employers') && showCompetencyTags) {
          // console.log('?!', selectedOpportunity.benchmarkId)

          if (selectedOpportunity && selectedOpportunity.benchmarkId) {
            Axios.get('/api/benchmarks/byid', { params: { _id: selectedOpportunity.benchmarkId } })
            .then((response) => {
              console.log('Benchmark query attempted in employer', response.data);

              if (response.data.success) {
                console.log('benchmark query worked')

                const ogBenchmark = response.data.benchmark
                let competencyTags = []
                if (ogBenchmark.skills) {
                  for (let i = 1; i <= ogBenchmark.skills.length; i++) {
                    console.log('show value: ', ogBenchmark.skills[i - 1])
                    if (ogBenchmark.skills[i - 1]) {
                      competencyTags.push({
                        name: ogBenchmark.skills[i - 1].title,
                        category: ogBenchmark.skills[i - 1].skillType,
                        description: ogBenchmark.skills[i - 1].description,
                        score: ogBenchmark.skills[i - 1].score,
                        weight: ogBenchmark.skills[i - 1].weight
                      })
                    }
                  }
                }
                if (ogBenchmark.abilities) {
                  for (let i = 1; i <= ogBenchmark.abilities.length; i++) {
                    console.log('show abilities value: ', ogBenchmark.abilities[i - 1])
                    if (ogBenchmark.abilities[i - 1]) {
                      competencyTags.push({
                        name: ogBenchmark.abilities[i - 1].title,
                        category: ogBenchmark.abilities[i - 1].category,
                        description: ogBenchmark.abilities[i - 1].description,
                        score: ogBenchmark.abilities[i - 1].score,
                        weight: ogBenchmark.abilities[i - 1].weight
                      })
                    }
                  }
                }
                if (ogBenchmark.knowledge) {
                  for (let i = 1; i <= ogBenchmark.knowledge.length; i++) {
                    console.log('show knowledge value: ', ogBenchmark.knowledge[i - 1])
                    if (ogBenchmark.knowledge[i - 1]) {
                      competencyTags.push({
                        name: ogBenchmark.knowledge[i - 1].title,
                        category: ogBenchmark.knowledge[i - 1].category,
                        description: ogBenchmark.knowledge[i - 1].description,
                        score: ogBenchmark.knowledge[i - 1].score,
                        weight: ogBenchmark.knowledge[i - 1].weight
                      })
                    }
                  }
                }

                this.setState({ ogBenchmark, competencyTags })

                if (org === 'guidedcompass') {
                  Axios.get('/api/applications/bypost', { params: { postingId: selectedOpportunity._id } })
                  .then((response) => {
                    console.log('Applications query attempted', response.data);

                    if (response.data.success) {
                      console.log('applications query worked')

                      if (response.data.applications.length !== 0) {

                        const applications = response.data.applications
                        this.setState({ applications, selectedOpportunity, org, animating: false })
                        this.calculateMatches(response.data.applications, selectedOpportunity, ogBenchmark, null, org)

                      }

                    } else {
                      console.log('applications query did not work', response.data.message)
                      this.setState({ animating: false });
                    }
                  }).catch((error) => {
                      console.log('Application query did not work for some reason', error);

                      this.setState({ animating: false });
                  });
                }

              } else {
                console.log('benchmark query did not work', response.data.message)
              }

            }).catch((error) => {
                console.log('Benchmark query did not work for some reason', error);
                this.setState({ animating: false, serverPostSuccess: false, serverErrorMessage: error })
            });
          } else {
            this.setState({ animating: false, serverPostSuccess: false, serverErrorMessage: "Please attach a benchmark or change the function to view applicants", applicationErrorMessage: "Please attach a benchmark or change the function to view applicants" })
          }
        } else {

          if (selectedOpportunity.placementPartners && selectedOpportunity.placementPartners.includes(org)) {
            console.log("this org doesn't own this posting")

            Axios.get('/api/benchmarks/byid', { params: { _id: selectedOpportunity.benchmarkId } })
            .then((response) => {
              console.log('Benchmark query by id attempted', response.data);

              if (response.data.success) {
                console.log('benchmark query worked')

                let benchmark = response.data.benchmark
                const ogBenchmark = benchmark
                const benchmarkOptions = []
                // console.log('benchmark: ', benchmark.title)
                this.setState({ benchmarkOptions, benchmark, ogBenchmark })

                Axios.get('/api/applications/bypost', { params: { postingId: selectedOpportunity._id } })
                .then((response) => {
                  console.log('Applications query attempted', response.data);

                  if (response.data.success) {
                    console.log('applications query worked')

                    if (response.data.applications.length !== 0) {

                      const applications = response.data.applications
                      this.setState({ applications, selectedOpportunity, benchmark, benchmarkOptions, org, animating: false })
                      this.calculateMatches(response.data.applications, selectedOpportunity, benchmark, benchmarkOptions, org, 'test')

                    }

                  } else {
                    console.log('applications query did not work', response.data.message)
                    this.setState({ benchmark, benchmarkOptions, animating: false });
                  }
                }).catch((error) => {
                    console.log('Application query did not work for some reason', error);

                    this.setState({ benchmark, benchmarkOptions, animating: false });
                });

              } else {
                console.log('benchmark query did not work', response.data.message)
                // let benchmark = { title: 'No Benchmark Added'}
                // let benchmark = { title: 'GC: Other Benchmark', _id: '6084f5fdeeeee99c010eaf08' }
                let benchmark = this.state.benchmark
                this.setState({ benchmark, animating: false, serverPostSuccess: false, serverErrorMessage: response.data.message })
              }

            }).catch((error) => {
                console.log('Benchmark query did not work for some reason', error);
                this.setState({ animating: false, serverPostSuccess: false, serverErrorMessage: error })
            });

          } else {

            Axios.get('/api/benchmarks', { params: { orgCodes } })
            .then((response) => {
              console.log('Benchmarks query attempted', response.data);

              if (response.data.success) {
                console.log('benchmark query worked')

                if (response.data.benchmarks.length !== 0) {

                  let benchmarkOptions = response.data.benchmarks
                  benchmarkOptions.unshift({ title: 'No Benchmark Added'})

                  // let benchmark = { title: 'No Benchmark Added' }
                  // let benchmark = { title: 'GC: Other Benchmark', _id: '6084f5fdeeeee99c010eaf08' }
                  let benchmark = this.state.benchmark

                  for (let i = 1; i <= response.data.benchmarks.length; i++) {
                    console.log(i);

                    if (response.data.benchmarks[i - 1]._id === selectedOpportunity.benchmarkId) {

                      benchmark = response.data.benchmarks[i - 1]

                    }
                  }

                  const ogBenchmark = benchmark
                  if (benchmark.thirdPartyAssessmentWeight && benchmark.thirdPartyAssessments && benchmark.thirdPartyAssessments.length > 0) {
                    if (appComponents) {
                      let alreadyIncluded = false
                      for (let i = 1; i <= appComponents.length; i++) {
                        if (appComponents[i - 1].name === 'Third Party Assessments') {
                          alreadyIncluded = true
                        }
                      }
                      if (!alreadyIncluded) {
                        const thirdPartyAssessmentComponent = { name: 'Third Party Assessments', include: false, required: true, disabled: false }
                        appComponents.splice(8,0,thirdPartyAssessmentComponent)
                      }
                    }
                  }
                  if (benchmark.dealBreakers && benchmark.dealBreakers.length > 0) {
                    if (appComponents) {
                      let alreadyIncluded = false
                      for (let i = 1; i <= appComponents.length; i++) {
                        if (appComponents[i - 1].name === 'Screening Questions (Deal Breakers)') {
                          alreadyIncluded = true
                        }
                      }
                      if (!alreadyIncluded) {
                        const dealBreakersComponent = { name: 'Screening Questions (Deal Breakers)', include: false, required: true, disabled: false }
                        appComponents.splice(10,0,dealBreakersComponent)
                      }
                    }
                  }

                  this.setState({ benchmarkOptions, benchmark, ogBenchmark, appComponents })

                  Axios.get('/api/applications/bypost', { params: { postingId: selectedOpportunity._id } })
                  .then((response) => {
                    console.log('Applications query attempted', response.data);

                    if (response.data.success) {
                      console.log('applications query worked')

                      if (response.data.applications.length !== 0) {

                        const applications = response.data.applications
                        this.setState({ applications, selectedOpportunity, benchmark, benchmarkOptions, org, animating: false })
                        this.calculateMatches(response.data.applications, selectedOpportunity, benchmark, benchmarkOptions, org)

                      }

                    } else {
                      console.log('applications query did not work', response.data.message)
                      this.setState({ benchmark, benchmarkOptions, animating: false });
                    }
                  }).catch((error) => {
                      console.log('Application query did not work for some reason', error);

                      this.setState({ benchmark, benchmarkOptions, animating: false });
                  });
                }

              } else {
                console.log('benchmark query did not work', response.data.message)
                // let benchmark = { title: 'No Benchmark Added'}
                // let benchmark = { title: 'GC: Other Benchmark', _id: '6084f5fdeeeee99c010eaf08' }
                let benchmark = this.state.benchmark

                this.setState({ benchmark, animating: false, serverPostSuccess: false, serverErrorMessage: response.data.message })
              }

            }).catch((error) => {
                console.log('Benchmark query did not work for some reason', error);
                this.setState({ animating: false, serverPostSuccess: false, serverErrorMessage: error })
            });
          }
        }

        let submissionDeadline = ''
        if (selectedOpportunity.submissionDeadline) {
          if (this.state.useNewSubmissionDeadline) {
            // console.log('show the sd 1: ', selectedOpportunity.submissionDeadline)
            // submissionDeadline = convertDateToString(selectedOpportunity.submissionDeadline,"first16")
            submissionDeadline = convertDateToString(new Date(selectedOpportunity.submissionDeadline), "rawDateTimeForInput")
            // console.log('show the sd: ', submissionDeadline, selectedOpportunity.submissionDeadline, new Date(selectedOpportunity.submissionDeadline), new Date(selectedOpportunity.submissionDeadline).toString())
          } else {
            submissionDeadline = convertDateToString(selectedOpportunity.submissionDeadline,"first10")
          }
        }

        let startDate = ''
        if (selectedOpportunity.startDate) {
          startDate = convertDateToString(selectedOpportunity.startDate,"first10")
        }

        let endDate = ''
        if (selectedOpportunity.endDate) {
          endDate = convertDateToString(selectedOpportunity.endDate,"first10")
        }

        let isFilled = false
        if (selectedOpportunity.placed) {
          isFilled = true
        }

        let includeInNotifications = selectedOpportunity.includeInNotifications
        if (!selectedOpportunity.includeInNotifications) {
          includeInNotifications = false
        }
        const typeTags = selectedOpportunity.typeTags
        const educationTags = selectedOpportunity.educationTags
        const adversityTags = selectedOpportunity.adversityTags
        const yearsOfExperience = selectedOpportunity.yearsOfExperience
        const allowReferrals = selectedOpportunity.allowReferrals
        const hideIdealCandidateProfile = selectedOpportunity.hideIdealCandidateProfile
        const hideViewMatch = selectedOpportunity.hideViewMatch

        const imageTitleURL = selectedOpportunity.imageTitleURL
        const useImageTitle = selectedOpportunity.useImageTitle
        const hideTextTitle = selectedOpportunity.hideTextTitle

        const selectedProgram = selectedOpportunity.selectedProgram

        this.setState({
            emailId: email, username, cuFirstName, cuLastName,
            org, orgFocus,

            selectedOpportunity, subPostType, workflowType,

            _id: selectedOpportunity._id,
            postType: selectedOpportunity.postType,
            title: selectedOpportunity.title,
            location: selectedOpportunity.location,
            type: selectedOpportunity.type,
            function: functionValue, pathways, departments, targetRoles, selectedPrograms,
            payType: selectedOpportunity.payType,
            payRange: selectedOpportunity.payRange,
            pay: selectedOpportunity.pay,

            hoursPerWeek: selectedOpportunity.hoursPerWeek,
            hireCount: selectedOpportunity.hireCount,
            isPerpetual: selectedOpportunity.isPerpetual,
            startDate, endDate,

            orgTransactionNoti: selectedOpportunity.orgTransactionNoti,
            usePrimaryOrgContact, employerTransactionNoti,
            employerContactFirstName: selectedOpportunity.employerContactFirstName,
            employerContactLastName: selectedOpportunity.employerContactLastName,
            employerContactEmail: selectedOpportunity.employerContactEmail,
            employerContactPhone: selectedOpportunity.employerContactPhone,
            accountCode: selectedOpportunity.accountCode,
            postingOrgCode: selectedOpportunity.postingOrgCode,

            description: selectedOpportunity.description,

            appComponents,
            includeBasicInfo: true,
            includeResume: true,
            includeCoverLetter, includeInterests,
            includeSkills, includeTraits,
            includeGoals: false,
            includeEndorsements, includeCustomAssessment,

            employerName: selectedOpportunity.employerName,
            employerURL: selectedOpportunity.employerURL,
            employerType: selectedOpportunity.employerType,
            industry: industryValue,
            employeeCount: selectedOpportunity.employerEmployees,
            employeeGrowth: selectedOpportunity.employerGrowth,
            isActive: selectedOpportunity.isActive,
            isUnlisted: selectedOpportunity.isUnlisted,
            featured: selectedOpportunity.featured,
            isSubsidized: selectedOpportunity.isSubsidized,
            submissionDeadline,
            politicalParty: selectedOpportunity.politicalParty,

            appRequirements: selectedOpportunity.appRequirements,
            applicationMethod: selectedOpportunity.applicationMethod,
            subjectLine: selectedOpportunity.subjectLine,
            jobLink: selectedOpportunity.jobLink,
            isFilled,
            referrerName: selectedOpportunity.referrerName,
            referralExplanation: selectedOpportunity.referralExplanation,
            supplementalPayArray: selectedOpportunity.supplementalPayArray,
            benefits: selectedOpportunity.benefits,
            zipcode: selectedOpportunity.zipcode,

            prioritizeSubsidized: selectedOpportunity.prioritizeSubsidized,
            prioritizeSchoolCredit: selectedOpportunity.prioritizeSchoolCredit,
            hostUndocumented: selectedOpportunity.hostUndocumented,
            customAssessmentId: selectedOpportunity.customAssessmentId,
            benchmarkId: selectedOpportunity.benchmarkId,
            candidatesOfInterest: selectedOpportunity.candidatesOfInterest,
            taggedPostings: selectedOpportunity.taggedPostings,
            includeInNotifications, typeTags, educationTags, adversityTags, yearsOfExperience,
            allowReferrals, imageTitleURL, useImageTitle, hideTextTitle, hideIdealCandidateProfile,
            hideViewMatch, selectedProgram
        });
      } else if (selectedOpportunity.postType === 'Project' || selectedOpportunity.postType === 'Assignment' || selectedOpportunity.postType === 'Problem' || selectedOpportunity.postType === 'Challenge') {
        console.log('in assignments')

        Axios.get('/api/comments', { params: { parentPostId: selectedOpportunity._id } })
        .then((response) => {
          console.log('Comments query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved comments')

             if (response.data.comments && response.data.comments.length > 0) {
               let comments = []
               let submissionComments = []
               for (let i = 1; i <= response.data.comments.length; i++) {

                 if (response.data.comments[i - 1].parentSubmissionId && response.data.comments[i - 1].parentSubmissionId !== '') {
                   submissionComments.push(response.data.comments[i - 1])
                 } else if (response.data.comments[i - 1].parentPostId && response.data.comments[i - 1].parentPostId !== '') {
                   comments.push(response.data.comments[i - 1])
                 }
               }

               console.log('test 2: ', comments, submissionComments)
              this.setState({ comments, submissionComments })
             }
           } else {
             console.log('no comments data found', response.data.message)
           }

        }).catch((error) => {
           console.log('Comments query did not work', error);
        });

        const sectionTemplateOptions = [
          { title: 'Custom', sectionTitles: [] },
          { title: 'Exploratory', sectionTitles: ['Major Pain Points','Problem Size','How I Discovered','Why I Posted','My Background','Employer Background'] },
          { title: 'Technical', sectionTitles: ['Technical Requirements','Required Skills'] },
        ]

        const problemTypeOptions = ['','Exploratory','Technical']

        const evaluationMethodOptions = ['','Judges Decide','Community Voting']
        const prizeOptions = ['','None','$10','$100','$1,000','$10,000']

        const startMonth = new Date().getMonth() + 2
        // const currentDay = new Date().getDate()
        const currentYear = new Date().getFullYear()
        const nextYear = currentYear + 1

        let dateOptions = ['']

        for (let i = 1; i <= 12; i++) {

          let givenMonth = startMonth + i - 1
          if (givenMonth > 12) {
            givenMonth = givenMonth - 12

            dateOptions.push(givenMonth + '/01/' + nextYear )
          } else {
            dateOptions.push(givenMonth + '/01/' + currentYear)
          }
        }

        const gradeLevelOptions = ['','6th - 8th','9th - 10th','11th - 12th','Freshman and Sophomore of College','Junior and Senior of College', 'All Grade Levels Are Welcome to Apply']
        const knowledgeLevelOptions = ['','Beginner','1-2 Years of Familiarity','3+ Years of Familiarity']

        const _id = selectedOpportunity._id
        const postType = selectedOpportunity.postType
        const projectPromptType = selectedOpportunity.projectPromptType

        const projectName = selectedOpportunity.name

        const problemType = selectedOpportunity.problemType
        let submissionDeadline = ''
        if (selectedOpportunity.submissionDeadline) {
          submissionDeadline = convertDateToString(new Date(selectedOpportunity.submissionDeadline), "rawDateTimeForInput")
        }

        let announcementDate = ''
        if (selectedOpportunity.announcementDate) {
          announcementDate = convertDateToString(new Date(selectedOpportunity.announcementDate), "rawDateTimeForInput")
        }

        const jobTitle = selectedOpportunity.contributorTitle
        const knowledgeLevel = selectedOpportunity.knowledgeLevel
        const duration = selectedOpportunity.duration
        const assigneeGroup = selectedOpportunity.assigneeGroup
        const assignees = selectedOpportunity.assignees

        const employerName = selectedOpportunity.employerName
        const employerURL = selectedOpportunity.employerURL
        const employerType = selectedOpportunity.employerType
        const employerSize = selectedOpportunity.employerSize
        const difficultyLevel = selectedOpportunity.difficultyLevel
        const industry = selectedOpportunity.industry
        const functions = selectedOpportunity.functions
        const tags = selectedOpportunity.tags
        const videoLink = selectedOpportunity.videoLink
        const summary = selectedOpportunity.summary
        const employerInfo = selectedOpportunity.employerInfo
        let isExternal = selectedOpportunity.isExternal
        if (isExternal) {
          isExternal = isExternal.toString()
        }

        const source = selectedOpportunity.source
        const guidelines = selectedOpportunity.guidelines
        const solution = selectedOpportunity.solution
        let resources = []
        if(selectedOpportunity.resources) {
          resources = selectedOpportunity.resources
        }
        const background = selectedOpportunity.background
        const description = selectedOpportunity.description

        const richDescriptionRaw = selectedOpportunity.richDescription
        const richDescription = { blocks: richDescriptionRaw, entityMap: {}}
        // const existingContent = {
        //   blocks: [{ data: {}, depth: 0, entityRanges: [], inlineStyleRanges: [], key: '18f7s', text: 'Test text', type: 'header-two' }],
        //   entityMap: {}
        // }
        console.log('richDescription: ', richDescription)

        const lessons  = selectedOpportunity.lessons
        const willEmployerLead = selectedOpportunity.willEmployerLead

        const sections = selectedOpportunity.sections
        const exhibits = selectedOpportunity.exhibits
        const pointValue = selectedOpportunity.pointValue
        const upvotes = selectedOpportunity.upvotes
        const downvotes = selectedOpportunity.downvotes
        const featured = selectedOpportunity.featured

        const contributorFirstName = selectedOpportunity.contributorFirstName
        const contributorLastName = selectedOpportunity.contributorLastName
        const contributorEmail = selectedOpportunity.contributorEmail
        const contributorTitle = selectedOpportunity.contributorTitle

        const accountCode = selectedOpportunity.accountCode

        const isActive = selectedOpportunity.isActive
        const isUnlisted = selectedOpportunity.isUnlisted
        const attachToCourses = selectedOpportunity.attachToCourses
        let selectedCourses = []
        if (selectedOpportunity.courses) {
          selectedCourses = selectedOpportunity.courses
        }

        const disableDownvoting = selectedOpportunity.disableDownvoting
        const status = selectedOpportunity.status

        const evaluationMethod = selectedOpportunity.evaluationMethod
        const prizes = selectedOpportunity.prizes
        const rules = selectedOpportunity.rules

        let startDate = ''
        if (selectedOpportunity.startDate) {
          startDate = convertDateToString(new Date(selectedOpportunity.startDate), "rawDateTimeForInput")
        }

        const includeVotingRound = selectedOpportunity.includeVotingRound
        const includeJudgingRound = selectedOpportunity.includeJudgingRound
        const announcementMethod = selectedOpportunity.announcementMethod
        const location = selectedOpportunity.location
        const voteRequirement = selectedOpportunity.voteRequirement

        let pathways = []
        if (selectedOpportunity.pathways) {
          pathways = selectedOpportunity.pathways
        }

        let departments = []
        if (selectedOpportunity.departments) {
          departments = selectedOpportunity.departments
        }

        let targetRoles = []
        if (selectedOpportunity.targetRoles) {
          targetRoles = selectedOpportunity.targetRoles
        }

        let selectedPrograms = []
        if (selectedOpportunity.selectedPrograms) {
          selectedPrograms = selectedOpportunity.selectedPrograms
        }

        let includeInNotifications = selectedOpportunity.includeInNotifications
        if (!selectedOpportunity.includeInNotifications) {
          includeInNotifications = false
        }
        const typeTags = selectedOpportunity.typeTags
        const educationTags = selectedOpportunity.educationTags
        const adversityTags = selectedOpportunity.adversityTags

        this.setState({ org,sectionTemplateOptions, problemTypeOptions, evaluationMethodOptions, prizeOptions,
          jobTitle, knowledgeLevel, tags, duration, assigneeGroup, assignees, problemType,
          dateOptions, gradeLevelOptions, knowledgeLevelOptions, _id, postType, projectPromptType,
          projectName,
          submissionDeadline, announcementDate, employerName,
          employerURL, employerType, employerSize, difficultyLevel, industry, functions, videoLink, summary,
          employerInfo, willEmployerLead, isExternal, source,
          guidelines, solution, resources, background, description, richDescription, lessons,
          sections, exhibits, pointValue, upvotes, downvotes, featured,
          contributorFirstName, contributorLastName, contributorEmail, contributorTitle,
          accountCode, disableDownvoting, status,
          evaluationMethod, prizes, rules, startDate,
          includeVotingRound, includeJudgingRound, announcementMethod, location, voteRequirement,
          isActive, isUnlisted, attachToCourses, selectedCourses,
          pathways, departments, targetRoles, selectedPrograms,
          includeInNotifications, typeTags, educationTags, adversityTags
        })

        if (selectedOpportunity.postType === 'Challenge' || selectedOpportunity.projectPromptType === 'Challenge') {
          let sortAlphabetically = true
          Axios.get('/api/rsvps/bypost', { params: { postingId: selectedOpportunity._id, sortAlphabetically } })
          .then((response) => {
            console.log('Rsvps query attempted', response.data);

            if (response.data.success) {
              console.log('applications query worked')

              const rsvps = response.data.rsvps
              this.setState({ rsvps })

            } else {
              console.log('applications query did not work', response.data.message)

            }
          }).catch((error) => {
              console.log('Application query did not work for some reason', error);
          });
        }

      } else if (selectedOpportunity.postType === 'Event') {

        console.log('in event')
        Axios.get('/api/comments', { params: { parentPostId: selectedOpportunity._id } })
        .then((response) => {
          console.log('Comments query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved comments')

             if (response.data.comments && response.data.comments.length > 0) {
              this.setState({ comments: response.data.comments })
             }
           } else {
             console.log('no comments data found', response.data.message)
           }

        }).catch((error) => {
           console.log('Comments query did not work', error);
        });

        const _id = selectedOpportunity._id
        const postType = selectedOpportunity.postType
        const eventType = selectedOpportunity.eventType
        const title = selectedOpportunity.title
        const summary = selectedOpportunity.summary
        let description = selectedOpportunity.description
        if (!selectedOpportunity.description && summary) {
          description = summary
        }

        let startDate = ''
        if (selectedOpportunity.startDate) {
          startDate = convertDateToString(new Date(selectedOpportunity.startDate), "rawDateTimeForInput")
        }

        let endDate = ''
        if (selectedOpportunity.endDate) {
          endDate = convertDateToString(new Date(selectedOpportunity.endDate), "rawDateTimeForInput")
        }

        const locationType = selectedOpportunity.locationType
        const location = selectedOpportunity.location
        let selectedAssessment = { title: 'Pick an RSVP Survey'}
        if (selectedOpportunity.selectedAssessments) {
          selectedAssessment = selectedOpportunity.selectedAssessments
        }
        const transportationDetails = selectedOpportunity.transportationDetails
        const links = selectedOpportunity.links

        const orgContactFirstName = selectedOpportunity.orgContactFirstName
        const orgContactLastName = selectedOpportunity.orgContactLastName
        const orgContactEmail = selectedOpportunity.orgContactEmail

        const locationTypeOptions = ['','My Organization / School', 'Employer', 'Remote']
        let rsvpLink = window.location.protocol + "//" + window.location.host + '/app/opportunities/' + _id
        // let careerSeekerRsvpLink = window.location.protocol + "//" + window.location.host + '/organizations/' + org + '/events/' + _id + '/student/rsvp'
        let careerSeekerRsvpLink = window.location.protocol + "//" + window.location.host + '/opportunities/organizations/' + org + '/' + _id + '/roles/career-seeker'
        let advisorRsvpLink = window.location.protocol + "//" + window.location.host + '/opportunities/organizations/' + org + '/' + _id + '/roles/volunteer'

        // if (selectedOpportunity.placementPartners && selectedOpportunity.placementPartners.includes(org)) {
        //   rsvpLink = window.location.protocol + "//" + window.location.host + '/schools/' + selectedOpportunity.orgCode + '/events/' + _id + '/rsvp'
        // }
        const featured = selectedOpportunity.featured
        const status = selectedOpportunity.status
        const willEmployerLead = selectedOpportunity.willEmployerLead

        const employerName = selectedOpportunity.employerName
        const employerContactFirstName = selectedOpportunity.employerContactFirstName
        const employerContactLastName = selectedOpportunity.employerContactLastName
        const employerContactEmail = selectedOpportunity.employerContactEmail

        let pathways = []
        if (selectedOpportunity.pathways) {
          pathways = selectedOpportunity.pathways
        }

        let departments = []
        if (selectedOpportunity.departments) {
          departments = selectedOpportunity.departments
        }

        let targetRoles = []
        if (selectedOpportunity.targetRoles) {
          targetRoles = selectedOpportunity.targetRoles
        }

        let selectedPrograms = []
        if (selectedOpportunity.selectedPrograms) {
          selectedPrograms = selectedOpportunity.selectedPrograms
        }

        let functions = []
        if (selectedOpportunity.functions) {
          functions = selectedOpportunity.functions
        }

        const industry = selectedOpportunity.industry

        if (selectedOpportunity.rsvpComponents && selectedOpportunity.rsvpComponents.length > 0) {
          rsvpComponents = selectedOpportunity.rsvpComponents
        }

        const sendReminderText = selectedOpportunity.sendReminderText
        const reminderTime = selectedOpportunity.reminderTime
        const reminderText = selectedOpportunity.reminderText

        const isActive = selectedOpportunity.isActive
        const isUnlisted = selectedOpportunity.isUnlisted
        const careerSeekerLimit = selectedOpportunity.careerSeekerLimit
        const mentorLimit = selectedOpportunity.mentorLimit
        // const rsvpConfirmationMessage = selectedOpportunity.rsvpConfirmationMessage
        let includeInNotifications = selectedOpportunity.includeInNotifications
        if (!selectedOpportunity.includeInNotifications) {
          includeInNotifications = false
        }
        const typeTags = selectedOpportunity.typeTags
        const educationTags = selectedOpportunity.educationTags
        const adversityTags = selectedOpportunity.adversityTags

        const imageTitleURL = selectedOpportunity.imageTitleURL
        const useImageTitle = selectedOpportunity.useImageTitle
        const hideTextTitle = selectedOpportunity.hideTextTitle

        this.setState({ _id, postType, eventType, title, summary, description, startDate, endDate, location, locationType, selectedAssessment,
          transportationDetails, links, orgContactFirstName, orgContactLastName, orgContactEmail, locationTypeOptions,
          rsvpLink, careerSeekerRsvpLink, advisorRsvpLink,
          featured, status, willEmployerLead, employerName, employerContactFirstName, employerContactLastName, employerContactEmail,
          pathways, departments, targetRoles, selectedPrograms, functions, industry, rsvpComponents,
          candidatesOfInterest: selectedOpportunity.candidatesOfInterest,
          taggedPostings: selectedOpportunity.taggedPostings,
          sendReminderText, reminderTime, reminderText, isActive, isUnlisted, careerSeekerLimit, mentorLimit,
          includeInNotifications, typeTags, educationTags, adversityTags,
          imageTitleURL, useImageTitle, hideTextTitle
        })
        console.log('sendReminderText? ', selectedOpportunity)

        let sortAlphabetically = true
        Axios.get('/api/rsvps/bypost', { params: { postingId: selectedOpportunity._id, sortAlphabetically } })
        .then((response) => {
          console.log('Rsvps query attempted', response.data);

          if (response.data.success) {
            console.log('applications query worked')

            const rsvps = response.data.rsvps
            this.setState({ rsvps })

          } else {
            console.log('applications query did not work', response.data.message)

          }
        }).catch((error) => {
            console.log('Application query did not work for some reason', error);
        });

        Axios.get('/api/attendees/bypost', { params: { postingId: selectedOpportunity._id, sortAlphabetically } })
        .then((response) => {
          console.log('Attendees query attempted', response.data);

          if (response.data.success) {
            console.log('attendees query worked')

            const attendees= response.data.attendees
            this.setState({ attendees })

          } else {
            console.log('applications query did not work', response.data.message)
          }
        }).catch((error) => {
            console.log('Application query did not work for some reason', error);
        });

        if (selectedOpportunity && selectedOpportunity.functions && selectedOpportunity.functions.length > 0) {

          Axios.get('/api/benchmarks/byid', { params: { jobFunction: selectedOpportunity.functions[0] } })
          .then((response) => {
            console.log('Benchmark query attempted', response.data);

            if (response.data.success) {
              console.log('benchmark query worked')

              const benchmark = response.data.benchmark
              this.calculateMatches(null, selectedOpportunity, benchmark, null, org, null)

            } else {
              console.log('benchmark query did not work', response.data.message)
            }

          }).catch((error) => {
              console.log('Benchmark query did not work for some reason', error);
              this.setState({ animating: false, serverPostSuccess: false, serverErrorMessage: error })
          });
        }
      }
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler clicked', event.target.name, event.target.value)

      if (event.target.name === 'subPostType') {
        console.log('show subpost')

        let isPerpetual = false
        let hoursPerWeek = ''
        if (event.target.value === 'Part-Time' || event.target.value === 'Full-Time') {
          isPerpetual = true
          if (event.target.value === 'Full-Time') {
            hoursPerWeek = '~ 40 Hours'
          } else {
            hoursPerWeek = '~ 20 Hours'
          }
        } else {
          isPerpetual = false
        }

        const subPostType = event.target.value
        this.setState({ subPostType, isPerpetual, hoursPerWeek, formHasChanged: true })

      } else if (event.target.name === 'function') {
        // this.setState({ function: event.target.value, formHasChanged: true })

        let benchmark = null
        let competencyTags = []
        if (this.state.benchmarkOptions && this.state.benchmarkOptions.length > 0) {
          for (let i = 1; i <= this.state.benchmarkOptions.length; i++) {
            const jobFunction = this.state.benchmarkOptions[i - 1].jobFunction
            // console.log('comparison: ', i, jobFunction, event.target.value)
            if (jobFunction === event.target.value) {
              // console.log('t0?!')
              if (jobFunction === 'Engineering') {
                // cybersecurity is a temporary problem
                if (!this.state.benchmarkOptions[i - 1].title.includes('Cybersecurity')) {
                  benchmark = this.state.benchmarkOptions[i - 1]
                }
              } else {
                benchmark = this.state.benchmarkOptions[i - 1]
              }
            } else if (event.target.value === 'Other') {
              if (this.state.benchmarkOptions[i - 1].title.includes('Basic')) {
                 benchmark = this.state.benchmarkOptions[i - 1]
              } else if (this.state.benchmarkOptions[i - 1].jobFunction === 'Marketing') {
                benchmark = this.state.benchmarkOptions[i - 1]
              }
            }
          }
        }

        if (benchmark.skills && benchmark.skills.length > 0) {
          for (let i = 1; i <= benchmark.skills.length; i++) {
            let selectedSkill = benchmark.skills[i - 1]
            competencyTags.push({
              name: selectedSkill.title, category: selectedSkill.skillType, description: selectedSkill.description,
              score: selectedSkill.score, weight: selectedSkill.weight
            })
          }
        }

        if (benchmark.abilities && benchmark.abilities.length > 0) {
          for (let i = 1; i <= benchmark.abilities.length; i++) {
            let selectedItem = benchmark.abilities[i - 1]
            competencyTags.push({
              name: selectedItem.title, category: selectedItem.skillType, description: selectedItem.description,
              score: selectedItem.score, weight: selectedItem.weight
            })
          }
        }

        if (benchmark.knowledge && benchmark.knowledge.length > 0) {
          for (let i = 1; i <= benchmark.knowledge.length; i++) {
            let selectedItem = benchmark.knowledge[i - 1]
            competencyTags.push({
              name: selectedItem.title, category: selectedItem.skillType, description: selectedItem.description,
              score: selectedItem.score, weight: selectedItem.weight
            })
          }
        }

        if (window.location.pathname.includes('/employers') && benchmark  && benchmark.accountCode !== this.state.accountCode) {
          // create new benchmark by deleting id for those who do not have the option to change the benchmark manually
          // delete benchmark._id
          benchmark['createNew'] = true

        }

        let alterBenchmark = false
        if (this.state.org === 'guidedcompass' && window.location.pathname.includes('/employers')) {
          alterBenchmark = true
        } else if (this.state.showCompetencyTags && window.location.pathname.includes('/employers')) {
          alterBenchmark = true
        }

        // console.log('show benchmark: ', alterBenchmark, benchmark, competencyTags)

        if (alterBenchmark) {

          let appComponents = this.state.appComponents

          if (benchmark.thirdPartyAssessmentWeight && benchmark.thirdPartyAssessments && benchmark.thirdPartyAssessments.length > 0) {
            let alreadyIncluded = false
            for (let i = 1; i <= appComponents.length; i++) {
              if (appComponents[i - 1].name === 'Third Party Assessments') {
                alreadyIncluded = true
              }
            }
            if (!alreadyIncluded) {
              const thirdPartyAssessmentComponent = { name: 'Third Party Assessments', include: false, required: true, disabled: false }
              appComponents.splice(8,0,thirdPartyAssessmentComponent)
            }
          } else {

            const componentIndex = appComponents.findIndex(x => x.name === 'Third Party Assessments');
            if (componentIndex > -1) {
              // const thirdPartyAssessmentComponent = { name: 'Third Party Assessments', include: false, required: true, disabled: false }
              appComponents.splice(componentIndex,1)
            }
          }

          if (benchmark.dealBreakers && benchmark.dealBreakers.length > 0) {
            let alreadyIncluded = false
            for (let i = 1; i <= appComponents.length; i++) {
              if (appComponents[i - 1].name === 'Screening Questions (Deal Breakers)') {
                alreadyIncluded = true
              }
            }
            if (!alreadyIncluded) {
              const thirdPartyAssessmentComponent = { name: 'Screening Questions (Deal Breakers)', include: false, required: true, disabled: false }
              appComponents.splice(10,0,thirdPartyAssessmentComponent)
            }
          } else {

            const componentIndex = appComponents.findIndex(x => x.name === 'Screening Questions (Deal Breakers)');
            if (componentIndex > -1) {
              appComponents.splice(componentIndex,1)
            }
          }
          console.log('show benchmark: ', benchmark)

          this.setState({ function: event.target.value, formHasChanged: true, benchmark, competencyTags, appComponents })
        } else {
          this.setState({ function: event.target.value, formHasChanged: true })
        }
      } else if (event.target.name === 'isPerpetual') {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({ isPerpetual: value, formHasChanged: true })
      } else if (event.target.name === 'orgTransactionNoti') {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({ orgTransactionNoti: value, formHasChanged: true })
      } else if (event.target.name === "selectEmployer") {

        let selectedEmployer = null
        for (let i = 1; i <= this.state.employerOptions.length; i++) {
          console.log(i, 'updating employer');

          if (event.target.value === this.state.employerOptions[i - 1].employerName) {
            selectedEmployer = this.state.employerOptions[i - 1]
          }
        }

        let employerContactFirstName = ''
        let employerContactLastName = ''
        let employerContactEmail = ''
        let employerContactPhone = ''

        if (selectedEmployer && selectedEmployer.orgContacts && selectedEmployer.orgContacts.length > 0) {
          for (let i = 1; i <= selectedEmployer.orgContacts.length; i++) {
            if (selectedEmployer.orgContacts[i - 1].orgCode === this.state.org) {
              employerContactFirstName = selectedEmployer.orgContacts[i - 1].contactFirstName
              employerContactLastName = selectedEmployer.orgContacts[i - 1].contactLastName
              employerContactEmail = selectedEmployer.orgContacts[i - 1].contactEmail
              employerContactPhone = selectedEmployer.orgContacts[i - 1].contactPhone
            }
          }
        } else if (selectedEmployer && selectedEmployer.contactFirstName) {
          employerContactFirstName = selectedEmployer.contactFirstName
          employerContactLastName = selectedEmployer.contactLastName
          employerContactEmail = selectedEmployer.contactEmail
          employerContactPhone = selectedEmployer.contactPhone
        }

        console.log('show selectedEmployer: ', selectedEmployer.contactFirstName, employerContactFirstName)

        this.setState({ selectedEmployer, formHasChanged: true,
          employerContactFirstName, employerContactLastName,
          employerContactEmail, employerContactPhone
        })
      } else if (event.target.name === "selectedProgram") {

        let selectedProgram = null
        for (let i = 1; i <= this.state.programOptions.length; i++) {
          console.log(i, 'updating program');

          if (event.target.value === this.state.programOptions[i - 1].title) {
            selectedProgram = this.state.programOptions[i - 1]
          }
        }

        let title = this.state.title
        let description = this.state.description
        let location = this.state.location
        let hoursPerWeek = this.state.hoursPerWeek
        let selectedEmployer = this.state.selectedEmployer
        let employerContactFirstName = this.state.employerContactFirstName
        let employerContactLastName = this.state.employerContactLastName
        let employerContactEmail = this.state.employerContactEmail
        let benchmark = this.state.benchmark
        let selectedPrograms = this.state.selectedPrograms

        if (selectedProgram) {
          if (selectedProgram.title) {
            title = selectedProgram.title + " - Cohort 1"
          }
          if (selectedProgram.description) {
            description = selectedProgram.description
          }

          if (selectedProgram.location) {
            location = selectedProgram.location
          }

          if (selectedProgram.hoursPerWeek) {
            hoursPerWeek = selectedProgram.hoursPerWeek
          }

          if (selectedProgram.selectedTrainer) {
            selectedEmployer = selectedProgram.selectedTrainer

            if (selectedProgram.selectedTrainer.contactFirstName) {
              employerContactFirstName = selectedProgram.selectedTrainer.contactFirstName
            }
            if (selectedProgram.selectedTrainer.contactLastName) {
              employerContactLastName = selectedProgram.selectedTrainer.contactLastName
            }
            if (selectedProgram.selectedTrainer.contactEmail) {
              employerContactEmail = selectedProgram.selectedTrainer.contactEmail
            }
          }

          // if (selectedProgram.employerContactFirstName) {
          //   employerContactFirstName = selectedProgram.employerContactFirstName
          // }
          //
          // if (selectedProgram.employerContactLastName) {
          //   employerContactLastName = selectedProgram.employerContactLastName
          // }
          //
          // if (selectedProgram.employerContactEmail) {
          //   employerContactEmail = selectedProgram.employerContactEmail
          // }

          if (selectedProgram.benchmarkId) {
            for (let i = 1; i <= this.state.benchmarkOptions.length; i++) {
              console.log(i, 'updating benchmark');

              if (i !== 1 && selectedProgram.benchmarkId === this.state.benchmarkOptions[i - 1]._id) {
                // selectedBenchmark = this.state.benchmarkOptions[i - 1]
                benchmark = this.state.benchmarkOptions[i - 1]
              }
            }
          }

          selectedPrograms = [selectedProgram]

        }
        this.setState({ formHasChanged: true, selectedProgram,
          title, description, location, hoursPerWeek, selectedEmployer,
          employerContactFirstName, employerContactLastName, employerContactEmail,
          benchmark, selectedPrograms
        })

      } else if (event.target.name === 'usePrimaryOrgContact') {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({ usePrimaryOrgContact: value, formHasChanged: true })
      } else if (event.target.name === 'customAssessment') {
        let customAssessment = { title: event.target.value }
        this.setState({ customAssessment, formHasChanged: true })
      } else if (event.target.name.includes('benchmark')) {

        //require the relevant application components
        let selectedBenchmark = null
        for (let i = 1; i <= this.state.benchmarkOptions.length; i++) {
          console.log(i, 'updating benchmark');

          if (i !== 1 && event.target.value === this.state.benchmarkOptions[i - 1].title) {
            selectedBenchmark = this.state.benchmarkOptions[i - 1]
          }
        }

        let includeInterests = true
        let disableInterests = false

        let topInterests = ['Enterprising']

        let includeSkills = true
        let disableSkills = false

        let topSkills = ['Programming']

        let includeTraits = true
        let disableTraits = false

        let topTraits = ['Resilience']

        let benchmark = this.state.benchmark
        benchmark = { title: event.target.value }

        let appComponents = this.state.appComponents

        if (selectedBenchmark && selectedBenchmark.thirdPartyAssessmentWeight && selectedBenchmark.thirdPartyAssessments && selectedBenchmark.thirdPartyAssessments.length > 0) {
          let alreadyIncluded = false
          for (let i = 1; i <= appComponents.length; i++) {
            if (appComponents[i - 1].name === 'Third Party Assessments') {
              alreadyIncluded = true
            }
          }
          if (!alreadyIncluded) {
            const thirdPartyAssessmentComponent = { name: 'Third Party Assessments', include: false, required: true, disabled: false }
            appComponents.splice(8,0,thirdPartyAssessmentComponent)
          }
        } else {

          const componentIndex = appComponents.findIndex(x => x.name === 'Third Party Assessments');
          if (componentIndex > -1) {
            appComponents.splice(componentIndex,1)
          }
        }

        if (selectedBenchmark && selectedBenchmark.dealBreakers && selectedBenchmark.dealBreakers.length > 0) {
          let alreadyIncluded = false
          for (let i = 1; i <= appComponents.length; i++) {
            if (appComponents[i - 1].name === 'Screening Questions (Deal Breakers)') {
              alreadyIncluded = true
            }
          }
          if (!alreadyIncluded) {
            const thirdPartyAssessmentComponent = { name: 'Screening Questions (Deal Breakers)', include: false, required: true, disabled: false }
            appComponents.splice(10,0,thirdPartyAssessmentComponent)
          }
        } else {

          const componentIndex = appComponents.findIndex(x => x.name === 'Screening Questions (Deal Breakers)');
          if (componentIndex > -1) {
            appComponents.splice(componentIndex,1)
          }
        }

        this.setState({ benchmark,
          formHasChanged: true,
          includeInterests, includeSkills, includeTraits,
          disableInterests, disableSkills, disableTraits,
          topInterests, topSkills, topTraits, applicationErrorMessage: null
        })
      } else if (event.target.name === 'orgName') {
        const orgName = event.target.value
        this.setState({ orgName, formHasChanged: true })
        this.searchOrganizations(orgName)
      } else if (event.target.name === 'prize1') {
        let prizes = this.state.prizes
        prizes[0] = event.target.value
        this.setState({ prizes, formHasChanged: true })
      } else if (event.target.name === 'prize2') {
        let prizes = this.state.prizes
        prizes[1] = event.target.value
        this.setState({ prizes, formHasChanged: true })
      } else if (event.target.name === 'prize3') {
        let prizes = this.state.prizes
        prizes[2] = event.target.value
        this.setState({ prizes, formHasChanged: true })
      } else if (event.target.name === 'employerName') {
        const employerName = event.target.value
        const accountCode = null
        this.setState({ employerName, accountCode })

        this.searchEmployers(employerName)

      } else if (event.target.name.includes('resourceTitle')) {
        let resources = this.state.resources
        const nameArray = event.target.name.split("|")
        const index = Number(nameArray[1]) - 1
        resources[index] = event.target.value
        this.setState({ resources, formHasChanged: true })
      } else if (event.target.name.includes('sectionBody')) {
        let sections = this.state.sections
        const nameArray = event.target.name.split("|")
        const index = Number(nameArray[1]) - 1
        sections[index] = { title: sections[index].title, body: event.target.value }
        this.setState({ sections, formHasChanged: true })
      } else if (event.target.name.includes('exhibitLink')) {
        let exhibits = this.state.exhibits
        const nameArray = event.target.name.split("|")
        const index = Number(nameArray[1]) - 1
        exhibits[index] = { link: event.target.value }
        this.setState({ exhibits, formHasChanged: true })
      } else if (event.target.name.includes('linkName')) {
        let links = this.state.links
        const nameArray = event.target.name.split("|")
        const index = Number(nameArray[1]) - 1
        links[index]['name'] = event.target.value
        this.setState({ links, formHasChanged: true })
      } else if (event.target.name.includes('linkURL')) {
        let links = this.state.links
        const nameArray = event.target.name.split("|")
        const index = Number(nameArray[1]) - 1
        links[index]['url'] = event.target.value
        this.setState({ links, formHasChanged: true })
      } else if (event.target.name === 'sectionTemplate') {

        let sectionTemplate = null
        for (let i = 1; i <= this.state.sectionTemplateOptions.length; i++) {
          if (this.state.sectionTemplateOptions[i - 1].title === event.target.value) {
            sectionTemplate = this.state.sectionTemplateOptions[i - 1]
          }
        }

        let sections = []
        let checked = []

        if (event.target.value !== 'Custom') {
          if (sectionTemplate) {
            for (let i = 1; i <= sectionTemplate.sectionTitles.length; i++) {
              sections.push({ title: sectionTemplate.sectionTitles[i - 1], body: '' })
              checked.push(false)
            }
          }
        }

        this.setState({ sectionTemplate: event.target.value, sections, checked, formHasChanged: true })
      } else if (event.target.name === 'isPerpetual') {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({ isPerpetual: value, formHasChanged: true })
      } else if (event.target.name === "selectAssessment") {

        let selectedAssessment = null
        for (let i = 1; i <= this.state.assessmentOptions.length; i++) {
          console.log(i, 'updating assessment');

          if (event.target.value === this.state.assessmentOptions[i - 1].title) {
            selectedAssessment = this.state.assessmentOptions[i - 1]
          }
        }

        let modalIsOpen = false
        if (selectedAssessment.title === 'Add a New Survey') {
          modalIsOpen = true
        }

        this.setState({ selectedAssessment, formHasChanged: true, modalIsOpen })

      } else if (event.target.name === 'customAssessment') {
        let customAssessment = { title: event.target.value }
        this.setState({ customAssessment, formHasChanged: true })
      } else if (event.target.name.includes('benchmark')) {
        const benchmark = { title: event.target.value }
        this.setState({ benchmark, formHasChanged: true })
      } else if (event.target.name === 'isExternal') {
          const isExternal = event.target.value
          this.setState({ isExternal, formHasChanged: true })
      } else if (event.target.name === 'firstName') {
          this.setState({ cuFirstName: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'lastName') {
          this.setState({ cuLastName: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'email') {
          this.setState({ emailId: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'course') {

        let course = {name: ''}
        for (let i = 1; i <= this.state.courseOptions.length; i++) {
          console.log(i, 'updating course', event.target.value, this.state.courseOptions[i - 1].name);

          if (event.target.value === this.state.courseOptions[i - 1].name) {
            console.log('were in')
            course = this.state.courseOptions[i - 1]
          }
        }
        console.log('show course: ', course)
        this.setState({ course, formHasChanged: true })

      } else if (event.target.name === 'assigneeName') {
          const assigneeName = event.target.value
          this.setState({ assigneeName, formHasChanged: true })
          this.searchAssignees(assigneeName)
      } else if (event.target.name === 'workerName') {
        const workerName = event.target.value
        this.setState({ workerName, formHasChanged: true })
        this.searchWorkers(workerName)
      } else if (event.target.name === 'tagName') {
        this.setState({ tagName: event.target.value, formHasChanged: true })
        this.searchCompetencies(event.target.value, 'All', null)
      } else if (event.target.name === 'postingTitle') {
          const postingTitle = event.target.value
          this.setState({ postingTitle, formHasChanged: true })
          this.searchItems(postingTitle,event.target.name)
      } else if (event.target.name.includes('screeningQuestion|')) {
        const nameArray = event.target.name.split("|")
        // const category = nameArray[0]
        const element = nameArray[1]
        const index = Number(nameArray[2])

        let screeningQuestions = this.state.screeningQuestions
        screeningQuestions[index][element] = event.target.value
        this.setState({ screeningQuestions, formHasChanged: true })
      } else if (event.target.name.includes('answerChoice|')) {
        const nameArray = event.target.name.split("|")
        // const category = nameArray[0]
        const element = nameArray[1]
        const questionIndex = Number(nameArray[2])
        const answerChoiceIndex = Number(nameArray[3])

        let screeningQuestions = this.state.screeningQuestions
        let screeningAnswerChoices = this.state.screeningQuestions[questionIndex].screeningAnswerChoices
        screeningAnswerChoices[answerChoiceIndex][element] = event.target.value

        screeningQuestions[questionIndex]['screeningAnswerChoices'] = screeningAnswerChoices
        this.setState({ screeningQuestions, formHasChanged: true })
      } else if (event.target.name.includes('candidatesOfInterest|')) {
        const nameArray = event.target.name.split("|")
        // const category = nameArray[0]
        const element = nameArray[1]
        const index = Number(nameArray[2])

        let candidatesOfInterest = this.state.candidatesOfInterest
        candidatesOfInterest[index][element] = event.target.value
        this.setState({ candidatesOfInterest, formHasChanged: true })
      } else {

        this.setState({ [event.target.name]: event.target.value, formHasChanged: true })
      }
    }

    searchEmployers(employerName) {
      console.log('searchEmployers ', employerName)

      Axios.get('/api/account/search', { params: { employerName } })
      .then((response) => {
        console.log('Employer search query attempted', response.data);

          if (response.data.success) {
            console.log('employer search query worked')

            const employers = response.data.employers
            this.setState({ employers });

          } else {
            console.log('employer search query did not work', response.data.message)
          }

      }).catch((error) => {
          console.log('Employer search query did not work for some reason', error);
      });
    }

    employerClicked(employer) {
      console.log('employerClicked called ', employer)

      const employerName = employer.employerName
      const employerType = employer.employerType
      const employerSize = employer.employeeCount
      const accountCode = employer.accountCode
      const industry = employer.employerIndustry

      const employerURL = employer.employerURL
      const employerEmployees = employer.employeeCount
      const employerGrowth = employer.employeeGrowth
      const employerLogoURL = employer.employerLogoURI

      let employerContactFirstName = employer.contactFirstName
      let employerContactLastName = employer.contactLastName
      let employerContactEmail = employer.contactEmail
      let employerContactPhone = employer.contactPhone

      if (employer && employer.orgContacts) {
        for (let i = 1; i <= employer.orgContacts.length; i++) {
          if (employer.orgContacts[i - 1].orgCode === this.state.org) {
            employerContactFirstName = employer.orgContacts[i - 1].contactFirstName
            employerContactLastName = employer.orgContacts[i - 1].contactLastName
            employerContactEmail = employer.orgContacts[i - 1].contactEmail
            employerContactPhone = employer.orgContacts[i - 1].contactPhone
          }
        }
      }

      this.setState({ employerName, employerType, employerSize, accountCode, employers: [],
        employerURL, employerEmployees, employerGrowth, employerLogoURL, employerContactFirstName, employerContactLastName,
        employerContactEmail, employerContactPhone, industry, formHasChanged: true })

    }

    searchItems(value, type) {
      console.log('searchAssignees ', value, type)

      this.setState({ isLoading: true, errorMessage: null, successMessage: null })

      const searchString = value
      const orgCode = this.state.org
      let accountCode = null
      if (window.location.pathname.includes('/employers')) {
        accountCode = this.state.accountCode
      }

      const placementPartners = this.state.placementPartners
      const search = true
      let postTypes = null
      // const resLimit = null
      const pullPartnerPosts = true

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called', orgCode)
        self.setState({ animating: true, errorMessage: null, successMessage: null })

        if (type === 'postingTitle') {

          Axios.get('/api/postings/search', { params: { searchString, orgCode, placementPartners, accountCode, search, postTypes, pullPartnerPosts } })
          .then((response) => {
            console.log('Opportunity search query attempted', response.data);

              if (response.data.success && response.data.postings) {
                console.log('opportunity search query worked')

                let postingOptions = response.data.postings
                self.setState({ postingOptions, isLoading: false })

              } else {
                console.log('opportunity search query did not work', response.data.message)

                self.setState({ postingOptions: [], isLoading: false, errorMessage: response.data.message })

              }

          }).catch((error) => {
              console.log('Search query did not work for some reason', error);
              self.setState({ isLoading: false, errorMessage: error.toString() })
          });
        }
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();

    }

    searchAssignees(assigneeName) {
      console.log('searchAssignees ', assigneeName)

      const orgCode = this.state.org
      let accountCode = null
      if (window.location.pathname.includes('/employers')) {
        accountCode = this.state.accountCode
      }

      Axios.get('/api/assignee/search', { params: { assigneeName, orgCode, accountCode } })
      .then((response) => {
        console.log('Assignee search query attempted', response.data);

          if (response.data.success) {
            console.log('assignee search query worked')

            const suggestedAssignees = response.data.assignees
            this.setState({ suggestedAssignees });

          } else {
            console.log('assignee search query did not work', response.data.message)
            const suggestedAssignees = []
            this.setState({ suggestedAssignees })
          }

      }).catch((error) => {
          console.log('Assignee search query did not work for some reason', error);
      });
    }

    searchWorkers(workerName) {
      console.log('searchWorkers ', workerName)

      const orgCode = this.state.org
      const accountCode = this.state.accountCode
      const postingId = this.state.selectedOpportunity._id

      Axios.get('/api/applications/search', { params: { searchString: workerName, orgCode, accountCode, postingId } })
      .then((response) => {
        console.log('Worker search query attempted', response.data);

          if (response.data.success) {
            console.log('worker search query worked')

            const suggestedWorkers = response.data.applicants
            this.setState({ suggestedWorkers });

          } else {
            console.log('workers search query did not work', response.data.message)
            const suggestedWorkers = []
            this.setState({ suggestedWorkers })
          }

      }).catch((error) => {
          console.log('Assignee search query did not work for some reason', error);
      });
    }

    assigneeClicked(assignee) {
      console.log('assigneeClicked called ', assignee)

      const suggestedAssignees = []
      const assigneeName = assignee.firstName + ' ' + assignee.lastName
      console.log('show aName: ', assigneeName)

      this.setState({ suggestedAssignees, assignee, assigneeName, formHasChanged: true })

    }

    workerClicked(worker) {
      console.log('workerClicked called ', worker)

      const suggestedWorkers = []
      const workerName = worker.firstName + ' ' + worker.lastName
      console.log('show aName: ', workerName)

      this.setState({ suggestedWorkers, worker, workerName, formHasChanged: true })

    }

    searchOrganizations(orgName) {
      console.log('searchOrganizations ', orgName)

      Axios.get('/api/org/search', { params: { orgName } })
      .then((response) => {
        console.log('Org search query attempted', response.data);

          if (response.data.success) {
            console.log('org search query worked')

            const organizations = response.data.organizations
            this.setState({ organizations });

          } else {
            console.log('organization search query did not work', response.data.message)
          }

      }).catch((error) => {
          console.log('Organization search query did not work for some reason', error);
      });
    }

    orgClicked(org) {
      console.log('orgClicked called ', org)

      const orgName = org.orgName
      const orgCode = org.orgCode
      const orgContactEmail = org.contactEmail
      const organizations = []

      this.setState({ orgName, org: orgCode, orgContactEmail, organizations })

    }

    calculateMatches(applications, selectedOpportunity, benchmark, benchmarkOptions, activeOrg, source) {
      console.log('calculateMatches called: ', applications, benchmark, benchmarkOptions, activeOrg, source)

      if (applications) {
        let newApplications = []
        for (let i = 1; i <= applications.length; i++) {
          console.log(i, 'calculating matches');

          let postType = ''
          if (selectedOpportunity.postType === 'Individual') {
            postType = 'Internship'
          } else {
            postType = selectedOpportunity.postType
          }

          if (postType === 'Internship' || postType === 'Work' || postType === 'Program') {
            console.log('shoe me 2')
            const index = i - 1
            let appEnd = applications.length - 1

            Axios.post('/api/applications/matches', {
              application: applications[i - 1], selectedJob: selectedOpportunity, benchmark, activeOrg, saveChanges: true })
            .then((response) => {
              console.log('application matches query attempted in individual')
              if (response.data.success) {
                //save values
                console.log('Application matches save worked in individual');

                newApplications.push(response.data.application)

                if (index === appEnd) {
                  console.log('last one ', newApplications)
                  newApplications.sort(function(a,b) {
                    return b.match - a.match;
                  })

                  this.setState({ applications: newApplications, benchmark, benchmarkOptions });
                }

              } else {
                console.log('application matches did not work', response.data.message)
              }
            }).catch((error) => {
                console.log('Application matches did not work for some reason', error);
            });

          } else {
            console.log('we already calculated matches')
            this.setState({ applications, benchmark, benchmarkOptions });
          }
        }
      }

      let workFunction = null
      if (selectedOpportunity.postType === 'Event' || selectedOpportunity.postType === 'Project') {
        workFunction = selectedOpportunity.functions[0]
      } else if (selectedOpportunity.postType === 'Work' && selectedOpportunity.field) {
        workFunction = selectedOpportunity.field.split("|")[0].trim()
      }

      Axios.post('/api/prospects/matches', { selectedOpportunity, benchmark, workFunction, resLimit: 30 })
      .then((response) => {
        console.log('prospect matches query attempted')
        if (response.data.success) {
          //save values
          console.log('Prospect matches save worked in individual', response.data.prospects);

          const prospects = response.data.prospects
          this.setState({ prospects });

        } else {
          console.log('prospect matches did not work', response.data.message)
        }
      }).catch((error) => {
          console.log('Prospect matches did not work for some reason', error);
      });
    }

    editJob() {
      console.log('editJob clicked', this.state.benchmarkOptions);

      this.setState({ clientErrorMessage: '', isSaving: true })

      if (this.state.formHasChanged === false) {
        this.setState({ clientErrorMessage: 'no changes detected. please update and then save.', isSaving: false })
      } else {
        console.log('show postType: ', this.state.postType)
        if (this.state.postType === 'Internship' || this.state.postType === 'Individual' || this.state.postType === 'Work') {
          console.log('in internship')
          let subPostType = this.state.subPostType
          if (this.state.postType === 'Individual') {
            subPostType = this.state.postType
          }
          const workflowType = this.state.workflowType

          if (this.state.title === '') {
            this.setState({ clientErrorMessage: 'please add a title', isSaving: false })
          } else if (this.state.location === '') {
            this.setState({ clientErrorMessage: 'please add a location', isSaving: false })
          } else if (this.state.type === '') {
            this.setState({ clientErrorMessage: 'please add a type', isSaving: false })
          } else if (!this.state.selectedEmployer) {
            this.setState({ clientErrorMessage: 'please add a employer', isSaving: false })
          } else if (this.state.function === '') {
            this.setState({ clientErrorMessage: 'please add a function', isSaving: false })
          } else if (this.state.usePrimaryOrgContact === false && this.state.orgContactEmail === '') {
            this.setState({ clientErrorMessage: 'please add an org contact, or toggle to use the primary org contact', isSaving: false })
          } else if (this.state.description === '') {
            this.setState({ clientErrorMessage: 'please add a job description', isSaving: false })
          } else if (this.state.includeBasicInfo === false || this.state.includeResume === false) {
            this.setState({ clientErrorMessage: 'applications require including basic info and resumes now. Please toggle on.', isSaving: false })
          } else if (this.state.hoursPerWeek === '' && !this.state.disableSomeRequirements) {
            this.setState({ clientErrorMessage: 'please add the hours per week the candidate will work', isSaving: false })
          } else if (this.state.hireCount === '') {
            this.setState({ clientErrorMessage: 'please add the number of hires you intend for this role', isSaving: false })
          } else if (!this.state.preSetStartDate && this.state.startDate === '' && !this.state.disableSomeRequirements) {
            this.setState({ clientErrorMessage: 'please add the ideal start date', isSaving: false })
          } else if (!this.state.preSetStartDate && this.state.isPerpetual === false &&  this.state.endDate === '' && !this.state.disableSomeRequirements) {
            this.setState({ clientErrorMessage: 'please add the ideal end date or indicate that this is a perpetual role', isSaving: false })
          } else if (this.state.customAssessment.title === 'No Assessment Selected' && this.state.includeCustomAssessment === true) {
            this.setState({ clientErrorMessage: 'please either add a custom assessment template or toggle include custom assessment to off', isSaving: false })
          } else if (!this.props.path.includes('/employers/') && this.state.benchmark && (this.state.benchmark.title === 'No Benchmarks Saved' || this.state.benchmark.title === 'No Benchmark Added') && !this.state.disableSomeRequirements) {
            this.setState({ clientErrorMessage: 'please include a benchmark', isSaving: false })
          // } else if (!this.props.path.includes('/organizations/') && this.state.postType === 'Individual' && this.state.benchmark && (this.state.benchmark.title === 'No Benchmarks Saved' || this.state.benchmark.title === 'No Benchmark Added')) {
          //   this.setState({ clientErrorMessage: 'please include a benchmark', isSaving: false })
          } else if (this.state.org === 'guidedcompass' && this.state.postType === 'Work' && this.state.subPostType !== 'Volunteer' && this.state.payType === '' && !this.state.disableSomeRequirements) {
            this.setState({ clientErrorMessage: 'please add a pay type', isSaving: false })
          } else if (this.state.org === 'guidedcompass' && this.state.postType === 'Work' && this.state.subPostType !== 'Volunteer' && this.state.payRange === '' && !this.state.disableSomeRequirements) {
            this.setState({ clientErrorMessage: 'please add a pay range', isSaving: false })
          } else if (this.state.org === 'guidedcompass' && this.state.hostUndocumented === '') {
            this.setState({ clientErrorMessage: 'please indicate whether you can host workers not documented in the U.S.', isSaving: false })
          } else if (!this.state.employerContactFirstName || this.state.employerContactFirstName === '') {
            this.setState({ clientErrorMessage: 'Please add a contact first name for the employer', isSaving: false })
          } else if (!this.state.employerContactLastName || this.state.employerContactLastName === '') {
            this.setState({ clientErrorMessage: 'Please add a contact last name for the employer', isSaving: false })
          } else if (!this.state.employerContactEmail || this.state.employerContactEmail === '') {
            this.setState({ clientErrorMessage: 'Please add a contact email for the employer', isSaving: false })
          // } else if (!this.state.industry || this.state.industry === '') {
          //   this.setState({ clientErrorMessage: 'Please add an industry. If no specific industry, selected "Other"', isSaving: false })
          } else {
            // console.log('saving work post', this.state.industry)

            if (this.state.screeningQuestions && this.state.screeningQuestions.length > 0) {
              let screeningQuestions = this.state.screeningQuestions
              for (let j = 1; j <= screeningQuestions.length; j++) {

                if (!screeningQuestions[j - 1].name || screeningQuestions[j - 1].name === '') {
                  return this.setState({ clientErrorMessage: 'Please add a name for all screening questions', isSaving: false })
                } else if (screeningQuestions[j - 1].questionType === 'Multiple Choice' || screeningQuestions[j - 1].questionType === 'Checkbox' || screeningQuestions[j - 1].questionType === 'Ranking') {

                  // make sure this isn't a custom assessment
                  if (screeningQuestions[j - 1].answerChoices && screeningQuestions[j - 1].answerChoices.length > 0) {
                    // do nothing, this is a custom assessment
                  } else {
                    let screeningAnswerChoices = screeningQuestions[j - 1].screeningAnswerChoices
                    console.log('show screeningAnswerChoices pre-save: ', screeningAnswerChoices)
                    if (!screeningAnswerChoices || screeningAnswerChoices.length === 0 || screeningAnswerChoices.length === 1) {
                      return this.setState({ clientErrorMessage: 'Please add at least two answer choices', isSaving: false })
                    } else if (screeningQuestions[j - 1].screeningAnswerChoices && screeningQuestions[j - 1].screeningAnswerChoices.length > 0) {
                      for (let k = 1; k <= screeningAnswerChoices.length; k++) {
                        if (!screeningAnswerChoices[k - 1].name || screeningAnswerChoices[k - 1].name === '') {
                          return this.setState({ clientErrorMessage: 'Please add an answer choice to all your answer choices', isSaving: false })
                        } else if (!screeningAnswerChoices[k - 1].score || screeningAnswerChoices[k - 1].score === '') {
                          return this.setState({ clientErrorMessage: 'Please add a point value 1 - 5 to all your answer choices', isSaving: false })
                        }
                      }
                    }
                  }
                }
              }
            }

            const _id = this.state._id
            let postType = this.state.postType
            // if (!this.state.org || this.state.org === '' || this.state.org === 'guidedcompass') {
            //   postType = this.state.postType
            // }

            const title = this.state.title
            const location = this.state.location
            const type = this.state.type
            let field = this.state.function + " | " + this.state.selectedEmployer.employerIndustry
            const pathways = this.state.pathways
            const departments = this.state.departments
            const targetRoles = this.state.targetRoles
            const selectedPrograms = this.state.selectedPrograms

            let startDate = undefined
            if (this.state.preSetStartDate) {
              startDate = this.state.preSetStartDate
            } else if (this.state.startDate) {
              startDate = convertStringToDate(this.state.startDate,"dateOnly")
              console.log('compare startDates: ', startDate, this.state.startDate)
            }
            console.log('showStartDate: ', this.state.startDate, this.state.preSetStartDate)

            const isPerpetual = this.state.isPerpetual
            let endDate = undefined
            if (this.state.preSetEndDate) {
              endDate = this.state.preSetEndDate
            } else if (this.state.endDate) {
              endDate = convertStringToDate(this.state.endDate,"dateOnly")
            }

            const hoursPerWeek = this.state.hoursPerWeek
            const hireCount = this.state.hireCount
            const payType = this.state.payType
            const payRange = this.state.payRange
            let pay = this.state.pay
            if (payType === 'Custom') {
              pay = payRange
            }
            const description = this.state.description

            let accountCode = ''
            let employerName = this.state.orgName
            let employerType = ''
            let employerURL = ''
            let employerEmployees = ''
            let employerGrowth = ''
            let imageURL = null

            accountCode = this.state.selectedEmployer.accountCode
            employerName = this.state.selectedEmployer.employerName
            employerType = this.state.selectedEmployer.employerType
            employerURL = this.state.selectedEmployer.employerURL
            employerEmployees = this.state.selectedEmployer.employeeCount
            employerGrowth = this.state.selectedEmployer.employeeGrowth
            imageURL = this.state.selectedEmployer.employerLogoURI

            console.log('show me the account code', accountCode, this.state.selectedEmployer)
            let applicationComponents = ['basicInfo','resume']
            if (this.state.includeCoverLetter) {
              applicationComponents.push('coverLetter')
            }

            if (this.state.includeInterests) {
              applicationComponents.push('interests')
            }

            if (this.state.includeSkills) {
              applicationComponents.push('skills')
            }

            if (this.state.includeTraits) {
              applicationComponents.push('personality')
            }

            if (this.state.includeGoals) {
              applicationComponents.push('goals')
            }
            if (this.state.includeEndorsements) {
              applicationComponents.push('endorsements')
            }

            if (this.state.includeCustomAssessment) {
              applicationComponents.push('customAssessment')
            }

            const appComponents = this.state.appComponents

            let interests = ['Realistic']
            let skills = ['Wireframing']
            let traits = ['Resilience']

            if (this.state.topInterests.length !== 0) {
              interests = this.state.topInterests
            }

            if (this.state.topSkills.length !== 0) {
              skills = this.state.topSkills
            }

            if (this.state.topTraits.length !== 0) {
              traits = this.state.topTraits
            }

            let customAssessmentId = ''
            if ( this.state.includeCustomAssessment === true || this.state.appComponents[this.state.appComponents.length - 1].include) {
              for (let i = 1; i <= this.state.customAssessmentOptions.length; i++) {
                console.log(i)

                if (this.state.customAssessmentOptions[i - 1].title === this.state.customAssessment.title) {
                  customAssessmentId = this.state.customAssessmentOptions[i - 1]._id
                }
              }
            }

            let benchmarkId = ''
            let benchmark = this.state.benchmark
            let useCustomBenchmark = false

            console.log('show benchmark: ', this.state.benchmark)

            if (this.state.benchmark.title && this.state.benchmark.title !== 'No Benchmarks Saved') {
              console.log('in individual benchie', this.state.benchmark)
              for (let j = 1; j <= this.state.benchmarkOptions.length; j++) {
                console.log(j, this.state.benchmarkOptions[j - 1].title, this.state.benchmark.title)

                if (this.state.benchmarkOptions[j - 1].title === this.state.benchmark.title) {
                  console.log(j, 'we got a match!', this.state.benchmarkOptions[j - 1])
                  benchmarkId = this.state.benchmarkOptions[j - 1]._id
                  useCustomBenchmark = true
                  benchmark = this.state.benchmarkOptions[j - 1]
                  console.log(j, 'one more check', benchmarkId)
                }
              }

            } else {

              console.log('automatically attach benchmark')

              if (!benchmark) {
                if (this.state.benchmarkOptions) {
                  for (let j = 1; j <= this.state.benchmarkOptions.length; j++) {
                    console.log(j)

                    if (this.state.benchmarkOptions[j - 1].jobFunction === this.state.function) {
                      benchmarkId = this.state.benchmarkOptions[j - 1]._id
                      useCustomBenchmark = true
                      benchmark = this.state.benchmarkOptions[j - 1]
                    }
                  }
                }

                if (benchmarkId === '' && this.state.benchmarkOptions[1]) {
                  benchmarkId = this.state.benchmarkOptions[1]._id
                  benchmark = this.state.benchmarkOptions[1]
                }
              }
            }

            if (subPostType === 'Individual' && benchmarkId) {
              //automatically attach benchmark
              console.log('automatically attach benchmark 2', benchmarkId)
            }

            console.log('testing 3 for me', benchmarkId)

            const orgTransactionNoti = this.state.orgTransactionNoti
            const usePrimaryEmployerContact = this.state.usePrimaryEmployerContact
            const employerContactFirstName = this.state.employerContactFirstName
            const employerContactLastName = this.state.employerContactLastName
            const employerContactEmail = this.state.employerContactEmail

            const employerContactPhone = this.state.employerContactPhone
            const usePrimaryOrgContact = this.state.usePrimaryOrgContact

            const orgContactFirstName = this.state.orgContactFirstName
            const orgContactLastName = this.state.orgContactLastName
            const orgContactEmail = this.state.orgContactEmail

            let applications = []
            let applicants = []
            if (this.state.selectedOpportunity) {
              applications = this.state.selectedOpportunity.applications
              applicants = this.state.selectedOpportunity.applicants
            }

            const orgCode = this.state.org
            let placementPartners = null
            if (window.location.pathname.includes('/employers/') && this.state.trainingProviders) {
              placementPartners = []
              for (let i = 1; i <= this.state.trainingProviders.length; i++) {
                if (this.state.trainingProviders[i - 1].orgCode !== orgCode) {
                  placementPartners.push(this.state.trainingProviders[i - 1].orgCode)
                }
              }
            }

            let orgName = ''
            if (this.state.orgName) {
              orgName = this.state.orgName
            }

            let orgURL = ''
            if (this.state.orgURL) {
              orgURL = this.state.orgURL
            }

            let orgMission = ''
            if (this.state.orgMission) {
              orgMission = this.state.orgMission
            }

            const isActive = this.state.isActive
            const isUnlisted = this.state.isUnlisted

            const featured = this.state.featured
            const isSubsidized = this.state.isSubsidized

            let submissionDeadline = undefined
            if (this.state.useNewSubmissionDeadline) {
              if (this.state.preSetSubmissionDeadline) {
                submissionDeadline = this.state.preSetSubmissionDeadline
              } else if (this.state.submissionDeadline) {
                submissionDeadline = new Date(this.state.submissionDeadline)
              }

            } else {
              submissionDeadline = convertStringToDate(this.state.submissionDeadline,"dateOnly")
            }

            if (this.state.selectedOpportunity && this.state.selectedOpportunity.submissionDeadline && !submissionDeadline && _id) {
              submissionDeadline = 'delete'
            }

            const politicalParty = this.state.politicalParty
            const appRequirements = this.state.appRequirements
            const applicationMethod = this.state.applicationMethod
            const subjectLine = this.state.subjectLine
            const jobLink = this.state.jobLink
            const referrerName = this.state.referrerName
            const referralExplanation = this.state.referralExplanation

            const supplementalPayArray = this.state.supplementalPayArray
            const benefits = this.state.benefits
            const zipcode = this.state.zipcode
            const prioritizeSubsidized = this.state.prioritizeSubsidized
            const prioritizeSchoolCredit = this.state.prioritizeSchoolCredit
            const hostUndocumented = this.state.hostUndocumented
            const competencyTags = this.state.competencyTags
            const candidatesOfInterest = this.state.candidatesOfInterest
            const taggedPostings = this.state.taggedPostings
            const applicationConfirmationSubjectLine = this.state.applicationConfirmationSubjectLine
            const applicationConfirmationMessage = this.state.applicationConfirmationMessage

            const includeInNotifications = this.state.includeInNotifications
            const typeTags = this.state.typeTags
            const educationTags = this.state.educationTags
            const adversityTags = this.state.adversityTags

            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const yearsOfExperience = this.state.yearsOfExperience
            const allowReferrals = this.state.allowReferrals
            const hideIdealCandidateProfile = this.state.hideIdealCandidateProfile
            const hideViewMatch = this.state.hideViewMatch

            const imageTitleURL = this.state.imageTitleURL
            const useImageTitle = this.state.useImageTitle
            const hideTextTitle = this.state.hideTextTitle

            const isPromotional = this.state.isPromotional

            const createdAt = new Date()
            const updatedAt = new Date()

            // need to save benchmark and custom assessment
            let newSave = true
            if (newSave) {
              [''].forEach(async(item, index) => {

                let direct = false
                if (this.state.org === 'guidedcompass') {
                  direct = true
                }

                if (this.state.org === 'guidedcompass') {
                  let saveCustomAssessment = false
                  if (this.state.screeningQuestions && this.state.screeningQuestions.length > 0) {
                    saveCustomAssessment = true
                  } else if (this.state.customAssessmentId) {
                    // questions were deleted
                    saveCustomAssessment = true
                  }
                  if (saveCustomAssessment) {
                    // proceed to save
                    customAssessmentId = await this.saveCustomAssessment()
                    console.log('received CustomAssessmentId: ', customAssessmentId)
                  }
                }

                if (window.location.pathname.includes('/employers')) {
                  let saveCustomBenchmark = false
                  if (benchmark['createNew']) {
                    saveCustomBenchmark = true
                  }
                  console.log('show the benchmark id: ', benchmarkId, saveCustomBenchmark)
                  // let saveCustomBenchmark = true
                  // if (competencyTags && competencyTags.length > 0) {
                  //   saveCustomBenchmark = false
                  // }
                  // console.log('proceed to save?!', this.state.benchmarkId, benchmarkId, benchmark, competencyTags, saveCustomBenchmark)
                  if (saveCustomBenchmark) {
                    // proceed to save

                    if (this.state.benchmarkId && !window.location.pathname.includes('/organizations') && !window.location.pathname.includes('/advisor')) {
                      benchmarkId = await this.saveCustomBenchmark(this.state.benchmarkId, benchmark)
                    } else {
                      benchmarkId = await this.saveCustomBenchmark(benchmarkId, benchmark)
                    }
                  }
                }

                const posting = {
                  _id, postType, subPostType, workflowType, title, location, type, field, pathways, departments,
                  targetRoles, selectedPrograms, payType, payRange, pay, description,
                  employerName, employerURL, employerType, employerEmployees, employerGrowth, imageURL,
                  applicationComponents, appComponents,
                  applications, applicants, orgCode, placementPartners,
                  interests, skills, traits, customAssessmentId, benchmarkId,
                  hoursPerWeek, hireCount, startDate, isPerpetual, endDate,
                  orgTransactionNoti, isActive, isUnlisted, featured, isSubsidized,
                  submissionDeadline, politicalParty, appRequirements, applicationMethod, subjectLine, jobLink,
                  accountCode, usePrimaryEmployerContact, employerContactFirstName, employerContactLastName,
                  employerContactEmail, employerContactPhone, usePrimaryOrgContact,
                  orgContactFirstName, orgContactLastName, orgContactEmail,
                  orgName, orgURL, orgMission, referrerName, referralExplanation,
                  supplementalPayArray, benefits, competencyTags, zipcode, prioritizeSubsidized, prioritizeSchoolCredit,
                  hostUndocumented, direct, candidatesOfInterest, taggedPostings, applicationConfirmationSubjectLine, applicationConfirmationMessage,
                  includeInNotifications, typeTags, educationTags, adversityTags, timeZone, yearsOfExperience,
                  allowReferrals, hideIdealCandidateProfile, hideViewMatch,
                  imageTitleURL, useImageTitle, hideTextTitle, isPromotional,
                  createdAt, updatedAt
                }

                this.finishPosting(posting, _id, useCustomBenchmark, title, employerName, orgName, orgContactEmail, updatedAt, subPostType)

              })
            } else {

              const posting = {
                _id, postType, subPostType, workflowType, title, location, type, field, pathways, departments,
                targetRoles, selectedPrograms, payType, payRange, pay, description,
                employerName, employerURL, employerType, employerEmployees, employerGrowth, applicationComponents, appComponents,
                applications, applicants, orgCode, placementPartners,
                interests, skills, traits, customAssessmentId, benchmarkId,
                hoursPerWeek, hireCount, startDate, isPerpetual, endDate,
                orgTransactionNoti, isActive, isUnlisted, featured, isSubsidized,
                submissionDeadline, politicalParty, appRequirements, applicationMethod, subjectLine, jobLink,
                accountCode, usePrimaryEmployerContact, employerContactFirstName, employerContactLastName,
                employerContactEmail, employerContactPhone, usePrimaryOrgContact,
                orgContactFirstName, orgContactLastName, orgContactEmail,
                orgName, orgURL, orgMission, referrerName, referralExplanation,
                supplementalPayArray, benefits, competencyTags, zipcode, prioritizeSubsidized, prioritizeSchoolCredit,
                hostUndocumented, candidatesOfInterest, taggedPostings, applicationConfirmationSubjectLine, applicationConfirmationMessage,
                includeInNotifications, typeTags, educationTags, adversityTags, timeZone, yearsOfExperience,
                allowReferrals, hideIdealCandidateProfile, hideViewMatch,
                imageTitleURL, useImageTitle, hideTextTitle, isPromotional,
                createdAt, updatedAt
              }

              this.finishPosting(posting, _id, useCustomBenchmark, title, employerName, orgName, orgContactEmail, updatedAt, subPostType)
            }
          }
        } else if (this.state.postType === 'Program') {
          console.log('in program')

          if (this.state.title === '') {
            this.setState({ clientErrorMessage: 'please add a title', isSaving: false })
          } else if (this.state.location === '') {
            this.setState({ clientErrorMessage: 'please add a location', isSaving: false })
          } else if (!this.state.selectedEmployer) {
            this.setState({ clientErrorMessage: 'please add a employer', isSaving: false })
          } else if (this.state.usePrimaryOrgContact === false && this.state.orgContactEmail === '') {
            this.setState({ clientErrorMessage: 'please add an org contact, or toggle to use the primary org contact', isSaving: false })
          } else if (this.state.description === '') {
            this.setState({ clientErrorMessage: 'please add a job description', isSaving: false })
          } else if (this.state.includeBasicInfo === false || this.state.includeResume === false) {
            this.setState({ clientErrorMessage: 'applications require including basic info and resumes now. Please toggle on.', isSaving: false })
          } else if (this.state.hoursPerWeek === '' && !this.state.disableSomeRequirements) {
            this.setState({ clientErrorMessage: 'please add the hours per week the candidate will work', isSaving: false })
          } else if (!this.state.preSetStartDate && this.state.startDate === '' && !this.state.disableSomeRequirements) {
            this.setState({ clientErrorMessage: 'please add the ideal start date', isSaving: false })
          } else if (!this.state.preSetStartDate && this.state.isPerpetual === false &&  this.state.endDate === '' && !this.state.disableSomeRequirements) {
            this.setState({ clientErrorMessage: 'please add the ideal end date or indicate that this is a perpetual role', isSaving: false })
          } else if (this.state.customAssessment.title === 'No Assessment Selected' && this.state.includeCustomAssessment === true) {
            this.setState({ clientErrorMessage: 'please either add a custom assessment template or toggle include custom assessment to off', isSaving: false })
          } else if (!this.props.path.includes('/employers/') && this.state.benchmark && (this.state.benchmark.title === 'No Benchmarks Saved' || this.state.benchmark.title === 'No Benchmark Added') && !this.state.disableSomeRequirements) {
            this.setState({ clientErrorMessage: 'please include a benchmark', isSaving: false })
          } else {
            // console.log('saving work post', this.state.industry)

            if (this.state.screeningQuestions && this.state.screeningQuestions.length > 0) {
              let screeningQuestions = this.state.screeningQuestions
              for (let j = 1; j <= screeningQuestions.length; j++) {

                if (!screeningQuestions[j - 1].name || screeningQuestions[j - 1].name === '') {
                  return this.setState({ clientErrorMessage: 'Please add a name for all screening questions', isSaving: false })
                } else if (screeningQuestions[j - 1].questionType === 'Multiple Choice' || screeningQuestions[j - 1].questionType === 'Checkbox' || screeningQuestions[j - 1].questionType === 'Ranking') {

                  // make sure this isn't a custom assessment
                  if (screeningQuestions[j - 1].answerChoices && screeningQuestions[j - 1].answerChoices.length > 0) {
                    // do nothing, this is a custom assessment
                  } else {
                    let screeningAnswerChoices = screeningQuestions[j - 1].screeningAnswerChoices
                    console.log('show screeningAnswerChoices pre-save: ', screeningAnswerChoices)
                    if (!screeningAnswerChoices || screeningAnswerChoices.length === 0 || screeningAnswerChoices.length === 1) {
                      return this.setState({ clientErrorMessage: 'Please add at least two answer choices', isSaving: false })
                    } else if (screeningQuestions[j - 1].screeningAnswerChoices && screeningQuestions[j - 1].screeningAnswerChoices.length > 0) {
                      for (let k = 1; k <= screeningAnswerChoices.length; k++) {
                        if (!screeningAnswerChoices[k - 1].name || screeningAnswerChoices[k - 1].name === '') {
                          return this.setState({ clientErrorMessage: 'Please add an answer choice to all your answer choices', isSaving: false })
                        } else if (!screeningAnswerChoices[k - 1].score || screeningAnswerChoices[k - 1].score === '') {
                          return this.setState({ clientErrorMessage: 'Please add a point value 1 - 5 to all your answer choices', isSaving: false })
                        }
                      }
                    }
                  }
                }
              }
            }

            const _id = this.state._id
            let postType = this.state.postType

            const title = this.state.title
            const location = this.state.location
            let field = this.state.function + " | " + this.state.selectedEmployer.employerIndustry
            const pathways = this.state.pathways
            const departments = this.state.departments
            const targetRoles = this.state.targetRoles
            const selectedPrograms = this.state.selectedPrograms

            let startDate = undefined
            if (this.state.preSetStartDate) {
              startDate = this.state.preSetStartDate
            } else if (this.state.startDate) {
              startDate = convertStringToDate(this.state.startDate,"dateOnly")
              console.log('compare startDates: ', startDate, this.state.startDate)
            }
            console.log('showStartDate: ', this.state.startDate, this.state.preSetStartDate)

            const isPerpetual = this.state.isPerpetual
            let endDate = undefined
            if (this.state.preSetEndDate) {
              endDate = this.state.preSetEndDate
            } else if (this.state.endDate) {
              endDate = convertStringToDate(this.state.endDate,"dateOnly")
            }

            const hoursPerWeek = this.state.hoursPerWeek
            const hireCount = this.state.hireCount
            const payType = this.state.payType
            const payRange = this.state.payRange
            let pay = this.state.pay
            if (payType === 'Custom') {
              pay = payRange
            }
            const description = this.state.description

            let accountCode = ''
            let employerName = this.state.orgName
            let employerType = ''
            let employerURL = ''
            let employerEmployees = ''
            let employerGrowth = ''
            let imageURL = null

            accountCode = this.state.selectedEmployer.accountCode
            employerName = this.state.selectedEmployer.employerName
            employerType = this.state.selectedEmployer.employerType
            employerURL = this.state.selectedEmployer.employerURL
            employerEmployees = this.state.selectedEmployer.employeeCount
            employerGrowth = this.state.selectedEmployer.employeeGrowth
            imageURL = this.state.selectedEmployer.employerLogoURI

            console.log('show me the account code', accountCode, this.state.selectedEmployer)
            let applicationComponents = ['basicInfo','resume']
            if (this.state.includeCoverLetter) {
              applicationComponents.push('coverLetter')
            }

            if (this.state.includeInterests) {
              applicationComponents.push('interests')
            }

            if (this.state.includeSkills) {
              applicationComponents.push('skills')
            }

            if (this.state.includeTraits) {
              applicationComponents.push('personality')
            }

            if (this.state.includeGoals) {
              applicationComponents.push('goals')
            }
            if (this.state.includeEndorsements) {
              applicationComponents.push('endorsements')
            }

            if (this.state.includeCustomAssessment) {
              applicationComponents.push('customAssessment')
            }

            const appComponents = this.state.appComponents

            let customAssessmentId = ''
            if ( this.state.includeCustomAssessment === true || this.state.appComponents[this.state.appComponents.length - 1].include) {
              for (let i = 1; i <= this.state.customAssessmentOptions.length; i++) {
                console.log(i)

                if (this.state.customAssessmentOptions[i - 1].title === this.state.customAssessment.title) {
                  customAssessmentId = this.state.customAssessmentOptions[i - 1]._id
                }
              }
            }

            let benchmarkId = ''
            let benchmark = this.state.benchmark
            let useCustomBenchmark = false

            console.log('show benchmark: ', this.state.benchmark)

            if (this.state.benchmark.title && this.state.benchmark.title !== 'No Benchmarks Saved') {
              console.log('in individual benchie', this.state.benchmark)
              for (let j = 1; j <= this.state.benchmarkOptions.length; j++) {
                console.log(j, this.state.benchmarkOptions[j - 1].title, this.state.benchmark.title)

                if (this.state.benchmarkOptions[j - 1].title === this.state.benchmark.title) {
                  console.log(j, 'we got a match!', this.state.benchmarkOptions[j - 1])
                  benchmarkId = this.state.benchmarkOptions[j - 1]._id
                  useCustomBenchmark = true
                  benchmark = this.state.benchmarkOptions[j - 1]
                  console.log(j, 'one more check', benchmarkId)
                }
              }

            } else {

              console.log('automatically attach benchmark')

              if (!benchmark) {
                if (this.state.benchmarkOptions) {
                  for (let j = 1; j <= this.state.benchmarkOptions.length; j++) {
                    console.log(j)

                    if (this.state.benchmarkOptions[j - 1].jobFunction === this.state.function) {
                      benchmarkId = this.state.benchmarkOptions[j - 1]._id
                      useCustomBenchmark = true
                      benchmark = this.state.benchmarkOptions[j - 1]
                    }
                  }
                }

                if (benchmarkId === '' && this.state.benchmarkOptions[1]) {
                  benchmarkId = this.state.benchmarkOptions[1]._id
                  benchmark = this.state.benchmarkOptions[1]
                }
              }
            }

            const orgTransactionNoti = this.state.orgTransactionNoti
            const usePrimaryEmployerContact = this.state.usePrimaryEmployerContact
            const employerContactFirstName = this.state.employerContactFirstName
            const employerContactLastName = this.state.employerContactLastName
            const employerContactEmail = this.state.employerContactEmail

            const employerContactPhone = this.state.employerContactPhone
            const usePrimaryOrgContact = this.state.usePrimaryOrgContact

            const orgContactFirstName = this.state.orgContactFirstName
            const orgContactLastName = this.state.orgContactLastName
            const orgContactEmail = this.state.orgContactEmail

            let applications = []
            let applicants = []
            if (this.state.selectedOpportunity) {
              applications = this.state.selectedOpportunity.applications
              applicants = this.state.selectedOpportunity.applicants
            }

            const orgCode = this.state.org
            let placementPartners = null
            if (window.location.pathname.includes('/employers/') && this.state.trainingProviders) {
              placementPartners = []
              for (let i = 1; i <= this.state.trainingProviders.length; i++) {
                if (this.state.trainingProviders[i - 1].orgCode !== orgCode) {
                  placementPartners.push(this.state.trainingProviders[i - 1].orgCode)
                }
              }
            }

            let orgName = ''
            if (this.state.orgName) {
              orgName = this.state.orgName
            }

            let orgURL = ''
            if (this.state.orgURL) {
              orgURL = this.state.orgURL
            }

            let orgMission = ''
            if (this.state.orgMission) {
              orgMission = this.state.orgMission
            }

            const isActive = this.state.isActive
            const isUnlisted = this.state.isUnlisted

            const featured = this.state.featured
            const isSubsidized = this.state.isSubsidized

            let submissionDeadline = undefined
            if (this.state.useNewSubmissionDeadline) {
              if (this.state.preSetSubmissionDeadline) {
                submissionDeadline = this.state.preSetSubmissionDeadline
              } else if (this.state.submissionDeadline) {
                submissionDeadline = new Date(this.state.submissionDeadline)
              }

            } else {
              submissionDeadline = convertStringToDate(this.state.submissionDeadline,"dateOnly")
            }

            if (this.state.selectedOpportunity && this.state.selectedOpportunity.submissionDeadline && !submissionDeadline && _id) {
              submissionDeadline = 'delete'
            }

            const appRequirements = this.state.appRequirements
            const applicationMethod = this.state.applicationMethod
            const subjectLine = this.state.subjectLine
            const jobLink = this.state.jobLink
            const referrerName = this.state.referrerName
            const referralExplanation = this.state.referralExplanation

            const supplementalPayArray = this.state.supplementalPayArray
            const benefits = this.state.benefits
            const zipcode = this.state.zipcode
            const prioritizeSubsidized = this.state.prioritizeSubsidized
            const prioritizeSchoolCredit = this.state.prioritizeSchoolCredit
            const hostUndocumented = this.state.hostUndocumented
            const competencyTags = this.state.competencyTags
            const candidatesOfInterest = this.state.candidatesOfInterest
            const taggedPostings = this.state.taggedPostings
            const applicationConfirmationSubjectLine = this.state.applicationConfirmationSubjectLine
            const applicationConfirmationMessage = this.state.applicationConfirmationMessage

            const includeInNotifications = this.state.includeInNotifications
            const typeTags = this.state.typeTags
            const educationTags = this.state.educationTags
            const adversityTags = this.state.adversityTags

            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const yearsOfExperience = this.state.yearsOfExperience
            const allowReferrals = this.state.allowReferrals
            const hideIdealCandidateProfile = this.state.hideIdealCandidateProfile
            const hideViewMatch = this.state.hideViewMatch

            const imageTitleURL = this.state.imageTitleURL
            const useImageTitle = this.state.useImageTitle
            const hideTextTitle = this.state.hideTextTitle

            let selectedProgram = null
            if (this.state.selectedProgram) {
              selectedProgram = {
                id: this.state.selectedProgram._id,
                title: this.state.selectedProgram.title,
                description: this.state.selectedProgram.description,
                location: this.state.selectedProgram.location,
                hoursPerWeek: this.state.selectedProgram.hoursPerWeek,
                selectedTrainer: this.state.selectedProgram.selectedTrainer,
                benchmarkId: this.state.selectedProgram.benchmarkId
              }
            }

            const isPromotional = this.state.isPromotional

            const createdAt = new Date()
            const updatedAt = new Date()

            // need to save benchmark and custom assessment
            const placeholderArray = ['']
            placeholderArray.forEach(async(item, index) => {

              let direct = false
              if (this.state.org === 'guidedcompass') {
                direct = true
              }

              if (this.state.org === 'guidedcompass') {
                let saveCustomAssessment = false
                if (this.state.screeningQuestions && this.state.screeningQuestions.length > 0) {
                  saveCustomAssessment = true
                } else if (this.state.customAssessmentId) {
                  // questions were deleted
                  saveCustomAssessment = true
                }
                if (saveCustomAssessment) {
                  // proceed to save
                  customAssessmentId = await this.saveCustomAssessment()
                  console.log('received CustomAssessmentId: ', customAssessmentId)
                }
              }

              if (window.location.pathname.includes('/employers')) {
                let saveCustomBenchmark = false
                if (benchmark['createNew']) {
                  saveCustomBenchmark = true
                }
                console.log('show the benchmark id: ', benchmarkId, saveCustomBenchmark)

                if (saveCustomBenchmark) {
                  // proceed to save

                  if (this.state.benchmarkId && !window.location.pathname.includes('/organizations') && !window.location.pathname.includes('/advisor')) {
                    benchmarkId = await this.saveCustomBenchmark(this.state.benchmarkId, benchmark)
                  } else {
                    benchmarkId = await this.saveCustomBenchmark(benchmarkId, benchmark)
                  }
                }
              }

              const posting = {
                _id, postType, title, location, field, pathways, departments,
                targetRoles, selectedPrograms, payType, payRange, pay, description,
                employerName, employerURL, employerType, employerEmployees, employerGrowth, imageURL,
                applicationComponents, appComponents,
                applications, applicants, orgCode, placementPartners,
                customAssessmentId, benchmarkId,
                hoursPerWeek, hireCount, startDate, isPerpetual, endDate,
                orgTransactionNoti, isActive, isUnlisted, featured, isSubsidized,
                submissionDeadline, appRequirements, applicationMethod, subjectLine, jobLink,
                accountCode, usePrimaryEmployerContact, employerContactFirstName, employerContactLastName,
                employerContactEmail, employerContactPhone, usePrimaryOrgContact,
                orgContactFirstName, orgContactLastName, orgContactEmail,
                orgName, orgURL, orgMission, referrerName, referralExplanation,
                supplementalPayArray, benefits, competencyTags, zipcode, prioritizeSubsidized, prioritizeSchoolCredit,
                hostUndocumented, direct, candidatesOfInterest, taggedPostings, applicationConfirmationSubjectLine, applicationConfirmationMessage,
                includeInNotifications, typeTags, educationTags, adversityTags, timeZone, yearsOfExperience,
                allowReferrals, hideIdealCandidateProfile, hideViewMatch,
                imageTitleURL, useImageTitle, hideTextTitle, selectedProgram, isPromotional,
                createdAt, updatedAt
              }

              this.finishPosting(posting, _id, useCustomBenchmark, title, employerName, orgName, orgContactEmail, updatedAt)

            })
          }
        } else if (this.state.postType === 'Project' || this.state.postType === 'Assignment' || this.state.postType === 'Problem' || this.state.postType === 'Challenge') {

          this.setState({ clientErrorMessage: '', serverErrorMessage: '' })

          if (!this.state.emailId || this.state.emailId === '') {
            if (this.state.path.includes('problem-platform')) {
              this.setState({ clientErrorMessage: 'please add your email', isSaving: false })
            } else {
              this.setState({ clientErrorMessage: 'you need to sign in to post', isSaving: false })
            }
          } else if (!this.state.cuFirstName || this.state.cuFirstName === '') {
            this.setState({ clientErrorMessage: 'please add your first name', isSaving: false })
          } else if (!this.state.cuLastName || this.state.cuLastName === '') {
            this.setState({ clientErrorMessage: 'please add your last name', isSaving: false })
          } else if (!this.state.roleName || this.state.roleName === '') {
            this.setState({ clientErrorMessage: 'please add your role name', isSaving: false })
          } else if (!this.state.projectPromptType || this.state.projectPromptType === '') {
            this.setState({ clientErrorMessage: 'please add the type of project this is', isSaving: false })
          } else if (!this.state.projectName || this.state.projectName === '') {
            this.setState({ clientErrorMessage: 'please add a name', isSaving: false })
          } else if ((this.state.postType === 'Assignment' || this.state.projectPromptType === 'Assignment') && (!this.state.duration || this.state.duration === '')) {
            this.setState({ clientErrorMessage: 'please add the duration (estimated man hours to complete)', isSaving: false })
          } else if (this.state.isExternal !== 'true' && this.state.description === '' && !this.state.showRich) {
            this.setState({ clientErrorMessage: 'please add a description', isSaving: false })
          } else if (this.state.isExternal === 'true' && !this.state.source) {
            this.setState({ clientErrorMessage: 'please add an external link to the source', isSaving: false })
          } else {

            if ((this.state.postType === 'Project' && this.state.projectPromptType !== 'Problem') || (this.state.postType !== 'Problem' && !this.state.projectPromptType)) {
              if (!this.state.submissionDeadline || this.state.submissionDeadline === '') {
                return this.setState({ clientErrorMessage: 'please add a submission deadline', isSaving: false })
              }
            }

            if ((this.state.postType === 'Project' && this.state.projectPromptType !== 'Assignment') || (this.state.postType !== 'Assignment' && !this.state.projectPromptType)) {
              if (this.state.jobTitle === '') {
                return this.setState({ clientErrorMessage: 'please add your job title', isSaving: false })
              } else if (this.state.employerName === '') {
                return this.setState({ clientErrorMessage: 'please add the name of your employer', isSaving: false })
              } else if (this.state.employerURL === '') {
                return this.setState({ clientErrorMessage: 'please add your employer URL', isSaving: false })
              } else if (this.state.difficultyLevel === '') {
                return this.setState({ clientErrorMessage: 'please add the difficulty level of this problem', isSaving: false })
              } else if (this.state.industry === '') {
                return this.setState({ clientErrorMessage: 'please add the industry', isSaving: false })
              } else if (this.state.functions.length === 0) {
                return this.setState({ clientErrorMessage: 'please tag some functions that the problem applies to', isSaving: false })
              } else if (this.state.summary === '') {
                return this.setState({ clientErrorMessage: 'please add a summary of the problem', isSaving: false })
              }
            }

            if ((this.state.postType === 'Challenge' || this.state.projectPromptType === 'Challenge')) {
              if (!this.state.announcementDate || this.state.announcementDate === '') {
                return this.setState({ clientErrorMessage: 'please add an announcement date', isSaving: false })
              } else if (!this.state.prizes || this.state.prizes[0] === '') {
                return this.setState({ clientErrorMessage: 'please add at least one prize', isSaving: false })
              } else if (!this.state.rules || this.state.rules === '') {
                return this.setState({ clientErrorMessage: 'please add rules to the challenge', isSaving: false })
              }
            }

            const _id = this.state._id
            const postType = this.state.postType
            const projectPromptType = this.state.projectPromptType
            const name = this.state.projectName

            let submissionDeadline = undefined
            if (this.state.submissionDeadline) {
              submissionDeadline = new Date(this.state.submissionDeadline)
            }

            const videoLink = this.state.videoLink
            const description = this.state.description

            // testing conversion for rich text
            let richDescription = null
            if (this.state.org === 'c2c') {
              richDescription = this.child.current.convertEditorState()
              console.log('show rawJSON: ', richDescription)
              if (richDescription.blocks && richDescription.blocks.length > 0) {
                richDescription = richDescription.blocks
              }
            }

            const isExternal = this.state.isExternal
            const source = this.state.source

            const guidelines = this.state.guidelines
            const resources = this.state.resources
            let assignees = this.state.assignees
            console.log('show assigneeGroup 1: ', this.state.assigneeGroup)
            if (this.state.assigneeGroup && this.state.assigneeGroup.includes('Workers')) {
              console.log('show assigneeGroup 2: ', this.state.assigneeGroup)
              // loop through workArray, take unique workers, add them to assignees
              assignees = []
              for (let i = 1; i <= this.state.workArray.length; i++) {
                //assuming all workers are unique
                console.log('show assigneeGroup 3: ', assignees)
                if (this.state.workArray[i - 1].workers) {
                  console.log('show assigneeGroup 4: ', assignees)
                  assignees = assignees.concat(this.state.workArray[i - 1].workers)
                }
              }
            }
            console.log('show assigneeGroup 5: ', assignees)

            const assigneeGroup = this.state.assigneeGroup
            const isExternalPost = this.state.isExternalPost
            const duration = this.state.duration

            const employerName = this.state.employerName
            const employerURL = this.state.employerURL
            const employerType = this.state.employerType
            const employerSize = this.state.employerSize
            const imageURL = this.state.employerLogoURL

            const difficultyLevel = this.state.difficultyLevel
            const knowledgeLevel = this.state.knowledgeLevel
            const industry = this.state.industry
            const functions = this.state.functions
            const status = this.state.status

            let contributorFirstName = this.state.cuFirstName
            let contributorLastName = this.state.cuLastName
            let contributorEmail = this.state.emailId
            let contributorTitle = this.state.jobTitle
            let contributorRoleName = this.state.roleName

            let orgCode = this.state.org
            let placementPartners = null
            if (window.location.pathname.includes('/employers/') && this.state.trainingProviders) {
              placementPartners = []
              for (let i = 1; i <= this.state.trainingProviders.length; i++) {
                if (this.state.trainingProviders[i - 1].orgCode !== orgCode) {
                  placementPartners.push(this.state.trainingProviders[i - 1].orgCode)
                }
              }
            }

            let orgName = this.state.orgName
            let requestMode = this.state.requestMode

            let autoNotify = false
            if (this.state.assigneeGroup) {
              if (this.state.assigneeGroup === 'Specific Individuals' || this.state.assigneeGroup.includes('Workers')) {
                requestMode = false
                autoNotify = true
              }
            }

            let orgContactFirstName = this.state.orgContactFirstName
            let orgContactLastName = this.state.orgContactLastName
            let orgContactEmail = this.state.orgContactEmail

            if (this.state.selectedOpportunity) {
              contributorFirstName = this.state.selectedOpportunity.contributorFirstName
              contributorLastName = this.state.selectedOpportunity.contributorLastName
              contributorEmail = this.state.selectedOpportunity.contributorEmail

              if (this.state.jobTitle && this.state.jobTitle !== '') {
                contributorTitle = this.state.jobTitle
              } else {
                contributorTitle = this.state.selectedOpportunity.contributorTitle
              }

              contributorRoleName = this.state.selectedOpportunity.contributorRoleName

              orgCode = this.state.selectedOpportunity.orgCode
              orgName = this.state.selectedOpportunity.orgName
              requestMode = this.state.selectedOpportunity.requestMode

              orgContactFirstName = this.state.selectedOpportunity.orgContactFirstName
              orgContactLastName = this.state.selectedOpportunity.orgContactLastName
              orgContactEmail = this.state.selectedOpportunity.orgContactEmail
            } else if (!orgCode || orgCode === ''){
              //set defaul orgCode to Guided Compass
              orgCode = 'guidedcompass'
              orgName = 'Guided Compass'
            }

            //tags, isExternal, source
            let tags = this.state.tags
            if (tags) {
              tags = tags.toLowerCase().trim()
            }

            const upvotes = []
            const downvotes = []
            const featured = this.state.featured

            const accountCode = this.state.accountCode

            const isActive = this.state.isActive
            const isUnlisted = this.state.isUnlisted
            const attachToCourses = this.state.attachToCourses

            let courses = undefined
            if (this.state.selectedCourses && this.state.selectedCourses.length > 0) {
              courses = []
              for (let i = 1; i <= this.state.selectedCourses.length; i++) {
                courses.push({
                  _id: this.state.selectedCourses[i - 1]._id,
                  name: this.state.selectedCourses[i - 1].name
                })
              }
            }

            const type = this.state.problemType
            const summary = this.state.summary
            const employerInfo = this.state.employerInfo
            const solution = this.state.solution
            const sections = this.state.sections
            const exhibits = this.state.exhibits
            const background = this.state.background
            const lessons = this.state.lessons
            console.log('show description: ', description)

            let pointValue = 0
            if (difficultyLevel === 'Very Easy') {
              pointValue = 1
            } else if (difficultyLevel === 'Easy') {
              pointValue = 3
            } else if (difficultyLevel === 'Medium') {
              pointValue = 4
            } else if (difficultyLevel === 'Hard') {
              pointValue = 7
            } else if (difficultyLevel === 'Challenger') {
              pointValue = 10
            } else {
              pointValue = 5
            }

            const disableDownvoting = this.state.disableDownvoting
            const emailId = this.state.emailId

            let announcementDate = undefined
            if (this.state.announcementDate) {
              announcementDate = new Date(this.state.announcementDate)
            }

            const evaluationMethod = this.state.evaluationMethod
            const prizes = this.state.prizes
            const rules = this.state.rules
            let startDate = undefined
            if (this.state.startDate) {
              startDate = new Date(this.state.startDate)
            }
            const includeVotingRound = this.state.includeVotingRound
            const includeJudgingRound = this.state.includeJudgingRound
            const announcementMethod = this.state.announcementMethod
            const location = this.state.location
            const voteRequirement = this.state.voteRequirement

            const projectConfirmationSubjectLine = this.state.projectConfirmationSubjectLine
            const projectConfirmationMessage = this.state.projectConfirmationMessage

            const referralSource = this.state.referralSource
            const pathways = this.state.pathways
            const departments = this.state.departments
            const targetRoles = this.state.targetRoles
            const selectedPrograms = this.state.selectedPrograms

            const includeInNotifications = this.state.includeInNotifications
            const typeTags = this.state.typeTags
            const educationTags = this.state.educationTags
            const adversityTags = this.state.adversityTags

            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            console.log('show departments for ass: ', this.state.departments)
            const createdAt = new Date()
            const updatedAt = new Date()

            let postURL = '/api/project-prompt'

            Axios.post(postURL, {
              _id, postType, projectPromptType, type, name, submissionDeadline, videoLink,
              summary, description, richDescription, isExternal, source,
              employerInfo, solution, sections, exhibits, background, lessons,
              pointValue, disableDownvoting, emailId,
              guidelines, resources, assigneeGroup, assignees, isExternalPost, autoNotify,
              announcementDate, evaluationMethod, prizes, rules, startDate, includeVotingRound,
              includeJudgingRound, announcementMethod, location, voteRequirement,
              employerName, employerURL, employerType, employerSize, imageURL,
              difficultyLevel, knowledgeLevel, industry, functions,
              contributorFirstName, contributorLastName, contributorEmail, contributorTitle, contributorRoleName,
              accountCode, orgCode, placementPartners,
              orgName, orgContactFirstName, orgContactLastName, orgContactEmail, requestMode,
              status, tags, featured, upvotes, downvotes, duration,
              projectConfirmationSubjectLine, projectConfirmationMessage,
              isActive, isUnlisted, attachToCourses, courses, referralSource, pathways, departments, targetRoles, selectedPrograms,
              includeInNotifications, typeTags, educationTags, adversityTags, timeZone,
              createdAt, updatedAt })
            .then((response) => {

              if (response.data.success) {
                //save values
                console.log('Project save worked', response.data);

                const serverPostSuccess = true
                let serverSuccessMessage = 'Project saved successfully!'
                if (this.state.selectedOpportunity) {
                  serverSuccessMessage = 'Project updated successfully!'
                  this.setState({ serverPostSuccess, serverSuccessMessage,isSaving: false })
                } else {
                  if (requestMode) {
                    serverSuccessMessage = 'Project request successfully sent!'
                  }

                  // empty fields after save

                  this.setState({ serverPostSuccess, serverSuccessMessage, isSaving: false })
                }

                if (this.props.duplicate && this.props.navigate.goBack) {
                  this.props.navigate(-1)
                }

              } else {
                console.error('there was an error posting the project');
                this.setState({
                  serverPostSuccess: false,
                  serverErrorMessage: response.data.message, isSaving: false
                })
              }
            }).catch((error) => {
                console.log('The project post did not work', error);
                this.setState({ serverPostSuccess: false, serverErrorMessage: error, isSaving: false })
            });
          }

        } else if (this.state.postType === 'Event') {
          if (this.state.eventType === '') {
            this.setState({ clientErrorMessage: 'please add an event type', isSaving: false })
          } else if (this.state.title === '') {
            this.setState({ clientErrorMessage: 'please add an event title', isSaving: false })
          } else if (this.state.description === '') {
            this.setState({ clientErrorMessage: 'please add a description', isSaving: false })
          } else if (this.state.startDate === '') {
            this.setState({ clientErrorMessage: 'please add a start date', isSaving: false })
          } else if (this.state.endDate === '') {
            this.setState({ clientErrorMessage: 'please add an end date', isSaving: false })
          } else if (this.state.willEmployerLead === 'One Employer Will Lead' && this.state.selectedEmployer.employerName === null) {
            this.setState({ clientErrorMessage: 'please select an employer', isSaving: false })
          } else if (this.state.willEmployerLead === 'One Employer Will Lead' && this.state.employerContactFirstName === '') {
            this.setState({ clientErrorMessage: 'please add an employer contact first name', isSaving: false })
          } else if (this.state.willEmployerLead === 'One Employer Will Lead' && this.state.employerContactLastName === '') {
            this.setState({ clientErrorMessage: 'please add an employer contact last name', isSaving: false })
          } else if (this.state.willEmployerLead === 'One Employer Will Lead' && this.state.employerContactEmail === '') {
            this.setState({ clientErrorMessage: 'please add an employer contact email', isSaving: false })
          } else if (!this.state.locationType || this.state.locationType === '') {
            this.setState({ clientErrorMessage: 'please specify a location type', isSaving: false })
          } else if (!this.state.location || this.state.location === '') {
            this.setState({ clientErrorMessage: 'please add an event location', isSaving: false })
          } else if (this.state.selectedAssessment._id === null) {
            this.setState({ clientErrorMessage: 'please add a survey', isSaving: false })
          } else if (!this.state.orgContactFirstName || this.state.orgContactFirstName === '') {
            this.setState({ clientErrorMessage: 'please add a school/org contact first name', isSaving: false })
          } else if (!this.state.orgContactLastName || this.state.orgContactLastName === '') {
            this.setState({ clientErrorMessage: 'please add a school/org contact last name', isSaving: false })
          } else if (!this.state.orgContactEmail || this.state.orgContactEmail === '') {
            this.setState({ clientErrorMessage: 'please add a school/org contact email', isSaving: false })
          } else if (this.state.org === 'unite-la' && (!this.state.targetRoles || this.state.targetRoles.length === 0 || (this.state.targetRoles && this.state.targetRoles.includes('Career-Seekers' ))) && (!this.state.customAssessment || (this.state.customAssessment && this.state.customAssessment.title === 'No Assessment Selected'))) {
            this.setState({ clientErrorMessage: 'All UNITE-LA events require attaching the demographics custom assessment', isSaving: false })
          } else {

            const _id = this.state._id
            const postType = this.state.postType
            const eventType = this.state.eventType
            const title = this.state.title
            const summary = this.state.summary
            const description = this.state.description

            let startDate = undefined
            if (this.state.startDate) {
              startDate = new Date(this.state.startDate)
            }

            let endDate = undefined
            if (this.state.endDate) {
              endDate = new Date(this.state.endDate)
            }
            console.log('show start date: ', startDate, this.state.startDate, typeof this.state.startDate)

            const willEmployerLead = this.state.willEmployerLead
            let accountCode = this.state.accountCode
            if (this.state.selectedEmployer.accountCode) {
              accountCode = this.state.selectedEmployer.accountCode
            }

            let employerName = this.state.employerName
            if (this.state.selectedEmployer.employerName && this.state.selectedEmployer.employerName !== 'No Employer Selected') {
              employerName = this.state.selectedEmployer.employerName
            }

            const employerContactFirstName = this.state.employerContactFirstName
            const employerContactLastName = this.state.employerContactLastName
            const employerContactEmail = this.state.employerContactEmail
            const imageURL = this.state.employerLogoURL
            const locationType = this.state.locationType
            const transportationDetails = this.state.transportationDetails
            const links = this.state.links
            const industry = this.state.industry
            const functions = this.state.functions
            const surveyId = '5e41f8bcd03e055d7c6143ae'

            const location = this.state.location

            let orgCode = this.state.org
            let placementPartners = null
            if (window.location.pathname.includes('/employers/') && this.state.trainingProviders) {
              placementPartners = []
              for (let i = 1; i <= this.state.trainingProviders.length; i++) {
                if (this.state.trainingProviders[i - 1].orgCode !== orgCode) {
                  placementPartners.push(this.state.trainingProviders[i - 1].orgCode)
                }
              }
            }

            let posterEmail = this.state.emailId

            const featured = this.state.featured
            const status = this.state.status

            let contributorFirstName = this.state.cuFirstName
            let contributorLastName = this.state.cuLastName
            let contributorEmail = this.state.emailId
            let contributorTitle = this.state.jobTitle
            let contributorRoleName = this.state.roleName
            let requestMode = this.state.requestMode
            let orgContactFirstName = this.state.orgContactFirstName
            let orgContactLastName = this.state.orgContactLastName
            let orgContactEmail = this.state.orgContactEmail
            let orgName = this.state.orgName
            if (this.state.selectedOpportunity) {
              orgCode = this.state.selectedOpportunity.orgCode
              posterEmail = this.state.selectedOpportunity.posterEmail
              contributorFirstName = this.state.selectedOpportunity.contributorFirstName
              contributorLastName = this.state.selectedOpportunity.contributorLastName
              contributorEmail = this.state.selectedOpportunity.contributorEmail
              contributorTitle = this.state.selectedOpportunity.contributorTItle
              contributorRoleName = this.state.selectedOpportunity.contributorRoleName
              requestMode = this.state.selectedOpportunity.requestMode
              // orgContactFirstName = this.state.selectedOpportunity.orgContactFirstName
              // orgContactLastName = this.state.selectedOpportunity.orgContactLastName
              // orgContactEmail = this.state.selectedOpportunity.orgContactEmail
              orgName = this.state.selectedOpportunity.orgName
            }


            const pathways = this.state.pathways
            const departments = this.state.departments
            const targetRoles = this.state.targetRoles
            const selectedPrograms = this.state.selectedPrograms

            const isActive = this.state.isActive
            const isUnlisted = this.state.isUnlisted
            const rsvpComponents = this.state.rsvpComponents
            const candidatesOfInterest = this.state.candidatesOfInterest

            let customAssessmentId = ''
            if ( this.state.rsvpComponents && this.state.rsvpComponents[this.state.rsvpComponents.length - 1].include) {
              for (let i = 1; i <= this.state.customAssessmentOptions.length; i++) {
                // console.log(i)

                if (this.state.customAssessmentOptions[i - 1].title === this.state.customAssessment.title) {
                  customAssessmentId = this.state.customAssessmentOptions[i - 1]._id
                }
              }
            }

            let sendReminderText = this.state.sendReminderText
            let reminderTime = this.state.reminderTime
            let reminderDate = null
            let reminderText = null
            if (sendReminderText) {
              console.log('show startDate: ', startDate, new Date(this.state.startDate))
              if (this.state.reminderTime === 'At time of event') {
                reminderDate = new Date(startDate.getFullYear(),startDate.getMonth(),startDate.getDate(),startDate.getHours(),startDate.getMinutes(),0,0)
              } else if (this.state.reminderTime === '5 minutes before') {
                reminderDate = new Date(startDate.getFullYear(),startDate.getMonth(),startDate.getDate(),startDate.getHours(),startDate.getMinutes() - 5,0,0)
              } else if (this.state.reminderTime === '10 minutes before') {
                reminderDate = new Date(startDate.getFullYear(),startDate.getMonth(),startDate.getDate(),startDate.getHours(),startDate.getMinutes() - 10,0,0)
              } else if (this.state.reminderTime === '15 minutes before') {
                reminderDate = new Date(startDate.getFullYear(),startDate.getMonth(),startDate.getDate(),startDate.getHours(),startDate.getMinutes() - 15,0,0)
              } else if (this.state.reminderTime === '30 minutes before') {
                reminderDate = new Date(startDate.getFullYear(),startDate.getMonth(),startDate.getDate(),startDate.getHours(),startDate.getMinutes() - 30,0,0)
              } else if (this.state.reminderTime === '1 hour before') {
                reminderDate = new Date(startDate.getFullYear(),startDate.getMonth(),startDate.getDate(),startDate.getHours() - 1,startDate.getMinutes(),0,0)
              } else if (this.state.reminderTime === '2 hours before') {
                reminderDate = new Date(startDate.getFullYear(),startDate.getMonth(),startDate.getDate(),startDate.getHours() - 2,startDate.getMinutes(),0,0)
              }
              // console.log('show reminderDate: ', reminderDate)
              // reminderText = "This is a reminder text that the " + this.state.title + " event is coming up at " + convertDateToString(this.state.startDate,'last5')+ ". Don't forget to attend! To unsubscribe from text reminders, click here: https://www.guidedcompass.com/notification-preferences/" + this.state.org + "."
              reminderText = "This is a reminder text that the " + this.state.title + " event is coming up at " + convertDateToString(this.state.startDate,'last5')+ ". Don't forget to attend! Text STOP to unsubscribe from these automated text reminders."
            }

            const emailId = this.state.emailId

            const careerSeekerLimit = this.state.careerSeekerLimit
            const mentorLimit = this.state.mentorLimit

            const rsvpConfirmationSubjectLine = this.state.rsvpConfirmationSubjectLine
            const rsvpConfirmationMessage = this.state.rsvpConfirmationMessage

            const includeInNotifications = this.state.includeInNotifications
            const typeTags = this.state.typeTags
            const educationTags = this.state.educationTags
            const adversityTags = this.state.adversityTags

            const imageTitleURL = this.state.imageTitleURL
            const useImageTitle = this.state.useImageTitle
            const hideTextTitle = this.state.hideTextTitle

            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            const createdAt = new Date()
            const updatedAt = new Date()

            Axios.post('/api/postings', {
              _id, postType, eventType, title, summary, description, startDate, endDate, location, surveyId,
              willEmployerLead, accountCode, employerName, employerContactFirstName, employerContactLastName,
              employerContactEmail, imageURL, locationType, transportationDetails, links, industry, functions,
              orgCode, placementPartners, posterEmail, contributorFirstName, contributorLastName, contributorEmail, contributorTitle,
              featured, sendReminderText, reminderTime, reminderDate, reminderText,
              orgContactFirstName, orgContactLastName, orgContactEmail, orgName, contributorRoleName,
              requestMode, status, emailId, pathways, departments, targetRoles, selectedPrograms,
              isActive, isUnlisted, rsvpComponents, candidatesOfInterest,
              customAssessmentId, careerSeekerLimit, mentorLimit,
              rsvpConfirmationSubjectLine, rsvpConfirmationMessage,
              includeInNotifications, typeTags, educationTags, adversityTags, timeZone,
              imageTitleURL, useImageTitle, hideTextTitle,
              createdAt, updatedAt })
            .then((response) => {

              if (response.data.success) {
                //save values
                console.log('Post save worked', response.data);

                const serverPostSuccess = true
                let serverSuccessMessage = 'Event saved successfully!'
                if (_id) {
                  serverSuccessMessage = 'Event updated successfully!'
                }

                this.setState({ serverPostSuccess, serverSuccessMessage, isSaving: false })

                if (this.props.duplicate && this.props.navigate.goBack) {
                  this.props.navigate(-1)
                }

              } else {
                console.error('there was an error posting the event');
                this.setState({
                  serverPostSuccess: false,
                  serverErrorMessage: response.data.message, isSaving: false
                })
              }
            }).catch((error) => {
                console.log('The talk post did not work', error);
            });
          }
        }
      }
    }

    deletePost(sendNotification) {
      console.log('deletePost')

      const _id = this.state.selectedOpportunity._id
      const posting = this.state.selectedOpportunity

      Axios.delete('/api/postings/' + _id, { data: { posting, sendNotification }})
      .then((response) => {
        console.log('tried to delete', response.data)
        if (response.data.success) {
          //save values
          console.log('Post delete worked');

          this.props.navigate(-1)
          // this.setState({
          //   serverPostSuccess: true,
          //   serverSuccessMessage: 'Posting was deleted successfully! Once you exit, you will no longer have access to the posting.', confirmDelete: false
          // })

        } else {
          console.error('there was an error deleting the posting');
          this.setState({
            serverPostSuccess: false,
            serverErrorMessage: response.data.message,
          })
        }
      }).catch((error) => {
          console.log('The deleting did not work', error);
          this.setState({
            serverPostSuccess: false,
            serverErrorMessage: error,
          })
      });
    }

    saveCustomAssessment = async() => {
      console.log('saveCustomAssessment called ', this.state.customAssessmentId)

      let customAssessment = {}
      if (this.state.customAssessmentId) {
        customAssessment['_id'] = this.state.customAssessmentId
      } else {
        // save new
        customAssessment['type'] = 'Screening'
        customAssessment['accountCode'] = this.state.accountCode
        customAssessment['orgCode'] = this.state.org
        customAssessment['createdAt'] = new Date()
      }

      customAssessment['title'] = this.state.title
      customAssessment['questions'] = this.state.screeningQuestions
      customAssessment['updatedAt'] = new Date()

      return Axios.post('/api/customassessments', customAssessment)
      .then((response) => {

        if (response.data.success) {
          //save values
          console.log('Custom assessment save worked', response.data);

          this.setState({ serverSuccessMessage: 'Saved Custom Assessment!' })

          const customAssessmentId = response.data._id
          return customAssessmentId

        } else {
          console.error('there was an error posting the custom assessment');
          this.setState({ errorMessage: response.data.message })
          return false
        }
      }).catch((error) => {
          console.log('The custom assessment save did not work', error);
          return false
      });
    }

    saveCustomBenchmark = async(benchmarkId, passedBenchmark) => {
      console.log('saveCustomBenchmark ', benchmarkId, passedBenchmark)

      let benchmark = this.state.benchmark
      if (this.state.benchmarkId && this.state.ogBenchmark && !window.location.pathname.includes('/organizations') && !window.location.pathname.includes('/advisor')) {
        // edit existing benchmark
        benchmark = this.state.ogBenchmark
      } else if (passedBenchmark) {
        console.log('something went wrong with the benchmark')
        // benchmark = passedBenchmark
      }

      benchmark['title'] = this.state.title
      benchmark['jobTitle'] = this.state.title
      benchmark['jobType'] = this.state.subPostType
      benchmark['jobFunction'] = this.state.function
      benchmark['industry'] = this.state.industry
      benchmark['proximityRequirements'] = this.state.location
      benchmark['location'] = this.state.zipcode
      benchmark['payType'] = this.state.payType
      benchmark['payRange'] = this.state.payRange
      benchmark['maxPay'] = this.state.payRange
      benchmark['accountCode'] = this.state.accountCode
      benchmark['orgCode'] = this.state.org
      console.log('t0 weird includes error')
      // account for competency tags
      const competencyTags = this.state.competencyTags
      let skills = []
      let lowPriorityHardSkills = []
      let lowPrioritySoftSkills = []
      let abilities = []
      let knowledge = []
      console.log('t1 weird includes error')

      if (competencyTags) {
        console.log('t2 weird includes error', competencyTags)
        for (let i = 1; i <= competencyTags.length; i++) {
          const title = competencyTags[i - 1].name
          const category = competencyTags[i - 1].category
          const description = competencyTags[i - 1].description
          const score = competencyTags[i - 1].score
          const weight = competencyTags[i - 1].weight
          console.log('t3 weird includes error', competencyTags[i - 1])
          if (competencyTags[i - 1] && competencyTags[i - 1].category && competencyTags[i - 1].category.includes('Skill')) {
            console.log('t4 weird includes error', competencyTags[i - 1])
            skills.push({ title, skillType: category, description, score, weight })
            if (category.includes('Soft')) {
              lowPrioritySoftSkills.push(title)
            } else {
              lowPriorityHardSkills.push(title)
            }
          } else if (competencyTags[i - 1] && competencyTags[i - 1].category && competencyTags[i - 1].category.includes('Ability')) {
            // ability
            abilities.push({ title, category, description, score, weight })
          } else if (competencyTags[i - 1] && competencyTags[i - 1].category && competencyTags[i - 1].category.includes('Knowledge')) {
            // knowledge
            knowledge.push({ title, category, description, score, weight })
          }

        }
      }

      benchmark['skills'] = skills
      benchmark['lowPrioritySoftSkills'] = lowPrioritySoftSkills
      benchmark['lowPriorityHardSkills'] = lowPriorityHardSkills
      benchmark['abilities'] = abilities
      benchmark['knowledge'] = knowledge
      benchmark['createdAt'] = new Date()
      benchmark['updatedAt'] = new Date()

      console.log('show skills before save: ', skills)

      return Axios.post('/api/benchmarks', benchmark)
      .then((response) => {

        if (response.data.success) {
          //save values
          console.log('Benchmark save worked', response.data);

          this.setState({ serverSuccessMessage: 'Saved benchmark!' })

          const benchmarkId = response.data._id
          return benchmarkId

        } else {
          console.error('there was an error posting the benchmark');
          this.setState({ errorMessage: response.data.message })
          return false
        }
      }).catch((error) => {
          console.log('The benchmark save did not work', error);
          return false
      });
    }

    renderApplications() {
      console.log('renderApplications called')

      if (this.state.applications) {

        let rows = []

        let arrayLength = 0
        if (this.state.applications.length > 3) {
          arrayLength = 3
        } else {
          arrayLength = this.state.applications.length
          // console.log('show applications ', this.state.applications, this.state.applications.length, arrayLength)
        }
        console.log('benchmark before nav: ', this.state.benchmark)
        for (let i = 1; i <= arrayLength; i++) {
          console.log(i, 'testers');

          let applicationDetailLink = '/organizations/' + this.state.org + '/postings/' + this.state._id + '/applications/' + this.state.applications[i - 1]._id
          if (this.state.org === 'guidedcompass' && window.location.pathname.includes('/employers')) {
            applicationDetailLink = '/employers/' + this.state.accountCode + '/postings/' + this.state._id + '/applications/' + this.state.applications[i - 1]._id
          } else if (window.location.pathname.includes('/advisor')) {
            applicationDetailLink = '/advisor/postings/' + this.state._id + '/applications/' + this.state.applications[i - 1]._id
          }

          const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primaryColor');

          rows.push(
            <Link className="secret-link" to={applicationDetailLink} state={{ selectedJob: this.state.selectedOpportunity, selectedApplication: this.state.applications[i - 1], benchmark: this.state.benchmark, benchmarkOptions: this.state.benchmarkOptions }}>
              <div className="column-1-of-3">
                {(this.state.postType === 'Individual') ? (
                  <div className="width-120 center-item">
                    <CircularProgressbar
                      value={this.state.applications[i - 1].match}
                      text={`${this.state.applications[i - 1].match}%`}
                      styles={{
                        path: { stroke: `${primaryColor}, ${this.state.applications[i - 1].match / 100}` },
                        text: { fill: primaryColor, fontSize: '24px' },
                        trail: { stroke: 'transparent' }
                      }}
                    />
                  </div>
                ) : (
                  <div className="width-120 center-item">
                    <img src={(this.state.applications[i - 1].pictureURL) ? this.state.applications[i - 1].pictureURL : profileIconGrey} alt="Compass profile icon icon" className="profile-image-medium" />
                  </div>
                )}
                <p>{this.state.applications[i - 1].firstName} {this.state.applications[i - 1].lastName}</p>
                <p className="profile-descriptor">View Application</p>
              </div>
            </Link>
          )
        }

        return rows
      }
    }

    renderTags(type) {
      console.log('renderTags called', type)

      if (type === 'functions') {
        let rows = []

        for (let i = 1; i <= this.state.functionOptions.length; i++) {

          const functionIndex = i - 1

          if (this.state.functionOptions[i - 1] !== '') {
            rows.push(
              <div key={i} className="float-left right-padding-5">
                <a onClick={() => this.buttonClicked(this.state.functionOptions[functionIndex])} className="background-link">
                  <div className={(this.state.functions.includes(this.state.functionOptions[i - 1])) ? "selected-tag-container-2 description-text-2 row-5" : "unselected-tag-container-2 description-text-2 row-5 standard-color"}>
                    <p className="white-text">{this.state.functionOptions[i - 1]}</p>
                  </div>
                </a>
                <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
              </div>
            )
          }
        }
        return rows
      } else if (type === 'competency') {

        let competencyTags = []
        if (this.state.competencyTags) {
          competencyTags = this.state.competencyTags
        }

        return (
          <div key="tags">
            {competencyTags.map((value, optionIndex) =>
              <div key={"tag" + optionIndex} className="float-left">
                <div className="close-button-container-1" >
                  <button className="background-button" onClick={() => this.removeTag(optionIndex, type)}>
                    <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                  </button>
                </div>
                <div>
                  <div className="half-spacer" />
                  {(value.category === 'Ability') && (
                    <div className="tag-container-8 primary-background primary-border white-text">
                      <p className="description-text-2">{value.name}{(value.description) && " (" + value.description + ")"}</p>
                    </div>
                  )}
                  {(value.category === 'Knowledge') && (
                    <div className="tag-container-8 secondary-background secondary-border white-text">
                      <p className="description-text-2">{value.name}{(value.description) && " (" + value.description + ")"}</p>
                    </div>
                  )}
                  {(value.category === 'Soft Skill') && (
                    <div className="tag-container-8 tertiary-background tertiary-border white-text">
                      <p className="description-text-2">{value.name}{(value.description) && " (" + value.description + ")"}</p>
                    </div>
                  )}
                  {(value.category === 'Hard Skill') && (
                    <div className="tag-container-8 quaternary-background quaternary-border white-text">
                      <p className="description-text-2">{value.name}{(value.description) && " (" + value.description + ")"}</p>
                    </div>
                  )}
                  <div className="half-spacer" />
                </div>
              </div>
            )}
          </div>
        )
      } else if (type === 'supplementalPay') {
        let supplementalPayArray = []
        if (this.state.supplementalPayArray) {
          supplementalPayArray = this.state.supplementalPayArray
        }

        return (
          <div key="tags">
            {supplementalPayArray.map((value, optionIndex) =>
              <div key={"tag" + optionIndex} className="float-left">
                <div className="close-button-container-1" >
                  <button className="background-button" onClick={() => this.removeTag(type, optionIndex)}>
                    <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                  </button>
                </div>
                <div>
                  <div className="half-spacer" />
                  <div className="tag-container-8 quaternary-background quaternary-border white-text">
                    <p className="description-text-2">{value}</p>
                  </div>
                  <div className="half-spacer" />
                </div>
              </div>
            )}
          </div>
        )
      } else if (type === 'benefit') {
        let benefits = []
        if (this.state.benefits) {
          benefits = this.state.benefits
        }

        return (
          <div key="tags">
            {benefits.map((value, optionIndex) =>
              <div key={"tag" + optionIndex} className="float-left">
                <div className="close-button-container-1" >
                  <button className="background-button" onClick={() => this.removeTag(optionIndex, type)}>
                    <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                  </button>
                </div>
                <div>
                  <div className="half-spacer" />
                  <div className="tag-container-8 tertiary-background tertiary-border white-text">
                    <p className="description-text-2">{value}</p>
                  </div>
                  <div className="half-spacer" />
                </div>
              </div>
            )}
          </div>
        )
      }
    }

    addTag(type, item) {
      console.log('addTag called')

      this.setState({ errorMessage: null, successMessage: null })

      if (type === 'competency') {

        let competencyTags = this.state.competencyTags
        let tagName = this.state.tagName
        let tagType = this.state.tagType

        if (!tagName || tagName === '') {
          this.setState({ errorMessage: 'Please add a tag name'})
        } else if (!tagType || tagType === '') {
          this.setState({ errorMessage: 'Please add a tag type'})
        } else if (competencyTags && competencyTags.some(tag => tag.name.toLowerCase() === tagName.toLowerCase())) {
          this.setState({ errorMessage: 'This tag has already been added'})
        } else {

          let score = 5
          let weight = 0
          if (competencyTags) {
            for (let i = 1; i <= competencyTags.length; i++) {
              if (competencyTags[i - 1].score) {
                score = competencyTags[i - 1].score
              }
              if (competencyTags[i - 1].weight) {
                weight = competencyTags[i - 1].weight
              }
            }
            competencyTags.push({ name: tagName, category: tagType, score, weight })
          } else {
            competencyTags = [{ name: tagName, category: tagType, score, weight }]
          }

          this.setState({ competencyTags, tagName: '', tagType: '' })
        }
      } else if (type === 'supplementalPay') {

        let supplementalPayArray = this.state.supplementalPayArray
        let supplementalPay = this.state.supplementalPay

        if (!supplementalPay || supplementalPay === '') {
          this.setState({ errorMessage: 'Please add the supplemental pay type'})
        } else if (supplementalPayArray && supplementalPayArray.includes(supplementalPay)) {
          this.setState({ errorMessage: 'This pay has already been added'})
        } else {

          if (supplementalPayArray) {
            supplementalPayArray.push(supplementalPay)
          } else {
            supplementalPayArray = [supplementalPay]
          }

          this.setState({ supplementalPayArray, supplementalPay })
        }
      } else if (type === 'benefit') {

        let benefits = this.state.benefits
        let benefit = this.state.benefit
        if (!benefit || benefit === '') {
          this.setState({ errorMessage: 'Please add a benefit'})
        } else if (benefits && benefits.includes(benefit)) {
          this.setState({ errorMessage: 'This benefit has already been added'})
        } else {

          if (benefits) {
            benefits.push(benefit)
          } else {
            benefits = [benefit]
          }

          this.setState({ benefits, benefit })
        }
      } else if (type === 'type') {

        let typeTags = this.state.typeTags
        if (typeTags && typeTags.includes(item)) {
          const removeIndex = typeTags.indexOf(item)
          typeTags.splice(removeIndex,1)
          this.setState({ typeTags })
        } else {

          if (typeTags) {
            typeTags.push(item)
          } else {
            typeTags = [item]
          }

          this.setState({ typeTags, formHasChanged: true })
        }
      } else if (type === 'education') {

        let educationTags = this.state.educationTags
        if (educationTags && educationTags.includes(item)) {
          const removeIndex = educationTags.indexOf(item)
          educationTags.splice(removeIndex,1)
          this.setState({ educationTags })
        } else {

          if (educationTags) {
            educationTags.push(item)
          } else {
            educationTags = [item]
          }

          this.setState({ educationTags, formHasChanged: true })
        }
      } else if (type === 'adversity') {

        let adversityTags = this.state.adversityTags
        if (adversityTags && adversityTags.includes(item)) {
          const removeIndex = adversityTags.indexOf(item)
          adversityTags.splice(removeIndex,1)
          this.setState({ adversityTags })
        } else {

          if (adversityTags) {
            adversityTags.push(item)
          } else {
            adversityTags = [item]
          }

          this.setState({ adversityTags, formHasChanged: true })
        }
      }
    }

    removeTag(index, type) {
      console.log('removeTag called', index, type)

      if (type === 'competency') {
        let competencyTags = this.state.competencyTags
        competencyTags.splice(index, 1)
        this.setState({ competencyTags, formHasChanged: true })
      } else if (type === 'supplementalPay') {
        let supplementalPayArray = this.state.supplementalPayArray
        supplementalPayArray.splice(index, 1)
        this.setState({ supplementalPayArray, formHasChanged: true })
      } else if (type === 'benefit') {
        let benefits = this.state.benefits
        benefits.splice(index, 1)
        this.setState({ benefits, formHasChanged: true })
      }
    }

    renderNotiTags() {
      console.log('renderNotiTags called')

      return (
        <div key="renderNotiTags">
          <div className="row-20">
            <p className="heading-text-3">Automatic Notifications</p>
          </div>

          <div className="row-10">
            <p className="slight-faint-text top-padding">Include in Automatic Notifications</p>
            <p className="profile-descriptor bottom-padding top-margin-5">Weekly emails that go to relevant career-seekers, partners, and stakeholders.</p>
            <Switch
              onChange={(change) => this.setState({ includeInNotifications: change, formHasChanged: true })}
              checked={this.state.includeInNotifications}
              id="normal-switch"
            />
          </div>

          {(this.state.includeInNotifications) && (
            <div>
              {(this.state.typeTagOptions && this.state.typeTagOptions.length > 0) && (
                <div>
                  <label className="profile-label">Type Tags (Optional - Default: All)</label>

                  <div className="row-10">
                    {this.state.typeTagOptions.map((item, index) =>
                      <div key={"typeTags" + index} className="float-left bottom-margin right-margin">
                        <button className="background-button" onClick={() => this.addTag('type', item)}>
                          <div className={(this.state.typeTags && this.state.typeTags.includes(item)) ? "tag-container-basic primary-background white-text description-text-3" : "tag-container-basic light-background description-text-3"}>
                            <p>{item}</p>
                          </div>
                        </button>
                      </div>
                    )}
                    <div className="clear" />
                  </div>
                </div>
              )}

              {(this.state.educationTagOptions && this.state.educationTagOptions.length > 0) && (
                <div>
                  <label className="profile-label">Education Tags (Optional - Default: All)</label>

                  <div className="row-10">
                    {this.state.educationTagOptions.map((item, index) =>
                      <div key={"typeTags" + index} className="float-left bottom-margin right-margin">
                        <button className="background-button" onClick={() => this.addTag('education', item)}>
                          <div className={(this.state.educationTags && this.state.educationTags.includes(item)) ? "tag-container-basic primary-background white-text description-text-3" : "tag-container-basic light-background description-text-3"}>
                            <p>{item}</p>
                          </div>
                        </button>
                      </div>
                    )}
                    <div className="clear" />
                  </div>
                </div>
              )}

              {(this.state.adversityTagOptions && this.state.adversityTagOptions.length > 0) && (
                <div>
                  <label className="profile-label">Adversity Tags (Optional - Default: All)</label>

                  <div className="row-10">
                    {this.state.adversityTagOptions.map((item, index) =>
                      <div key={"typeTags" + index} className="float-left bottom-margin right-margin">
                        <button className="background-button" onClick={() => this.addTag('adversity', item)}>
                          <div className={(this.state.adversityTags && this.state.adversityTags.includes(item)) ? "tag-container-basic primary-background white-text description-text-3" : "tag-container-basic light-background description-text-3"}>
                            <p>{item}</p>
                          </div>
                        </button>
                      </div>
                    )}
                    <div className="clear" />
                  </div>
                </div>
              )}
            </div>
          )}

          <hr className="vertical-margin-20" />
        </div>
      )
    }

    searchCompetencies(competency, type) {
      console.log('searchCompetencies ', competency, type)

      if (type === 'Knowledge') {
        console.log('this is an error. We are not turning on this functionality now')
      } else {
        if (competency === '') {
          const competencyOptions = []
          this.setState({ competencyOptions })
        } else {
          Axios.get('/api/skilltrait/search', { params: { skillTrait: competency, type } })
          .then((response) => {
            console.log('Skilltrait search query attempted', response.data);

              if (response.data.success) {
                console.log('skillTrait search query worked')

                const competencyOptions = response.data.skills
                this.setState({ competencyOptions });

              } else {
                console.log('skilltrait search query did not work', response.data.message)
              }

          }).catch((error) => {
              console.log('Skilltrait search query did not work for some reason', error);
          });
        }
      }
    }

    competencyClicked(optionIndex, type) {
      console.log('competencyClicked', optionIndex, type, this.state.competencyOptions[optionIndex])

      let tagName = this.state.competencyOptions[optionIndex].name
      let tagType = this.state.competencyOptions[optionIndex].type
      const competencyOptions = []
      this.setState({ tagName, tagType, competencyOptions })

    }

    buttonClicked(value) {
      console.log('buttonClicked', value)

      let functions = this.state.functions
      if (functions.includes(value)) {
        let index = functions.indexOf(value);
        if (index > -1) {
          functions.splice(index, 1);
        }
      } else {
        functions.push(value)
      }
      console.log('show functions ', functions)
      this.setState({ functions, formHasChanged: true })

    }

    renderResources() {
      console.log('renderResources called')

      let rows = []

      for (let i = 1; i <= this.state.resources.length; i++) {

        const index = i - 1

        const resourceTitle = "resourceTitle|" + i.toString()

        rows.push(
          <div key={i}>
            <div className="fixed-column-30">
              <button className="background-button top-margin" onClick={() => this.removeItem(index,'resource')}>
                <img src={deniedIcon} alt="GC" className="image-auto-20" />
              </button>
            </div>
            <div className="calc-column-offset-30">
              <input type="text" className="text-field" placeholder="Add the url..." name={resourceTitle} value={this.state.resources[index]} onChange={this.formChangeHandler}></input>
            </div>
            <div className="clear" />

            {(this.state.resources[index] && this.state.resources[index] !== '' && !this.state.resources[index].startsWith('http')) && (
              <div>
                <p className="error-message">Please start your link with http</p>
              </div>
            )}
            <div className="spacer" />
          </div>
        )
      }

      return rows
    }

    addResource() {
      console.log('addResource called')

      let resources = this.state.resources
      resources.push('')

      let checked = this.state.checked
      checked.push(false)
      this.setState({ resources, checked })
    }

    renderExhibits() {
      console.log('renderExhibits called')

      let rows = []

      for (let i = 1; i <= this.state.exhibits.length; i++) {

        const index = i - 1

        const exhibitLink = "exhibitLink|" + i.toString()
        // https://imgur.com/rwBom3b.png
        rows.push(
          <div key={i}>
            <div>
              <div className="fixed-column-30">
                <button className="background-button top-margin" onClick={() => this.removeExhibit(index)}>
                  <img src={deniedIcon} alt="GC" className="image-20-fit" />
                </button>
              </div>
              <div className="calc-column-offset-30">
                <input type="text" className="text-field" placeholder="Add the 'embed' link to the image of your exhibit..." name={exhibitLink} value={this.state.exhibits[index].link} onChange={this.formChangeHandler}></input>
              </div>
              <div className="clear" />
            </div>

            {(this.state.exhibits[index].link && this.state.exhibits[index].link !== '' && !this.state.exhibits[index].link.startsWith('http')) && (
              <div>
                <p className="error-message">Please start your link with http</p>
              </div>
            )}
            <div className="spacer" /><div className="half-spacer"/>
            {(this.state.exhibits[index].link !== '') && (
              <img src={this.state.exhibits[index].link} alt="GC" className="image-auto-50" />
            )}
          </div>
        )
      }

      return rows
    }

    addExhibit() {
      console.log('addExhibit called')

      let exhibits = this.state.exhibits
      exhibits.push({ link: '' })

      this.setState({ exhibits })
    }

    removeExhibit(index) {
      console.log('removeExhibit called')

      let exhibits = this.state.exhibits
      exhibits.splice(index,1)

      this.setState({ exhibits })
    }

    renderLinks() {
      console.log('renderLinks called')

      let rows = []

      if (this.state.links) {
        for (let i = 1; i <= this.state.links.length; i++) {

          const index = i - 1

          const linkName = "linkName|" + i.toString()
          const linkURL = "linkURL|" + i.toString()

          rows.push(
            <div key={i}>
              <div className="fixed-column-30 right-padding">
                <button type="button" onClick={() => this.deleteItem('link', index, null)} className="background-button">
                  <img src={deniedIcon} className="answer-bubble" alt="Compass close icon icon" />
                </button>
              </div>
              <div className="calc-column-offset-30">
                <div className="relative-column-50 right-padding">
                  <input type="text" className="text-field" placeholder="Add the name..." name={linkName} value={this.state.links[index].name} onChange={this.formChangeHandler}></input>
                </div>
                <div className="relative-column-50">
                  <input type="text" className="text-field" placeholder="Add the url..." name={linkURL} value={this.state.links[index].url} onChange={this.formChangeHandler}></input>
                  {(this.state.links[index].url && this.state.links[index].url !== '' && !this.state.links[index].url.startsWith('http')) && (
                    <div>
                      <p className="error-message">Please start your link with http</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="clear" />
              <div className="spacer" />
            </div>
          )
        }
      }

      return rows
    }

    addLink() {
      console.log('addResource called')

      let links = this.state.links
      links.push({ name: '', url: ''})

      this.setState({ links })
    }

    exportInfo = async(type) => {
    // exportInfo(type) {
      console.log('exportInfo called', type)

      this.setState({ clientErrorMessage: '' })

      // let csvData = []

      // let rsvps = this.state.rsvps
      // if (type === 'attendees') {
      //   rsvps = this.state.attendees
      //   if (this.state.org === 'unite-la') {
      //     csvData[0].push('Certified')
      //   } else {
      //     csvData[0].push('Passed')
      //   }
      // }

      const reportOptions = this.state.reportOptions
      let reportName = "RSVP Report"
      if (type === 'attendees') {
        reportName = "Attendees Report"
      }
      const passedName = null
      const passedValue = null
      const fields = null
      const reportType = type
      const orgCode = this.state.org
      const placementPartners = this.state.placementPartners
      const surveyName = null
      const lessonName = null
      const selectedEvent = this.state.selectedOpportunity
      const selectedProject = null
      const selectedWork = null
      const approved = null
      const logType = null
      const assignedStaffName = null
      const assignedStaffEmail = null
      const olderThan = null
      const youngerThan = null
      const department = null
      const pathway = null
      const accountCode = null
      const afterDate = null
      const beforeDate = null
      const reportFilters = null
      const sortCriteria = null
      const resLimit = 10000
      const csvData = []
      const componentState = this.state

      const payload = { reportOptions, reportName, passedName, passedValue, fields, reportType, orgCode, placementPartners, surveyName, lessonName, selectedEvent, selectedProject, selectedWork, approved, logType, assignedStaffName, assignedStaffEmail, olderThan, youngerThan, department, pathway, accountCode, afterDate, beforeDate, reportFilters, sortCriteria, resLimit, csvData, componentState }
      // console.log("?!@?@?!@??!@", csvData)
      const returnedData = await subExportStandardCSV(payload)

      if (!returnedData.success) {
        this.setState(returnedData.stateObject)
      } else {

        let reportTitle = type

        const returnedData2 = subExportCSV(reportTitle,returnedData.csvData)

      }
    }

    renderRAndA() {
      console.log('renderRAndA called')

      return (
        <div key="renderRAndA">
          <div className="spacer" />

          <div className="row-10">
            <div className="container-left">
              <div>
                <div className="calc-column-offset-30">
                  <div className="float-left right-padding">
                    <label className="heading-text-3">{(this.state.rsvps && this.state.rsvps.length > 0) && this.state.rsvps.length + " "}RSVPs</label>
                  </div>
                  {(!this.props.path.includes('/employers')) && (
                    <div className="float-left">
                      <button className="btn background-button" disabled={this.state.isSaving} onClick={() => this.exportInfo('rsvps')}><img src={exportIconBlue} alt="GC" className="image-auto-25" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Export RSVPs"/></button>
                    </div>
                  )}
                </div>
                <div className="fixed-column-30">
                  <button className="btn background-button" disabled={this.state.isSaving} onClick={() => this.setState({ modalIsOpen: true, confirmAttendeeConvert: true })}><img src={convertIcon} alt="GC" className="image-auto-20" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Convert all to attendees"/></button>
                </div>

                <div className="clear" />
                <div className="spacer" />
              </div>

              { (this.state.clientErrorMessage!== '') && <p className="error-message">{this.state.clientErrorMessage}</p> }
              { (this.state.serverPostSuccess) ? (
                <p className="success-color description-text-2">{this.state.serverSuccessMessage}</p>
              ) : (
                <p className="error-color description-text-2">{this.state.serverErrorMessage}</p>
              )}

              <div className="spacer" />
              {this.renderRSVPs('rsvps')}
            </div>
            <div className="container-right">
              <div>
                <div className="calc-column-offset-60">
                  <div className="float-left right-padding">
                    <label className="heading-text-3">{(this.state.attendees && this.state.attendees.length > 0) && this.state.attendees.length + " "}Attendees</label>
                  </div>
                  {(!this.props.path.includes('/employers')) && (
                    <div>
                      <div className="float-left">
                        <button className="btn background-button" disabled={this.state.isSaving} onClick={() => this.exportInfo('attendees')}><img src={exportIconBlue} alt="Compass export icon icon" className="image-auto-25" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Export attendees" /></button>
                      </div>
                    </div>
                  )}
                </div>

                {(!this.props.path.includes('/employers')) && (
                  <div>
                    <div className="fixed-column-60 right-text">
                      <button className="btn background-button" disabled={this.state.isSaving} onClick={() => this.setState({ modalIsOpen: true, showAddAttendee: true, showRSVPs: false })}><img src={addIconBlue} alt="GC" className="image-auto-20 float-right right-margin" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Add attendee"/></button>
                      <button className="btn background-button" disabled={this.state.isSaving} onClick={() => this.setState({ modalIsOpen: true, confirmBulkPassAttendees: true })}><img src={checkboxEmpty} alt="GC" className="image-auto-20 float-right" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Bulk pass attendees"/></button>
                    </div>
                  </div>
                )}

                <div className="clear" />
                <div className="spacer" />
              </div>

              { (this.state.clientErrorMessage!== '') && <p className="error-message">{this.state.clientErrorMessage}</p> }
              { (this.state.serverPostSuccess) ? (
                <p className="success-message">{this.state.serverSuccessMessage}</p>
              ) : (
                <p className="error-message">{this.state.serverErrorMessage}</p>
              )}

              <div className="spacer" />
              {this.renderRSVPs('attendees')}
            </div>

            <div className="clear" />
            <ReactTooltip id="tooltip-placeholder-id" />

          </div>
        </div>
      )
    }

    renderRSVPs(type) {
      console.log('renderRSVPs called', type)

      let rows = []

      let items = this.state.rsvps
      if (type === 'attendees') {
        items = this.state.attendees
      }

      for (let i = 1; i <= items.length; i++) {

        const index = i - 1
        const rowKey = "rsvp" + i.toString()

        rows.push(
          <div key={rowKey} className="row-10">
            <div className="float-left right-margin-20">
              <img src={items[i - 1].pictureURL ? items[i - 1].pictureURL : profileIconGrey} alt="Compass profile icon icon" className="profile-thumbnail-2"/>
            </div>
            <div className="calc-column-offset-100-static">
              <label className="heading-text-5">{items[i - 1].firstName} {items[i - 1].lastName}</label>
              <div className="clear" />
              {(this.state.rsvpType === 'Employer Representative / Mentor' || this.state.rsvpType === 'Mentor') ? (
                <label className="description-text-1">{items[i - 1].jobTitle} {items[i - 1].employerName}</label>
              ) : (
                <label className="description-text-1">{items[i - 1].schoolName}</label>
              )}
              <div className="clear" />
            </div>

            {(type === 'rsvps') ? (
              <div className="fixed-column-30">
                <button className="btn background-button" disabled={this.state.isSaving} onClick={() => this.saveAttendee(items[index])}>
                  <img src={convertIcon} alt="GC" className="image-auto-20" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Convert to attendee" />
                </button>
                <button className="btn background-button top-margin full-width" disabled={this.state.isSaving} onClick={() => this.setState({ rsvpConfirmDeleteIndex: index })}>
                  <img src={xIcon} alt="GC" className="image-auto-13 pin-right" />
                </button>
              </div>
            ) : (
              <div className="fixed-column-30">
                <button className="btn background-button full-width" disabled={this.state.isSaving} onClick={() => this.itemClicked(i - 1,'attendee')}>
                  <img src={(items[i - 1].approved) ? checkboxChecked : checkboxEmpty} alt="GC" className="image-auto-20 pin-right" />
                </button>
                <button className="btn background-button top-margin full-width" disabled={this.state.isSaving} onClick={() => this.deleteItem('attendee',index,null,items[index])}>
                  <img src={xIcon} alt="GC" className="image-auto-13 pin-right" />
                </button>
              </div>
            )}

            <div className="clear" />
            <div className="half-spacer" />

            {(this.state.rsvpConfirmDeleteIndex === index) && (
              <div className="full-width row-10">
                <label className="row-10 description-text-1 error-color">Are you sure?</label>
                <div className="clear" />
                <button className="btn btn-squarish error-background-color error-border right-margin" onClick={() => this.deleteItem('rsvp',index,null,items[index])}>
                  <p>Delete Permanently</p>
                </button>
                <button className="btn btn-squarish white-background cta-color" onClick={() => this.setState({ rsvpConfirmDeleteIndex: null })}>
                  <p>Cancel</p>
                </button>
              </div>
            )}
          </div>
        )
      }

      return rows
    }

    saveAttendee(rsvp) {
      console.log('saveAttendee')

      let attendees = this.state.attendees
      const self = this

      function actuallySave(saveObject) {
        console.log('actuallySave called', saveObject)

        Axios.post('/api/attendee', saveObject)
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Reserve worked', response.data);

            attendees.push(response.data.attendee)
            // attendees.sort(function(a,b) {
            //   return a.lastName - b.lastName;
            // })

            attendees.sort(function(a, b) {
                let textA = ''
                let textB = ''
                if (a.lastName) {
                  textA = a.lastName.toUpperCase();
                }
                if (b.lastName) {
                  textB = b.lastName.toUpperCase();
                }
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });

            const message = 'Attendee added'
            self.setState({ serverPostSuccess: true, serverSuccessMessage: message, successMessage: message, disableSubmit: false, attendees })

          } else {
            console.error('there was an error posting the wbl opportunity');
            const message = response.data.message
            self.setState({ serverPostSuccess: false, serverErrorMessage: message, errorMessage: message, disableSubmit: false
            })
          }
        }).catch((error) => {
            console.log('The postings post did not work', error);
            self.setState({ serverPostSuccess: false, serverErrorMessage: error, disableSubmit: false })
        });
      }

      if (rsvp) {

        let attendeeObject = rsvp
        attendeeObject['attendeeType'] = rsvp.rsvpType
        attendeeObject['rsvpd'] = true
        actuallySave(attendeeObject)

      } else {

        if (this.state.isExistingUser && this.state.isExistingUser === 'Yes' && this.state.selectedAttendee) {
          const postingId = this.state.selectedOpportunity._id
          const postingTitle = this.state.selectedOpportunity.name
          const postType = this.state.selectedOpportunity.postType

          let firstName = this.state.selectedAttendee.firstName
          let lastName = this.state.selectedAttendee.lastName
          let email = this.state.selectedAttendee.email
          const jobTitle = this.state.selectedAttendee.jobTitle
          const employerName = this.state.selectedAttendee.employerName
          const schoolName = this.state.selectedAttendee.school
          const roleName = this.state.selectedAttendee.roleName
          const otherRoleName = this.state.selectedAttendee.otherRoleName

          const username = this.state.selectedAttendee.username
          const phoneNumber = this.state.selectedAttendee.phoneNumber
          const gradYear = this.state.selectedAttendee.gradYear
          const degree = this.state.selectedAttendee.degree
          const major = this.state.selectedAttendee.major
          const educationStatus = this.state.selectedAttendee.educationStatus
          const studentActivityInterests = []

          let rsvpResponses = []
          rsvpResponses.push({ question: 'First Name', answer: firstName })
          rsvpResponses.push({ question: 'Last Name', answer: lastName })
          rsvpResponses.push({ question: 'Email', answer: email })
          rsvpResponses.push({ question: 'Role Name', answer: roleName })
          rsvpResponses.push({ question: 'Other Role Name', answer: otherRoleName})
          // const offsetCount = 5

          // verify that all required fields are filled out
          if (this.state.screeningQuestions) {
            let questions = this.state.screeningQuestions
            // let customAssessment = this.state.customAssessment
            // customAssessmentId = this.state.customAssessment._id

            // if (item.questionType === 'Multiple Answer' && (!this.state[item.shortname] || this.state[item.shortname].length === 0)) {
            //   return this.setState({ serverErrorMessage: 'Please add answer(s) for ' + item.name })
            // } else if (!item.shortname.includes("|") && (!this.state[item.shortname] || !this.state[item.shortname] === '')) {
            //   return this.setState({ serverErrorMessage: 'Please add an answer for ' + item.name })
            // } else if (item.shortname.includes("|") && (!this.state[item.shortname.split("|")[0]] || this.state[item.shortname.split("|")[0]].length === 0)) {
            //   return this.setState({ serverErrorMessage: 'Please add answer(s) for the education fields' })
            // }

            for (let i = 1; i <= questions.length; i++) {
              rsvpResponses.push({
                question: questions[i - 1].name,
                questionType: questions[i - 1].questionType,
                answerChoices: questions[i - 1].answerChoices,
                shortname: questions[i - 1].shortname,
                mapToProfile: questions[i - 1].mapToProfile,
                // answer: this.state.responses[this.state.survey.questions.length + i - 1]
              })

              if ((questions[i - 1].mapToProfile && questions[i - 1].shortname === 'dateOfBirth') || (questions[i - 1].name === 'Date of Birth' || questions[i - 1].name === 'Birthdate')) {
                rsvpResponses[rsvpResponses.length - 1]['answer'] = this.state.selectedAttendee.dateOfBirth
              } else if ((questions[i - 1].mapToProfile && questions[i - 1].shortname === 'gender')) {
                rsvpResponses[rsvpResponses.length - 1]['answer'] = this.state.selectedAttendee.gender
              } else if ((questions[i - 1].mapToProfile && questions[i - 1].shortname === 'race')) {
                rsvpResponses[rsvpResponses.length - 1]['answer'] = this.state.selectedAttendee.race
              } else if ((questions[i - 1].mapToProfile && questions[i - 1].shortname === 'races')) {
                rsvpResponses[rsvpResponses.length - 1]['answer'] = this.state.selectedAttendee.races
              } else if ((questions[i - 1].mapToProfile && questions[i - 1].shortname === 'selfDescribedRace')) {
                rsvpResponses[rsvpResponses.length - 1]['answer'] = this.state.selectedAttendee.selfDescribedRace
              } else if ((questions[i - 1].mapToProfile && questions[i - 1].shortname === 'address')) {
                rsvpResponses[rsvpResponses.length - 1]['answer'] = this.state.selectedAttendee.address
              } else if ((questions[i - 1].mapToProfile && questions[i - 1].shortname === 'zipcode')) {
                rsvpResponses[rsvpResponses.length - 1]['answer'] = this.state.selectedAttendee.zipcode
              } else if ((questions[i - 1].mapToProfile && questions[i - 1].shortname === 'numberOfMembers')) {
                rsvpResponses[rsvpResponses.length - 1]['answer'] = this.state.selectedAttendee.numberOfMembers
              } else if ((questions[i - 1].mapToProfile && questions[i - 1].shortname === 'householdIncome')) {
                rsvpResponses[rsvpResponses.length - 1]['answer'] = this.state.selectedAttendee.householdIncome
              } else if ((questions[i - 1].mapToProfile && questions[i - 1].shortname === 'workAuthorization')) {
                rsvpResponses[rsvpResponses.length - 1]['answer'] = this.state.selectedAttendee.workAuthorization
              } else if ((questions[i - 1].mapToProfile && questions[i - 1].shortname === 'adversityList')) {
                rsvpResponses[rsvpResponses.length - 1]['answer'] = this.state.selectedAttendee.adversityList
              } else if ((questions[i - 1].mapToProfile && questions[i - 1].shortname === 'educationStatus')) {
                rsvpResponses[rsvpResponses.length - 1]['answer'] = this.state.selectedAttendee.educationStatus
              } else if ((questions[i - 1].mapToProfile && questions[i - 1].shortname === 'phoneNumber')) {
                rsvpResponses[rsvpResponses.length - 1]['answer'] = this.state.selectedAttendee.phoneNumber
              } else if ((questions[i - 1].mapToProfile && questions[i - 1].shortname === 'alternativePhoneNumber')) {
                rsvpResponses[rsvpResponses.length - 1]['answer'] = this.state.selectedAttendee.alternativePhoneNumber
              } else if ((questions[i - 1].mapToProfile && questions[i - 1].shortname === 'alternativeEmail')) {
                rsvpResponses[rsvpResponses.length - 1]['answer'] = this.state.selectedAttendee.alternativeEmail
              } else if ((questions[i - 1].mapToProfile && questions[i - 1].shortname === 'referrerName')) {
                rsvpResponses[rsvpResponses.length - 1]['answer'] = this.state.selectedAttendee.referrerName
              } else if ((questions[i - 1].mapToProfile && questions[i - 1].shortname === 'referrerEmail')) {
                rsvpResponses[rsvpResponses.length - 1]['answer'] = this.state.selectedAttendee.referrerEmail
              } else if ((questions[i - 1].mapToProfile && questions[i - 1].shortname === 'referrerOrg')) {
                rsvpResponses[rsvpResponses.length - 1]['answer'] = this.state.selectedAttendee.referrerOrg
              } else if ((questions[i - 1].mapToProfile && questions[i - 1].shortname === 'education|name')) {
                rsvpResponses[rsvpResponses.length - 1]['answer'] = this.state.selectedAttendee.school
              } else if ((questions[i - 1].mapToProfile && questions[i - 1].shortname === 'education|degree')) {
                rsvpResponses[rsvpResponses.length - 1]['answer'] = this.state.selectedAttendee.degree
              } else if ((questions[i - 1].mapToProfile && questions[i - 1].shortname === 'education|endDate')) {

                rsvpResponses[rsvpResponses.length - 1]['answer'] = this.state.selectedAttendee.gradYear
                if (this.state.selectedAttendee.gradDate && this.state.selectedAttendee.gradDate.split(" ") && this.state.selectedAttendee.gradDate.split(" ")[1]) {
                  rsvpResponses[rsvpResponses.length - 1]['answer'] = this.state.selectedAttendee.gradDate.split(" ")[1]
                }
              } else if ((questions[i - 1].mapToProfile && questions[i - 1].shortname === 'education|major') || (questions[i - 1].name === 'Referrer Major')) {
                rsvpResponses[rsvpResponses.length - 1]['answer'] = this.state.selectedAttendee.major
              }
            }
          } else {
            rsvpResponses.push({ question: 'Education Status', answer: educationStatus })
            rsvpResponses.push({ question: 'School Name', answer: schoolName })
            rsvpResponses.push({ question: 'Grad Date', answer: this.state.selectedAttendee.gradDate})
            rsvpResponses.push({ question: 'Degree Type', answer: degree })
            rsvpResponses.push({ question: 'Major / Pathway', answer: major})
            rsvpResponses.push({ question: 'Activity Interests', answer: studentActivityInterests })
          }

          const location = this.state.selectedOpportunity.location

          const orgCode = this.state.org
          const departments = this.state.selectedOpportunity.departments
          const targetRoles = this.state.selectedOpportunity.targetRoles
          const selectedPrograms = this.state.selectedOpportunity.selectedPrograms

          const createdAt = new Date()
          const updatedAt = new Date()

          const orgName = this.state.orgName
          const orgContactFirstName = this.state.orgContactFirstName
          const orgContactLastName = this.state.orgContactLastName
          const orgContactEmail = this.state.orgContactEmail

          const attendeeObject = {
            postingId, postingTitle, postType, firstName, lastName, email, schoolName, jobTitle, employerName, orgCode,
            departments, targetRoles, selectedPrograms, roleName, otherRoleName, createdAt, updatedAt,
            orgName, orgContactFirstName, orgContactLastName, orgContactEmail,
            username, phoneNumber, gradYear, degree, major, educationStatus, studentActivityInterests,
            rsvpResponses, location
          }

          console.log('rsvpquestions? ', rsvpResponses)

          actuallySave(attendeeObject)

        } else {
          if (!this.state.attendeeRoleName || this.state.attendeeRoleName === '') {
            this.setState({ errorMessage: "Please add the attendee's role name", disableSubmit: false })
          } else if (!this.state.attendeeFirstName || this.state.attendeeFirstName === '') {
            this.setState({ errorMessage: "Please add the attendee's first name", disableSubmit: false})
          } else if (!this.state.attendeeLastName || this.state.attendeeLastName === '') {
            this.setState({ errorMessage: "Please add the attendee's last name", disableSubmit: false})
          } else if (!this.state.attendeeEmail || this.state.attendeeEmail === '') {
            this.setState({ errorMessage: "Please add the attendee's email", disableSubmit: false})
          } else if (!this.state.attendeeEmail.includes('@')) {
            this.setState({ errorMessage: "Please add a valid email for the attendee", disableSubmit: false})
          } else {

            const postingId = this.state.selectedOpportunity._id
            const postingTitle = this.state.selectedOpportunity.name
            const postType = this.state.selectedOpportunity.postType

            let firstName = this.state.attendeeFirstName
            let lastName = this.state.attendeeLastName
            let email = this.state.attendeeEmail
            const jobTitle = this.state.attendeeJobTitle
            const employerName = this.state.attendeeEmployerName
            const schoolName = this.state.attendeeSchoolName
            const roleName = this.state.roleName
            const otherRoleName = this.state.attendeeOtherRoleName
            const orgCode = this.state.org
            const departments = this.state.selectedOpportunity.departments
            const targetRoles = this.state.selectedOpportunity.targetRoles
            const selectedPrograms = this.state.selectedOpportunity.selectedPrograms

            const createdAt = new Date()
            const updatedAt = new Date()

            const orgName = this.state.orgName
            const orgContactFirstName = this.state.orgContactFirstName
            const orgContactLastName = this.state.orgContactLastName
            const orgContactEmail = this.state.orgContactEmail

            const attendeeObject = {
              postingId, postingTitle, postType, firstName, lastName, email, schoolName, jobTitle, employerName, orgCode,
              departments, targetRoles, selectedPrograms, roleName, otherRoleName, createdAt, updatedAt,
              orgName, orgContactFirstName, orgContactLastName, orgContactEmail
            }

            actuallySave(attendeeObject)

          }
        }
      }
    }

    renderProspects(type) {
      console.log('renderProspects called', type)

      let pathPrefix = ""
      if (window.location.pathname.includes('/organizations')) {
        pathPrefix = "/organizations/" + this.state.org
      } else if (window.location.pathname.includes('/employers')) {
        pathPrefix = "/employers/" + this.state.accountCode
      } else if (window.location.pathname.includes('/advisor')) {
        pathPrefix = "/advisor"
      }

      let rows = []

      let items = this.state.prospects

      for (let i = 1; i <= items.length; i++) {

        const item = items[i - 1]
        // const index = i - 1
        const rowKey = "rsvp" + i.toString()

        rows.push(
          <div key={rowKey} className="row-20">
            <Link to={pathPrefix + '/members/' + item.username} className="background-button calc-column-offset-50">
              <div className="fixed-column-60">
                <img src={items[i - 1].pictureURL ? items[i - 1].pictureURL : profileIconGrey} alt="GC" className="profile-thumbnail-2"/>
              </div>
              <div className="calc-column-offset-60">
                <p className="heading-text-5">{items[i - 1].firstName} {items[i - 1].lastName}</p>
                <p className="description-text-2 standard-color top-padding-5">{(item.headline) ? item.headline : (item.schoolName) ? item.schoolName : 'Student'}{(item.gradYear) && " " + item.gradYear}{(item.major) && " - " + item.major}</p>
                <div className="clear" />
              </div>
              <div className="clear" />
            </Link>
            <div className="fixed-column-50 top-margin-5">
              {(this.state.candidatesOfInterest && this.state.candidatesOfInterest.some(coi => coi.email === item.email)) ? (
                <img src={checkmarkIcon} alt="GC" className="image-auto-30 pin-right" />
              ) : (
                <button className="btn btn-squarish" disabled={this.state.isSaving} onClick={() => this.itemClicked(i - 1,'prospect')}>Tag</button>
              )}
            </div>

            <div className="clear" />

            <div className="half-spacer" />
          </div>
        )
      }

      return rows
    }

    closeModal(passedPostIsFilled) {

      let isFilled = this.state.isFilled
      if (passedPostIsFilled) {
        isFilled = true
      }

      this.setState({ modalIsOpen: false, isFilled, benchmarkInfo: false, applicationDeadlineInfo: false,
        politicalPartyInfo: false, showEditCourse: false, showOrgInfo: false, submissionStartDateInfo: false,
        submissionDeadlineInfo: false, votingRoundInfo: false, judgingRoundInfo: false, announcementDateInfo: false,
        voteRequirementInfo: false, challengesProcess: false, showAddAttendee: false,
        showTags: false, showSubsidyInfo: false, showSchoolCreditInfo: false, showInfoCollected: false,
        showHandlebarsInfo: false, showActiveDefinition: false, showUnlistedDefinition: false,
        confirmAttendeeConvert: false, confirmBulkPassAttendees: false, showOrgDetails: false,
        selectedOrg: null, selectedIndex: null, addTrainingPartners: false, showCandidateInvites: false,
        showChangeStage: false, confirmFill: false, showConfirmDelete: false, showRSVPs: false, showProspects: false
      });
    }

    indicateFormChange(formHasChanged) {
      console.log('indicateFormChange ', formHasChanged)
      this.setState({ formHasChanged })
    }

    addItem(type) {
      console.log('addItem called', type)

      if (type === 'course') {
        let selectedCourses = this.state.selectedCourses
        let course = this.state.course

        if (course.name === 'Add New Course') {
          console.log('add new course')

          this.setState({ modalIsOpen: true, showEditCourse: true })

        } else if (selectedCourses.some(selectedCourse => selectedCourse.name === course.name)) {
          console.log('course is already included in selected courses')
        } else {

          console.log('course is not already in selected courses')
          selectedCourses.push(course)
          course = { name: ''}
          this.setState({ selectedCourses, course, formHasChanged: true })
        }
      } else if (type === 'assignee') {
        if (this.state.assignee) {
          let assignee = this.state.assignee
          let assignees = this.state.assignees
          assignees.push(this.state.assignee)
          assignee = null
          let assigneeName = ''
          this.setState({ assignees, assignee, assigneeName })
        }
      } else if (type === 'candidatesOfInterestSearched') {
        if (this.state.assignee) {
          let assignee = this.state.assignee
          let candidatesOfInterest = this.state.candidatesOfInterest
          candidatesOfInterest.push(this.state.assignee)
          assignee = null
          let assigneeName = ''
          this.setState({ candidatesOfInterest, assignee, assigneeName })
        }
      } else if (type === 'worker') {
        if (this.state.worker) {
          let worker = this.state.worker
          let workers = this.state.workers
          workers.push(this.state.worker)
          worker = null
          let workerName = ''
          this.setState({ workers, worker, workerName })
        }
      } else if (type === 'screeningQuestion') {
        let screeningQuestions = this.state.screeningQuestions
        if (screeningQuestions) {
          screeningQuestions.push({ name: '', questionType: 'Multiple Choice', screeningAnswerChoices: []})
        } else {
          screeningQuestions = [{ name: '', questionType: 'Multiple Choice', screeningAnswerChoices: []}]
        }

        this.setState({ screeningQuestions, formHasChanged: true })
      } else if (type === 'candidatesOfInterest') {
        let candidatesOfInterest = this.state.candidatesOfInterest
        if (candidatesOfInterest) {
          candidatesOfInterest.push({ firstName: '', lastName: '', email: '' })
        } else {
          candidatesOfInterest = [{ firstName: '', lastName: '', email: '' }]
        }

        this.setState({ candidatesOfInterest, formHasChanged: true })
      } else if (type === 'posting') {

        if (!this.state.selectedPosting) {
          this.setState({ errorMessage: 'Please add a posting to tag' })
        } else {

          const postingId = this.state.selectedPosting._id
          const imageURL = this.state.selectedPosting.imageURL
          let title = this.state.selectedPosting.title
          if (this.state.selectedPosting.name) {
            title = this.state.selectedPosting.name
          }
          const employerName = this.state.selectedPosting.employerName
          const postType = this.state.selectedPosting.postType
          const submissionDeadline = this.state.selectedPosting.submissionDeadline

          const projectPromptType = this.state.selectedPosting.projectPromptType
          const startDate = this.state.selectedPosting.startDay
          const createdAt = this.state.selectedPosting.createdAt

          let postingObject = { postingId, imageURL, title, employerName, postType, submissionDeadline,
            projectPromptType, startDate, createdAt
          }

          let taggedPostings = this.state.taggedPostings
          if (taggedPostings) {
            taggedPostings.push(postingObject)
          } else {
            taggedPostings = [postingObject]
          }

          this.setState({ taggedPostings, postingTitle: '', selectedPosting: null, formHasChanged: true })
        }
      } else if (type === 'attendee') {
        if (this.state.assignee) {
          let assignee = this.state.assignee
          let selectedAttendee = this.state.assignee
          assignee = null
          let assigneeName = ''
          this.setState({ selectedAttendee, assignee, assigneeName })
        }
      }
    }

    removeItem(index, type) {
      console.log('removeItem called', index, type)

      if (type === 'course') {
        let selectedCourses = this.state.selectedCourses
        selectedCourses.splice(index, 1)
        this.setState({ selectedCourses, formHasChanged: true })
      } else if (type === 'assignee') {
        let assignees = this.state.assignees
        assignees.splice(index, 1)
        this.setState({ assignees, formHasChanged: true })
      } else if (type === 'worker') {
        let workers = this.state.workers
        workers.splice(index, 1)
        this.setState({ workers, formHasChanged: true })
      } else if (type === 'candidateOfInterest') {
        let candidatesOfInterest = this.state.candidatesOfInterest
        candidatesOfInterest.splice(index, 1)
        this.setState({ candidatesOfInterest, formHasChanged: true })
      } else if (type === 'posting') {
        let taggedPostings = this.state.taggedPostings
        taggedPostings.splice(index, 1)
        this.setState({ taggedPostings, formHasChanged: true })
      } else if (type === 'resource') {
        let resources = this.state.resources
        resources.splice(index, 1)
        this.setState({ resources, formHasChanged: true })
      }
    }

    addNewCourse(courseObject) {
      console.log('addNewCourse called', courseObject)

      const course = courseObject

      let courseOptions = this.state.courseOptions
      courseOptions.splice(courseOptions.length - 2,0,courseObject)

      console.log('compare course: ', course, courseOptions)
      this.setState({ courseOptions, course })
    }

    optionClicked(value, index, type) {
      console.log('optionClicked called', value, index, type)

      if (type === 'pathway') {
        let pathways = this.state.pathways
        if (pathways && pathways.length > 0) {

          let removeIndex = -1
          if (!value.name && !value.title) {
            removeIndex = pathways.indexOf(value)
          } else if (!value.name && value.title) {
            removeIndex = pathways.findIndex(p => p.title === value.title)
          } else if (value.name && !value.title) {
            removeIndex = pathways.findIndex(p => p.name === value.name)
          }
          // console.log('show the logs: ', removeIndex1, removeIndex2, removeIndex3)
          if (removeIndex > -1) {
            pathways.splice(removeIndex, 1)
          } else {
            pathways.push(value)
          }
        } else {
          pathways.push(value)
        }
        this.setState({ pathways, formHasChanged: true })
      } else if (type === 'department') {
        let departments = this.state.departments
        if (departments && departments.length > 0) {
          // console.log('show deparments and value: ', departments, value)
          if (departments.includes(value)) {

            const removeIndex = departments.indexOf(value)
            departments.splice(removeIndex, 1)

          } else {
            departments.push(value)
          }
        } else {
          departments.push(value)
        }
        this.setState({ departments, formHasChanged: true })
      } else if (type === 'targetRole') {
        let targetRoles = this.state.targetRoles
        if (targetRoles && targetRoles.includes(value)) {
          const removeIndex = targetRoles.indexOf(value)
          targetRoles.splice(removeIndex, 1)
        } else {
          targetRoles.push(value)
        }
        console.log('gimme the target', targetRoles)
        this.setState({ targetRoles, formHasChanged: true })
      } else if (type === 'program') {
        let selectedPrograms = this.state.selectedPrograms
        if (selectedPrograms && selectedPrograms.length > 0) {
          // console.log('show deparments and value: ', departments, value)
          if (selectedPrograms.includes(value)) {

            const removeIndex = selectedPrograms.indexOf(value)
            selectedPrograms.splice(removeIndex, 1)

          } else {
            selectedPrograms.push(value)
          }
        } else {
          selectedPrograms = [value]
        }
        this.setState({ selectedPrograms, formHasChanged: true })
      }
    }

    switchPreference(type, name, index, value) {
      console.log('switchPreference called', type, name, index, value)

      if (type === 'app') {
        let appComponents = this.state.appComponents
        appComponents[index][name] = value

        let includeCustomAssessment = this.state.includeCustomAssessment
        if (appComponents[index].name === 'Custom Assessment') {
          includeCustomAssessment = value
        }

        this.setState({ appComponents, formHasChanged: true, includeCustomAssessment })

      } else {
        let rsvpComponents = this.state.rsvpComponents
        rsvpComponents[index][name] = value

        let includeCustomAssessment = this.state.includeCustomAssessment
        if (rsvpComponents[index].name === 'Custom Assessment') {
          includeCustomAssessment = value
        }

        this.setState({ rsvpComponents, formHasChanged: true, includeCustomAssessment })

      }
    }

    renderQuestions(type) {
      console.log('renderQuestions called', type)

      if (type === 'screeningQuestions') {
        let rows = [];
        console.log('show the postings: ', this.state.postings)

        if (this.state.screeningQuestions) {
          const questions = this.state.screeningQuestions
          for (let i = 1; i <= questions.length; i++) {
            console.log(i);

            const index = i - 1

            // let eventName = "question" + i.toString()
            // let typeName = "type" + i.toString()

            rows.push(
              <div key={i}>
                <div className="close-button-container-2" >
                  <button type="button" onClick={() => this.deleteItem('question', index)} className="background-button">
                    <img src={deniedIcon} className="image-auto-20" alt="Compass close icon icon" />
                  </button>
                </div>

                <div className="left-padding-5">
                  <div className="highlighted-container">
                    <div className="row-5">
                      <p>Question #{i}</p>
                    </div>

                    <div className="calc-column-offset-140 right-padding row-5">
                      <input type="text" className="text-field" placeholder="Write a question..." name={"screeningQuestion|name|" + index} value={questions[i - 1].name} onChange={this.formChangeHandler}></input>
                    </div>
                    <div className="fixed-column-140 row-5">
                      <select name={"screeningQuestion|questionType|" + index} value={questions[i - 1].questionType} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.questionTypeOptions.map(value =>
                          <option key={value.key} value={value}>{value}</option>
                        )}
                      </select>
                    </div>
                    <div className="clear"/>

                    {(questions[i - 1].questionType === 'Multiple Choice'  || questions[i - 1].questionType === 'Checkbox' || questions[i - 1].questionType === 'Ranking') ? (
                      <div className="top-padding">

                        {(questions[i - 1].screeningAnswerChoices && questions[i - 1].screeningAnswerChoices.length > 0) && (
                          <div>
                            <div className="row-5 description-text-5 bold-text">
                              <div className="fixed-column-30">
                                <p> </p>
                              </div>
                              <div className="calc-column-offset-80 right-padding">
                                <p>ANSWER CHOICE</p>
                              </div>
                              <div className="fixed-column-50">
                                <p className="full-width center-text">POINTS</p>
                              </div>
                              <div className="clear" />
                            </div>

                            {this.renderAnswerChoices(i - 1, questions[i - 1].screeningAnswerChoices)}
                          </div>
                        )}

                        <div>
                          <div className="add-item-line-button">
                            <hr />
                            <a className="background-button add-task-link-container" onClick={() => this.addAnswerChoice(i - 1)}>
                              <img src={addCircleOutlineIcon} alt="Compass add circle outline icon" className="add-task" />
                            </a>
                          </div>
                          <div className="clear" />
                        </div>

                      </div>
                    ) : (
                      <div>
                        {(questions[i - 1].questionType === 'Long Answer') ? (
                          <p className="fake-text-area">Candidates will write long answer here...</p>
                        ) : (
                          <p className="fake-text-field">Candidates will write {questions[i - 1].questionType.toLowerCase()} here...</p>
                        )}
                      </div>
                    )}
                  </div>
                </div>

              </div>
            )
          }
        }

        return rows;
      } else if (type === 'candidatesOfInterest') {

        let rows = [];
        // console.log('show the postings: ', this.state.postings)

        if (this.state.candidatesOfInterest) {
          const candidatesOfInterest = this.state.candidatesOfInterest
          for (let i = 1; i <= candidatesOfInterest.length; i++) {
            console.log(i);

            const index = i - 1

            // let eventName = "candidatesOfInterest" + i.toString()
            // let typeName = "type" + i.toString()

            rows.push(
              <div key={i}>
                <div className="close-button-container-2" >
                  <button type="button" onClick={() => this.deleteItem('candidatesOfInterest', index)} className="background-button">
                    <img src={deniedIcon} className="image-auto-20" alt="Compass close icon icon" />
                  </button>
                </div>

                <div className="left-padding-5">
                  <div className="highlighted-container">
                    <div className="row-5">
                      <p>Candidate #{i}</p>
                    </div>

                    <div className="row-5">
                      <div className="container-left">
                        <input type="text" className="text-field" placeholder="First name..." name={"candidatesOfInterest|firstName|" + index} value={candidatesOfInterest[i - 1].firstName} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="container-right">
                        <input type="text" className="text-field" placeholder="Last name..." name={"candidatesOfInterest|lastName|" + index} value={candidatesOfInterest[i - 1].lastName} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="clear" />
                    </div>

                    <div className="row-5">
                      <input type="text" className="text-field" placeholder="Email..." name={"candidatesOfInterest|email|" + index} value={candidatesOfInterest[i - 1].email} onChange={this.formChangeHandler}></input>
                    </div>

                    <div className="clear" />

                  </div>
                </div>
              </div>
            )
          }
        }

        return rows;
      }
    }

    renderAnswerChoices(questionIndex, answerChoices) {

      let rows = []
      for (let i = 1; i <= answerChoices.length; i++) {
        console.log(i);

        // let key = "answerChoice" + iCount.toString() + "|" + i.toString()
        // let eventName = "answerChoice" + iCount.toString() +  "|" +  i.toString()
        const answerChoiceIndex = i - 1

        rows.push(
          <div key={"screeningAnswerChoice|" + i} className="row-5">
            <div className="fixed-column-30">
              <button type="button" onClick={() => this.deleteItem('answerChoice', questionIndex, answerChoiceIndex)} className="background-button">
                <img src={deniedIcon} className="answer-bubble" alt="Compass close icon icon" />
              </button>
            </div>
            <div className="calc-column-offset-80 right-padding">
              <input type="text" className="text-field height-40" placeholder="Write an answer choice..." name={"answerChoice|name|" + questionIndex + "|" + answerChoiceIndex} value={answerChoices[i - 1].name} onChange={this.formChangeHandler}></input>
            </div>
            <div className="fixed-column-50">
              <input type="number" className="number-field cta-color" min="0" max="5" placeholder="0" name={"answerChoice|score|" + questionIndex + "|" + answerChoiceIndex} value={answerChoices[i - 1].score} onChange={this.formChangeHandler}></input>
            </div>
            <div className="clear" />
          </div>
        )
      }

      return rows
    }

    addAnswerChoice(index) {
      console.log('addAnswerChoice called', index)

      let screeningAnswerChoices = this.state.screeningQuestions[index].screeningAnswerChoices
      screeningAnswerChoices.push({ name: '', score: '' })

      let screeningQuestions = this.state.screeningQuestions
      screeningQuestions[index] = { name: this.state.screeningQuestions[index].name, questionType:this.state.screeningQuestions[index].questionType, screeningAnswerChoices }

      this.setState({ screeningQuestions })
    }

    deleteItem(itemType, questionIndex, answerChoiceIndex) {
      console.log('deleteItem called', itemType, questionIndex, answerChoiceIndex)

      if (itemType === 'question') {
        let screeningQuestions = this.state.screeningQuestions
        screeningQuestions.splice(questionIndex, 1)
        this.setState({ screeningQuestions })
      } else if (itemType === 'answerChoice') {
        let screeningAnswerChoices = this.state.screeningQuestions[questionIndex].screeningAnswerChoices
        screeningAnswerChoices.splice(answerChoiceIndex,1)

        let screeningQuestions = this.state.screeningQuestions
        screeningQuestions[questionIndex]['screeningAnswerChoices'] = screeningAnswerChoices
        this.setState({ screeningQuestions })

      } else if (itemType === 'prompt') {
        // let questions = this.state.questions
        // delete questions[index].prompt
        // this.setState({ questions, formHasChanged: true })
      } else if (itemType === 'rsvp') {

        let rsvps = this.state.rsvps
        const _id = rsvps[questionIndex]._id
        const postingId = rsvps[questionIndex].postingId
        const passedData = _id + "|||" + postingId

        Axios.delete('/api/rsvp/' + passedData)
        .then((response) => {
          console.log('tried to  delete', response.data)
          if (response.data.success) {
            //save values
            console.log('Rsvp delete worked');

            rsvps.splice(questionIndex, 1)
            this.setState({ rsvps, successMessage: 'Rsvp was deleted successfully!', rsvpConfirmDeleteIndex: null })

          } else {
            console.error('there was an error deleting the rsvp');
            this.setState({ errorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('The deleting did not work', error);
            this.setState({ errorMessage: error })
        });
      } else if (itemType === 'attendee') {

        let attendees = this.state.attendees
        const _id = attendees[questionIndex]._id

        Axios.delete('/api/attendee/' + _id)
        .then((response) => {
          console.log('tried to  delete', response.data)
          if (response.data.success) {
            //save values
            console.log('Attendee delete worked');

            attendees.splice(questionIndex, 1)
            this.setState({ attendees, successMessage: 'Attendee was deleted successfully!' })

          } else {
            console.error('there was an error deleting the attendee');
            this.setState({ errorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('The deleting did not work', error);
            this.setState({ errorMessage: error })
        });
      } else if (itemType === 'candidatesOfInterest') {
        let candidatesOfInterest = this.state.candidatesOfInterest
        candidatesOfInterest.splice(questionIndex, 1)
        this.setState({ candidatesOfInterest, formHasChanged: true })
      } else if (itemType === 'link') {
        let links = this.state.links
        links.splice(questionIndex, 1)
        this.setState({ links, formHasChanged: true })
      }
    }

    finishPosting(posting, _id, useCustomBenchmark, title, employerName, orgName, orgContactEmail, updatedAt, subPostType) {
      console.log('finishPosting called')

      Axios.post('/api/postings', posting)
      .then((response) => {

        if (response.data.success) {
          //save values
          console.log('Post save worked', response.data);

          let serverSuccessMessage = this.state.postType + ' posted successfully!'
          if (this.state.selectedOpportunity) {
            serverSuccessMessage = this.state.postType + ' saved successfully!'
          }

          this.setState({ serverPostSuccess: true, serverSuccessMessage, isSaving: false })

        } else {
          console.error('there was an error posting the job');
          this.setState({
            serverPostSuccess: false,
            serverErrorMessage: response.data.message, isSaving: false
          })
        }
      }).catch((error) => {
          console.log('The job post did not work', error);
          this.setState({ serverPostSuccess: false, serverErrorMessage: error, isSaving: false })
      });
    }

    renderReadOnlyContent() {
      console.log('renderReadOnlyContent called')

      return (
        <div key="readOnlyContent">
          {(this.state.selectedOpportunity.placementPartners && this.state.selectedOpportunity.placementPartners.includes(this.state.org)) && (
            <div className="top-padding">
              <p className="error-color">This is a {this.state.selectedOpportunity.orgCode} post. Please email help@guidedcompass.com if you want to remove all {this.state.selectedOpportunity.orgCode} posts.</p>
            </div>
          )}


          <div className="top-padding-30">
            <SubRenderOpportunityDetails history={this.props.navigate} activeOrg={this.state.org} favorites={[]} selectedOpportunity={this.state.selectedOpportunity} showHeader={true} showBody={true} />
            <div className="clear" />
          </div>
          <div className="clear" />
        </div>
      )
    }

    itemClicked(value,type) {
      console.log('itemClicked', value, type)

      if (type === 'roleName') {
        this.setState({ attendeeRoleName: value })
      } else if (type === 'attendee') {

        let _id = this.state.attendees[value]._id
        const orgCode = this.state.org
        let saveObject = { _id, orgCode, switchApproval: true }

        Axios.post('/api/attendee', saveObject)
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Attendee update worked', response.data);

            let attendees = this.state.attendees
            attendees[value] = response.data.attendee
            // if (attendees[value].approved) {
            //   attendees[value]['approved'] = false
            // } else {
            //   attendees[value]['approved'] = true
            // }

            const message = 'Attendee updated'
            this.setState({ serverPostSuccess: true, serverSuccessMessage: message, successMessage: message, disableSubmit: false, attendees })

          } else {
            console.error('there was an error saving the attendee change');
            const message = response.data.message
            this.setState({ serverPostSuccess: false, serverErrorMessage: message, errorMessage: message, disableSubmit: false })
          }
        }).catch((error) => {
            console.log('The attendee update did not work', error);
            this.setState({ serverPostSuccess: false, serverErrorMessage: error, disableSubmit: false })
        });
      } else if (type === 'posting') {
        const postingOptions = []
        let postingTitle = ''
        if (value.title) {
          postingTitle = value.title
        } else {
          postingTitle = value.name
        }
        if (value.employerName && value.employerName !== 'No Employer Selected') {
          postingTitle = postingTitle + ' @ ' + value.employerName
        }

        this.setState({ postingOptions, selectedPosting: value, postingTitle, formHasChanged: true })
      } else if (type === 'prospect') {
        console.log('add candidate of interest')

        let item = this.state.prospects[value]

        let candidatesOfInterest = this.state.candidatesOfInterest
        if (candidatesOfInterest) {
          const existsIndex = candidatesOfInterest.findIndex(coi => coi.email === item.email)
          if (existsIndex > -1) {
            this.setState({ errorMessage: 'Cannot add duplicate candidates' })
          } else {
            candidatesOfInterest.push(item)
          }
        } else {
          candidatesOfInterest = [item]
        }

        this.setState({ candidatesOfInterest })
      }
    }

    bulkConvert(type) {
      console.log('bulkConvert called', type)

      if (type === 'cancel') {
        if (this.state.showRSVPs) {
          this.setState({ confirmAttendeeConvert: false })
        } else {
          this.closeModal()
        }
      } else if (type === 'attendee') {

        if (this.state.rsvps && this.state.rsvps.length > 0) {
          let attendees = []
          for (let i = 1; i <= this.state.rsvps.length; i++) {
            let attendeeObject = this.state.rsvps[i - 1]
            attendeeObject['attendeeType'] = this.state.rsvps[i - 1].rsvpType
            attendeeObject['rsvpd'] = true
            attendees.push(attendeeObject)
          }

          const emailId = this.state.emailId
          const postingId = this.state.selectedOpportunity._id

          let attendeesToDelete = this.state.attendees
          const orgCode = this.state.org

          const saveObject = { emailId, postingId, attendees, attendeesToDelete, orgCode }
          Axios.post('/api/attendee/bulk-update', saveObject)
          .then((response) => {

            if (response.data.success) {
              //save values
              console.log('Reserve worked', response.data);

              attendees = response.data.attendees
              const message = 'Attendees added'

              let modalIsOpen = false
              if (this.state.showRSVPs) {
                modalIsOpen = true
              }

              this.setState({ serverPostSuccess: true, serverSuccessMessage: message, successMessage: message, disableSubmit: false, attendees, modalIsOpen, confirmAttendeeConvert: false })

            } else {
              console.error('there was an error adding the attendees');
              const message = response.data.message
              this.setState({ serverPostSuccess: false, serverErrorMessage: message, errorMessage: message, disableSubmit: false
              })
            }
          }).catch((error) => {
              console.log('The attendee bulk post did not work', error);
              this.setState({ serverPostSuccess: false, serverErrorMessage: error, disableSubmit: false })
          });
        } else {
          return this.setState({ serverPostSuccess: false, serverErrorMessage: "You don't have any rsvps" })
        }
      } else if (type === 'pass') {
        if (this.state.attendees && this.state.attendees.length > 0) {

          const emailId = this.state.emailId
          const postingId = this.state.selectedOpportunity._id

          let attendeeIds = []
          let attendees = []
          for (let i = 1; i <= this.state.attendees.length; i++) {
            let attendee = this.state.attendees[i - 1]
            attendeeIds.push(attendee._id)

            attendee['approved'] = true
            attendees.push(attendee)
          }

          const orgCode = this.state.org

          const saveObject = { emailId, postingId, attendeeIds, attendees, orgCode, bulkApprove: true }
          Axios.post('/api/attendee/bulk-update', saveObject)
          .then((response) => {

            if (response.data.success) {
              //save values
              console.log('Bulk update attendees worked', response.data);

              const message = 'Attendees updated'
              this.setState({ serverPostSuccess: true, serverSuccessMessage: message, successMessage: message, disableSubmit: false, attendees, modalIsOpen: false })

            } else {
              console.error('there was an error adding the attendees');
              const message = response.data.message
              this.setState({ serverPostSuccess: false, serverErrorMessage: message, errorMessage: message, disableSubmit: false
              })
            }
          }).catch((error) => {
              console.log('The attendee bulk post did not work', error);
              this.setState({ serverPostSuccess: false, serverErrorMessage: error, disableSubmit: false })
          });
        } else {
          return this.setState({ serverPostSuccess: false, serverErrorMessage: "You don't have any attendees" })
        }
      }
    }

    removeOrg() {
      console.log('removeOrg called', this.state.selectedIndex)

      let trainingProviders = this.state.trainingProviders
      trainingProviders.splice(this.state.selectedIndex,1)
      this.setState({ trainingProviders, modalIsOpen: false,  showOrgDetails: false,
        selectedOrg: null, selectedIndex: null, formHasChanged: true
      })
    }

    addOrgToPosting(selectedOrg) {
      console.log('addOrgToPosting called', selectedOrg)

      let trainingProviders = this.state.trainingProviders

      if (trainingProviders) {
        if (trainingProviders.includes(selectedOrg)) {
          // do nothing
        } else {
          trainingProviders.push(selectedOrg)
        }
      } else {
        trainingProviders = [selectedOrg]
      }

      this.setState({ trainingProviders })
      this.closeModal()

    }

    renderPathways() {
      console.log('renderPathways called')

      let rows = []

      if ((this.state.pathwayOptions && this.state.pathwayOptions.length > 0) && (window.location.pathname.includes('/organizations') || window.location.pathname.includes('/advisor'))) {

        rows.push(
          <div key={"pathways|0"}>
            <div className="spacer" />
            <label className="profile-label">Pathways</label>
          </div>
        )

        for (let i = 1; i <= this.state.pathwayOptions.length; i++) {

          let value = this.state.pathwayOptions[i - 1]
          let optionIndex = i - 1

          let tagText = ''
          let tagContainerClass = ''
          if (value.school) {
            tagText = value.title + " _ " + value.school
            if (this.state.pathways.some(p => (p.title + ' - ' + p.school) === (value.title + ' - ' + value.school))) {
              tagContainerClass = "background-button tag-container-4 float-left top-margin-5 left-margin-5 white-text"
            } else {
              tagContainerClass = "background-button tag-container-5"
            }
          } else if (value.title) {
            tagText = value.title
            if (this.state.pathways.some(p => (p.title) === (value.title))) {
              tagContainerClass = "background-button tag-container-4 float-left top-margin-5 left-margin-5 white-text"
            } else {
              tagContainerClass = "background-button tag-container-5"
            }
          } else {
            tagText = value.name
            if (this.state.pathways.some(p => (p.name) === (value.name))) {
              tagContainerClass = "background-button tag-container-4 float-left top-margin-5 left-margin-5 white-text"
            } else {
              tagContainerClass = "background-button tag-container-5"
            }
          }

          rows.push(
            <div key={"pathway" + i}>
              <div key={optionIndex}>
                <div className="float-left row-10 right-padding">
                  <button className={tagContainerClass} onClick={() => this.optionClicked(value, optionIndex,'pathway')}>
                    <div>
                      <div className="float-left left-text">
                        <p className="description-text-2">{tagText}</p>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          )
        }

        rows.push(
          <div key={"pathways|last"}>
            <div className="clear" />
            <div className="spacer" />
          </div>
        )
      }

      return rows
    }

    renderDepartments() {
      console.log('renderDepartments called')

      return (
        <div key="renderDepartments">
          {(this.state.departmentOptions && this.state.departmentOptions.length > 0 && (window.location.pathname.includes('/organizations') || window.location.pathname.includes('/advisor'))) && (
            <div className="row-10">
              <label className="profile-label">Departments</label>
              {this.state.departmentOptions.map((value, optionIndex) =>
                <div key={optionIndex}>
                  <div className="float-left row-10 right-padding">
                    <div>
                      {(this.state.departments.some(d => (d.name) === (value.name))) ? (
                        <button className="background-button tag-container-4 float-left top-margin-5 left-margin-5" onClick={() => this.optionClicked(value, optionIndex,'department')}>
                          <div>
                            <div className="float-left left-text">
                              <p className="description-text-2 white-text">{value.name}</p>
                            </div>
                          </div>
                        </button>
                      ) : (
                        <button className="background-button tag-container-5" onClick={() => this.optionClicked(value, optionIndex,'department')}>
                          <div>
                            <div className="float-left left-text">
                              <p className="description-text-2">{value.name}</p>
                            </div>
                          </div>
                        </button>
                      )}
                    </div>

                  </div>
                </div>
              )}
              <div className="clear" />
            </div>
          )}
        </div>
      )
    }

    renderTargetRoles() {
      console.log('renderTargetRoles called')

      return (
        <div key="renderTargetRoles">
          {(this.state.org === 'unite-la' && this.state.targetRoleOptions && this.state.targetRoleOptions.length > 0 && (window.location.pathname.includes('/organizations') || window.location.pathname.includes('/advisor'))) && (
            <div className="row-10">
              <label className="profile-label">Target Roles (If selected, it will only show up for their portal)</label>
              {this.state.targetRoleOptions.map((value, optionIndex) =>
                <div key={optionIndex}>
                  <div className="float-left row-10 right-padding">
                    <div>
                      {(this.state.targetRoles.some(d => (d) === (value))) ? (
                        <button className="background-button tag-container-4 float-left top-margin-5 left-margin-5" onClick={() => this.optionClicked(value, optionIndex,'targetRole')}>
                          <div>
                            <div className="float-left left-text">
                              <p className="description-text-2 white-text">{value}</p>
                            </div>
                          </div>
                        </button>
                      ) : (
                        <button className="background-button tag-container-5" onClick={() => this.optionClicked(value, optionIndex,'targetRole')}>
                          <div>
                            <div className="float-left left-text">
                              <p className="description-text-2">{value}</p>
                            </div>
                          </div>
                        </button>
                      )}
                    </div>

                  </div>
                </div>
              )}
              <div className="clear" />
            </div>
          )}
        </div>
      )
    }

    renderPrograms() {
      console.log('renderPrograms called')

      return (
        <div key="renderPrograms">
          {(this.state.programOptions && this.state.programOptions.length > 0 && (window.location.pathname.includes('/organizations') || window.location.pathname.includes('/advisor'))) && (
            <div className="row-10">
              <label className="profile-label">Programs</label>
              {this.state.programOptions.map((value, optionIndex) =>
                <div key={optionIndex}>
                  {(value.title) && (
                    <div className="float-left row-10 right-padding">
                      <div>
                        {(this.state.selectedPrograms && this.state.selectedPrograms.some(d => (d.title) === (value.title))) ? (
                          <button className="background-button tag-container-4 float-left top-margin-5 left-margin-5" onClick={() => this.optionClicked(value, optionIndex,'program')}>
                            <div>
                              <div className="float-left left-text">
                                <p className="description-text-2 white-text">{value.title}</p>
                              </div>
                            </div>
                          </button>
                        ) : (
                          <button className="background-button tag-container-5" onClick={() => this.optionClicked(value, optionIndex,'program')}>
                            <div>
                              <div className="float-left left-text">
                                <p className="description-text-2">{value.title}</p>
                              </div>
                            </div>
                          </button>
                        )}
                      </div>

                    </div>
                  )}
                </div>
              )}
              <div className="clear" />
            </div>
          )}
        </div>
      )
    }

    renderSharePartners() {
      console.log('renderSharePartners')

      return (
        <div key="renderSharePartners">
          <div className="row-10">
            <div>
              <div className="float-left">
                <p className="heading-text-4">Training/Referral Partners</p>
              </div>
              <div>
                <div className="float-left left-margin width-30 height-30 cta-border circle-radius center-text">
                  <button className="background-button cta-color clear-margin clear-padding padding-7" onClick={() => this.setState({ modalIsOpen: true, addTrainingPartners: true })}>
                    <img src={addIconBlue} alt="GC" className="image-14-fit center-item" />
                  </button>
                </div>
              </div>
            </div>

            <div className="clear" />
            <p className="description-text-2 standard-color bottom-margin top-margin-5">Tagged partners will be view this posting, and community members can apply to it and refer candidates for it.</p>

            <div className="spacer" />

            {(this.state.trainingProviders && this.state.trainingProviders.length > 0) ? (
              <div>
                <div className="row-10">
                  {this.state.trainingProviders.map((item, index) =>
                    <div key={"trainingProviders" + index}>
                      {(item.orgName !== 'Sandbox') && (
                        <div className="full-width bottom-margin">
                          <button className="background-button full-width left-text" onClick={() => this.setState({ modalIsOpen: true, showOrgDetails: true, selectedOrg: item })}>
                            <div className="fixed-column-40 top-padding">
                              <img src={trashIconDark} alt="GC" className="image-20-fit" />
                            </div>
                            <div className="fixed-column-60">
                              <img src={(item.webLogoURIColor) ? item.webLogoURIColor : industryIconDark} alt="GC" className="image-40-fit" />
                            </div>
                            <div className="calc-column-offset-130 left-padding top-padding-5">
                              <p className="heading-text-6 full-width">{item.orgName}</p>
                            </div>
                            <div className="fixed-column-30 top-padding-5">
                              <img src={arrowIndicatorIcon} alt="GC" className="image-auto-15" />
                            </div>
                            <div className="clear" />
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="clear" />
                </div>
              </div>
            ) : (
              <p className="description-text-color description-text-2 row-20">No training providers yet. Add them <button className="background-button cta-color" onClick={() => this.setState({ modalIsOpen: true, addTrainingPartners: true })}>here</button>.</p>
            )}
          </div>

          <hr />

        </div>
      )
    }

    renderTaggedCandidatesOfInterest() {
      console.log('renderTaggedCandidatesOfInterest called')

      return (
        <div key="renderTaggedCandidatesOfInterest">
          <div>
            <div className="row-10">
              <label className="profile-label">Tag Candidates of Interest (Optional)</label>
              <p className="description-text-2">Add up to 10 candidates you would like to invite to {(this.state.postType === 'Event') ? "RSVP" : (this.state.postType === 'Project') ? "Submit" : "Apply"} to this posting.</p>
            </div>

            <div>
              <div className="calc-column-offset-100 right-padding">
                <input type="text" className="text-field" placeholder="Search a name or email..." name="assigneeName" value={this.state.assigneeName} onChange={this.formChangeHandler}></input>
              </div>
              <div className="fixed-column-100">
                <button className="btn btn-squarish" onClick={() => this.addItem('candidatesOfInterestSearched')}>Add</button>
              </div>
              <div className="clear" />
            </div>

            <div className="clear" />
            <div className="spacer" />

            {(this.state.suggestedAssignees.length > 0) && (
              <div>
                <div className="spacer" />
                {this.state.suggestedAssignees.map(value =>
                  <div key={value._id} className="row-5">
                    <button className="background-button" onClick={() => this.assigneeClicked(value)}>
                      <div>
                        <div className="float-left right-padding">
                          <img src={profileIconBlue} alt="Compass employer icon icon" className="image-auto-22" />
                        </div>
                        <div className="float-left">
                          <p className="cta-color">{value.firstName} {value.lastName}</p>
                        </div>
                      </div>
                    </button>
                  </div>)}
              </div>
            )}

            {this.state.candidatesOfInterest && this.state.candidatesOfInterest.map((value, optionIndex) =>
              <div key={value.name + "|" + optionIndex} className="float-left">
                <div className="close-button-container-1">
                  <button className="background-button" onClick={() => this.removeItem(optionIndex,'candidateOfInterest')}>
                    <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                  </button>
                </div>
                <div className="float-left row-5 right-padding">
                  <div className="tag-container-5">
                    <p className="description-text-2">{value.firstName} {value.lastName}</p>
                  </div>
                </div>
              </div>
            )}

            <div className="clear" />

            <div className="spacer" /><div className="spacer" />
          </div>
        </div>
      )
    }

    renderTaggedPostings() {
      console.log('renderTaggedPostings called')

      return (
        <div key="renderTaggedPostings">
          <div>
            <label className="profile-label">Tag Postings (Optional)</label>
            <p className="description-text-2">Reference other postings in this master posting.</p>
            <div className="spacer" />

            <div>
              <div className="calc-column-offset-100 right-padding">
                <input type="text" className="text-field" placeholder="Search a posting..." name="postingTitle" value={this.state.postingTitle} onChange={this.formChangeHandler}></input>
              </div>
              <div className="fixed-column-100">
                <button className="btn btn-squarish" onClick={() => this.addItem('posting')}>Add</button>
              </div>
              <div className="clear" />
            </div>

            <div className="clear" />
            <div className="spacer" />

            {(this.state.isLoading) && (
              <div className="flex-container flex-center row-10">
                <div>
                  <img src={loadingGIF} alt="GC" className="image-auto-80 center-horizontally"/>
                  <div className="spacer" /><div className="spacer"/><div className="spacer"/>
                  <p className="center-text cta-color bold-text">Retrieving info...</p>
                </div>
              </div>
            )}

            {(this.state.postingOptions && this.state.postingOptions.length > 0) && (
              <div>
                <div className="spacer" />
                {this.state.postingOptions.map(value =>
                  <div key={value._id} className="row-5">
                    <button className="background-button full-width" onClick={() => this.itemClicked(value,'posting')}>
                      <div>
                        <div className="fixed-column-30">
                          <img src={profileIconBlue} alt="GC" className="image-auto-22" />
                        </div>
                        <div className="calc-column-offset-30">
                          <p className="cta-color left-text">{(value.title) ? value.title : value.name}{(value.employerName && value.employerName !== 'No Employer Selected') && ' @ ' + value.employerName} ({value.postType})</p>
                        </div>
                        <div className="clear" />
                      </div>
                    </button>
                  </div>)}
              </div>
            )}

            {this.state.taggedPostings && this.state.taggedPostings.map((value, optionIndex) =>
              <div key={value.name + "|" + optionIndex} className="float-left">
                <div className="close-button-container-1">
                  <button className="background-button" onClick={() => this.removeItem(optionIndex,'posting')}>
                    <img src={deniedIcon} alt="GC" className="image-auto-20" />
                  </button>
                </div>
                <div className="float-left row-5 right-padding">
                  <div className="tag-container-5">
                    <p className="description-text-2">{(value.title) ? value.title : value.name}{(value.employerName && value.employerName !== 'No Employer Selected') && ' @ ' + value.employerName}</p>
                  </div>
                </div>
              </div>
            )}
            <div className="clear" />
          </div>

          <div className="spacer" /><div className="spacer" />
          <hr />
          <div className="spacer" /><div className="spacer" />
        </div>
      )
    }

    renderConfirmDelete(modalIsOpen) {
      console.log('renderConfirmDelete called')

      const self = this
      function cancelDelete() {
        console.log('cancelDelete called')

        self.setState({ confirmDelete: false })
        self.closeModal()

      }

      return (
        <div key="renderConfirmDelete">
          <div className="bottom-margin-20">
            <p className="error-color">Are you sure you want to delete this posting? All information saved, applications received, and other related data will be lost forever.</p>
          </div>
          <div className="clear" />
          <div className="float-left right-padding-20 top-margin">
            <button className="btn btn-quaternary" onClick={() => this.deletePost(true)}>{(this.state.postType === 'Internship' || this.state.postType === 'Individual' || this.state.postType === 'Work') ? "Delete and Notify Applicants" : "Delete"}</button>
          </div>
          {(this.state.postType === 'Internship' || this.state.postType === 'Individual' || this.state.postType === 'Work') && (
            <div className="float-left right-padding-20 top-margin">
              <button className="btn btn-secondary error-border error-color" onClick={() => this.deletePost(false)}>Delete Without Notifying Applicants</button>
            </div>
          )}
          <div className="float-left right-padding-20 top-margin">
            <button className="btn btn-secondary" onClick={() => cancelDelete()}>Cancel</button>
          </div>
          <div className="clear" />
        </div>
      )
    }

    renderConfirmationMessage() {
      console.log('renderConfirmationMessage called')

      let messageDescription = "This message will go out to those who RSVP to this event."
      let subjectLinePlaceholder = "Thank you for rsvping to our event!"
      let messageBodyPlaceholder = "Thank you for RSVPing to our event. Before you attend, be sure to complete the following steps..."
      let subjectLineShortname = "rsvpConfirmationSubjectLine"
      let messageBodyShortname = "rsvpConfirmationMessage"
      if (this.state.postType === 'Project') {
        messageDescription = "This message will go out to those who submit projects to this posting."
        subjectLinePlaceholder = "Thank you for submitting your project to this posting!"
        messageBodyPlaceholder = "Thank you for submitting your project to this posting. As for next steps, first..."
        subjectLineShortname = "projectConfirmationSubjectLine"
        messageBodyShortname = "projectConfirmationMessage"
      } else if (this.state.postType === 'Program' || this.state.postType === 'Work') {
        messageDescription = "This message will go out to those apply for this role."
        subjectLinePlaceholder = "Thank you for applying for this role!"
        messageBodyPlaceholder = "Thank you for submitting your project to this posting. As for next steps, first..."
        subjectLineShortname = "applicationConfirmationSubjectLine"
        messageBodyShortname = "applicationConfirmationMessage"
      }
      return (
        <div key="renderConfirmationMessage">
          <div className="row-10">
            <div className="row-10">
              <div className="float-left">
                <p className="heading-text-4">Confirmation Message</p>
              </div>
              <div>
                <div className="float-left left-margin noti-bubble-info-7-9">
                  <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showHandlebarsInfo: true })}>
                    <img src={questionMarkBlue} alt="GC" className="image-auto-14 center-item" />
                  </a>
                </div>
              </div>
            </div>

            <div className="clear" />
            <p className="description-text-2 standard-color bottom-margin top-margin-5">{messageDescription}</p>

            <div className="row-10">
              <label className="profile-label">Subject Line</label>
              <input type="text" className="text-field" placeholder={subjectLinePlaceholder} name={subjectLineShortname} value={this.state[subjectLineShortname]} onChange={this.formChangeHandler}></input>
            </div>

            <div className="row-10">
              <label className="profile-label">Message Body</label>
              <textarea type="text" className="text-field" placeholder={messageBodyPlaceholder} name={messageBodyShortname} value={this.state[messageBodyShortname]} onChange={this.formChangeHandler}></textarea>
            </div>
          </div>

          <div className="spacer" />
          <hr />
          <div className="spacer" /><div className="spacer" />

          {this.renderNotiTags()}
        </div>
      )
    }

    renderApplicationMethodDetails() {
      console.log('renderApplicationMethodDetails called')

      return (
        <div key="renderApplicationMethodDetails">
          <div className="row-30">
            <hr className="clear-margin clear-padding"/>
          </div>

          <p className="heading-text-3">Application Method Details</p>
          <div className="spacer" />

          <div className="edit-profile-row">
            <label className="profile-label">What is your preferred method of receiving applications?</label>
            <select name="applicationMethod" value={this.state.applicationMethod} onChange={this.formChangeHandler} className="dropdown">
              {this.state.applicationMethodOptions.map(value =>
                <option key={value} value={value}>{value}</option>
              )}
            </select>
          </div>

          <div className="edit-profile-row">
            <div className="name-container">
              <label className="profile-label">Contact First Name:<label className="error-color bold-text">*</label></label>
              <input type="text" className="text-field" placeholder="First name..." name="employerContactFirstName" value={this.state.employerContactFirstName} onChange={this.formChangeHandler}></input>
            </div>
            <div className="name-container">
              <label className="profile-label">Contact Last Name:<label className="error-color bold-text">*</label></label>
              <input type="text" className="text-field" placeholder="Last name..." name="employerContactLastName" value={this.state.employerContactLastName} onChange={this.formChangeHandler}></input>
            </div>
            <div className="clear" />
          </div>

          <div className="edit-profile-row clear-padding">
            <div className="name-container">
              <label className="profile-label">Contact Email:<label className="error-color bold-text">*</label></label>
              <input type="text" className="text-field" placeholder="Email address..." name="employerContactEmail" value={this.state.employerContactEmail} onChange={this.formChangeHandler}></input>
            </div>
            <div className="name-container">
              <label className="profile-label">Application components:</label>
              <input type="text" className="text-field" placeholder="e.g. resume, cover letter, writing samples, letters of recommendation..." name="appRequirements" value={this.state.appRequirements} onChange={this.formChangeHandler}></input>
            </div>
            <div className="clear" />
          </div>

          {(this.state.applicationMethod === 'Applicants may be referred, but they must also apply via email') && (
            <div className="edit-profile-row">
              <label className="profile-label">Subject line:<label className="error-color bold-text">*</label></label>
              <input type="text" className="text-field" placeholder="Please write the desired subject line..." name="subjectLine" value={this.state.subjectLine} onChange={this.formChangeHandler}></input>
            </div>
          )}
          {(this.state.applicationMethod === 'Applicants may be referred, but they must also apply via our website posting') && (
            <div className="edit-profile-row">
              <label className="profile-label">Web link to posting:<label className="error-color bold-text">*</label></label>
              <input type="text" className="text-field" placeholder="Please add the link to the opening..." name="jobLink" value={this.state.jobLink} onChange={this.formChangeHandler}></input>
              {(this.state.jobLink && this.state.jobLink !== '' && !this.state.jobLink.startsWith('http')) && (
                <div>
                  <p className="error-message">Please start your link with http</p>
                </div>
              )}
            </div>
          )}
        </div>
      )
    }

    renderApplicationComponents() {
      console.log('renderApplicationComponents called')

      return (
        <div key="renderApplicationComponents">

          <div className="row-20">
            <p className="heading-text-3">Application Components</p>
            <p className="profile-descriptor top-margin-5">Select what should be included in each application.</p>
          </div>

          <div className="spacer" />

          <div>
            <div className="half-bold-text description-text-1">
              <div className="relative-column-5">
                <p>No.</p>
              </div>
              <div className="relative-column-45">
                <p>Name</p>
              </div>
              <div className="relative-column-25 center-text">
                <p>Include?</p>
              </div>
              <div className="relative-column-25 center-text">
                <p>Required?</p>
              </div>
              <div className="clear" />
            </div>
            {this.state.appComponents.map((value, index) =>
              <div className="row-5 heading-text-6">
                <div className="relative-column-5">
                  <p>{index + 1}.</p>
                </div>
                <div className="relative-column-45">
                  <p>{value.name}</p>
                </div>
                <div className="relative-column-25 center-text top-padding">
                  <Switch
                    onChange={(change) => this.switchPreference('app','include', index, change)}
                    checked={value.include}
                    id="normal-switch"
                    disabled={value.disabled}
                  />
                </div>
                <div className="relative-column-25 center-text top-padding">
                  <Switch
                    onChange={(change) => this.switchPreference('app','required', index, change)}
                    checked={value.required}
                    id="normal-switch"
                    disabled={value.disabled}
                  />
                </div>
                <div className="clear" />
              </div>
            )}
          </div>

          <hr className="vertical-margin-20" />

          {(this.state.includeCustomAssessment || this.state.appComponents[this.state.appComponents.length - 1].include) && (
            <div>
              {this.renderCustomAssessment()}
            </div>
          )}
        </div>
      )
    }

    renderImageAsTitle() {
      console.log('renderImageAsTitle called')

      return (
        <div key="renderImageAsTitle">
          <div className="row-10">
            <div className="container-left">
              <label className="profile-label">Use Image As Title</label>
              <Switch
                onChange={(change) => this.setState({ useImageTitle: change, formHasChanged: true })}
                checked={this.state.useImageTitle}
                id="normal-switch"
              />
            </div>
            {(this.state.useImageTitle) && (
              <div className="container-right">
                <label className="profile-label">Hide Text As Title</label>
                <Switch
                  onChange={(change) => this.setState({ hideTextTitle: change, formHasChanged: true })}
                  checked={this.state.hideTextTitle}
                  id="normal-switch"
                />
              </div>
            )}
            <div className="clear" />
          </div>
          {(this.state.useImageTitle) && (
            <div className="row-10">
              <label className="profile-label">Link to Image</label>
              <input type="text" className="text-field" placeholder="Add link to image..." name="imageTitleURL" value={this.state.imageTitleURL} onChange={this.formChangeHandler}></input>
            </div>
          )}

          {(this.state.useImageTitle) && (
            <div className="standard-border padding-30">
              <img src={(this.state.imageTitleURL) ? this.state.imageTitleURL : imageIconDark} alt="GC" className="image-full-auto" />
            </div>
          )}
        </div>
      )
    }

    renderWorkFunctions() {
      console.log('renderWorkFunctions called')

      return (
        <div key="renderWorkFunctions">
          <div className="row-10">
            <label className="profile-label">Tap Work Functions This Applies To</label>
            <div className="float-left">
              {this.renderTags('functions')}
            </div>
            <div className="clear"/>
          </div>
        </div>
      )
    }

    renderVisibilityOptions() {
      console.log('renderVisibilityOptions called')

      return (
        <div key="renderVisibilityOptions">
          <div className="row-10">
            <div className="container-left">
              <div>
                <div className="float-left right-padding">
                  <label className="profile-label">Is Active/Visible?</label>
                </div>
                <div className="float-left">
                  <div className="mini-spacer" />
                  <div className="noti-bubble-info-7-9 top-margin-5">
                    <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showActiveDefinition: true })}>
                      <img src={questionMarkBlue} alt="GC" className="image-auto-14 center-item" />
                    </a>
                  </div>
                </div>
                <div className="clear" />
              </div>

              <Switch
                onChange={(change) => this.setState({ isActive: change, formHasChanged: true })}
                checked={this.state.isActive}
                id="normal-switch"
              />
            </div>
            {(this.state.isActive) && (
              <div className="container-right">
                <div>
                  <div className="float-left right-padding">
                    <label className="profile-label">Is Unlisted?</label>
                  </div>
                  <div className="float-left">
                    <div className="mini-spacer" />
                    <div className="noti-bubble-info-7-9 top-margin-5">
                      <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showUnlistedDefinition: true })}>
                        <img src={questionMarkBlue} alt="GC" className="image-auto-14 center-item" />
                      </a>
                    </div>
                  </div>
                  <div className="clear" />
                </div>

                <Switch
                  onChange={(change) => this.setState({ isUnlisted: change, formHasChanged: true })}
                  checked={this.state.isUnlisted}
                  id="normal-switch"
                />
              </div>
            )}

            <div className="clear" />
          </div>
        </div>
      )
    }

    renderDateRange() {
      console.log('renderDateRange called')

      return (
        <div key="renderDateRange">

          {(!this.state.preSetStartDate) ? (
            <div>
              <div className="edit-profile-row">
                <div className="anonymous-checkmark">
                  <input type="checkbox" name="isPerpetual" checked={this.state.isPerpetual} onChange={this.formChangeHandler} />
                </div>
                <div className="anonymous-descriptor">
                  <label className="description-text-1">This opportunity is continual</label>
                </div>
              </div>

              <div className="clear"/>

              <div className="edit-profile-row">
                <div className="container-left">
                  <label className="profile-label">Ideal Start Date{(!this.state.disableSomeRequirements) && <label className="error-color bold-text">*</label>}</label>
                  <input type="date" className="date-picker" placeholder="Add the start date..." min={convertDateToString(new Date(),"rawDateForInput")} name="startDate" value={this.state.startDate} onChange={this.formChangeHandler}></input>
                </div>

                <div className="container-right">
                  {(this.state.isPerpetual) ? (
                    <div>
                      <label className="profile-label">Ideal End Date</label>
                      <label className="heading-text-6">Continual</label>
                    </div>
                  ) : (
                    <div>
                      <label className="profile-label">Ideal End Date</label>
                      <input type="date" className="date-picker" placeholder="Add the end date..." min={convertDateToString(new Date(),"rawDateForInput")} name="endDate" value={this.state.endDate} onChange={this.formChangeHandler}></input>
                    </div>
                  )}
                </div>
                <div className="clear"/>
              </div>

            </div>
          ) : (
            <div>

              <div className="edit-profile-row">
                <div className="container-left">
                  <label className="profile-label">Ideal Start Date<label className="error-color bold-text">*</label></label>
                  <p>{this.state.preSetStartDate}</p>
                </div>

                <div className="container-right">
                  <div>
                    <label className="profile-label">Ideal End Date</label>
                    <p>{this.state.preSetEndDate}</p>
                  </div>
                </div>
                <div className="clear"/>
              </div>

            </div>
          )}
        </div>
      )
    }

    renderDescription() {
      console.log('renderDescription called')

      let descriptionLabel = "Description of the Project Prompt"
      let descriptionPlaceholder = "(e.g., design our logo)..."

      if (this.state.postType === 'Event') {
        descriptionLabel = "Event Description"
        descriptionPlaceholder = "Add a description for the event..."
      } else if (this.state.postType === 'Program') {
        descriptionLabel = "Program Description"
        descriptionPlaceholder = "Add a description of the training program..."
      } else if (this.state.postType === 'Work') {
        descriptionLabel = "Job Description"
        descriptionPlaceholder = "Add a job description for the role..."
      }

      return (
        <div key="renderDescription">
          {(this.state.postType === 'Project') && (
            <div className="row-10">
              <label className="profile-label">Would you like to provide a description internally or link to a document?<label className="error-color bold-text">*</label></label>
              <select name="isExternal" value={this.state.isExternal} className="dropdown" onChange={this.formChangeHandler}>
                  <option />
                  <option value={false}>Provide the description below (internal)</option>
                  <option value={true}>Provide an external link for the description (external)</option>
              </select>
            </div>
          )}

          {(this.state.isExternal === 'true') ? (
            <div>
              <input type="text" className="text-field" placeholder="Add source..." name="source" value={this.state.source} onChange={this.formChangeHandler}></input>
              {(this.state.source && this.state.source !== '' && !this.state.source.startsWith('http')) && (
                <div>
                  <p className="error-message">Please start your link with http</p>
                </div>
              )}
            </div>
          ) : (
            <div className="edit-profile-row">
              <label className="profile-label">{descriptionLabel}<label className="error-color bold-text">*</label></label>

              {(this.state.showRich) ? (
                <div>
                  <MyEditor ref={this.child} existingContent={this.state.richDescription} indicateFormChange={this.indicateFormChange}/>
                </div>
              ) : (
                <textarea type="text" className="text-field calc-height-half-view" placeholder={descriptionPlaceholder} name="description" value={this.state.description} onChange={this.formChangeHandler} maxLength="2000"></textarea>
              )}

              <label className="description-text-3 description-text-color half-bold-text">2,000 Word Limit</label>

            </div>
          )}
        </div>
      )
    }

    renderOrgContactInformation() {
      console.log('renderOrgContactInformation called')

      return (
        <div key="renderOrgContactInformation">
          <div className="row-20">
            <p className="heading-text-3">Org Contact Information</p>
          </div>

          <div>
            <div className="edit-profile-row">
              <div className="name-container">
                <label className="profile-label">Internal Contact First Name:<label className="error-color bold-text">*</label></label>
                <input type="text" className="text-field" placeholder="First name..." name="orgContactFirstName" value={this.state.orgContactFirstName} onChange={this.formChangeHandler}></input>
              </div>
              <div className="name-container">
                <label className="profile-label">Internal Contact Last Name:<label className="error-color bold-text">*</label></label>
                <input type="text" className="text-field" placeholder="Last name..." name="orgContactLastName" value={this.state.orgContactLastName} onChange={this.formChangeHandler}></input>
              </div>
              <div className="clear" />
            </div>

            <div className="edit-profile-row clear-padding">
              <div className="name-container">
                <label className="profile-label">Internal Contact Email:<label className="error-color bold-text">*</label></label>
                <input type="text" className="text-field" placeholder="Email address..." name="orgContactEmail" value={this.state.orgContactEmail} onChange={this.formChangeHandler}></input>
              </div>
              <div className="clear" />
            </div>
          </div>
        </div>
      )
    }

    renderCustomAssessment() {
      console.log('renderCustomAssessment called')

      return (
        <div key="renderCustomAssessment">
          {(this.state.org === 'guidedcompass' && window.location.pathname.startsWith('/employers')) ? (
            <div>
              <div className="row-10">
                <div className={(window.innerWidth < 767) ? "calc-column-offset-40" : "float-left right-padding"}>
                  <p className="heading-text-3">Add Screening Questions (Optional)</p>
                </div>
                <div className={(window.innerWidth < 767) ? "fixed-column-40 top-padding-5" : "float-left top-padding-5"}>
                  <button className="background-button" onClick={() => this.addItem('screeningQuestion',null)}>
                      <div className="padding-5 cta-border circle-radius">
                        <img src={addIconBlue} alt="GC" className="image-auto-15" />
                      </div>
                  </button>
                </div>
                <div className="clear" />
                <p className="description-text-2">Add up to 10 screening questions. <button className="background-button cta-color bold-text" onClick={() => this.setState({ modalIsOpen: true, showInfoCollected: true })}>Click Here</button> for information we already collect.</p>
              </div>

              <div className="spacer" />

              {this.renderQuestions('screeningQuestions')}
              <div className="clear" />

              <div className="spacer" /><div className="spacer" />
              <hr />

              <div className="spacer" /><div className="spacer" />
            </div>
          ) : (
            <div>
              <p className="heading-text-3">Custom Assessment</p>
              <p className="profile-descriptor top-margin-5">Templates can be created and saved in the assessments tab</p>

              <div className="edit-profile-row">
                  <label className="profile-label">Include a Template</label>
                  <select name="customAssessment" value={this.state.customAssessment.title} onChange={this.formChangeHandler} className="dropdown">
                    {this.state.customAssessmentOptions.map(value =>
                      <option key={value.key} value={value.title}>{value.title}</option>
                    )}
                  </select>
              </div>

              <div className="clear" />
              <br/>

              <hr className="vertical-margin-20" />
            </div>
          )}
        </div>
      )
    }

    render() {

      let payRangeOptions = this.state.hourlyPayRangeOptions
      if (this.state.payType === 'Salary') {
        payRangeOptions = this.state.annualPayRangeOptions
      }

      let statusBarClass = "vertical-bar unselected-background-color"
      let statusTextClass = "heading-text-6 unselected-color"
      if (this.state.status === 'Approved') {
        statusBarClass = "vertical-bar cta-background-color"
        statusTextClass = "cta-color"
      } else if (this.state.status === 'Denied') {
        statusBarClass = "vertical-bar error-background-color"
        statusTextClass = "cta-color"
      }

      let pathPrefix = ""
      if (window.location.pathname.includes('/organizations')) {
        pathPrefix = "/organizations/" + this.state.org
      } else if (window.location.pathname.includes('/employers')) {
        pathPrefix = "/employers/" + this.state.accountCode
      } else if (window.location.pathname.includes('/advisor')) {
        pathPrefix = "/advisor"
      }

      let cardClass = 'standard-container-2'
      if (window.location.pathname.includes('/walkthrough') || (window.innerWidth > 768 && this.state._id)) {
        cardClass = 'full-width'
      }

      return (
          <div>
              <div className={cardClass}>
                <div className={(window.innerWidth > 768 && this.state._id && !window.location.pathname.includes('/walkthrough')) ? "relative-column-70 padding-five-percent-left" : ""}>
                  <div className={(window.innerWidth > 768 && this.state._id && !window.location.pathname.includes('/walkthrough')) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-right" : ""}>
                    {(window.innerWidth > 768 && this.state._id && !window.location.pathname.includes('/walkthrough')) && (
                      <div className="full-width height-5 dark-background" />
                    )}

                    <div className={(window.innerWidth > 768 && this.state._id && !window.location.pathname.includes('/walkthrough')) ? "padding-40" : ""}>
                      <div>
                        <div className="calc-column-offset-60">
                          {(this.state.path && this.state.path.includes('/problem-platform')) ? (
                            <div className="right-padding-30 row-20">
                              <div className="spacer" />
                              <h2>Post a Virtual Project Opportunity</h2>
                              <p>You can post project opportunities here for <label className="half-bold-text">free</label>. We'll give you an email notification of your request and a heads up before they go live.</p>
                            </div>
                          ) : (
                            <div className="heading-text-2">
                              <div className="spacer" />
                              {(this.state.selectedOpportunity) ? (
                                <div>
                                  <p>{(this.state.selectedOpportunity.title) ? this.state.selectedOpportunity.title : this.state.selectedOpportunity.name}{(this.state.selectedOpportunity.employerName && this.state.selectedOpportunity.employerName !== 'No Employers Saved' && this.state.selectedOpportunity.employerName !== 'No Employer Selected') && " @ " + this.state.selectedOpportunity.employerName} Posting</p>
                                </div>
                              ) : (
                                <div>
                                  <div className="row-10 top-margin">
                                    {(this.state.postType) ? (
                                      <div>

                                        {(this.state.postType.startsWith('A') || this.state.postType.startsWith('E') || this.state.postType.startsWith('I') || this.state.postType.startsWith('O') || this.state.postType.startsWith('U')) ? (
                                          <div>
                                            <p>Post an {this.state.postType}</p>
                                          </div>
                                        ) : (
                                          <div>
                                            {(this.state.postType === 'Work') ? (
                                              <p>Post a {this.state.postType} Opportunity</p>
                                            ) : (
                                              <p>Post a {this.state.postType}</p>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      <div>
                                        <p>Add a Posting</p>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}

                              {(this.props.duplicate) ? (
                                <div className="row-10">
                                  <p className="description-text-5 error-color bold-text">This is a duplicated posting. Saving this will create a new posting.</p>
                                </div>
                              ) : (
                                <div className="spacer" />
                              )}

                            </div>
                          )}
                        </div>
                        {(this.state.path && this.state.path.includes('/problem-platform') && this.state.webLogoURIColor) && (
                          <div className="fixed-column-60 right-padding">
                            <div className="spacer" /> <div className="spacer" /> <div className="spacer" /> <div className="spacer" />
                            <img src={this.state.webLogoURIColor} alt="img" className="image-auto-50" />
                          </div>
                        )}

                        <div className="clear" />
                      </div>

                      {(this.state.path && this.state.path.includes('/problem-platform')) && (
                        <div>
                          <div className="outlined-container">
                            <div className="col span-1-of-3 box">
                              <div className="calc-column-offset-40">
                                <p className="heading-text-3">#1 Assignments</p>
                              </div>
                              <div className="fixed-column-40 row-5 right-padding-5">
                                <div className="spacer" />
                                <img src={assignmentsIconBlue} alt="Intern icon Guided Compass" className="image-auto-25" />
                              </div>
                              <div className="clear" />

                              <p>Assignments are explicit prompts mimicking freelance work assignments (e.g. design a logo) that career seekers may submit projects to.</p>
                              <div className="half-spacer" />
                              <Link to={'/problem-platform/assignments'} className="description-text-3 half-bold-text">View Examples</Link>
                              <div className="half-spacer" />

                            </div>
                            <div className="col span-1-of-3 box">
                              <div className="calc-column-offset-40">
                                <p className="heading-text-3">#2 Problems</p>
                              </div>
                              <div className="fixed-column-40 row-5 right-padding-5">
                                <div className="spacer" />
                                <img src={caseIconBlue} alt="Case icon Guided Compass" className="image-auto-25" />
                              </div>
                              <div className="clear" />
                              <div className="half-spacer" />
                              <p>Problems are virtual <a href="https://en.wikipedia.org/wiki/Cooperative_education" target="_blank" rel="noopener noreferrer" className="half-bold-text">case studies</a> posted to Guided Compass. Career seekers may submit project solutions, vote on submissions, and comment.</p>

                              <Link to={'/problem-platform/problems'} className="description-text-3 half-bold-text">View Examples</Link>
                              <div className="half-spacer" />
                            </div>
                            <div className="col span-1-of-3 box">
                              <div className="calc-column-offset-40">
                                <p className="heading-text-3">#3 Challenges</p>
                              </div>
                              <div className="fixed-column-40 row-5 right-padding-5">
                                <div className="spacer" />
                                <img src={challengeIconBlue} alt="Challenge icon Guided Compass" className="image-auto-25" />
                              </div>
                              <div className="clear" />
                              <div className="half-spacer" />
                              <p>Challenges are case studies with awards, rules, and a submission deadline. Winners may be decided by judges or community voting.</p>

                              <Link to={'/problem-platform/challenges'} className="description-text-3 half-bold-text">View Examples</Link>
                              <div className="half-spacer" />
                            </div>

                            <div className="clear" />
                          </div>

                          <div className="spacer" /><div className="spacer" />
                        </div>
                      )}

                      {(!this.state.selectedOpportunity) && (
                        <div>
                          <div className="container-left">
                              <label className="profile-label">Post Type</label>
                              <select name="postType" value={this.state.postType} onChange={this.formChangeHandler} className="dropdown">
                                  <option />
                                  {this.state.postTypeOptions.map(value =>
                                    <option key={value.key} value={value}>{value}</option>
                                  )}
                              </select>
                          </div>
                          {(this.state.path && this.state.path.includes('/problem-platform') && this.state.path.endsWith('/add')) && (
                            <div className="container-right">
                              <div className="float-left">
                                <label className="profile-label">Affiliated Organization (Optional)</label>
                              </div>
                              <div>
                                <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                <div className="float-left left-margin noti-bubble-info-7-9">
                                  <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showOrgInfo: true })}>
                                    <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                                  </a>
                                </div>

                              </div>
                              <div className="clear" />

                              <input type="text" className="text-field" placeholder="Org name..." name="orgName" value={this.state.orgName} onChange={this.formChangeHandler}></input>
                              {(this.state.organizations.length > 0) && (
                                <div>
                                  <div className="spacer" />
                                  {this.state.organizations.map(value =>
                                    <div key={value._id} className="row-5">
                                      <button className="background-button" onClick={() => this.orgClicked(value)}>
                                        <div>

                                          <div className="float-left right-padding">
                                            <div className="spacer"/><div className="mini-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/>
                                            <img src={employersIconBlue} alt="Compass employer icon icon" className="image-auto-22" />
                                          </div>
                                          <div className="float-left">
                                            <p className="cta-color">{value.orgName}</p>
                                          </div>
                                        </div>
                                      </button>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}

                          <div className="clear" />
                        </div>
                      )}

                      <div className="clear" />

                      {(this.state.postType === 'Event') && (
                        <div>
                          <div className="clear" />
                          <div className="half-spacer" />
                          <div className="row-10">
                            <p><label className="bold-text cta-color">Events</label> are any work-based learning activities allowing professionals and career-seekers to intermingle.</p>
                          </div>

                          <div className="spacer" /><div className="half-spacer"/>
                          <hr className="clear-margin clear-padding"/>

                          {(this.state.selectedOpportunity && this.state.selectedOpportunity._id) && (
                            <div>

                              <div className={(this.state.viewIndex === 0) ? "app-title-container-1-of-2-1 selected-item-container-1" : "app-title-container-1-of-2-1 unselected-item-container-1"}>
                                <p className={(this.state.viewIndex === 0) ? "heading-text-4 cta-color" : "heading-text-4 unselected-color"}><a className="background-button" onClick={() => this.setState({ viewIndex: 0 })}>Details</a></p>
                              </div>

                              <div className={(this.state.viewIndex === 1) ? "app-title-container-1-of-2-1 margin-five-percent-left selected-item-container-1" : "app-title-container-1-of-2-1 margin-five-percent-left unselected-item-container-1"}>
                                { ( this.state.comments.length === 0 ) ? (
                                  <p className={(this.state.viewIndex === 1) ? "heading-text-4 cta-color" : "heading-text-4 unselected-color"}><a className="background-button" onClick={() => this.setState({ viewIndex: 1 })}>Comments</a></p>
                                ) : (
                                  <div>
                                    <p className={(this.state.viewIndex === 1) ? "heading-text-4 cta-color float-left margin-right-3" : "heading-text-4 unselected-color float-left margin-right-3"}><a className="background-button" onClick={() => this.setState({ viewIndex: 1 })}>Comments</a></p>
                                    {(this.state.viewIndex === 1) ? (
                                      <div className="noti-bubble selected-background description-text-4 float-left">{this.state.comments.length}</div>
                                    ) : (
                                      <div className="noti-bubble unselected-background description-text-4 float-left">{this.state.comments.length}</div>
                                    )}
                                  </div>
                                )}
                              </div>
                              <div className="clear" />
                            </div>
                          )}

                          {(this.state.viewIndex === 0) && (
                            <div>

                              {(this.state.selectedOpportunity && this.state.selectedOpportunity.requestMode) && (
                                <div>
                                  <div className="spacer" /><div className="spacer"/><div className="spacer"/><div className="spacer"/>

                                  {(this.state.roleName === 'Teacher' || this.state.roleName === 'School Support'|| this.state.roleName === 'WBLC' || this.state.roleName === 'Admin') ? (
                                    <div>
                                      <div className={statusBarClass} />
                                      <div className="vertical-spacer" /><div className="vertical-spacer" />
                                      <div className="float-left">
                                        <select name="status" value={this.state.status} onChange={this.formChangeHandler} className="dropdown">
                                          {this.state.statusOptions.map(value =>
                                            <option key={value} value={value}>{value}</option>
                                          )}
                                        </select>
                                      </div>
                                    </div>
                                  ) : (
                                    <div>
                                      <div className={statusBarClass} />
                                      <div className="vertical-spacer" /><div className="vertical-spacer" />
                                      <div className="float-left top-margin-negative-8">
                                        <p className={statusTextClass}>{this.state.status}</p>
                                      </div>
                                    </div>
                                  )}

                                  <div className="clear" />

                                </div>
                              )}

                              <div className="spacer" /><div className="spacer"/><div className="spacer"/>

                              {(this.state.rsvpLink && this.state.selectedOpportunity && this.state.selectedOpportunity._id) && (
                                <div className="row-10">
                                  <label className="description-text-1 bold-text">General RSVP Link: <a href={this.state.rsvpLink} target="_blank" rel="noopener noreferrer" className="heading-text-5 wrap-word">{this.state.rsvpLink}</a></label>
                                  <div className="spacer" /><div className="spacer" />
                                  <label className="description-text-1 bold-text">Career-Seeker Link: <a href={this.state.careerSeekerRsvpLink} target="_blank" rel="noopener noreferrer" className="heading-text-5 wrap-word">{this.state.careerSeekerRsvpLink}</a></label>
                                  <div className="spacer" /><div className="spacer" />
                                  <label className="description-text-1 bold-text">Advisor RSVP Link (External): <a href={this.state.advisorRsvpLink} target="_blank" rel="noopener noreferrer" className="heading-text-5 wrap-word">{this.state.advisorRsvpLink}</a></label>
                                  <div className="spacer" /><div className="spacer" />
                                </div>
                              )}

                              {(this.state.selectedOpportunity && this.state.readOnly && this.props.path && !this.props.path.includes('/employers/')) ? (
                                <div>
                                  {this.renderReadOnlyContent()}
                                  <div className="clear" />
                                </div>
                              ) : (
                                <div>

                                  <div className="row-20">
                                    <p className="heading-text-4">Core Information</p>
                                  </div>

                                  <div className="row-10">
                                    <div className="container-left">
                                      <label className="profile-label">Event Title<label className="error-color bold-text">*</label></label>
                                      <input type="text" className="text-field" placeholder="Name the event..." name="title" value={this.state.title} onChange={this.formChangeHandler}></input>

                                    </div>
                                    <div className="container-right">
                                      <label className="profile-label">Event Type<label className="error-color bold-text">*</label></label>
                                      <select name="eventType" value={this.state.eventType} onChange={this.formChangeHandler} className="dropdown">
                                        {this.state.eventTypeOptions.map(value =>
                                          <option key={value} value={value}>{value}</option>
                                        )}
                                      </select>
                                    </div>
                                    <div className="clear" />
                                  </div>

                                  {(this.state.org === 'unite-la' && !window.location.pathname.includes('/employers')) && (
                                    <div>
                                      {this.renderImageAsTitle()}
                                    </div>
                                  )}

                                  {(this.state.eventType !== '') && (
                                    <div>
                                      {this.renderDescription()}

                                      <div>
                                        <div className="name-container">
                                          <label className="profile-label">Start Date<label className="error-color bold-text">*</label></label>
                                          <input type="datetime-local" min={convertDateToString(new Date(),"rawDateTimeForInput")} className="date-picker" placeholder=" date" name="startDate" value={this.state.startDate} onChange={this.formChangeHandler}></input>
                                        </div>

                                        <div className="name-container">
                                          <label className="profile-label">End Date<label className="error-color bold-text">*</label></label>
                                          <input type="datetime-local" className="date-picker" placeholder=" date" min={convertDateToString(new Date(),"rawDateTimeForInput")} name="endDate" value={this.state.endDate} onChange={this.formChangeHandler}></input>
                                        </div>

                                        <div className="clear"/>
                                      </div>

                                      {this.renderWorkFunctions()}

                                      <div className="row-10">
                                        <label className="profile-label">Select An Industry (Select Other for Multiple)</label>
                                        <select name="industry" value={this.state.industry} onChange={this.formChangeHandler} className="dropdown">
                                          {this.state.industryOptions.map(value =>
                                            <option key={value} value={value}>{value}</option>
                                          )}
                                        </select>
                                      </div>

                                      <div className="edit-profile-row">
                                        <label className="profile-label clear-padding">Links</label>
                                        <p className="profile-descriptor">Add links to prior event images, recaps, videos, and other relevant resources.</p>

                                        <div className="spacer" />
                                        {this.renderLinks()}
                                        <div className="clear" />
                                        <div className="add-item-line-button">
                                          <hr />
                                          <a className="background-button" onClick={() => this.addLink()}>
                                            <img src={addCircleOutlineIcon} alt="Compass add circle outline icon" />
                                          </a>
                                        </div>
                                      </div>

                                      <div className="row-10">
                                        <div className="container-left">
                                          <label className="profile-label">Career-Seeker RSVP Limit</label>
                                          <input type="number" className="number-field full-width" placeholder="0" name="careerSeekerLimit" value={this.state.careerSeekerLimit} onChange={this.formChangeHandler}></input>
                                        </div>
                                        <div className="container-left">
                                          <label className="profile-label">Volunteer/Mentor RSVP Limit</label>
                                          <input type="number" className="number-field full-width" placeholder="0" name="mentorLimit" value={this.state.mentorLimit} onChange={this.formChangeHandler}></input>
                                        </div>
                                        <div className="clear" />
                                      </div>

                                      {(!this.props.path.includes('/employers')) && (
                                        <div>
                                        {(this.state.eventType === 'Talk' || this.state.eventType === 'Workshop' || this.state.eventType === 'Field Trip' || this.state.eventType === 'Job Shadow') && (
                                          <div>
                                            <div className="edit-profile-row">
                                              <label className="profile-label">Will Employer(s) Lead?<label className="error-color bold-text">*</label></label>
                                              <select name="willEmployerLead" value={this.state.willEmployerLead} onChange={this.formChangeHandler} className="dropdown">
                                                {this.state.employerHostOptions.map(value =>
                                                  <option key={value} value={value}>{value}</option>
                                                )}
                                              </select>
                                            </div>

                                            {(this.state.willEmployerLead && this.state.willEmployerLead === 'One Employer Will Lead') && (
                                              <div>
                                                <div className="edit-profile-row">
                                                  <label className="profile-label">Add the Employer Lead<label className="error-color bold-text">*</label></label>
                                                  <input className="text-field" type="text" name="employerName"
                                                    placeholder="Add the employer you're associated with..." value={this.state.employerName}
                                                    onChange={this.formChangeHandler}
                                                  />
                                                  {(this.state.employers.length > 0) && (
                                                    <div>
                                                      <div className="spacer" />
                                                      {this.state.employers.map(value =>
                                                        <div key={value._id} className="row-5">
                                                          <button className="background-button" onClick={() => this.employerClicked(value)}>
                                                            <div>
                                                              <div className="float-left right-padding">
                                                                <img src={employersIconBlue} alt="Compass employer icon icon" className="image-auto-22" />
                                                              </div>
                                                              <div className="float-left">
                                                                <p className="cta-color">{value.employerName}</p>
                                                              </div>
                                                            </div>
                                                          </button>
                                                        </div>)}
                                                    </div>
                                                  )}

                                                </div>

                                                <div>
                                                  <div className="name-container">
                                                    <label className="profile-label">Employer Contact First Name<label className="error-color bold-text">*</label></label>
                                                    <input type="text" className="text-field" placeholder="Add first name..." name="employerContactFirstName" value={this.state.employerContactFirstName} onChange={this.formChangeHandler}></input>
                                                  </div>

                                                  <div className="name-container">
                                                    <label className="profile-label">Employer Contact Last Name<label className="error-color bold-text">*</label></label>
                                                    <input type="text" className="text-field" placeholder="Add the location..." name="employerContactLastName" value={this.state.employerContactLastName} onChange={this.formChangeHandler}></input>
                                                  </div>

                                                  <div className="clear"/>

                                                  <div className="name-container">
                                                    <label className="profile-label">Employer Contact Email<label className="error-color bold-text">*</label></label>
                                                    <input type="text" className="text-field" placeholder="Add the email..." name="employerContactEmail" value={this.state.employerContactEmail} onChange={this.formChangeHandler}></input>
                                                  </div>

                                                  <div className="clear"/>
                                                </div>

                                                <div className="edit-profile-row">
                                                  <label className="profile-label">Employer Contact Bio</label>
                                                  <textarea type="text" className="text-field" placeholder="Share the bio of the employer contact..." name="bio" value={this.state.bio} onChange={this.formChangeHandler}></textarea>
                                                </div>

                                              </div>
                                            )}
                                          </div>
                                        )}
                                        </div>
                                      )}

                                      <div>
                                        <div className="name-container">
                                          <label className="profile-label">Which location type?<label className="error-color bold-text">*</label></label>
                                          <select name="locationType" value={this.state.locationType} onChange={this.formChangeHandler} className="dropdown">
                                            {this.state.locationTypeOptions.map(value =>
                                              <option key={value} value={value}>{value}</option>
                                            )}
                                          </select>
                                        </div>

                                        {(!this.state.locationType || this.state.locationType !== 'Remote') ? (
                                          <div className="name-container">
                                            <label className="profile-label">Location<label className="error-color bold-text">*</label></label>
                                            <input type="text" className="text-field" placeholder="Add the location address" name="location" value={this.state.location} onChange={this.formChangeHandler}></input>
                                          </div>
                                        ) : (
                                          <div className="name-container">
                                            <label className="profile-label">Virtual Location<label className="error-color bold-text">*</label></label>
                                            <input type="text" className="text-field" placeholder="Add the video conference link, or dial-in number..." name="location" value={this.state.location} onChange={this.formChangeHandler}></input>
                                            {(this.state.location && this.state.location !== '' && !this.state.location.startsWith('http')) && (
                                              <div>
                                                <p className="error-message">Please start your link with http</p>
                                              </div>
                                            )}
                                          </div>
                                        )}

                                        <div className="clear" />
                                      </div>

                                      {(this.state.locationType && this.state.locationType !== 'Remote') && (
                                        <div className="edit-profile-row">
                                          <label className="profile-label">Transportation Details & Instructions</label>
                                          <textarea type="text" className="text-field" placeholder="Share transportation details..." name="transportationDetails" value={this.state.transportationDetails} onChange={this.formChangeHandler}></textarea>
                                        </div>
                                      )}

                                      <hr className="vertical-margin-30" />

                                      <div className="row-20">
                                        <p className="heading-text-4">Tag, Categorize, & Configure</p>
                                      </div>

                                      {this.renderVisibilityOptions()}

                                      {(this.props.path.includes('/organizations')) && (
                                        <div>
                                          <div className="row-15">
                                            <div className="container-left">
                                              <label className="profile-label clear-padding">Would you like to feature this posting?</label>
                                              <label className="profile-descriptor">Featuring a posting highlights the posting to career-seekers.</label>
                                              <div className="clear" />
                                              <div className="spacer" /><div className="half-spacer"/>
                                              <Switch
                                                onChange={(change) => this.setState({ featured: change, formHasChanged: true })}
                                                checked={this.state.featured}
                                                id="normal-switch"
                                              />
                                            </div>
                                            <div className="clear" />
                                          </div>
                                        </div>
                                      )}

                                      {this.renderPathways()}
                                      {this.renderDepartments()}
                                      {this.renderTargetRoles()}
                                      {this.renderPrograms()}

                                      <div>
                                        {(window.location.pathname.includes('/organizations')) && (
                                          <div>
                                            {this.renderTaggedCandidatesOfInterest()}

                                            <hr className="vertical-margin-30" />

                                            <div className="edit-profile-row">
                                              <p className="heading-text-4">RSVP Components (Career-Seekers Only)</p>
                                              <p className="profile-descriptor top-margin-5">Select what should be included in each RSVP.</p>
                                              <br/>

                                              <div>
                                                <div className="half-bold-text description-text-1">
                                                  <div className="relative-column-5">
                                                    <p>No.</p>
                                                  </div>
                                                  <div className="relative-column-45">
                                                    <p>Name</p>
                                                  </div>
                                                  <div className="relative-column-25 center-text">
                                                    <p>Include?</p>
                                                  </div>
                                                  <div className="relative-column-25 center-text">
                                                    <p>Required?</p>
                                                  </div>
                                                  <div className="clear" />
                                                </div>

                                                {this.state.rsvpComponents.map((value, index) =>
                                                  <div className="row-5 heading-text-6">
                                                    <div className="relative-column-5">
                                                      <p>{index + 1}.</p>
                                                    </div>
                                                    <div className="relative-column-45">
                                                      <p>{value.name}</p>
                                                    </div>
                                                    <div className="relative-column-25 center-text top-padding">
                                                      <Switch
                                                        onChange={(change) => this.switchPreference('rsvp','include', index, change)}
                                                        checked={value.include}
                                                        id="normal-switch"
                                                        disabled={value.disabled}
                                                      />
                                                    </div>
                                                    <div className="relative-column-25 center-text top-padding">
                                                      <Switch
                                                        onChange={(change) => this.switchPreference('rsvp','required', index, change)}
                                                        checked={value.required}
                                                        id="normal-switch"
                                                        disabled={value.disabled}
                                                      />
                                                    </div>
                                                    <div className="clear" />
                                                  </div>
                                                )}

                                                {(this.state.rsvpComponents[this.state.rsvpComponents.length - 1].include) && (
                                                  <div>
                                                    <hr className="vertical-margin-20" />

                                                    <div className="row-20">
                                                      <p className="heading-text-3">Custom Assessment</p>
                                                      <p className="profile-descriptor top-margin-5">Survey templates can be created and saved in the assessments tab</p>

                                                      <div className="edit-profile-row">
                                                          <label className="profile-label">Include a Custom RSVP Survey for Career-Seekers</label>
                                                          <select name="customAssessment" value={this.state.customAssessment.title} onChange={this.formChangeHandler} className="dropdown">
                                                            {this.state.customAssessmentOptions.map(value =>
                                                              <option key={value.key} value={value.title}>{value.title}</option>
                                                            )}
                                                          </select>
                                                      </div>

                                                      <div className="clear" />

                                                      <p className="error-color description-text-2 bold-text row-10">Custom RSVP surveys can currently only be attached for career-seekers. Email help@guidedcompass.com if you need to change the default RSVP survey for other roles.</p>

                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            </div>

                                            {(this.state.showEventReminder) && (
                                              <div>

                                                <hr className="vertical-margin-30" />

                                                <div className="row-10">
                                                  <p className="heading-text-4">Schedule Reminder Text</p>
                                                  <p className="description-text-2 standard-color">This text will go to career-seekers who have added a valid phone number to Guided Compass.</p>

                                                  <div className="row-10">
                                                    <div className="row-10">
                                                      <div className="container-left">
                                                        <label className="profile-label">Send Reminder Text</label>
                                                        <Switch
                                                          onChange={(change) => this.setState({ sendReminderText: change, formHasChanged: true })}
                                                          checked={this.state.sendReminderText}
                                                          id="normal-switch"
                                                        />
                                                      </div>
                                                      {(this.state.sendReminderText) && (
                                                        <div className="container-right">
                                                          <label className="profile-label">When should it be sent?</label>
                                                          <select name="reminderTime" value={this.state.reminderTime} onChange={this.formChangeHandler} className="dropdown">
                                                            {this.state.reminderTimeOptions.map(value =>
                                                              <option key={value} value={value}>{value}</option>
                                                            )}
                                                          </select>
                                                        </div>
                                                      )}

                                                      <div className="clear" />
                                                    </div>

                                                    {(this.state.sendReminderText) && (
                                                      <div className="standard-border padding-30">
                                                        <label className="description-text-color description-text-2 bottom-padding-5">Message</label>
                                                        <p className="standard-text">This is a reminder text that the {this.state.title} event is coming up at {(this.state.startDate) && convertDateToString(this.state.startDate,'last5')}. Don't forget to attend! Text STOP to unsubscribe from these automated text reminders.</p>
                                                      </div>
                                                    )}

                                                    <div className="spacer" /><div className="spacer" /><div className="spacer" />

                                                    <hr />
                                                  </div>
                                                </div>

                                              </div>
                                            )}

                                          </div>
                                        )}

                                        {((this.state.showCustomConfirmationMessage) && (window.location.pathname.includes('/organizations') || (window.location.pathname.includes('/employers') && this.state.org === 'guidedcompass'))) && (
                                          <div>
                                            {this.renderConfirmationMessage()}
                                          </div>
                                        )}

                                        {(window.location.pathname.includes('/employers') && this.state.org === 'guidedcompass') && (
                                          <div>
                                            {this.renderSharePartners('event')}
                                          </div>
                                        )}

                                        {(!this.props.path.includes('/employers')) && (
                                          <div>
                                            {this.renderOrgContactInformation()}
                                          </div>
                                        )}

                                        <hr className="vertical-margin-20" />

                                      </div>

                                    </div>
                                  )}

                                  { (this.state.clientErrorMessage!== '') && <p className="error-message">{this.state.clientErrorMessage}</p> }
                                  { (this.state.serverPostSuccess) ? (
                                    <p className="success-message">{this.state.serverSuccessMessage}</p>
                                  ) : (
                                    <p className="error-message">{this.state.serverErrorMessage}</p>
                                  )}

                                  {(this.state.selectedOpportunity && this.state.selectedOpportunity._id) ? (
                                    <div>
                                      {(this.state.confirmDelete === true) ? (
                                        <div>
                                          {this.renderConfirmDelete()}
                                        </div>
                                      ) : (
                                        <div>
                                          <div className="float-left right-padding-20">
                                            <button className="btn btn-primary"  disabled={this.state.isSaving} onClick={() => this.editJob()}>Save Edits</button>
                                          </div>
                                          <div className="float-left right-padding-20">
                                            <button className="btn btn-secondary"  disabled={this.state.isSaving} onClick={() => this.setState({ confirmDelete: true })}>Delete Post</button>
                                          </div>
                                          <div className="clear" />
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <div>
                                      <button className="btn btn-primary"  disabled={this.state.isSaving} onClick={() => this.editJob()}>{this.state.requestMode ? "Request Approval for " + this.state.postType : "Save " + this.state.postType}</button>
                                    </div>
                                  )}
                                </div>
                              )}

                              {(this.state.selectedOpportunity && this.state.selectedOpportunity._id && (window.innerWidth < 768 || window.location.pathname.includes('/walkthrough'))) && (
                                <div>
                                  <div className="clear"/>
                                  <div className="spacer" />
                                  <hr className="vertical-margin-20" />

                                  {this.renderRAndA()}
                                </div>
                              )}
                            </div>
                          )}

                          {(this.state.viewIndex === 1) && (
                            <div>
                              <div>
                                <SubComments selectedOpportunity={this.state.selectedOpportunity} activeOrg={this.state.activeOrg} accountCode={this.state.accountCode} comments={this.state.comments} postingOrgCode={this.state.postingOrgCode} postingOrgName={this.state.postingOrgName} orgContactEmail={this.state.orgContactEmail} pictureURL={this.state.pictureURL} />
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                      {(this.state.postType === 'Project' || this.state.postType === 'Assignment' || this.state.postType === 'Problem' || this.state.postType === 'Challenge') && (
                        <div>
                          <div className="clear" />
                          <div className="half-spacer" />

                          {(this.state.path && !this.state.path.includes('/problem-platform')) && (
                            <div className="row-10">
                              {(this.state.postType === 'Assignment' || this.state.projectPromptType === 'Assignment') && (
                                <p><label className="bold-text cta-color">Assignments</label> are explicit prompts mimicking freelance work assignments that students / career-seekers may submit projects to.</p>
                              )}
                              {(this.state.postType === 'Problem' || this.state.projectPromptType === 'Problem') && (
                                <p><label className="bold-text cta-color">Problems</label> allow employer representatives to post case studies on problems they experience at work to inspire discussions and projects in class.</p>
                              )}
                              {(this.state.postType === 'Challenge' || this.state.projectPromptType === 'Challenge') && (
                                <p><label className="bold-text cta-color">Challenges</label> allow student / career-seeker teams to compete to solve challenges professionals experience at work for a prize.</p>
                              )}

                            </div>
                          )}

                          <div className="spacer" /><div className="half-spacer" />
                          <hr className="clear-margin clear-padding"/>

                          {(this.state.selectedOpportunity && this.state.selectedOpportunity._id) && (
                            <div>

                              <div className={(this.state.viewIndex === 0) ? "app-title-container-1-of-2-1 selected-item-container-1" : "app-title-container-1-of-2-1 unselected-item-container-1"}>
                                <p className={(this.state.viewIndex === 0) ? "heading-text-4 cta-color" : "heading-text-4 unselected-color"}><a className="background-button" onClick={() => this.setState({ viewIndex: 0 })}>Details</a></p>
                              </div>
                              <div className={(this.state.viewIndex === 1) ? "app-title-container-1-of-2-1 margin-five-percent-left selected-item-container-1" : "app-title-container-1-of-2-1 margin-five-percent-left unselected-item-container-1"}>
                                { ( !this.state.selectedOpportunity.submissions || this.state.selectedOpportunity.submissions.length === 0 ) ? (
                                  <p className={(this.state.viewIndex === 1) ? "heading-text-4 cta-color" : "heading-text-4 unselected-color"}><a className="background-button" onClick={() => this.setState({ viewIndex: 1 })}>Submissions</a></p>
                                ) : (
                                  <div>
                                    <p className={(this.state.viewIndex === 1) ? "heading-text-4 cta-color float-left margin-right-3" : "heading-text-4 unselected-color float-left margin-right-3"}><a className="background-button" onClick={() => this.setState({ viewIndex: 1 })}>Submissions</a></p>
                                    {(this.state.viewIndex === 1) ? (
                                      <div className="noti-bubble selected-background description-text-4 float-left">{this.state.selectedOpportunity.submissions.length}</div>
                                    ) : (
                                      <div className="noti-bubble unselected-background description-text-4 float-left">{this.state.selectedOpportunity.submissions.length}</div>
                                    )}
                                  </div>
                                )}
                              </div>
                              <div className={(this.state.viewIndex === 2) ? "app-title-container-1-of-2-1 margin-five-percent-left selected-item-container-1" : "app-title-container-1-of-2-1 margin-five-percent-left unselected-item-container-1"}>
                                { ( this.state.comments.length === 0 ) ? (
                                  <p className={(this.state.viewIndex === 2) ? "heading-text-4 cta-color" : "heading-text-4 unselected-color"}><a className="background-button" onClick={() => this.setState({ viewIndex: 2 })}>Comments</a></p>
                                ) : (
                                  <div>
                                    <p className={(this.state.viewIndex === 2) ? "heading-text-4 cta-color float-left margin-right-3" : "heading-text-4 unselected-color float-left margin-right-3"}><a className="background-button" onClick={() => this.setState({ viewIndex: 2 })}>Comments</a></p>
                                    {(this.state.viewIndex === 2) ? (
                                      <div className="noti-bubble selected-background description-text-4 float-left">{this.state.comments.length}</div>
                                    ) : (
                                      <div className="noti-bubble unselected-background description-text-4 float-left">{this.state.comments.length}</div>
                                    )}
                                  </div>
                                )}
                              </div>
                              <div className="clear" />
                            </div>
                          )}

                          {(this.state.viewIndex === 0) && (
                            <div>

                              {(this.state.selectedOpportunity && this.state.readOnly && this.props.path && !this.props.path.includes('/employers/')) ? (
                                <div>
                                  {this.renderReadOnlyContent()}
                                </div>
                              ) : (
                                <div>
                                  {(this.state.selectedOpportunity && this.state.selectedOpportunity.requestMode) && (
                                    <div>
                                      <div className="spacer" /><div className="spacer"/><div className="spacer"/><div className="spacer"/>

                                      {(this.state.roleName === 'Teacher' || this.state.roleName === 'School Support'|| this.state.roleName === 'WBLC' || this.state.roleName === 'Admin') ? (
                                        <div>

                                          <div className={statusBarClass} />
                                          <div className="vertical-spacer" /><div className="vertical-spacer" />
                                          <div className="float-left">
                                            <select name="status" value={this.state.status} onChange={this.formChangeHandler} className="dropdown">
                                              {this.state.statusOptions.map(value =>
                                                <option key={value} value={value}>{value}</option>
                                              )}
                                            </select>
                                          </div>
                                        </div>
                                      ) : (
                                        <div>
                                          <div className={statusBarClass} />
                                          <div className="vertical-spacer" /><div className="vertical-spacer" />
                                          <div className="float-left top-margin-negative-8">
                                            <p className={statusTextClass}>{this.state.status}</p>
                                          </div>
                                        </div>
                                      )}

                                      <div className="clear" />

                                    </div>
                                  )}

                                  <div className="spacer" />

                                  <div className="row-20">
                                    <p className="heading-text-4">Core Information</p>
                                  </div>

                                  <div className="row-10">
                                    <div className="container-left">
                                      <label className="profile-label">Name<label className="error-color bold-text">*</label></label>
                                      <input type="text" className="text-field" placeholder="Name the project prompt..." name="projectName" value={this.state.projectName} onChange={this.formChangeHandler}></input>
                                    </div>
                                    <div className="container-right">
                                      <label className="profile-label">Duration (Estimated Man Hours to Complete)<label className="error-color bold-text">*</label></label>
                                      <select name="duration" value={this.state.duration} onChange={this.formChangeHandler} className="dropdown">
                                        {this.state.durationOptions.map(value =>
                                          <option key={value} value={value}>{value}</option>
                                        )}
                                      </select>
                                    </div>
                                    <div className="clear" />
                                  </div>

                                  <div className="edit-profile-row">
                                    <div className="container-left">
                                      <label className="profile-label">Project Type<label className="error-color bold-text">*</label></label>
                                      <select name="projectPromptType" value={this.state.projectPromptType} onChange={this.formChangeHandler} className="dropdown">
                                        {this.state.projectPromptTypeOptions.map(value =>
                                          <option key={value} value={value}>{value}</option>
                                        )}
                                      </select>
                                    </div>

                                    <div className="clear" />
                                  </div>

                                  {(this.state.postType === 'Challenge' || this.state.projectPromptType === 'Challenge') && (
                                    <div className="cta-border padding-40">
                                      <div className="edit-profile-row">
                                        <div className="container-left">
                                          <div className="float-left">
                                            <label className="profile-label">Start Date<label className="error-color bold-text">*</label></label>
                                          </div>
                                          <div>
                                            <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                            <div className="float-left left-margin noti-bubble-info-7-9">
                                              <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, submissionStartDateInfo: true })}>
                                                <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                                              </a>
                                            </div>

                                          </div>
                                          <div className="clear" />
                                          <input type="datetime-local" className="date-picker" min={convertDateToString(new Date(),"rawDateForInput")} name="startDate" value={this.state.startDate} onChange={this.formChangeHandler}></input>
                                        </div>

                                        <div className="clear"/>
                                      </div>

                                      <div className="edit-profile-row">
                                        <div className="container-left">
                                          <div className="float-left">
                                            <label className="profile-label">Include Voting Round<label className="error-color bold-text">*</label></label>
                                          </div>
                                          <div>
                                            <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                            <div className="float-left left-margin noti-bubble-info-7-9">
                                              <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, votingRoundInfo: true })}>
                                                <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                                              </a>
                                            </div>

                                          </div>
                                          <div className="clear" />
                                          <Switch
                                            onChange={(change) => this.setState({ includeVotingRound: change, formHasChanged: true })}
                                            checked={this.state.includeVotingRound}
                                            id="normal-switch"
                                          />
                                        </div>
                                        <div className="container-right">
                                          <div className="float-left">
                                            <label className="profile-label">Include Judging Round<label className="error-color bold-text">*</label></label>
                                          </div>
                                          <div>
                                            <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                            <div className="float-left left-margin noti-bubble-info-7-9">
                                              <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, judgingRoundInfo: true })}>
                                                <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                                              </a>
                                            </div>

                                          </div>
                                          <div className="clear" />
                                          <Switch
                                            onChange={(change) => this.setState({ includeJudgingRound: change, formHasChanged: true })}
                                            checked={this.state.includeJudgingRound}
                                            id="normal-switch"
                                          />
                                        </div>
                                        <div className="clear"/>
                                      </div>

                                      <div className="edit-profile-row">
                                        <div className="container-left">
                                          <label className="profile-label">1st Place Prize<label className="error-color bold-text">*</label></label>

                                          <div>
                                            <div className="text-field-prefix-container">
                                              <img src={dollarSign} alt="GC" className="image-auto-22 center-item" />
                                            </div>
                                            <div className="calc-column-offset-50">
                                              <input type="number" className="number-field full-width" placeholder="0" name="prize1" value={this.state.prizes[0]} onChange={this.formChangeHandler}></input>
                                            </div>
                                            <div className="clear"/>
                                          </div>
                                        </div>
                                        <div className="container-right">
                                          <label className="profile-label">2nd Place Prize<label className="error-color bold-text">*</label></label>

                                          <div>
                                            <div className="text-field-prefix-container">
                                              <img src={dollarSign} alt="GC" className="image-auto-22 center-item"/>
                                            </div>
                                            <div className="calc-column-offset-50">
                                              <input type="number" className="number-field full-width" placeholder="0" name="prize2" value={this.state.prizes[1]} onChange={this.formChangeHandler}></input>
                                            </div>
                                            <div className="clear"/>
                                          </div>
                                        </div>

                                        <div className="clear"/>
                                      </div>

                                      <div className="edit-profile-row">

                                      <div className="container-left">
                                        <label className="profile-label">3rd Place Prize<label className="error-color bold-text">*</label></label>

                                        <div>
                                          <div className="text-field-prefix-container">
                                            <img src={dollarSign} alt="GC" className="image-auto-22 center-item" />
                                          </div>
                                          <div className="calc-column-offset-50">
                                            <input type="number" className="number-field full-width" placeholder="0" name="prize3" value={this.state.prizes[2]} onChange={this.formChangeHandler}></input>
                                          </div>
                                          <div className="clear"/>
                                        </div>
                                      </div>
                                        <div className="container-right">

                                          <div className="float-left">
                                            <label className="profile-label">Date to Announce Winner<label className="error-color bold-text">*</label></label>
                                          </div>
                                          <div>
                                            <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                            <div className="float-left left-margin noti-bubble-info-7-9">
                                              <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, announcementDateInfo: true })}>
                                                <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                                              </a>
                                            </div>

                                          </div>
                                          <div className="clear" />
                                          <input type="datetime-local" className="date-picker" name="announcementDate" value={this.state.announcementDate} onChange={this.formChangeHandler}></input>
                                        </div>
                                        <div className="clear"/>
                                      </div>

                                      <div className="edit-profile-row">
                                        <div className="container-left">
                                          <label className="profile-label">Announcement Method<label className="error-color bold-text">*</label></label>
                                          <select name="announcementMethod" value={this.state.announcementMethod} className="dropdown" onChange={this.formChangeHandler}>
                                            {this.state.announcementMethodOptions.map(value =>
                                              <option key={value} value={value}>{value}</option>
                                            )}
                                          </select>
                                        </div>
                                        {(this.state.announcementMethod === 'At Event') && (
                                          <div className="container-right">
                                            <label className="profile-label">Event Location<label className="error-color bold-text">*</label></label>
                                            <input type="text" className="text-field" placeholder="Add an address or conferencing link..." name="location" value={this.state.location} onChange={this.formChangeHandler}></input>
                                          </div>
                                        )}

                                        <div className="clear"/>
                                      </div>

                                      <div className="edit-profile-row">
                                        <div className="container-left">
                                          <div className="float-left">
                                            <label className="profile-label">Require Voting for Consideration<label className="error-color bold-text">*</label></label>
                                          </div>
                                          <div>
                                            <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                            <div className="float-left left-margin noti-bubble-info-7-9">
                                              <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, voteRequirementInfo: true })}>
                                                <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                                              </a>
                                            </div>

                                          </div>
                                          <div className="clear" />
                                          <Switch
                                            onChange={(change) => this.setState({ voteRequirement: change, formHasChanged: true })}
                                            checked={this.state.voteRequirement}
                                            id="normal-switch"
                                          />
                                        </div>
                                        <div className="clear"/>
                                      </div>

                                      <div className="edit-profile-row">
                                        <label className="profile-label">Rules<label className="error-color bold-text">*</label></label>
                                        <textarea type="text" className="text-field" placeholder="Add rules you would like participants to know about..." name="rules" value={this.state.rules} onChange={this.formChangeHandler}></textarea>
                                      </div>
                                    </div>
                                  )}

                                  <div className="edit-profile-row">
                                    <div className="name-container">
                                      <label className="profile-label">Your Employer Name<label className="error-color bold-text">*</label></label>
                                      <input className="text-field" type="text" name="employerName"
                                        placeholder="Add the employer you're associated with..." value={this.state.employerName}
                                        onChange={this.formChangeHandler}
                                      />
                                      {(this.state.employers.length > 0) && (
                                        <div>
                                          <div className="spacer" />
                                          {this.state.employers.map(value =>
                                            <div key={value._id} className="row-5">
                                              <button className="background-button" onClick={() => this.employerClicked(value)}>
                                                <div>

                                                  <div className="float-left right-padding">
                                                    <img src={employersIconBlue} alt="Compass employer icon icon" className="image-auto-22" />
                                                  </div>
                                                  <div className="float-left">
                                                    <p className="cta-color">{value.employerName}</p>
                                                  </div>
                                                </div>
                                              </button>
                                            </div>)}
                                        </div>
                                      )}
                                    </div>

                                    <div className="name-container">
                                      <label className="profile-label">Employer URL<label className="error-color bold-text">*</label></label>
                                      <input type="text" className="text-field" placeholder="Add your employer url..." name="employerURL" value={this.state.employerURL} onChange={this.formChangeHandler}></input>
                                      {(this.state.employerURL && this.state.employerURL !== '' && !this.state.employerURL.startsWith('http')) && (
                                        <div>
                                          <p className="error-message">Please start your link with http</p>
                                        </div>
                                      )}
                                    </div>
                                    <div className="clear"/>
                                  </div>

                                  <div className="edit-profile-row">
                                    <label className="profile-label">Employer Info (Optional)</label>
                                    <textarea type="text" className="text-field" placeholder="Add a short description of employer as it relates to this problem..." name="employerInfo" value={this.state.employerInfo} onChange={this.formChangeHandler}></textarea>
                                    <label className="description-text-3 description-text-color half-bold-text">100 Word Limit</label>
                                  </div>

                                  {this.renderWorkFunctions()}

                                  <div className="edit-profile-row">
                                    <div className="name-container">
                                      <label className="profile-label">Difficulty Level<label className="error-color bold-text">*</label></label>
                                      <select name="difficultyLevel" value={this.state.difficultyLevel} onChange={this.formChangeHandler} className="dropdown">
                                        {this.state.difficultyOptions.map(value =>
                                          <option key={value} value={value}>{value}</option>
                                        )}
                                      </select>
                                    </div>
                                    <div className="name-container">
                                      <label className="profile-label">Primary Industry Focus<label className="error-color bold-text">*</label></label>
                                      <select name="industry" value={this.state.industry} onChange={this.formChangeHandler} className="dropdown">
                                        {this.state.industryOptions.map(value =>
                                          <option key={value} value={value}>{value}</option>
                                        )}
                                      </select>
                                    </div>
                                    <div className="clear" />
                                  </div>

                                  {(!window.location.pathname.includes('/problem-platform') && (this.state.postType === 'Assignment' || this.state.projectPromptType === 'Assignment')) && (
                                    <div className="edit-profile-row">
                                      <div className="container-left">
                                        <label className="profile-label">Assign To<label className="error-color bold-text">*</label></label>
                                        <select name="assigneeGroup" value={this.state.assigneeGroup} onChange={this.formChangeHandler} className="dropdown">
                                          {this.state.assigneeGroupOptions.map(value =>
                                            <option key={value} value={value}>{value}</option>
                                          )}
                                        </select>
                                      </div>
                                      {(this.state.assigneeGroup === 'Specific Individuals') && (
                                        <div className="container-right">
                                          <label className="profile-label">Search & Add Individuals<label className="error-color bold-text">*</label></label>
                                          <div>
                                            <div className="calc-column-offset-100 right-padding">
                                              <input type="text" className="text-field" placeholder="Search the name..." name="assigneeName" value={this.state.assigneeName} onChange={this.formChangeHandler}></input>
                                            </div>
                                            <div className="fixed-column-100">
                                              <button className="btn btn-squarish" onClick={() => this.addItem('assignee')}>Add</button>
                                            </div>
                                            <div className="clear" />
                                          </div>

                                          <div className="clear" />
                                          <div className="spacer" />

                                          {(this.state.suggestedAssignees.length > 0) && (
                                            <div>
                                              <div className="spacer" />
                                              {this.state.suggestedAssignees.map(value =>
                                                <div key={value._id} className="row-5">
                                                  <button className="background-button" onClick={() => this.assigneeClicked(value)}>
                                                    <div>
                                                      <div className="float-left right-padding">
                                                        <div className="spacer"/><div className="mini-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/>
                                                        <img src={profileIconBlue} alt="Compass employer icon icon" className="image-auto-22" />
                                                      </div>
                                                      <div className="float-left">
                                                        <p className="cta-color">{value.firstName} {value.lastName}</p>
                                                      </div>
                                                    </div>
                                                  </button>
                                                </div>)}
                                            </div>
                                          )}

                                          {this.state.assignees && this.state.assignees.map((value, optionIndex) =>
                                            <div key={value.name + "|" + optionIndex} className="float-left">
                                              <div className="close-button-container-1">
                                                <button className="background-button" onClick={() => this.removeItem(optionIndex,'assignee')}>
                                                  <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                                                </button>
                                              </div>
                                              <div className="float-left row-5 right-padding">
                                                <div className="tag-container-5">
                                                  <p className="description-text-2">{value.firstName} {value.lastName}</p>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                      <div className="clear" />
                                    </div>
                                  )}
                                  {/*
                                  <div className="edit-profile-row">
                                    <div className="name-container">
                                      <label className="profile-label">Employer Name<label className="error-color bold-text">*</label></label>
                                      <input className="text-field" type="text" name="employerName"
                                        placeholder="Add the employer you're associated with..." value={this.state.employerName}
                                        onChange={this.formChangeHandler}
                                      />
                                      {(this.state.employers.length > 0) && (
                                        <div>
                                          <div className="spacer" />
                                          {this.state.employers.map(value =>
                                            <div key={value._id} className="row-5">
                                              <button className="background-button" onClick={() => this.employerClicked(value)}>
                                                <div>

                                                  <div className="float-left right-padding">
                                                    <img src={employersIconBlue} alt="Compass employer icon icon" className="image-auto-22" />
                                                  </div>
                                                  <div className="float-left">
                                                    <p className="cta-color">{value.employerName}</p>
                                                  </div>
                                                </div>
                                              </button>
                                            </div>)}
                                        </div>
                                      )}
                                    </div>
                                    <div className="name-container">
                                      <label className="profile-label">Employer URL<label className="error-color bold-text">*</label></label>
                                      <input type="text" className="text-field" placeholder="Add the employer URL..." name="employerURL" value={this.state.employerURL} onChange={this.formChangeHandler}></input>
                                      {(this.state.employerURL && this.state.employerURL !== '' && !this.state.employerURL.startsWith('http')) && (
                                        <div>
                                          <p className="error-message">Please start your link with http</p>
                                        </div>
                                      )}
                                    </div>
                                    <div className="clear"/>
                                  </div>*/}

                                  <div className="edit-profile-row">
                                    <div className="name-container">
                                      <label className="profile-label">Your Job Title<label className="error-color bold-text">*</label></label>
                                      <input type="text" className="text-field" placeholder="Add your job title..." name="jobTitle" value={this.state.jobTitle} onChange={this.formChangeHandler}></input>
                                    </div>
                                    <div className="name-container">
                                      <label className="profile-label">Submission Deadline<label className="error-color bold-text">*</label></label>
                                      <input type="datetime-local" className="date-picker" min={convertDateToString(new Date(),"rawDateForInput")} name="submissionDeadline" value={this.state.submissionDeadline} onChange={this.formChangeHandler}></input>
                                    </div>
                                    <div className="clear" />
                                  </div>


                                  <div className="edit-profile-row">
                                    <label className="profile-label">Tags</label>
                                    <p className="profile-descriptor">Add tags, separated by commas, that make this easy to find</p>
                                    <input type="text" className="text-field" placeholder="Add tags..." name="tags" value={this.state.tags} onChange={this.formChangeHandler}></input>
                                  </div>

                                  <div className="edit-profile-row">
                                    <label className="profile-label clear-padding">Video Link (Optional)</label>
                                    <p className="profile-descriptor">The video will show below if successfully added. Make sure to take the "embed" version of the link. We suggest going to YouTube, clicking "Share", and pasting the link in quotes after "src=" here. This is an example link: "https://www.youtube.com/embed/92mBt-NFx50"</p>
                                    <div className="half-spacer" />
                                    <input type="text" className="text-field" placeholder="Add a 'embed' link to a video explaing the problem..." name="videoLink" value={this.state.videoLink} onChange={this.formChangeHandler}></input>
                                    {(this.state.videoLink && this.state.videoLink !== '' && !this.state.videoLink.startsWith('http')) && (
                                      <div>
                                        <p className="error-message">Please start your link with http</p>
                                      </div>
                                    )}
                                  </div>

                                  {(this.state.videoLink) && (
                                    <div>
                                      <div className="video-container">
                                        <iframe
                                          title="videoLink"
                                          className="video-iframe"
                                          src={this.state.videoLink}
                                          frameBorder="0"
                                        />
                                      </div>
                                    </div>
                                  )}

                                  {((this.state.postType === 'Project' && this.state.projectPromptType !== 'Assignment') || (this.state.postType !== 'Assignment' && !this.state.projectPromptType)) && (
                                    <div className="edit-profile-row">
                                      <label className="profile-label">Summary<label className="error-color bold-text">*</label></label>
                                      <textarea type="text" className="text-field" placeholder="Provide a brief summary of the problem..." name="summary" value={this.state.summary} onChange={this.formChangeHandler}></textarea>
                                      <label className="description-text-3 description-text-color half-bold-text">100 Word Limit</label>
                                    </div>
                                  )}

                                  {this.renderDescription()}

                                  {((this.state.postType === 'Project' && this.state.projectPromptType !== 'Assignment') || (this.state.postType !== 'Assignment' && !this.state.projectPromptType)) && (
                                    <div className="edit-profile-row">
                                      <label className="profile-label">Background</label>
                                      <textarea type="text" className="text-field" placeholder="Add some background information..." name="background" value={this.state.background} onChange={this.formChangeHandler}></textarea>
                                      <label className="description-text-3 description-text-color half-bold-text">200 Word Limit</label>
                                    </div>
                                  )}

                                  <div className="edit-profile-row">
                                    <label className="profile-label clear-padding">Guidelines (Optional)</label>
                                    <p className="profile-descriptor">If you have suggestions on the format or the way the problem likely should be approached, please write them here.</p>
                                    <div className="half-spacer" />
                                    <textarea type="text" className="text-field" placeholder="Add guidelines..." name="guidelines" value={this.state.guidelines} onChange={this.formChangeHandler}></textarea>
                                    <label className="description-text-3 description-text-color half-bold-text">100 Word Limit</label>
                                  </div>

                                  <div className="edit-profile-row">
                                    <label className="profile-label clear-padding">Resources (Optional)</label>
                                    <p className="profile-descriptor">Add links to articles, whitepapers, videos, online courses, and other resources.</p>

                                    <div className="spacer" />
                                    {this.renderResources()}
                                    <div className="clear" />
                                    <div className="add-item-line-button">
                                      <hr className="vertical-margin-20" />
                                      <a className="background-button" onClick={() => this.addResource()}>
                                        <img src={addCircleOutlineIcon} alt="Compass add circle outline icon" />
                                      </a>
                                    </div>
                                  </div>

                                  {((this.state.postType === 'Project' && this.state.projectPromptType !== 'Assignment') || (this.state.postType !== 'Assignment' && !this.state.projectPromptType)) && (
                                    <div>
                                      <div className="edit-profile-row">
                                        <label className="profile-label">Lessons to be Learned (Optional)</label>
                                        <p className="profile-descriptor">If you have big takeaways, skills, or competencies you expect students to learn solving this, list them here.</p>
                                        <div className="half-spacer" />
                                        <textarea type="text" className="text-field" placeholder="List academic competencies and career skills to be learned, separated by commas..." name="lessons" value={this.state.lessons} onChange={this.formChangeHandler}></textarea>
                                        <label className="description-text-3 description-text-color half-bold-text">100 Word Limit</label>
                                      </div>

                                      <div className="edit-profile-row">
                                        <label className="profile-label clear-padding">"Solution" (Optional)</label>
                                        <p className="profile-descriptor">If your team has already executed a solution on the problem, add it here so that the teacher can reveal how you approached at the appropriate time.</p>
                                        <div className="half-spacer" />
                                        <textarea type="text" className="text-field" placeholder="Add solution..." name="solution" value={this.state.solution} onChange={this.formChangeHandler}></textarea>
                                        <label className="description-text-3 description-text-color half-bold-text">800 Word Limit</label>
                                      </div>

                                      <div className="edit-profile-row">
                                        <label className="profile-label clear-padding">Exhibits (Optional)</label>
                                        <p className="profile-descriptor">Add graphs, charts, data, and additional information via links. The image will show below if successfully added. Make sure to take the "embed" version of the link.</p>

                                        <div className="spacer" />
                                        {this.renderExhibits()}
                                        <div className="clear" />
                                        <div className="add-item-line-button">
                                          <hr />
                                          <a className="background-button" onClick={() => this.addExhibit()}>
                                            <img src={addCircleOutlineIcon} alt="Compass add circle outline icon" />
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  <div className="clear" />

                                  <hr className="vertical-margin-20" />

                                  {(this.state.showFeatured) && (
                                    <div>

                                      <div className="row-20">
                                        <p className="heading-text-4">Tag, Categorize, & Configure</p>
                                      </div>

                                      {this.renderVisibilityOptions()}

                                      <div className="row-15">
                                        <div className="container-left right-padding">
                                          <label className="profile-label clear-padding">Would you like to feature this posting?</label>
                                          <label className="profile-descriptor top-margin-5">Featuring a posting highlights the posting to career-seekers. It will be among the first postings the student sees.</label>
                                          <div className="clear" />
                                          <div className="spacer" /><div className="half-spacer"/>
                                          <Switch
                                            onChange={(change) => this.setState({ featured: change, formHasChanged: true })}
                                            checked={this.state.featured}
                                            id="normal-switch"
                                          />
                                        </div>

                                        <div className="container-right right-padding">
                                          <label className="profile-label clear-padding">Would you like to disable downvoting?</label>
                                          <label className="profile-descriptor top-margin-5">Disable downvoting if posting within a contained, moderated commmunity of sensitive creators. Learn more <a href="http://lanayarosh.com/2017/07/down-with-downvotes-why-do-subreddits-disable-downvotes/" target="_blank" rel="noopener noreferrer">here</a>.</label>
                                          <div className="clear" />
                                          <div className="spacer" /><div className="half-spacer"/>
                                          <Switch
                                            onChange={(change) => this.setState({ disableDownvoting: change, formHasChanged: true })}
                                            checked={this.state.disableDownvoting}
                                            id="normal-switch"
                                          />
                                        </div>
                                        <div className="clear" />
                                      </div>

                                      <div className="row-15">
                                        <div className="container-left right-padding">
                                          <label className="profile-label clear-padding">Attach to courses?</label>
                                          <label className="profile-descriptor">This will restrict this posting to those enrolled in courses</label>
                                          <div className="clear" />
                                          <div className="spacer" /><div className="half-spacer"/>
                                          <Switch
                                            onChange={(change) => this.setState({ attachToCourses: change, formHasChanged: true })}
                                            checked={this.state.attachToCourses}
                                            id="normal-switch"
                                          />
                                        </div>

                                        <div className="clear" />

                                        {(this.state.attachToCourses) && (
                                          <div>
                                            <div>
                                              <label className="profile-label">Attach Courses</label>
                                              <div className="clear" />
                                            </div>

                                            <div className="container-left">
                                              <select name="course" value={this.state.course.name} onChange={this.formChangeHandler} className="dropdown">
                                                {this.state.courseOptions.map(value =>
                                                  <option key={value.name} value={value.name}>{value.name}</option>
                                                )}
                                              </select>
                                            </div>
                                            <div className="container-right">
                                              <button className="btn btn-squarish" onClick={() => this.addItem('course') }>Add</button>
                                            </div>
                                            <div className="clear" />
                                            <div className="spacer" />

                                            {this.state.selectedCourses.map((value, optionIndex) =>
                                              <div key={value.name + "|" + optionIndex} className="float-left">
                                                <div className="close-button-container-1">
                                                  <button className="background-button" onClick={() => this.removeItem(optionIndex,'course')}>
                                                    <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                                                  </button>
                                                </div>
                                                <div className="float-left row-5 right-padding">
                                                  <div className="tag-container-5">
                                                    <p className="description-text-2">{value.name}</p>
                                                  </div>
                                                </div>
                                              </div>
                                            )}

                                            <div className="clear" />

                                          </div>
                                        )}
                                      </div>

                                      {this.renderPathways()}
                                      {this.renderDepartments()}
                                      {this.renderTargetRoles()}
                                      {this.renderPrograms()}
                                    </div>
                                  )}

                                  {((this.state.showCustomConfirmationMessage) && (window.location.pathname.includes('/organizations') || (window.location.pathname.includes('/employers') && this.state.org === 'guidedcompass'))) && (
                                    <div>
                                      <div className="spacer" />
                                      <hr />
                                      <div className="spacer" /><div className="spacer" />

                                      {this.renderConfirmationMessage()}

                                    </div>
                                  )}

                                  {(window.location.pathname.includes('/employers') && this.state.org === 'guidedcompass') && (
                                    <div>
                                      {this.renderSharePartners('project')}
                                    </div>
                                  )}

                                  {(this.state.path && this.state.path.includes('problem-platform')) && (
                                    <div>
                                      <hr className="vertical-margin-20" />

                                      <div className="spacer" /><div className="spacer"/>

                                      <div className="edit-profile-row">
                                        <div className="container-left">
                                          <label className="profile-label">Your First Name<label className="error-color bold-text">*</label></label>
                                          <input type="text" className="text-field" placeholder="First name..." name="firstName" value={this.state.cuFirstName} onChange={this.formChangeHandler}></input>
                                        </div>
                                        <div className="container-right">
                                          <label className="profile-label">Your Last Name<label className="error-color bold-text">*</label></label>
                                          <input type="text" className="text-field" placeholder="Last name..." name="lastName" value={this.state.cuLastName} onChange={this.formChangeHandler}></input>
                                        </div>
                                        <div className="clear" />
                                      </div>

                                      <div className="row-10">
                                        <div className="container-left">
                                          <label className="profile-label">Your Email<label className="error-color bold-text">*</label></label>
                                          <input type="text" className="text-field" placeholder="Email..." name="email" value={this.state.emailId} onChange={this.formChangeHandler}></input>
                                        </div>
                                        <div className="container-right">
                                          <label className="profile-label">Job Title<label className="error-color bold-text">*</label></label>
                                          <input type="text" className="text-field" placeholder="Job title..." name="jobTitle" value={this.state.jobTitle} onChange={this.formChangeHandler}></input>
                                        </div>
                                        <div className="clear" />
                                      </div>

                                      <div className="edit-profile-row">
                                        <div className="container-left">
                                          <label className="profile-label">Which best describes your role?<label className="error-color bold-text">*</label></label>
                                          <select name="roleName" value={this.state.roleName} onChange={this.formChangeHandler} className="dropdown">
                                            {this.state.roleNameOptions.map(value =>
                                              <option key={value} value={value}>{value}</option>
                                            )}
                                          </select>
                                        </div>
                                        <div className="container-right">
                                          <label className="profile-label">How did you hear about this? (Optional)</label>
                                          <input type="text" className="text-field" placeholder="Briefly share..." name="referralSource" value={this.state.referralSource} onChange={this.formChangeHandler}></input>
                                        </div>
                                        <div className="clear" />
                                      </div>

                                      <div className="spacer" /><div className="spacer"/>

                                      <hr className="vertical-margin-20" />

                                    </div>
                                  )}

                                  { (this.state.clientErrorMessage!== '') && <p className="error-message">{this.state.clientErrorMessage}</p> }
                                  { (this.state.serverPostSuccess) ? (
                                    <p className="success-message">{this.state.serverSuccessMessage}</p>
                                  ) : (
                                    <p className="error-message">{this.state.serverErrorMessage}</p>
                                  )}

                                  {(this.state.selectedOpportunity && this.state.selectedOpportunity._id) ? (
                                    <div>
                                      {(this.state.confirmDelete === true) ? (
                                        <div>
                                          {this.renderConfirmDelete()}
                                        </div>
                                      ) : (
                                        <div>
                                          <div className="float-left right-padding-20">
                                            <button className="btn btn-primary"  disabled={this.state.isSaving} onClick={() => this.editJob()}>Save Edits</button>
                                          </div>
                                          <div className="float-left right-padding-20">
                                            <button className="btn btn-secondary"  disabled={this.state.isSaving} onClick={() => this.setState({ confirmDelete: true })}>Delete Post</button>
                                          </div>
                                          <div className="clear" />
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <div>
                                      {(this.state.assigneeGroup === 'Specific Individuals' || this.state.assigneeGroup.includes('Workers')) ? (
                                        <button className="btn btn-primary"  disabled={this.state.isSaving} onClick={() => this.editJob()}>Save and Send Assignment</button>
                                      ) : (
                                        <button className="btn btn-primary"  disabled={this.state.isSaving} onClick={() => this.editJob()}>{this.state.requestMode ? "Request Approval for " + this.state.postType : "Save " + this.state.postType}</button>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}

                              <div className="spacer" /><div className="half-spacer"/>

                              {((this.state.postType === 'Challenge' || this.state.projectPromptType === 'Challenge') && this.state.selectedOpportunity && this.state.selectedOpportunity._id) && (
                                <div>
                                  <div className="clear"/>
                                  <div className="spacer" />
                                  <hr className="vertical-margin-20" />

                                  <div className="spacer" /><div className="spacer" />

                                  <div>
                                    <div>
                                      <div className="float-left right-padding">
                                        <label className="heading-text-3">{(this.state.rsvps && this.state.rsvps.length > 0) && this.state.rsvps.length + " "}RSVPs</label>
                                      </div>
                                      {(!this.props.path.includes('/employers')) && (
                                        <div className="float-left">
                                          <button className="btn background-button"  disabled={this.state.isSaving} onClick={() => this.exportInfo('rsvps')}><img src={exportIconBlue} alt="Compass export icon icon" className="image-auto-25"/></button>
                                        </div>
                                      )}

                                      <div className="clear" />
                                      <div className="spacer" />
                                    </div>

                                    { (this.state.clientErrorMessage!== '') && <p className="error-message">{this.state.clientErrorMessage}</p> }
                                    { (this.state.serverPostSuccess) ? (
                                      <p className="success-message">{this.state.serverSuccessMessage}</p>
                                    ) : (
                                      <p className="error-message">{this.state.serverErrorMessage}</p>
                                    )}

                                    <div className="spacer" />
                                    {this.renderRSVPs('rsvps')}
                                  </div>
                                </div>
                              )}
                            </div>
                          )}

                          {(this.state.viewIndex === 1) && (
                            <div>
                              <SubSubmissions selectedOpportunity={this.state.selectedOpportunity} activeOrg={this.state.activeOrg} accountCode={this.state.accountCode} submissionComments={this.state.submissionComments} postingOrgCode={this.state.postingOrgCode} postingOrgName={this.state.postingOrgName} orgContactEmail={this.state.orgContactEmail} pictureURL={this.state.pictureURL}  gradeOptions={this.state.gradeOptions}/>
                            </div>
                          )}

                          {(this.state.viewIndex === 2) && (
                            <div>
                              <div>
                                <SubComments selectedOpportunity={this.state.selectedOpportunity} activeOrg={this.state.activeOrg} accountCode={this.state.accountCode} comments={this.state.comments} postingOrgCode={this.state.postingOrgCode} postingOrgName={this.state.postingOrgName} orgContactEmail={this.state.orgContactEmail} pictureURL={this.state.pictureURL} />
                              </div>
                            </div>
                          )}

                        </div>
                      )}

                      {(this.state.postType === 'Program') && (
                        <div>

                          <div className="clear" />
                          <div className="half-spacer" />

                          <div className="row-15">
                            <p><label className="bold-text cta-color">Programs</label> are training programs offered by an organization for a limited period of time.</p>
                          </div>

                          {(this.state.selectedOpportunity && this.state.selectedOpportunity.isActive && this.state.selectedOpportunity._id) && (
                            <div className="row-10">
                              <p>Link to share with career-seekers: <a href={window.location.protocol + "//" + window.location.host + '/app/opportunities/' + this.state.selectedOpportunity._id} target="_blank" rel="noopener noreferrer" className="wrap-word">{window.location.protocol + "//" + window.location.host + '/app/opportunities/' + this.state.selectedOpportunity._id}</a></p>

                              <div className="spacer" /><div className="spacer" />
                            </div>
                          )}

                          <hr className="clear-margin clear-padding"/>
                          <div className="spacer" /><div className="half-spacer" />

                          {(this.state.selectedOpportunity && this.state.selectedOpportunity._id && ((this.state.showFeatured && window.innerWidth < 768) || (this.state.org === 'guidedcompass' && window.innerWidth < 768) || window.location.pathname.includes('/walkthrough'))) && (
                            <div>
                              <div className="benchmark-container-1">
                                <p className="heading-text-3">Applicants</p>
                              </div>

                              {(this.state.applications.length > 0) && (
                                <div>
                                  {(this.state.org === 'guidedcompass') ? (
                                    <Link className="secret-link" to={(window.location.pathname.includes('/employers')) ? '/employers/' + this.state.accountCode + '/postings/' + this.state._id + '/applications' : pathPrefix + '/postings/' + this.state._id + '/applications'} state={{ selectedJob: this.state.selectedOpportunity, applications: this.state.applications, benchmark: this.state.benchmark, benchmarkOptions: this.state.benchmarkOptions }}>
                                      <div className="benchmark-container-4">
                                        <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                        <label className="heading-text-4">See All ></label>
                                      </div>
                                    </Link>
                                  ) : (
                                    <Link className="secret-link" to={pathPrefix + '/postings/' + this.state._id + '/applications'} state={{ selectedJob: this.state.selectedOpportunity, applications: this.state.applications, benchmark: this.state.benchmark, benchmarkOptions: this.state.benchmarkOptions }}>
                                      <div className="benchmark-container-4">
                                        <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                        <label className="heading-text-4">See All ></label>
                                      </div>
                                    </Link>
                                  )}
                                </div>
                              )}

                              <br/>
                              <div className="clear"/>

                              {(this.state.applications.length !== 0) ? (
                                <div>
                                  <br/>
                                  <div>
                                    {this.renderApplications()}
                                  </div>
                                  <div className="clear" />
                                </div>
                              ) : (
                                <div>
                                  {(this.state.animating) ? (
                                    <div className="flex-container flex-center row-10">
                                      <div>
                                        <img src={loadingGIF} alt="GC" className="image-auto-80 center-horizontally"/>
                                        <div className="spacer" /><div className="spacer"/><div className="spacer"/>
                                        <p className="center-text cta-color bold-text">Retrieving applications...</p>
                                      </div>
                                    </div>
                                  ) : (
                                    <div>
                                      {(this.state.applicationErrorMessage) ? (
                                        <p className="error-message">{this.state.applicationErrorMessage}</p>
                                      ) : (
                                        <p className="error-message">There are no applicants</p>
                                      )}

                                      <br/>
                                    </div>
                                  )}

                                </div>
                              )}

                              <hr className="vertical-margin-20" />

                            </div>
                          )}

                          {(this.state.selectedOpportunity && this.state.readOnly && this.props.path && !this.props.path.includes('/employers/')) ? (
                            <div>
                              {this.renderReadOnlyContent()}
                            </div>
                          ) : (
                            <div>

                              {(this.props.path && !this.props.path.includes('/employers/')) && (
                                <div>

                                  <div className="row-20">
                                    <p className="heading-text-4">Core Information</p>
                                  </div>

                                  <div className="row-10">
                                    <div>
                                      <div className="float-left">
                                        <label className="profile-label">Add & Import a Training Program</label>
                                      </div>
                                      <div className="float-left left-padding top-padding">
                                        <Link to={"/organizations/" + this.state.org + "/programs/add"} className="background-button" target="_blank" rel="noopener noreferrer">
                                          <div className="padding-5 cta-border circle-radius" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Add a program">
                                            <img src={addIconBlue} className="image-auto-15" alt="GC"/>
                                          </div>
                                        </Link>
                                      </div>
                                      <div className="clear" />
                                      <ReactTooltip id="tooltip-placeholder-id" />
                                    </div>

                                    <select name="selectedProgram" value={(this.state.selectedProgram) ? this.state.selectedProgram.title : ""} onChange={this.formChangeHandler} className="dropdown">
                                      {this.state.programOptions.map(value =>
                                        <option key={value.title} value={value.title}>{value.title}</option>
                                      )}
                                    </select>
                                  </div>

                                  <div className="row-10">
                                    <div className="container-left vertical-margin-10">
                                      <label className="profile-label">Name<label className="error-color bold-text">*</label></label>
                                      <input type="text" className="text-field" placeholder="Add the name of the program..." name="title" value={this.state.title} onChange={this.formChangeHandler}></input>
                                    </div>
                                    <div className="container-right vertical-margin-10">
                                      <label className="profile-label">Include a Benchmark{(!this.state.disableSomeRequirements) && <label className="error-color bold-text">*</label>}</label>
                                      <select name="benchmark" value={this.state.benchmark.title} onChange={this.formChangeHandler} className="dropdown">
                                        {this.state.benchmarkOptions.map(value =>
                                          <option key={value.key} value={value.title}>{value.title}</option>
                                        )}
                                      </select>
                                    </div>
                                    <div className="clear" />
                                  </div>

                                </div>
                              )}

                              {(this.state.org === 'unite-la' && !window.location.pathname.includes('/employers')) && (
                                <div>
                                  {this.renderImageAsTitle()}
                                </div>
                              )}

                              {(this.state.showFeatured) && (
                                <div>
                                  <div className="edit-profile-row">
                                    <div>
                                      <div className="float-left">
                                        <label className="profile-label">Add the Training Provider<label className="error-color bold-text">*</label></label>
                                      </div>
                                      <div className="float-left left-padding top-padding">
                                        <Link to={"/organizations/" + this.state.org + "/employers/add"} className="background-button" target="_blank" rel="noopener noreferrer">
                                          <div className="padding-5 cta-border circle-radius" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Add an employer to the dropdown list">
                                            <img src={addIconBlue} className="image-auto-15" alt="GC"/>
                                          </div>

                                        </Link>
                                      </div>
                                      <div className="clear" />
                                      <ReactTooltip id="tooltip-placeholder-id" />
                                    </div>

                                    <select name="selectEmployer" value={this.state.selectedEmployer.employerName} onChange={this.formChangeHandler} className="dropdown">
                                      {this.state.employerOptions.map(value =>
                                        <option key={value.employerName} value={value.employerName}>{value.employerName}</option>
                                      )}
                                    </select>
                                  </div>
                                </div>
                              )}

                              <div className="container-left">
                                <label className="profile-label">Location<label className="error-color bold-text">*</label></label>
                                <select name="location" value={this.state.location} onChange={this.formChangeHandler} className="dropdown">
                                  {this.state.locationOptions.map(value =>
                                    <option key={value} value={value}>{value}</option>
                                  )}
                                </select>
                              </div>

                              {(this.state.location === 'One Location') && (
                                <div>
                                  <div className="container-right">
                                    <label className="profile-label">Position Zip Code<label className="error-color bold-text">*</label></label>
                                    <input type="number" className="number-field" max="99999" placeholder="90210" name="zipcode" value={this.state.zipcode} onChange={this.formChangeHandler}></input>
                                  </div>
                                </div>
                              )}

                              {(this.state.location === 'One Location') ? (
                                <div>
                                  <div className="clear" />
                                  <div className="container-left row-10">
                                    <label className="profile-label">Hours per Week{(!this.state.disableSomeRequirements) && <label className="error-color bold-text">*</label>}</label>
                                    <select name="hoursPerWeek" value={this.state.hoursPerWeek} onChange={this.formChangeHandler} className="dropdown">
                                      {this.state.hoursPerWeekOptions.map(value =>
                                        <option key={value.key} value={value}>{value}</option>
                                      )}
                                    </select>
                                  </div>
                                  <div className="clear" />
                                </div>
                              ) : (
                                <div className="container-right">
                                  <label className="profile-label">Hours per Week{(!this.state.disableSomeRequirements) && <label className="error-color bold-text">*</label>}</label>
                                  <select name="hoursPerWeek" value={this.state.hoursPerWeek} onChange={this.formChangeHandler} className="dropdown">
                                    {this.state.hoursPerWeekOptions.map(value =>
                                      <option key={value.key} value={value}>{value}</option>
                                    )}
                                  </select>
                                </div>
                              )}

                              <div className="clear"/>

                              <div className="edit-profile-row">
                                <div className="container-left">
                                  <label className="profile-label">How many openings are there?</label>
                                  <input className="number-field" type="number" placeholder="0" name="hireCount" value={this.state.hireCount} onChange={this.formChangeHandler}/>
                                </div>

                                <div className="clear" />
                              </div>

                              {this.renderDateRange()}
                              {this.renderDescription()}

                              <div>
                                <div className="container-left">
                                  <div className="float-left">
                                    <label className="profile-label">Application Deadline</label>
                                  </div>
                                  <div>
                                    <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                    <div className="float-left left-margin noti-bubble-info-7-9">
                                      <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, applicationDeadlineInfo: true  })}>
                                        <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                                      </a>
                                    </div>

                                  </div>
                                  <div className="clear" />

                                  {(this.state.useNewSubmissionDeadline) ? (
                                    <input type="datetime-local" className="date-picker" min={convertDateToString(new Date(),"rawDateForInput")} placeholder="Application deadline..." name="submissionDeadline" value={this.state.submissionDeadline} onChange={this.formChangeHandler}></input>
                                  ) : (
                                    <input type="date" className="date-picker" min={convertDateToString(new Date(),"rawDateForInput")} placeholder="Application deadline..." name="submissionDeadline" value={this.state.submissionDeadline} onChange={this.formChangeHandler}></input>
                                  )}

                                </div>

                                {(this.state.org === 'guidedcompass') && (
                                  <div>
                                    <div>
                                      <div className="row-10">
                                        <div className="container-left">
                                          <label className="profile-label">U.S. documented workers only?<label className="error-color bold-text">*</label></label>
                                          <select name="hostUndocumented" value={this.state.hostUndocumented} onChange={this.formChangeHandler} className="dropdown">
                                            {this.state.binaryOptions.map(value =>
                                              <option key={value} value={value}>{value}</option>
                                            )}
                                          </select>
                                        </div>
                                        <div className="clear" />
                                      </div>

                                    </div>
                                  </div>
                                )}

                                <div className="clear" />
                              </div>

                              {(!this.state.showFeatured) && (
                                <div>
                                  {this.renderTaggedCandidatesOfInterest()}
                                </div>
                              )}

                              {(this.state.showApplicationMethodDetails) && (
                                <div>
                                  {this.renderApplicationMethodDetails()}
                                </div>
                              )}

                              <hr className="vertical-margin-20" />

                              {(this.state.showFeatured || this.state.org === 'guidedcompass') ? (
                                <div>
                                  {this.renderApplicationComponents()}

                                  <div>
                                    {(window.location.pathname.includes('/organizations')) ? (
                                      <div>
                                        <div className="row-20">
                                          <p className="heading-text-4">Tag, Categorize, & Configure</p>
                                        </div>

                                        {this.renderVisibilityOptions()}

                                        <div>
                                          <div className="row-15">
                                            <div className="container-left">
                                              <label className="profile-label clear-padding">Would you like to feature this posting?</label>
                                              <label className="profile-descriptor top-margin-5">Featuring a posting highlights the posting to students. It will be among the first postings the student sees.</label>
                                              <div className="clear" />
                                              <div className="spacer" /><div className="half-spacer"/>
                                              <Switch
                                                onChange={(change) => this.setState({ featured: change, formHasChanged: true })}
                                                checked={this.state.featured}
                                                id="normal-switch"
                                              />
                                            </div>

                                            <div className="clear" />

                                          </div>
                                          <div className="row-15">
                                            <div className="container-left">
                                              <label className="profile-label clear-padding">Allow Referrals?</label>
                                              <label className="profile-descriptor top-margin-5">Do you want to allow people to refer others and add that referral code when they apply?</label>
                                              <div className="clear" />
                                              <div className="spacer" /><div className="half-spacer"/>
                                              <Switch
                                                onChange={(change) => this.setState({ allowReferrals: change, formHasChanged: true })}
                                                checked={this.state.allowReferrals}
                                                id="normal-switch"
                                              />
                                            </div>
                                            <div className="clear" />
                                          </div>

                                          <div className="row-15">
                                            <div className="container-left">
                                              <label className="profile-label clear-padding">Hide Ideal Candidate Profile</label>
                                              <label className="profile-descriptor top-margin-5">Hide career-seekers' ability to see benchmark details</label>
                                              <div className="clear" />
                                              <div className="spacer" /><div className="half-spacer"/>
                                              <Switch
                                                onChange={(change) => this.setState({ hideIdealCandidateProfile: change, formHasChanged: true })}
                                                checked={this.state.hideIdealCandidateProfile}
                                                id="normal-switch"
                                              />
                                            </div>
                                            <div className="container-right">
                                              <label className="profile-label clear-padding">Hide Match Score</label>
                                              <label className="profile-descriptor top-margin-5">Hide career-seekers' ability to see their match score.</label>
                                              <div className="clear" />
                                              <div className="spacer" /><div className="half-spacer"/>
                                              <Switch
                                                onChange={(change) => this.setState({ hideViewMatch: change, formHasChanged: true })}
                                                checked={this.state.hideViewMatch}
                                                id="normal-switch"
                                              />
                                            </div>
                                            <div className="clear" />
                                          </div>
                                        </div>

                                        {this.renderPathways()}
                                        {this.renderDepartments()}
                                        {this.renderTargetRoles()}
                                        {this.renderPrograms()}
                                        {this.renderTaggedCandidatesOfInterest()}
                                        {this.renderTaggedPostings()}
                                      </div>
                                    ) : (
                                      <div>
                                        {this.renderTaggedCandidatesOfInterest()}

                                        <hr />
                                        <div className="spacer" /><div className="spacer" />
                                      </div>
                                    )}

                                  </div>

                                </div>
                              ) : (
                                <div>
                                  {this.renderVisibilityOptions()}
                                </div>
                              )}

                              {((this.state.showCustomConfirmationMessage) && (window.location.pathname.includes('/organizations') || (window.location.pathname.includes('/employers') && this.state.org === 'guidedcompass'))) && (
                                <div>
                                  {this.renderConfirmationMessage()}
                                </div>
                              )}

                              {(window.location.pathname.includes('/employers') && this.state.org === 'guidedcompass') && (
                                <div>
                                  {this.renderSharePartners('work')}
                                </div>
                              )}

                              {(window.location.pathname.includes('/organizations/')) && (
                                <div>
                                  {this.renderOrgContactInformation()}

                                  {(this.state.org === 'dpscd') && (
                                    <div className="edit-profile-row">
                                      <div className="name-container">
                                        <label className="profile-label">Referrer Name<label className="error-color bold-text">*</label></label>
                                        <select name="referrerName" value={this.state.referrerName} onChange={this.formChangeHandler} className="dropdown">
                                          {this.state.referrerOptions.map(value =>
                                            <option key={value} value={(value.contactFirstName) && value.contactFirstName + ' ' + value.contactLastName}>{(value.contactFirstName) && value.contactFirstName + ' '+ value.contactLastName}</option>
                                          )}
                                        </select>
                                      </div>
                                      {(this.state.referrerName === 'Other' || this.state.referrerName === 'Other ') && (
                                        <div className="name-container">
                                          <label className="profile-label">Other, Please Explain:<label className="error-color bold-text">*</label></label>
                                          <input type="text" className="text-field" placeholder="Add name..." name="referralExplanation" value={this.state.referralExplanation} onChange={this.formChangeHandler}></input>
                                        </div>
                                      )}

                                      <div className="clear" />
                                    </div>
                                  )}

                                  {(!this.state.showApplicationMethodDetails) && (
                                    <div>
                                      <div className="edit-profile-row">
                                        <div className="name-container">
                                          <label className="profile-label">Employer Contact First Name:<label className="error-color bold-text">*</label></label>
                                          <input type="text" className="text-field" placeholder="First name..." name="employerContactFirstName" value={this.state.employerContactFirstName} onChange={this.formChangeHandler}></input>
                                        </div>
                                        <div className="name-container">
                                          <label className="profile-label">Employer Contact Last Name:<label className="error-color bold-text">*</label></label>
                                          <input type="text" className="text-field" placeholder="Last name..." name="employerContactLastName" value={this.state.employerContactLastName} onChange={this.formChangeHandler}></input>
                                        </div>
                                        <div className="clear" />
                                      </div>

                                      <div className="edit-profile-row clear-padding">
                                        <div className="name-container">
                                          <label className="profile-label">Employer Contact Email:<label className="error-color bold-text">*</label></label>
                                          <input type="text" className="text-field" placeholder="Email address..." name="employerContactEmail" value={this.state.employerContactEmail} onChange={this.formChangeHandler}></input>
                                        </div>
                                        <div className="clear" />
                                      </div>

                                    </div>
                                  )}

                                  <hr className="vertical-margin-20" />

                                </div>
                              )}

                              { (this.state.clientErrorMessage!== '') && <p className="error-message">{this.state.clientErrorMessage}</p> }
                              { (this.state.serverPostSuccess) ? (
                                <p className="success-message">{this.state.serverSuccessMessage}</p>
                              ) : (
                                <p className="error-message">{this.state.serverErrorMessage}</p>
                              )}

                              {(this.state.selectedOpportunity && this.state.selectedOpportunity._id) ? (
                                <div>
                                  {(this.state.confirmDelete === true) ? (
                                    <div>
                                      {this.renderConfirmDelete()}
                                    </div>
                                  ) : (
                                    <div>
                                      {(this.state.isFilled) && (
                                        <div>
                                          <p className="error-color half-bold-text bottom-margin">This posting has been placed</p>
                                        </div>
                                      )}

                                      <div className="float-left right-padding-20">
                                        <button className="btn btn-primary"  disabled={this.state.isSaving} onClick={() => this.editJob()}>Save Edits</button>
                                      </div>
                                      {(window.location.pathname.includes('/organizations') || window.location.pathname.includes('/advisor')) && (
                                        <div className="float-left right-padding-20">
                                          {(!this.state.isFilled) && (
                                            <div>
                                              {console.log('---- yes -!', this.state.location)}
                                              <button className="btn btn-secondary" disabled={this.state.isSaving} onClick={() => this.setState({ modalIsOpen: true, confirmFill: true })}>Declare Filled</button>
                                            </div>
                                          )}

                                        </div>
                                      )}
                                      <div className="float-left right-padding-20">
                                        <button className="btn btn-quaternary"  disabled={this.state.isSaving} onClick={() => this.setState({ confirmDelete: true })}>Delete Post</button>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div>
                                  <button className="btn btn-primary"  disabled={this.state.isSaving} onClick={() => this.editJob()}>Post {this.state.postType}</button>
                                </div>
                              )}

                            </div>
                          )}

                        </div>
                      )}

                      {(this.state.postType === 'Internship' || this.state.postType === 'Individual' || this.state.postType === 'Work') && (
                        <div>

                          <div className="clear" />
                          <div className="half-spacer" />

                          <div>
                            {(this.state.postType === 'Work') ? (
                              <div className="row-15">
                                <p><label className="bold-text cta-color">Work</label> refers to full-time, part-time, internship, apprenticeship, or volunteer work.</p>
                              </div>
                            ) : (
                              <div className="row-15">
                                <p><label className="bold-text cta-color">Internships</label> are periods of work experience offered by an organization for a limited period of time.</p>
                              </div>
                            )}
                          </div>

                          {(this.state.selectedOpportunity && this.state.selectedOpportunity.isActive && this.state.selectedOpportunity._id) && (
                            <div className="row-10">
                              <p>Link to share with career-seekers: <a href={window.location.protocol + "//" + window.location.host + '/app/opportunities/' + this.state.selectedOpportunity._id} target="_blank" rel="noopener noreferrer" className="wrap-word">{window.location.protocol + "//" + window.location.host + '/app/opportunities/' + this.state.selectedOpportunity._id}</a></p>

                              <div className="spacer" /><div className="spacer" />
                            </div>
                          )}

                          {(this.state.isFilled) && (
                            <div>
                              <p className="error-color description-text-2 half-bold-text">This posting has been placed</p>
                              <div className="spacer" /><div className="spacer" />
                            </div>
                          )}

                          <hr className="clear-margin clear-padding"/>
                          <div className="spacer" /><div className="half-spacer" />

                          {(this.state.roleName === 'Mentor') ? (
                            <div>
                              <p>Work opportunities must be posted through the employer portal dedicated to your organization. Use <a href={"https://www.guidedcompass.com/employers/organizations/" + this.state.org + '/join'} target="_blank" rel="noopener noreferrer" className="half-bold-text">this link</a>.</p>
                            </div>
                          ) : (
                            <div>

                              {(this.state.selectedOpportunity && this.state.selectedOpportunity._id && ((this.state.showFeatured && window.innerWidth < 768) || (this.state.org === 'guidedcompass' && window.innerWidth < 768) || window.location.pathname.includes('/walkthrough'))) && (
                                <div>
                                  <div className="benchmark-container-1">
                                    <p className="heading-text-3">Applicants</p>
                                  </div>

                                  {(this.state.applications.length > 0) && (
                                    <div>
                                      {(this.state.org === 'guidedcompass') ? (
                                        <Link className="secret-link" to={(window.location.pathname.includes('/employers')) ? '/employers/' + this.state.accountCode + '/postings/' + this.state._id + '/applications' : pathPrefix + '/postings/' + this.state._id + '/applications'} state={{ selectedJob: this.state.selectedOpportunity, applications: this.state.applications, benchmark: this.state.benchmark, benchmarkOptions: this.state.benchmarkOptions }}>
                                          <div className="benchmark-container-4">
                                            <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                            <label className="heading-text-4">See All ></label>
                                          </div>
                                        </Link>
                                      ) : (
                                        <Link className="secret-link" to={pathPrefix + '/postings/' + this.state._id + '/applications'} state={{ selectedJob: this.state.selectedOpportunity, applications: this.state.applications, benchmark: this.state.benchmark, benchmarkOptions: this.state.benchmarkOptions }}>
                                          <div className="benchmark-container-4">
                                            <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                            <label className="heading-text-4">See All ></label>
                                          </div>
                                        </Link>
                                      )}
                                    </div>
                                  )}

                                  <br/>
                                  <div className="clear"/>

                                  {(this.state.applications.length !== 0) ? (
                                    <div>
                                      <br/>
                                      <div>
                                        {this.renderApplications()}
                                      </div>
                                      <div className="clear" />
                                    </div>
                                  ) : (
                                    <div>
                                      {(this.state.animating) ? (
                                        <div className="flex-container flex-center row-10">
                                          <div>
                                            <img src={loadingGIF} alt="GC" className="image-auto-80 center-horizontally"/>
                                            <div className="spacer" /><div className="spacer"/><div className="spacer"/>
                                            <p className="center-text cta-color bold-text">Retrieving applications...</p>
                                          </div>
                                        </div>
                                      ) : (
                                        <div>
                                          {(this.state.applicationErrorMessage) ? (
                                            <p className="error-message">{this.state.applicationErrorMessage}</p>
                                          ) : (
                                            <p className="error-message">There are no applicants</p>
                                          )}

                                          <br/>
                                        </div>
                                      )}

                                    </div>
                                  )}

                                  <hr className="vertical-margin-20" />

                                </div>
                              )}

                              {(this.state.selectedOpportunity && this.state.readOnly && this.props.path && !this.props.path.includes('/employers/')) ? (
                                <div>
                                  {this.renderReadOnlyContent()}
                                </div>
                              ) : (
                                <div>

                                  <div className="row-20">
                                    <p className="heading-text-4">Core Information</p>
                                  </div>

                                  <div className="row-10">
                                    <div className="container-left">
                                      <label className="profile-label">Job Title<label className="error-color bold-text">*</label></label>
                                      <input type="text" className="text-field" placeholder="Add the job title..." name="title" value={this.state.title} onChange={this.formChangeHandler}></input>
                                    </div>

                                    <div className="container-right">
                                        <label className="profile-label">Function<label className="error-color bold-text">*</label></label>
                                        <select name="function" value={this.state.function} onChange={this.formChangeHandler} className="dropdown">
                                          {this.state.functionOptions.map(value =>
                                            <option key={value.key} value={value}>{value}</option>
                                          )}
                                        </select>
                                    </div>

                                    <div className="clear" />
                                  </div>

                                  {((this.props.path && !this.props.path.includes('/employers/')) || this.state.org === 'guidedcompass') && (
                                    <div>
                                      <div className="container-left vertical-margin-10">
                                        <label className="profile-label">Work Type<label className="error-color bold-text">*</label></label>
                                        <select name="subPostType" value={this.state.subPostType} onChange={this.formChangeHandler} className="dropdown">
                                          {this.state.subPostTypeOptions.map(value =>
                                            <option key={value} value={value}>{value}</option>
                                          )}
                                        </select>
                                      </div>
                                      {(this.state.org === 'guidedcompass') && (
                                        <div className="clear" />
                                      )}
                                    </div>
                                  )}
                                  {(this.props.path && !this.props.path.includes('/employers/')) && (
                                    <div>
                                      <div>
                                        <div className="container-right vertical-margin-10">
                                          <label className="profile-label">Include a Benchmark{(!this.state.disableSomeRequirements) && <label className="error-color bold-text">*</label>}</label>
                                          <select name="benchmark" value={this.state.benchmark.title} onChange={this.formChangeHandler} className="dropdown">
                                            {this.state.benchmarkOptions.map(value =>
                                              <option key={value.key} value={value.title}>{value.title}</option>
                                            )}
                                          </select>
                                        </div>
                                      </div>
                                      <div className="clear" />

                                      {(this.state.org === 'yearup') && (
                                        <div className="row-10">
                                          <div className="container-left">
                                            <label className="profile-label">Workflow Type</label>
                                            <select name="workflowType" value={this.state.workflowType} onChange={this.formChangeHandler} className="dropdown">
                                              {['','Direct to Employer','Direct to Program','Common App'].map(value =>
                                                <option key={value} value={value}>{value}</option>
                                              )}
                                            </select>
                                          </div>
                                          <div className="clear" />

                                        </div>
                                      )}
                                    </div>
                                  )}

                                  {(this.state.org === 'unite-la' && !window.location.pathname.includes('/employers')) && (
                                    <div>
                                      {this.renderImageAsTitle()}
                                    </div>
                                  )}
                                  {(window.location.pathname.includes('/employers')) && (
                                    <div>

                                      {(this.state.competencyTags && this.state.competencyTags.length > 0) && (
                                        <div className="row-10">
                                          <div className="row-10">
                                            <div className="float-left right-padding">
                                              <p className="description-text-4">COMPETENCY TAGS</p>
                                            </div>
                                            <div className="float-left right-padding top-margin-negative-4">
                                              <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showTags: true })}>
                                                  <div className="padding-5 cta-border circle-radius">
                                                    <img src={addIconBlue} alt="GC" className="image-auto-10" />
                                                  </div>
                                              </button>
                                            </div>
                                            <div className="clear" />
                                          </div>

                                          {this.renderTags('competency')}

                                          <div className="clear" />
                                        </div>
                                      )}
                                    </div>
                                  )}

                                  {(this.state.showFeatured) && (
                                    <div>
                                      <div className="edit-profile-row">
                                        <div>
                                          <div className="float-left">
                                            <label className="profile-label">Add the Providing Employer<label className="error-color bold-text">*</label></label>
                                          </div>
                                          <div className="float-left left-padding top-padding">
                                            <Link to={"/organizations/" + this.state.org + "/employers/add"} className="background-button" target="_blank" rel="noopener noreferrer">
                                              <div className="padding-5 cta-border circle-radius" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Add an employer to the dropdown list">
                                                <img src={addIconBlue} className="image-auto-15" alt="GC"/>
                                              </div>

                                            </Link>
                                          </div>
                                          <div className="clear" />
                                          <ReactTooltip id="tooltip-placeholder-id" />
                                        </div>

                                        <select name="selectEmployer" value={this.state.selectedEmployer.employerName} onChange={this.formChangeHandler} className="dropdown">
                                          {this.state.employerOptions.map(value =>
                                            <option key={value.employerName} value={value.employerName}>{value.employerName}</option>
                                          )}
                                        </select>
                                      </div>
                                    </div>
                                  )}

                                  <div className="container-left">
                                    <label className="profile-label">Job Location<label className="error-color bold-text">*</label></label>
                                    <select name="location" value={this.state.location} onChange={this.formChangeHandler} className="dropdown">
                                      {this.state.locationOptions.map(value =>
                                        <option key={value} value={value}>{value}</option>
                                      )}
                                    </select>
                                  </div>

                                  {(this.state.location === 'One Location') && (
                                    <div>
                                      <div className="container-right">
                                        <label className="profile-label">Position Zip Code<label className="error-color bold-text">*</label></label>
                                        <input type="number" className="number-field" max="99999" placeholder="90210" name="zipcode" value={this.state.zipcode} onChange={this.formChangeHandler}></input>
                                      </div>
                                    </div>
                                  )}

                                  {(this.state.location === 'One Location') ? (
                                    <div>
                                      <div className="clear" />
                                      <div className="container-left row-10">
                                        <label className="profile-label">Hours per Week{(!this.state.disableSomeRequirements) && <label className="error-color bold-text">*</label>}</label>
                                        <select name="hoursPerWeek" value={this.state.hoursPerWeek} onChange={this.formChangeHandler} className="dropdown">
                                          {this.state.hoursPerWeekOptions.map(value =>
                                            <option key={value.key} value={value}>{value}</option>
                                          )}
                                        </select>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                  ) : (
                                    <div className="container-right">
                                      <label className="profile-label">Hours per Week{(!this.state.disableSomeRequirements) && <label className="error-color bold-text">*</label>}</label>
                                      <select name="hoursPerWeek" value={this.state.hoursPerWeek} onChange={this.formChangeHandler} className="dropdown">
                                        {this.state.hoursPerWeekOptions.map(value =>
                                          <option key={value.key} value={value}>{value}</option>
                                        )}
                                      </select>
                                    </div>
                                  )}

                                  <div className="clear"/>

                                  <div className="edit-profile-row">
                                    <div className="container-left">
                                      <label className="profile-label">How many openings are there?</label>
                                      <input className="number-field" type="number" placeholder="0" name="hireCount" value={this.state.hireCount} onChange={this.formChangeHandler}/>
                                    </div>

                                    <div className="clear" />
                                  </div>

                                  {this.renderDateRange()}

                                  {(this.state.org) && (
                                    <div>

                                      <div className="edit-profile-row">
                                        <div className="container-left">
                                          <label className="profile-label">Pay Type{(!this.state.disableSomeRequirements) && <label className="error-color bold-text">*</label>}</label>
                                          <select name="payType" value={this.state.payType} onChange={this.formChangeHandler} className="dropdown">
                                              {this.state.payTypeOptions.map(value =>
                                                <option key={value.key} value={value}>{value}</option>
                                              )}
                                          </select>
                                        </div>
                                        <div className="container-right">
                                          <label className="profile-label">{(this.state.payType === 'Custom') ? "Pay" : "Pay Range"}{(!this.state.disableSomeRequirements) && <label className="error-color bold-text">*</label>}</label>
                                          {(this.state.payType === 'Custom') ? (
                                            <input type="text" className="text-field" placeholder="$16.04" name="payRange" value={this.state.payRange} onChange={this.formChangeHandler}></input>
                                          ) : (
                                            <select name="payRange" value={this.state.payRange} onChange={this.formChangeHandler} className="dropdown">
                                              {payRangeOptions.map(value =>
                                                <option key={value.key} value={value}>{value}</option>
                                              )}
                                            </select>
                                          )}
                                        </div>
                                        <div className="clear" />
                                      </div>

                                      {(this.state.subPostType === 'Internship') && (
                                        <div>
                                          <div className="row-10">
                                            <div className="container-left">
                                              <div className="float-left right-padding">
                                                <label className="profile-label">Prioritize Subsidized Candidates</label>
                                              </div>
                                              <div className="float-left">
                                                <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                                <div className="float-left left-margin noti-bubble-info-7-9">
                                                  <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showSubsidyInfo: true })}>
                                                    <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                                                  </a>
                                                </div>

                                              </div>
                                              <div className="clear" />
                                              <Switch
                                                onChange={(change) => this.setState({ prioritizeSubsidized: change })}
                                                checked={this.state.prioritizeSubsidized}
                                                id="normal-switch"
                                              />
                                            </div>

                                            <div className="container-right">
                                              <div className="float-left right-padding">
                                                <label className="profile-label">Prioritize School Credit Candidates</label>
                                              </div>
                                              <div className="float-left">
                                                <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                                <div className="float-left left-margin noti-bubble-info-7-9">
                                                  <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showSchoolCreditInfo: true })}>
                                                    <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                                                  </a>
                                                </div>

                                              </div>
                                              <div className="clear" />
                                              <Switch
                                                onChange={(change) => this.setState({ prioritizeSchoolCredit: change })}
                                                checked={this.state.prioritizeSchoolCredit}
                                                id="normal-switch"
                                              />
                                            </div>

                                            <div className="clear" />
                                          </div>
                                        </div>
                                      )}

                                      {(this.state.subPostType === 'Part-Time' || this.state.subPostType === 'Full-Time') && (
                                        <div>
                                          <div>
                                            <div className="row-10">
                                              <div className="calc-column-offset-60 right-padding">
                                                <label className="profile-label">Add any supplemental pay (Optional):</label>
                                                <select name="supplementalPay" value={this.state.supplementalPay} onChange={this.formChangeHandler} className="dropdown">
                                                  {this.state.supplementalPayOptions.map(value =>
                                                    <option key={value.key} value={value}>{value}</option>
                                                  )}
                                                </select>
                                              </div>
                                              <div className="fixed-column-60 top-padding-40">
                                                <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                                <button className="btn btn-squarish" onClick={() => this.addTag('supplementalPay')}>Add</button>
                                              </div>
                                              <div className="clear" />
                                            </div>

                                            {this.renderTags('supplementalPay')}
                                            <div className="clear" />
                                          </div>

                                          <div>
                                            <div className="row-10">
                                              <div className="calc-column-offset-60 right-padding">
                                                <label className="profile-label">Add any benefits (Optional):</label>
                                                <select name="benefit" value={this.state.benefit} onChange={this.formChangeHandler} className="dropdown">
                                                  {this.state.benefitOptions.map(value =>
                                                    <option key={value.key} value={value}>{value}</option>
                                                  )}
                                                </select>
                                              </div>
                                              <div className="fixed-column-60 top-padding-40">
                                                <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                                <button className="btn btn-squarish" onClick={() => this.addTag('benefit')}>Add</button>
                                              </div>
                                              <div className="clear" />
                                            </div>

                                            {this.renderTags('benefit')}
                                            <div className="clear" />
                                          </div>

                                          {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2">{this.state.errorMessage}</p>}
                                          {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2">{this.state.successMessage}</p>}
                                        </div>
                                      )}

                                    </div>
                                  )}

                                  {this.renderDescription()}

                                  <div>
                                    {(!this.state.preSetStartDate) && (
                                      <div className="container-left">
                                        <div className="float-left">
                                          <label className="profile-label">Application Deadline</label>
                                        </div>
                                        <div>
                                          <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                          <div className="float-left left-margin noti-bubble-info-7-9">
                                            <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, applicationDeadlineInfo: true  })}>
                                              <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                                            </a>
                                          </div>

                                        </div>
                                        <div className="clear" />

                                        {(this.state.useNewSubmissionDeadline) ? (
                                          <input type="datetime-local" className="date-picker" min={convertDateToString(new Date(),"rawDateForInput")} placeholder="Application deadline..." name="submissionDeadline" value={this.state.submissionDeadline} onChange={this.formChangeHandler}></input>
                                        ) : (
                                          <input type="date" className="date-picker" min={convertDateToString(new Date(),"rawDateForInput")} placeholder="Application deadline..." name="submissionDeadline" value={this.state.submissionDeadline} onChange={this.formChangeHandler}></input>
                                        )}

                                      </div>
                                    )}

                                    <div className="container-right">
                                      <label className="profile-label">Estimated Years of Experience Required</label>
                                      <select name={"yearsOfExperience"} value={this.state.yearsOfExperience} onChange={this.formChangeHandler} className="dropdown">
                                        {this.state.yearsOfExperienceOptions.map(value =>
                                          <option key={value} value={value}>{value}</option>
                                        )}
                                      </select>
                                    </div>

                                    {(this.state.org === 'guidedcompass') && (
                                      <div>
                                        <div>
                                          <div>
                                            <div className="clear" />
                                          </div>
                                          <div className="row-10">
                                            <div className="container-left">
                                              <label className="profile-label">U.S. documented workers only?<label className="error-color bold-text">*</label></label>
                                              <select name="hostUndocumented" value={this.state.hostUndocumented} onChange={this.formChangeHandler} className="dropdown">
                                                {this.state.binaryOptions.map(value =>
                                                  <option key={value} value={value}>{value}</option>
                                                )}
                                              </select>
                                            </div>
                                            <div className="clear" />
                                          </div>

                                        </div>
                                      </div>
                                    )}

                                    <div className="clear" />
                                  </div>

                                  {(!this.state.showFeatured) && (
                                    <div>
                                      {this.renderTaggedCandidatesOfInterest()}
                                    </div>
                                  )}

                                  {(this.state.showApplicationMethodDetails) && (
                                    <div>
                                      {this.renderApplicationMethodDetails()}
                                    </div>
                                  )}

                                  <hr className="vertical-margin-20" />

                                  {(this.state.showFeatured || this.state.org === 'guidedcompass') ? (
                                    <div>
                                      {this.renderApplicationComponents()}

                                      <div>
                                        {(window.location.pathname.includes('/organizations')) ? (
                                          <div>

                                            <div className="row-20">
                                              <p className="heading-text-4">Tag, Categorize, & Configure</p>
                                            </div>

                                            {this.renderVisibilityOptions()}

                                            <div>
                                              <div className="row-15">
                                                <div className="container-left">
                                                  <label className="profile-label clear-padding">Would you like to feature this posting?</label>
                                                  <label className="profile-descriptor top-margin-5">Featuring a posting highlights the posting to students. It will be among the first postings the student sees.</label>
                                                  <div className="clear" />
                                                  <div className="spacer" /><div className="half-spacer"/>
                                                  <Switch
                                                    onChange={(change) => this.setState({ featured: change, formHasChanged: true })}
                                                    checked={this.state.featured}
                                                    id="normal-switch"
                                                  />
                                                </div>
                                                {(this.state.org === 'unite-la') && (
                                                  <div className="container-right">
                                                    <label className="profile-label clear-padding">Is this role subsidized?</label>
                                                    <label className="profile-descriptor top-margin-5">Will your organization, the government, or some other organization help pay for those hired?</label>
                                                    <div className="clear" />
                                                    <div className="spacer" /><div className="half-spacer"/>
                                                    <Switch
                                                      onChange={(change) => this.setState({ isSubsidized: change, formHasChanged: true })}
                                                      checked={this.state.isSubsidized}
                                                      id="normal-switch"
                                                    />
                                                  </div>
                                                )}
                                                <div className="clear" />

                                              </div>
                                              <div className="row-15">
                                                <div className="container-left">
                                                  <label className="profile-label clear-padding">Allow Referrals?</label>
                                                  <label className="profile-descriptor top-margin-5">Do you want to allow people to refer others and add that referral code when they apply?</label>
                                                  <div className="clear" />
                                                  <div className="spacer" /><div className="half-spacer"/>
                                                  <Switch
                                                    onChange={(change) => this.setState({ allowReferrals: change, formHasChanged: true })}
                                                    checked={this.state.allowReferrals}
                                                    id="normal-switch"
                                                  />
                                                </div>
                                                <div className="clear" />
                                              </div>

                                              <div className="row-15">
                                                <div className="container-left">
                                                  <label className="profile-label clear-padding">Hide Ideal Candidate Profile</label>
                                                  <label className="profile-descriptor top-margin-5">Hide career-seekers' ability to see benchmark details</label>
                                                  <div className="clear" />
                                                  <div className="spacer" /><div className="half-spacer"/>
                                                  <Switch
                                                    onChange={(change) => this.setState({ hideIdealCandidateProfile: change, formHasChanged: true })}
                                                    checked={this.state.hideIdealCandidateProfile}
                                                    id="normal-switch"
                                                  />
                                                </div>
                                                <div className="container-right">
                                                  <label className="profile-label clear-padding">Hide Match Score</label>
                                                  <label className="profile-descriptor top-margin-5">Hide career-seekers' ability to see their match score.</label>
                                                  <div className="clear" />
                                                  <div className="spacer" /><div className="half-spacer"/>
                                                  <Switch
                                                    onChange={(change) => this.setState({ hideViewMatch: change, formHasChanged: true })}
                                                    checked={this.state.hideViewMatch}
                                                    id="normal-switch"
                                                  />
                                                </div>
                                                <div className="clear" />
                                              </div>
                                            </div>

                                            {this.renderPathways()}
                                            {this.renderDepartments()}
                                            {this.renderTargetRoles()}
                                            {this.renderPrograms()}
                                            {this.renderTaggedCandidatesOfInterest()}
                                            {this.renderTaggedPostings()}
                                          </div>
                                        ) : (
                                          <div>
                                            {this.renderTaggedCandidatesOfInterest()}

                                            <hr />
                                            <div className="spacer" /><div className="spacer" />
                                          </div>
                                        )}

                                      </div>

                                    </div>
                                  ) : (
                                    <div>
                                      {this.renderVisibilityOptions()}
                                    </div>
                                  )}

                                  {((this.state.showCustomConfirmationMessage) && (window.location.pathname.includes('/organizations') || (window.location.pathname.includes('/employers') && this.state.org === 'guidedcompass'))) && (
                                    <div>
                                      {this.renderConfirmationMessage()}
                                    </div>
                                  )}

                                  {(window.location.pathname.includes('/employers') && this.state.org === 'guidedcompass') && (
                                    <div>
                                      {this.renderSharePartners('work')}
                                    </div>
                                  )}

                                  {(window.location.pathname.includes('/organizations/')) && (
                                    <div>
                                      {this.renderOrgContactInformation()}

                                      {(this.state.org === 'dpscd') && (
                                        <div className="edit-profile-row">
                                          <div className="name-container">
                                            <label className="profile-label">Referrer Name<label className="error-color bold-text">*</label></label>
                                            <select name="referrerName" value={this.state.referrerName} onChange={this.formChangeHandler} className="dropdown">
                                              {this.state.referrerOptions.map(value =>
                                                <option key={value} value={(value.contactFirstName) && value.contactFirstName + ' ' + value.contactLastName}>{(value.contactFirstName) && value.contactFirstName + ' '+ value.contactLastName}</option>
                                              )}
                                            </select>
                                          </div>
                                          {(this.state.referrerName === 'Other' || this.state.referrerName === 'Other ') && (
                                            <div className="name-container">
                                              <label className="profile-label">Other, Please Explain:<label className="error-color bold-text">*</label></label>
                                              <input type="text" className="text-field" placeholder="Add name..." name="referralExplanation" value={this.state.referralExplanation} onChange={this.formChangeHandler}></input>
                                            </div>
                                          )}

                                          <div className="clear" />
                                        </div>
                                      )}

                                      {(!this.state.showApplicationMethodDetails) && (
                                        <div>
                                          <div className="edit-profile-row">
                                            <div className="name-container">
                                              <label className="profile-label">Employer Contact First Name:<label className="error-color bold-text">*</label></label>
                                              <input type="text" className="text-field" placeholder="First name..." name="employerContactFirstName" value={this.state.employerContactFirstName} onChange={this.formChangeHandler}></input>
                                            </div>
                                            <div className="name-container">
                                              <label className="profile-label">Employer Contact Last Name:<label className="error-color bold-text">*</label></label>
                                              <input type="text" className="text-field" placeholder="Last name..." name="employerContactLastName" value={this.state.employerContactLastName} onChange={this.formChangeHandler}></input>
                                            </div>
                                            <div className="clear" />
                                          </div>

                                          <div className="edit-profile-row clear-padding">
                                            <div className="name-container">
                                              <label className="profile-label">Employer Contact Email:<label className="error-color bold-text">*</label></label>
                                              <input type="text" className="text-field" placeholder="Email address..." name="employerContactEmail" value={this.state.employerContactEmail} onChange={this.formChangeHandler}></input>
                                            </div>
                                            <div className="clear" />
                                          </div>

                                        </div>
                                      )}

                                      <hr className="vertical-margin-20" />

                                    </div>
                                  )}

                                  { (this.state.clientErrorMessage!== '') && <p className="error-message">{this.state.clientErrorMessage}</p> }
                                  { (this.state.serverPostSuccess) ? (
                                    <p className="success-message">{this.state.serverSuccessMessage}</p>
                                  ) : (
                                    <p className="error-message">{this.state.serverErrorMessage}</p>
                                  )}

                                  {(this.state.selectedOpportunity && this.state.selectedOpportunity._id) ? (
                                    <div>
                                      {(this.state.confirmDelete === true) ? (
                                        <div>
                                          {this.renderConfirmDelete()}
                                        </div>
                                      ) : (
                                        <div>
                                          {(this.state.isFilled) && (
                                            <div>
                                              <p className="error-color half-bold-text bottom-margin">This posting has been placed</p>
                                            </div>
                                          )}

                                          <div className="float-left right-padding-20">
                                            <button className="btn btn-primary"  disabled={this.state.isSaving} onClick={() => this.editJob()}>Save Edits</button>
                                          </div>
                                          {(window.location.pathname.includes('/organizations') || window.location.pathname.includes('/advisor')) && (
                                            <div className="float-left right-padding-20">
                                              {(!this.state.isFilled) && (
                                                <div>
                                                  {console.log('---- yes -!', this.state.location)}
                                                  <button className="btn btn-secondary" disabled={this.state.isSaving} onClick={() => this.setState({ modalIsOpen: true, confirmFill: true })}>Declare Filled</button>
                                                </div>
                                              )}

                                            </div>
                                          )}
                                          <div className="float-left right-padding-20">
                                            <button className="btn btn-quaternary"  disabled={this.state.isSaving} onClick={() => this.setState({ confirmDelete: true })}>Delete Post</button>
                                          </div>
                                          <div className="clear" />
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <div>
                                      <button className="btn btn-primary"  disabled={this.state.isSaving} onClick={() => this.editJob()}>Post {this.state.postType}</button>
                                    </div>
                                  )}

                                </div>
                              )}

                            </div>
                          )}

                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {(window.innerWidth > 768 && this.state._id && !window.location.pathname.includes('/walkthrough')) && (
                  <div className="relative-column-30 horizontal-padding top-margin-40">
                    <div className="left-padding-20">
                      <div className="card-clear-padding min-width-100 max-width-310 bottom-margin-20">
                        <div className="full-width height-5 senary-background" />
                        <div className="padding-20">
                          <p className="heading-text-5">Actions</p>

                          <div className="spacer" /><div className="spacer" />

                          <div className="row-10">
                            <button onClick={() => this.setState({ modalIsOpen: true, showCandidateInvites: true })} className="background-button full-width left-text cta-color">
                              <div className="fixed-column-30">
                                <img src={addPeopleIconDark} alt="GC" className="image-auto-22" />
                              </div>
                              <div className="calc-column-offset-50 left-padding-5">
                                <p>Invite{(this.state.postType === 'Event') ? " to RSVP" : (this.state.postType === 'Project' || this.state.postType === 'Assignment' || this.state.postType === 'Problem' || this.state.postType === 'Challenge') ? " to Submit" : " to Apply"}</p>
                              </div>
                              <div className="fixed-column-20">
                                <span className="float-right top-margin-5">
                                  <Link to={'/opportunities/organizations/'} className="background-link">
                                    <img src={arrowIndicatorIcon} alt="GC" className="image-auto-12"/>
                                  </Link>
                                </span>
                              </div>
                            </button>

                            <div className="clear"/>

                            <div className="spacer" /><div className="spacer" />
                            <hr />
                            <div className="clear"/>
                            <div className="spacer" />
                          </div>
                          {/*
                          <div className="row-10">
                            <button onClick={() => this.setState({ modalIsOpen: true, showChangeStage: true })} className="background-button full-width left-text cta-color">
                              <div className="fixed-column-35">
                                <img src={profileIconDark} alt="GC" className="image-auto-25" />
                              </div>
                              <div className="calc-column-offset-70 left-padding-5">
                                <p>Change Stage</p>
                              </div>
                              <div className="float-left left-padding">
                                <span className="float-right top-margin-5">
                                  <Link to={'/opportunities/organizations/'} className="background-link">
                                    <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-15"/>
                                  </Link>
                                </span>
                              </div>
                            </button>

                            <div className="clear"/>

                            <div className="spacer" /><div className="spacer" />
                            <hr />
                            <div className="clear"/>
                            <div className="spacer" />
                          </div>*/}

                          {(!this.state.readOnly) && (
                            <div>
                              {((this.state.postType === 'Work' || this.state.postType === 'Internship' || this.state.postType === 'Program') && !this.state.isFilled) && (
                                <div className="row-10">
                                  <button onClick={() => this.setState({ modalIsOpen: true, confirmFill: true })} className="background-button full-width left-text cta-color">
                                    <div className="fixed-column-30">
                                      <img src={careerMatchesIconDark} alt="GC" className="image-auto-22" />
                                    </div>
                                    <div className="calc-column-offset-50 left-padding-5">
                                      <p>Declare Filled</p>
                                    </div>
                                    <div className="fixed-column-20">
                                      <span className="float-right top-margin-5">
                                        <Link to={'/opportunities/organizations/'} className="background-link">
                                          <img src={arrowIndicatorIcon} alt="GC" className="image-auto-12"/>
                                        </Link>
                                      </span>
                                    </div>
                                  </button>

                                  <div className="clear"/>

                                  <div className="spacer" /><div className="spacer" />
                                  <hr />
                                  <div className="clear"/>
                                  <div className="spacer" />
                                </div>
                              )}

                              {/*
                              {(!window.location.pathname.includes('/walkthrough') && (this.state.postType === 'Work' || this.state.postType === 'Internship' || this.state.postType === 'Individual')) && (
                                <div>
                                  <div className="row-10">
                                    <button onClick={() => console.log('')} className="background-button full-width left-text cta-color">
                                      <div className="fixed-column-35">
                                        <img src={referIconDark} alt="GC" className="image-auto-25" />
                                      </div>
                                      <div className="calc-column-offset-70 left-padding-5">
                                        <p>Refer to Employer</p>
                                      </div>
                                      <div className="float-left left-padding">
                                        <span className="float-right top-margin-5">
                                          <Link to={'/opportunities/organizations/'} className="background-link">
                                            <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-15"/>
                                          </Link>
                                        </span>
                                      </div>
                                    </button>

                                    <div className="clear"/>

                                    <div className="spacer" /><div className="spacer" />
                                    <hr />
                                    <div className="clear"/>
                                    <div className="spacer" />
                                  </div>
                                </div>
                              )}*/}

                              <div className="row-10">
                                <button onClick={() => this.setState({ modalIsOpen: true, showConfirmDelete: true })} className="background-button full-width left-text cta-color">
                                  <div className="fixed-column-30">
                                    <img src={trashIconDark} alt="GC" className="image-auto-22" />
                                  </div>
                                  <div className="calc-column-offset-50 left-padding-5">
                                    <p>Delete Posting</p>
                                  </div>
                                  <div className="fixed-column-20">
                                    <span className="float-right top-margin-5">
                                      <Link to={'/opportunities/organizations/'} className="background-link">
                                        <img src={arrowIndicatorIcon} alt="GC" className="image-auto-12"/>
                                      </Link>
                                    </span>
                                  </div>
                                </button>

                                <div className="clear"/>
                              </div>
                            </div>
                          )}
                          {/*
                          {(showApplyTwo) && (
                            <div className="bottom-padding">
                              <div className="row-10">
                                {(applyButton2) ? (
                                  <button onClick={() => this.setState(applyButton2)} className="background-button full-width left-text cta-color">
                                    <div className="fixed-column-35">
                                      <img src={sendIcon} alt="GC" className="image-auto-25" />
                                    </div>
                                    <div className="calc-column-offset-70 left-padding-5">
                                      <p>{applyText2}</p>
                                    </div>
                                    <div className="float-left left-padding">
                                      <span className="float-right top-margin-5">
                                        <Link to={'/opportunities/organizations/'} className="background-link">
                                          <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-15"/>
                                        </Link>
                                      </span>
                                    </div>
                                  </button>
                                ) : (
                                  <div>
                                    <a href={applyLink2} className="background-link" target="_blank" rel="noopener noreferrer">
                                      <div className="fixed-column-35">
                                        <img src={websiteIconDark} alt="GC" className="image-auto-25" />
                                      </div>
                                      <div className="calc-column-offset-70 left-padding-5">
                                        <p>{applyText2}</p>
                                      </div>
                                    </a>
                                    <div className="float-left left-padding">
                                      <span className="float-right top-margin-5">
                                        <a href={applyLink2} to={'/opportunities/organizations/'} className="background-link" target="_blank" rel="noopener noreferrer">
                                          <img src={linkIcon} alt="Compass arrow indicator icon" className="image-auto-15"/>
                                        </a>
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>

                              <div className="clear"/>

                              <div className="spacer" /><div className="spacer" />
                              <hr />
                              <div className="clear"/>
                              <div className="spacer" />
                            </div>
                          )}

                          {(this.props.pageSource !== 'landingPage' && this.state.selectedOpportunity && this.state.selectedOpportunity.benchmarkId && window.location.pathname.includes('/app/opportunities')) && (
                            <div className="bottom-padding">
                              <button className="background-button clear-margin full-width left-text cta-color" onClick={() => this.setState({ modalIsOpen: true, showMatch: true })}>
                                <div className="fixed-column-35">
                                  <img src={benchmarksIconDark} alt="GC" className="image-auto-25 standard-border" />
                                </div>
                                <div className="calc-column-offset-70 left-padding-5">
                                  <p>View Match</p>
                                </div>
                                <div className="float-left left-padding">
                                  <span className="float-right top-margin-5">
                                    <img src={modalIconDark} alt="Compass arrow indicator icon" className="image-auto-15"/>
                                  </span>
                                </div>
                              </button>

                              <div className="clear"/>

                              <div className="spacer" /><div className="spacer" />
                              <hr />
                              <div className="clear"/>
                              <div className="spacer" />
                            </div>
                          )}

                          {(this.state.showAbilityToRefer && !this.state.remoteAuth && this.state.selectedOpportunity && (this.state.selectedOpportunity.postType === 'Work' || this.state.selectedOpportunity.postType === 'Internship')) && (
                            <div className="bottom-padding">
                              <button onClick={() => this.setState({ modalIsOpen: true, showReferSomeone: true })} className="background-button full-width left-text cta-color">
                                <div className="fixed-column-35">
                                  <img src={referIconDark} alt="GC" className="image-auto-25" />
                                </div>
                                <div className="calc-column-offset-70 left-padding-5">
                                  <p>Refer Someone</p>
                                </div>
                                <div className="float-left left-padding">
                                  <span className="float-right top-margin-5">
                                    <img src={modalIconDark} alt="Compass arrow indicator icon" className="image-auto-15"/>
                                  </span>
                                </div>
                              </button>
                              <div className="clear"/>
                            </div>
                          )}*/}

                          <div className="spacer" />
                        </div>
                      </div>

                      {(this.state.postType === 'Work' || this.state.postType === 'Internship' || this.state.postType === 'Individual' || this.state.postType === 'Program') && (
                        <div className="card-clear-padding min-width-100 max-width-310 bottom-margin-20">
                          <div className="full-width height-5 denary-background" />
                          <div className="padding-20">
                            <div>
                              <div className="calc-column-offset-80">
                                <p className="heading-text-5">{(this.state.applications && this.state.applications.length > 0) ? this.state.applications.length : "0"} Applications</p>
                              </div>
                              {(this.state.applications && this.state.applications.length > 0) && (
                                <div className="fixed-column-80">
                                  <Link className="background-button full-width right-text" to={pathPrefix + '/postings/' + this.state._id + '/applications'} state={{ selectedJob: this.state.selectedOpportunity, applications: this.state.applications, benchmark: this.state.benchmark, benchmarkOptions: this.state.benchmarkOptions }}>
                                    <p className="description-text-2">See All <label className="description-text-2 left-padding-5">></label></p>
                                  </Link>
                                </div>
                              )}
                              <div className="clear" />
                            </div>

                            <div className="spacer" /><div className="half-spacer" />

                            {(this.state.applications && this.state.applications.length > 0) ? (
                              <div>
                                {this.state.applications.map((item, index) =>
                                  <div key={item}>
                                    {(index < 3 && item.email !== this.state.emailId) && (
                                      <div className="bottom-padding">
                                        <div className="spacer" />
                                        <Link to={(this.state.org === 'guidedcompass' && window.location.pathname.includes('/employers')) ? '/employers/' + this.state.accountCode + '/postings/' + this.state._id + '/applications/' + this.state.applications[index]._id : '/organizations/' + this.state.org + '/postings/' + this.state._id + '/applications/' + this.state.applications[index]._id} state={{ selectedJob: this.state.selectedOpportunity, selectedApplication: this.state.applications[index], benchmark: this.state.benchmark, benchmarkOptions: this.state.benchmarkOptions }} className="background-link">
                                          <div className="fixed-column-50">
                                            <img src={(item.pictureURL) ? item.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-1" />
                                          </div>
                                          <div className="calc-column-offset-90 left-padding-5">
                                            <p>{item.firstName} {item.lastName}</p>
                                            <p className="description-text-2 standard-color top-padding-5">{(item.headline) ? item.headline : (item.schoolName) ? item.schoolName : 'Student'}{(item.gradYear) && " " + item.gradYear}{(item.degree) && " - " + item.degree}{(item.major) && " - " + item.major}</p>
                                            {(item.match) && (
                                              <p className="cta-color description-text-2 top-padding-5">{item.match}% Match</p>
                                            )}
                                          </div>
                                        </Link>
                                        <div className="float-left left-padding">
                                          <span className="float-right">
                                            <div className="spacer"/>
                                            <Link to={(this.state.org === 'guidedcompass' && window.location.pathname.includes('/employers')) ? '/employers/' + this.state.accountCode + '/postings/' + this.state._id + '/applications/' + this.state.applications[index]._id : '/organizations/' + this.state.org + '/postings/' + this.state._id + '/applications/' + this.state.applications[index]._id} state={{ selectedJob: this.state.selectedOpportunity, selectedApplication: this.state.applications[index], benchmark: this.state.benchmark, benchmarkOptions: this.state.benchmarkOptions }} className="background-link">
                                              <img src={arrowIndicatorIcon} alt="GC" className="image-auto-22"/>
                                            </Link>
                                          </span>
                                        </div>
                                        <div className="clear"/>

                                        <div className="spacer" /><div className="spacer" />
                                        <hr />
                                        <div className="clear"/>
                                        <div className="spacer" />
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className="row-10">
                                <p className="error-color description-text-1">There are no applicants yet</p>
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      {(this.state.postType === 'Event') && (
                        <div className="card-clear-padding min-width-100 max-width-310 bottom-margin-20">
                          <div className="full-width height-5 tertiary-background" />
                          <div className="padding-20">
                            <div>
                              <div className="calc-column-offset-80">
                                <p className="heading-text-5">{(this.state.rsvps && this.state.rsvps.length > 0) && this.state.rsvps.length + " "}RSVPs</p>
                              </div>
                              {(this.state.rsvps && this.state.rsvps.length > 0) && (
                                <div className="fixed-column-80">
                                  <button className="background-button full-width right-text cta-color" onClick={() => this.setState({ modalIsOpen: true, showRSVPs: true })}>
                                    <p className="description-text-2">See All <label className="description-text-2 left-padding-5">></label></p>
                                  </button>
                                </div>
                              )}
                              <div className="clear" />
                            </div>

                            <div className="spacer" /><div className="half-spacer" />

                            {(this.state.rsvps && this.state.rsvps.length > 0) ? (
                              <div>
                                {this.state.rsvps.map((item, index) =>
                                  <div key={item}>

                                    {(index < 3 && item.email !== this.state.emailId) && (
                                      <div className="bottom-padding">
                                        <div className="spacer" />
                                        <Link to={pathPrefix + '/members/' + item.username} className={(item.username) ? "background-link" : "background-link no-pointers"}>
                                          <div className="fixed-column-50">
                                            <img src={(item.pictureURL) ? item.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-1" />
                                          </div>
                                          <div className="calc-column-offset-90 left-padding-5">
                                            <p className={(item.username) ? "" : "standard-color"}>{item.firstName} {item.lastName}</p>
                                            <p className="description-text-2 standard-color top-padding-5">{(item.headline) ? item.headline : (item.schoolName) ? item.schoolName : 'Student'}{(item.gradYear) && " " + item.gradYear}{(item.major) && " - " + item.major}</p>
                                            {(item.match) && (
                                              <p className="cta-color description-text-2 top-padding-5">{item.match}% Match</p>
                                            )}
                                          </div>
                                        </Link>
                                        {(item.username) && (
                                          <div className="float-left left-padding">
                                            <span className="float-right">
                                              <div className="spacer"/>
                                              <Link to={pathPrefix + '/members/' + item.username} className="background-link">
                                                <img src={arrowIndicatorIcon} alt="GC" className="image-auto-22"/>
                                              </Link>
                                            </span>
                                          </div>
                                        )}

                                        <div className="clear"/>

                                        <div className="spacer" /><div className="spacer" />
                                        <hr />
                                        <div className="clear"/>
                                        <div className="spacer" />
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className="row-10">
                                <p className="error-color description-text-1">There are no rsvps yet</p>
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      {(this.state.attendees && this.state.attendees.length > 0) && (
                        <div className="card-clear-padding min-width-100 max-width-310 bottom-margin-20">
                          <div className="full-width height-5 quaternary-background" />
                          <div className="padding-20">
                            <div>
                              <div className="calc-column-offset-80">
                                <p className="heading-text-5">{(this.state.attendees && this.state.attendees.length > 0) && this.state.attendees.length + " "}Attendees</p>
                              </div>
                              {(this.state.attendees.length > 0) && (
                                <div className="fixed-column-80">
                                  <button className="background-button full-width right-text cta-color" onClick={() => this.setState({ modalIsOpen: true, showRSVPs: true })}>
                                    <p className="description-text-2">See All <label className="description-text-2 left-padding-5">></label></p>
                                  </button>
                                </div>
                              )}
                              <div className="clear" />
                            </div>

                            <div className="spacer" /><div className="half-spacer" />

                            {this.state.attendees.map((item, index) =>
                              <div key={item}>
                                {(index < 3 && item.email !== this.state.emailId) && (
                                  <div className="bottom-padding">
                                    <div className="spacer" />
                                    <Link to={pathPrefix + '/members/' + item.username} className={(item.username) ? "background-link" : "background-link no-pointers"}>
                                      <div className="fixed-column-50">
                                        <img src={(item.pictureURL) ? item.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-1" />
                                      </div>
                                      <div className="calc-column-offset-90 left-padding-5">
                                        <p className={(item.username) ? "" : "standard-color"}>{item.firstName} {item.lastName}</p>
                                        <p className="description-text-2 standard-color top-padding-5">{(item.headline) ? item.headline : (item.schoolName) ? item.schoolName : 'Student'}{(item.gradYear) && " " + item.gradYear}{(item.major) && " - " + item.major}</p>
                                        {(item.match) && (
                                          <p className="cta-color description-text-2 top-padding-5">{item.match}% Match</p>
                                        )}
                                      </div>
                                    </Link>
                                    {(item.username) && (
                                      <div className="float-left left-padding">
                                        <span className="float-right">
                                          <div className="spacer"/>
                                          <Link to={pathPrefix + '/members/' + item.username} className="background-link">
                                            <img src={arrowIndicatorIcon} alt="GC" className="image-auto-22"/>
                                          </Link>
                                        </span>
                                      </div>
                                    )}
                                    <div className="clear"/>

                                    <div className="spacer" /><div className="spacer" />
                                    <hr />
                                    <div className="clear"/>
                                    <div className="spacer" />
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      {(this.state.prospects && this.state.prospects.length > 0) && (
                        <div className="card-clear-padding min-width-100 max-width-310 bottom-margin-20">
                          <div className="full-width height-5 quinary-background" />
                          <div className="padding-20">
                            <div>
                              <div className="calc-column-offset-80">
                                <p className="heading-text-5">{(this.state.prospects && this.state.prospects.length > 0) ? this.state.prospects.length : "0"} Prospects</p>
                              </div>
                              {(this.state.prospects.length > 0) && (
                                <div className="fixed-column-80">
                                  <button className="background-button full-width right-text cta-color" onClick={() => this.setState({ modalIsOpen: true, showProspects: true })}>
                                    <p className="description-text-2">See All <label className="description-text-2 left-padding-5">></label></p>
                                  </button>
                                </div>
                              )}
                              <div className="clear" />
                            </div>

                            <div className="spacer" /><div className="half-spacer" />

                            {this.state.prospects.map((item, index) =>
                              <div key={item}>
                                {(index < 3 && item.email !== this.state.emailId) && (
                                  <div className="bottom-padding">
                                    <div className="spacer" />
                                    <Link to={pathPrefix + '/members/' + item.username} className="background-link">
                                      <div className="fixed-column-50">
                                        <img src={(item.pictureURL) ? item.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-1" />
                                      </div>
                                      <div className="calc-column-offset-90 left-padding-5">
                                        <p>{item.firstName} {item.lastName}</p>
                                        <p className="description-text-2 standard-color top-padding-5">{(item.headline) ? item.headline : (item.schoolName) ? item.schoolName : 'Student'}{(item.gradYear) && " " + item.gradYear}{(item.major) && " - " + item.major}</p>
                                        {(item.match) && (
                                          <p className="cta-color description-text-2 top-padding-5">{item.match}% Match</p>
                                        )}
                                      </div>
                                    </Link>
                                    <div className="float-left left-padding">
                                      <span className="float-right">
                                        <div className="spacer"/>
                                        <Link to={pathPrefix + '/members/' + item.username} className="background-link">
                                          <img src={arrowIndicatorIcon} alt="GC" className="image-auto-22"/>
                                        </Link>
                                      </span>
                                    </div>
                                    <div className="clear"/>

                                    <div className="spacer" /><div className="spacer" />
                                    <hr />
                                    <div className="clear"/>
                                    <div className="spacer" />
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div className="clear" />

                {(this.state.showEditCourse) ? (
                  <div>
                    <EditCourse closeModal={this.closeModal} modalIsOpen={this.state.modalIsOpen} selectedCourse={null} orgCode={this.state.org} orgName={this.state.orgName} gradeLevelOptions={this.state.gradeLevelOptions} knowledgeLevelOptions={this.state.knowledgeLevelOptions} industryOptions={this.state.industryOptions} functionOptions={this.state.functionOptions} addNewCourse={this.addNewCourse}/>
                  </div>
                ) : (
                  <div>
                    {(this.state.showProjectDetail) ? (
                      <div>
                        {console.log('showProjectDetail 2: ', this.state.showProjectDetail)}
                        <ProjectDetails closeModal={this.closeModal} modalIsOpen={this.state.modalIsOpen} selectedProject={this.state.selectedOpportunity.submissions[this.state.selectedIndex]} orgCode={this.state.activeOrg} />
                        {console.log('showProjectDetail 3: ', this.state.showProjectDetail)}
                      </div>
                    ) : (
                      <Modal
                       isOpen={this.state.modalIsOpen}
                       onAfterOpen={this.afterOpenModal}
                       onRequestClose={this.closeModal}
                       className="modal"
                       overlayClassName="modal-overlay"
                       contentLabel="Example Modal"
                      >

                        {(this.state.benchmarkInfo) && (
                          <div className="padding-40">
                            <p className="heading-text-2">Benchmark Info</p>
                            <div className="spacer" /><div className="spacer" />
                            <p><label className="half-bold-text cta-color">Benchmarks</label> represent ideal candidates: interests, skills, personality, education, experience, and more. They may be edited in the benchmarks tab and attached to postings.</p>
                            <div className="spacer" /><div className="spacer" />
                            <p>Benchmarks help candidates prepare and help us to recommend candidates.</p>
                          </div>
                        )}

                        {(this.state.applicationDeadlineInfo) && (
                          <div className="padding-40">
                            <p className="heading-text-2">Application Deadline Info</p>
                            <div className="spacer" /><div className="spacer" />
                            <p>The <label className="half-bold-text cta-color">application deadline</label> is the date that candidates should apply or be presented by for first round consideration.</p>
                          </div>
                        )}

                        {(this.state.politicalPartyInfo) && (
                          <div className="padding-40">
                            <p className="heading-text-2">Political Party Info</p>
                            <div className="spacer" /><div className="spacer" />
                            <p>The <label className="half-bold-text cta-color">political party</label> that this post is affiliated with helps direct candidates that are potentially a better match.</p>
                          </div>
                        )}

                        {(this.state.showOrgInfo) && (
                          <div className="padding-40">
                            <p className="heading-text-2">Organization Info</p>
                            <div className="spacer" /><div className="spacer" />
                            <p><label className="half-bold-text cta-color">Organizations</label> are registered partners with Guided Compass with dedicated portals set up to tackle virtual projects. They may be schools, nonprofits, or groups that help students / career seekers solve the project opportunity you've posted. They often have staff and mentors. Specifying an organization means your posting will only be available for that organization.</p>
                            <div className="spacer" /><div className="spacer" />
                            <p><label className="half-bold-text">You must select an organization from the suggestion box that appears when you type.</label> Otherwise, the posting will be treated as a general posting rather than for a specific organizations's portal.</p>
                          </div>
                        )}

                        {(this.state.submissionStartDateInfo) && (
                          <div className="padding-40">
                            <p className="heading-text-2">Submission Start Date Info</p>
                            <div className="spacer" /><div className="spacer" />
                            <p><label className="half-bold-text cta-color">Submission Start Date</label> marks the date that individuals or teams may begin to submit project solutions to your challenge.</p>
                            <div className="spacer" /><div className="spacer" />
                          </div>
                        )}

                        {(this.state.submissionDeadlineInfo) && (
                          <div className="padding-40">
                            <p className="heading-text-2">Submission Deadline Info</p>
                            <div className="spacer" /><div className="spacer" />
                            <p><label className="half-bold-text cta-color">Submission Deadline</label> marks the date that individuals or teams must submit project solutions by to be considered as candidates to win the challenge.</p>
                            <div className="spacer" /><div className="spacer" />
                          </div>
                        )}

                        {(this.state.votingRoundInfo) && (
                          <div className="padding-40">
                            <p className="heading-text-2">Voting Round Info</p>
                            <div className="spacer" /><div className="spacer" />
                            <p><label className="half-bold-text cta-color">Voting Round</label> allows candidates and the broader community to view submissions and upvote which submissions they like best. This round opens the moment the submission deadline passes and lasts for one week.</p>
                            <div className="spacer" /><div className="spacer" />
                          </div>
                        )}

                        {(this.state.judgingRoundInfo) && (
                          <div className="padding-40">
                            <p className="heading-text-2">Judging Round Info</p>
                            <div className="spacer" /><div className="spacer" />
                            <p><label className="half-bold-text cta-color">Judging Round</label> allows designated judges to input scores for submissions. We recommend that this comes after the voting round to narrow down the number of submissions required to review. This round opens after the voting round or after the submission deadline passes if there is no voting round.</p>
                            <div className="spacer" /><div className="spacer" />
                          </div>
                        )}

                        {(this.state.announcementDateInfo) && (
                          <div className="padding-40">
                            <p className="heading-text-2">Announcement Date Info</p>
                            <div className="spacer" /><div className="spacer" />
                            <p><label className="half-bold-text cta-color">Announcement Date</label> marks the date that the winner of the challenge is made known to the public. Announcements may be made either via an automated notification email or within an event.</p>
                            <div className="spacer" /><div className="spacer" />
                          </div>
                        )}

                        {(this.state.voteRequirementInfo) && (
                          <div className="padding-40">
                            <p className="heading-text-2">Vote Requirement Info</p>
                            <div className="spacer" /><div className="spacer" />
                            <p><label className="half-bold-text cta-color">Vote Requirement</label> requires submitters to vote and comment on at least two other submissions during the voting round to be considered valid candidates for the prize money.</p>
                            <div className="spacer" /><div className="spacer" />
                          </div>
                        )}

                        {(this.state.challengesProcess) && (
                          <div className="padding-40">
                            <p className="heading-text-2">GC Challenges Process</p>
                            <div className="spacer" /><div className="spacer" />
                            <img src={challengesProcess} alt="GC" className="image-full-auto" />
                            <div className="spacer" /><div className="spacer" />
                          </div>
                        )}

                        {(this.state.confirmAttendeeConvert) && (
                          <div className="padding-40">
                            <p className="heading-text-2 full-width center-text">Are you sure?</p>
                            <div className="spacer" /><div className="spacer" />

                            <p className="full-width center-text">Clicking yes will bulk convert all RSVPs to attendees.</p>
                            <div className="spacer" /><div className="spacer" /><div className="spacer" />

                            <div className="row-10 center-text">
                              <button className="btn btn-primary right-margin" onClick={() => this.bulkConvert('attendee')}>Yes</button>
                              <button className="btn btn-secondary left-margin" onClick={() => this.bulkConvert('cancel')}>Cancel</button>
                            </div>
                          </div>
                        )}

                        {(this.state.confirmBulkPassAttendees) && (
                          <div className="padding-40">
                            <p className="heading-text-2 full-width center-text">Are you sure?</p>
                            <div className="spacer" /><div className="spacer" />

                            <p className="full-width center-text">Clicking yes will bulk pass/approve all attendees.</p>
                            <div className="spacer" /><div className="spacer" /><div className="spacer" />

                            <div className="row-10 center-text">
                              <button className="btn btn-primary right-margin" onClick={() => this.bulkConvert('pass')}>Yes</button>
                              <button className="btn btn-secondary left-margin" onClick={() => this.closeModal()}>Cancel</button>
                            </div>
                          </div>
                        )}

                        {(this.state.confirmFill) && (
                          <div className="padding-40">
                            <div>
                              <SubEditPlacement
                                closeModal={this.closeModal} modalIsOpen={this.state.modalIsOpen} inModal={true}
                                activeOrg={this.state.org} accountCode={this.state.accountCode} history={this.props.navigate} fillRole={(this.state.isFilled) ? false : true}
                                title={this.state.title} workType={this.state.subPostType} postingId={this.state._id}

                                description={this.state.description}
                                hiringManagerFirstName={this.state.employerContactFirstName}
                                hiringManagerLastName={this.state.employerContactLastName}
                                hiringManagerEmail={this.state.employerContactEmail}
                                orgContactEmail={this.state.orgContactEmail}
                                payType={this.state.payType} startDate={this.state.startDate} endDate={this.state.endDate}
                                isPerpetual={this.state.isPerpetual} locationValue={this.state.location}
                                workFunction={this.state.function} industry={this.state.industry}
                                pathways={this.state.pathways} departments={this.state.departments}
                                targetRoles={this.state.targetRoles} selectedPrograms={this.state.selectedPrograms}
                                hoursPerWeek={this.state.hoursPerWeek}
                                employerName={this.state.employerName} employerURL={this.state.employerURL}
                                placementPartners={this.state.placementPartners}
                              />
                            </div>

                          </div>
                        )}

                        {(this.state.showTags) && (
                          <div>
                            <p className="heading-text-2">Edit Competency Tags</p>

                            <div className="row-10">
                              <div className="container-left">
                                <label className="profile-label">Tag Name</label>
                                <input type="text" className="text-field" placeholder="Name of tag..." name="tagName" value={this.state.tagName} onChange={this.formChangeHandler}></input>
                              </div>
                              <div className="container-right">
                                <div className="calc-column-offset-60 right-padding">
                                  <label className="profile-label">Tag Type</label>
                                  <select name="tagType" value={this.state.tagType} onChange={this.formChangeHandler} className="dropdown">
                                    {this.state.tagTypeOptions.map(value =>
                                      <option key={value} value={value}>{value}</option>
                                    )}
                                  </select>
                                </div>
                                <div className="fixed-column-60 top-padding-40">
                                  <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                  <button className="btn btn-squarish" onClick={() => this.addTag('competency')}>Add</button>
                                </div>
                                <div className="clear" />
                              </div>
                              <div className="clear" />

                              {(this.state.competencyOptions) && (
                                <div>
                                  <div className="spacer" />

                                  {this.state.competencyOptions.map((value, optionIndex) =>
                                    <div key={value._id} className="left-text row-5 full-width">
                                      <button className="background-button" onClick={() => this.competencyClicked(optionIndex, null)}>
                                        <div className="full-width">
                                          <div className="float-left right-padding">
                                            <img src={skillsIcon} alt="Compass employer icon icon" className="image-auto-22" />
                                          </div>
                                          <div className="float-left">
                                            <p className="cta-color">{value.name}</p>
                                          </div>
                                        </div>
                                      </button>
                                    </div>
                                  )}
                                </div>
                              )}

                            </div>

                            {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2">{this.state.errorMessage}</p>}
                            {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2">{this.state.successMessage}</p>}

                            <div className="spacer" />

                            {this.renderTags('competency')}

                            <div className="clear" />

                            <div className="spacer" />

                            <div className="row-10">
                              <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                            </div>
                          </div>
                        )}

                        {(this.state.showSubsidyInfo) && (
                          <div className="padding-40">
                            <p className="heading-text-2">Prioritize Subsidized Candidates</p>
                            <div className="spacer" /><div className="spacer" />
                            <p>In some cases, through our partners, interns and entry-level workers can be <label className="half-bold-text cta-color">subsidized</label> by the city, county, or other program. This typically means candidates are paid (usually around minimum wage) for up to 120 hours of work.</p>
                          </div>
                        )}

                        {(this.state.showSchoolCreditInfo) && (
                          <div className="padding-40">
                            <p className="heading-text-2">Prioritize School Credit Candidates</p>
                            <div className="spacer" /><div className="spacer" />
                            <p>In some cases, through our educational partners, interns and apprentices can receive <label className="half-bold-text cta-color">school credit</label> for working with you as a client partner. This typically means student teams will work on projects for 1-10 hours each week. Some classes are dedicated to "Work Experience" and "Independent Projects".</p>
                          </div>
                        )}

                        {(this.state.showActiveDefinition) && (
                          <div className="padding-40">
                            <p className="heading-text-2">What happens when you make a posting active or inactive?</p>
                            <div className="spacer" /><div className="spacer" />
                            <p>If you mark a posting inactive, it is not visible for outside parties to interact with (e.g., rsvp to, submit projects, apply to, etc...). If a career-seeker visits the posting directly via a link, the posting will say that this posting has been marked inactive and disallow aforementioned interactions.</p>

                            <div className="spacer" /><div className="spacer" />

                            <div className="row-10">
                              <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                            </div>
                          </div>
                        )}

                        {(this.state.showUnlistedDefinition) && (
                          <div className="padding-40">
                            <p className="heading-text-2">What happens when you mark a posting unlisted?</p>
                            <div className="spacer" /><div className="spacer" />
                            <p>If you mark a posting unlisted, the posting is not discoverable by outside parties. Only people who have the direct link will be able to interact with it (e.g., rsvp to it, submit projects to it, apply to it, etc...).</p>

                            <div className="spacer" /><div className="spacer" />

                            <div className="row-10">
                              <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                            </div>
                          </div>
                        )}

                        {(this.state.showInfoCollected) && (
                          <div className="padding-40">
                            <p className="heading-text-2 full-width center-text">Information We Typically Collect from Applicants</p>
                            <div className="spacer" /><div className="spacer" />

                            {this.state.infoCollected.map((value, index) =>
                              <div key={value}>
                                <div className="relative-column-33 cta-color bold-text description-text-1 top-margin-20">
                                  <p className="full-width center-text">{value}</p>
                                </div>
                                {(index % 3 === 2) && (
                                  <div className="clear" />
                                )}
                              </div>
                            )}

                            <div className="clear" />
                            <div className="spacer" /><div className="spacer" />
                          </div>
                        )}

                        {(this.state.showAddAttendee) && (
                          <div className="padding-40">
                            <p className="heading-text-2">Add Attendee</p>
                            <div className="spacer" /><div className="spacer" />

                            <div className="row-10">
                              <div className="name-container">
                                <label className="profile-label">Is This an Existing User?<label className="error-color">*</label></label>
                                <select name="isExistingUser" value={this.state.isExistingUser} onChange={this.formChangeHandler} className="dropdown">
                                  {this.state.binaryOptions.map(value =>
                                    <option key={value.key} value={value}>{value}</option>
                                  )}
                                </select>
                              </div>
                              <div className="clear" />
                            </div>

                            {(this.state.isExistingUser && this.state.isExistingUser === 'Yes') ? (
                              <div>
                                <label className="profile-label">Search Members</label>

                                <div>
                                  <div className="calc-column-offset-100 right-padding">
                                    <input type="text" className="text-field" placeholder="Search a name or email..." name="assigneeName" value={this.state.assigneeName} onChange={this.formChangeHandler}></input>
                                  </div>
                                  <div className="fixed-column-100">
                                    <button className="btn btn-squarish" onClick={() => this.addItem('attendee')}>Add</button>
                                  </div>
                                  <div className="clear" />
                                </div>

                                <div className="clear" />
                                <div className="spacer" />

                                {(this.state.suggestedAssignees.length > 0) && (
                                  <div>
                                    <div className="spacer" />
                                    {this.state.suggestedAssignees.map(value =>
                                      <div key={value._id} className="row-5">
                                        <button className="background-button" onClick={() => this.assigneeClicked(value)}>
                                          <div>
                                            <div className="float-left right-padding">
                                              <img src={profileIconBlue} alt="Compass employer icon icon" className="image-auto-22" />
                                            </div>
                                            <div className="float-left">
                                              <p className="cta-color">{value.firstName} {value.lastName} ({value.email})</p>
                                            </div>
                                          </div>
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                )}

                                {(this.state.selectedAttendee) && (
                                  <div key={"selectedAttendee"} className="float-left">
                                    <div className="close-button-container-1">
                                      <button className="background-button" onClick={() => this.setState({ selectedAttendee: null })}>
                                        <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                                      </button>
                                    </div>
                                    <div className="float-left row-5 right-padding">
                                      <div className="tag-container-5">
                                        <p className="description-text-2">{this.state.selectedAttendee.firstName} {this.state.selectedAttendee.lastName} ({this.state.selectedAttendee.email})</p>
                                      </div>
                                    </div>
                                  </div>
                                )}

                                <div className="clear" />
                              </div>
                            ) : (
                              <div>
                                <div className="edit-profile-row">
                                  <div className="name-container">
                                    <label className="profile-label">First Name<label className="error-color">*</label></label>
                                    <input className="text-field" type="text" placeholder="Attendee First Name" name="attendeeFirstName" value={this.state.attendeeFirstName} onChange={this.formChangeHandler} />
                                  </div>
                                  <div className="name-container">
                                      <label className="profile-label">Last Name<label className="error-color">*</label></label>
                                      <input className="text-field" type="text" placeholder="Attendee Last Name" name="attendeeLastName" value={this.state.attendeeLastName} onChange={this.formChangeHandler}/>
                                  </div>
                                  <div className="clear" />
                                </div>

                                <div className="edit-profile-row">
                                  <label className="profile-label">Email<label className="error-color">*</label></label>
                                  <input className="text-field" type="text" placeholder="Attendee Email" name="attendeeEmail" value={this.state.attendeeEmail} onChange={this.formChangeHandler} />
                                </div>

                                <div className="edit-profile-row">
                                  <label className="profile-label">Which best describes them?<label className="error-color">*</label></label>
                                  {this.state.roleNameOptions.map((value, index) =>
                                    <div key={value} className="float-left">
                                      {(value && value !== '') && (
                                        <div className="float-left row-5 right-padding">
                                          <button className="background-button" onClick={() => this.itemClicked(value,'roleName')}>
                                            {(this.state.attendeeRoleName === value) ? (
                                              <div className="tag-container-4">
                                                <p className="description-text-2 white-text half-bold-text">{value}</p>
                                              </div>
                                            ) : (
                                              <div className="tag-container-inactive-2">
                                                <p className="description-text-2 cta-color half-bold-text">{value}</p>
                                              </div>
                                            )}
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                  <div className="clear" />
                                </div>

                                {(this.state.attendeeOtherRoleName === 'Other') && (
                                  <div className="edit-profile-row">
                                    <label className="profile-label">Other - Role Name (Please Specify)<label className="error-color">*</label></label>
                                    <input className="text-field" type="text" placeholder="Role Name" name="otherRoleName" value={this.state.attendeeOtherRoleName} onChange={this.formChangeHandler} />
                                  </div>
                                )}

                                {(this.state.attendeeRoleName) && (
                                  <div>
                                    {(this.state.attendeeRoleName === 'Student') ? (
                                      <div className="edit-profile-row">
                                        <div className="name-container">
                                          <label className="profile-label">School Name</label>
                                          <input className="text-field" type="text" placeholder="Attendee School Name" name="attendeeSchoolName" value={this.state.attendeeSchoolName} onChange={this.formChangeHandler} />
                                        </div>
                                        <div className="clear" />
                                      </div>
                                    ) : (
                                      <div className="edit-profile-row">
                                        <div className="name-container">
                                          <label className="profile-label">Job Title</label>
                                          <input className="text-field" type="text" placeholder="Attendee Job Name" name="attendeeJobTitle" value={this.state.attendeeJobTitle} onChange={this.formChangeHandler} />
                                        </div>
                                        <div className="name-container">
                                            <label className="profile-label">Employer Name</label>
                                            <input className="text-field" type="text" placeholder="Attendee Employer Name" name="attendeeEmployerName" value={this.state.attendeeEmployerName} onChange={this.formChangeHandler}/>
                                        </div>
                                        <div className="clear" />
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}

                            {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-message">{this.state.errorMessage}</p>}
                            {(this.state.successMessage && this.state.successMessage !== '') && <p className="success-message">{this.state.successMessage}</p>}

                            <div className="spacer" /><div className="spacer" />

                            <button className="btn btn-primary"  disabled={this.state.isSaving} onClick={() => this.saveAttendee()}>Add Attendee</button>
                          </div>
                        )}

                        {(this.state.showHandlebarsInfo) && (
                          <div className="padding-40">
                            <p className="heading-text-2">Confirmation Messages and Handlebars Explained</p>
                            <div className="spacer" /><div className="spacer" />
                            <p>You can tailor the confirmation messages career-seekers receive after they RSVP, submit a project, or submit an application. You can also set variables in the message using the Handlebars (i.e., {"{{ Variable Name }}"}) methodology. Below is a list of available variables:</p>

                            <div className="spacer" /><div className="spacer" />

                            <p className="top-padding"><label className="bold-text">{"{{ First Name }}"}</label> -> <label className="left-padding">[assigned to career-seeker] (available on all postings)</label></p>
                            <p className="top-padding"><label className="bold-text">{"{{ Posting Title }}"}</label> -> <label className="left-padding">[i.e., {this.state.title}] (available on all postings)</label></p>
                            <p className="top-padding"><label className="bold-text">{"{{ Employer Name }}"}</label> -> <label className="left-padding">[i.e., {this.state.employerName}] (available on all work postings)</label></p>
                            <p className="top-padding"><label className="bold-text">{"{{ Org Name }}"}</label> -> <label className="left-padding">[i.e., {this.state.orgName}] (available on all postings)</label></p>
                            <p className="top-padding"><label className="bold-text">{"{{ Org Contact Email }}"}</label> -> <label className="left-padding">[i.e., {this.state.orgContactEmail}] (available on work postings)</label></p>
                            <p className="top-padding"><label className="bold-text">{"{{ Location }}"}</label> -> <label className="left-padding">[i.e., {this.state.location}] (available on event postings)</label></p>
                            <p className="top-padding"><label className="bold-text">{"{{ Start Date }}"}</label> -> <label className="left-padding">[i.e., {this.state.startDate}] (available on event postings)</label></p>

                            <div className="spacer" /><div className="spacer" />

                            <div className="row-10">
                              <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                            </div>
                          </div>
                        )}

                        {(this.state.showOrgDetails) && (
                          <div className="padding-40 center-text">
                            {(this.state.selectedOrg.webLogoURI) ? (
                              <div className="full-width center-text bottom-margin-20">
                                <div className="fixed-column-30 height-30">
                                  <p> </p>
                                </div>
                                <div className="calc-column-offset-60">
                                  <img className="image-auto-80 center-horizontally" alt="GC" src={this.state.selectedOrg.webLogoURIColor} />
                                </div>
                                <div className="fixed-column-30">
                                  <button className="background-button" onClick={() => this.closeModal()}>
                                    <img src={closeIcon} alt="GC" className="image-auto-20 pin-right" />
                                  </button>
                                </div>
                                <div className="clear" />
                              </div>
                            ) : (
                              <div className="full-width center-text">
                                <div className="fixed-column-30 height-30">
                                  <p> </p>
                                </div>
                                <div className="calc-column-offset-60 height-30">
                                  <p> </p>
                                </div>
                                <div className="fixed-column-30">
                                  <button className="background-button" onClick={() => this.closeModal()}>
                                    <img src={closeIcon} alt="GC" className="image-auto-20 pin-right" />
                                  </button>
                                </div>
                                <div className="clear" />
                              </div>
                            )}

                            <p className="heading-text-2">{this.state.selectedOrg.orgName}</p>
                            <a href={this.state.selectedOrg.orgURL} className="full-width center-text top-padding">{this.state.selectedOrg.orgURL}</a>

                            <div className="spacer" /><div className="spacer" /><div className="spacer" />

                            <p className="description-text-1">{this.state.selectedOrg.orgDescription}</p>

                            <div className="spacer" /><div className="spacer" /><div className="spacer" />

                            <hr />

                            <div className="spacer" />

                            <p className="description-text-2 row-20">Removing this partner from the posting does not remove them from your overall organization and/or other postings</p>

                            <div className="spacer" />

                            <button className="btn btn-squarish error-background-color clear-border" onClick={() => this.removeOrg()}>Remove {this.state.selectedOrg.orgName} this Posting</button>
                          </div>
                        )}

                        {(this.state.addTrainingPartners) && (
                          <div>
                            <SubAddWorkspaces history={this.props.navigate} modalIsOpen={true} addOrgToPosting={this.addOrgToPosting} />
                          </div>
                        )}

                        {(this.state.showCandidateInvites) && (
                          <div>
                            {this.renderTaggedCandidatesOfInterest()}


                            <div className="row-10">
                              <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                            </div>
                          </div>
                        )}

                        {(this.state.showChangeStage) && (
                          <div className="padding-40">
                            <p className="heading-text-2">Change Stage</p>
                            <div className="spacer" /><div className="spacer" />

                            <select name="stage" value={this.state.stage} onChange={this.formChangeHandler} className="dropdown">
                              {this.state.stageOptions.map(value =>
                                <option key={value.key} value={value}>{value}</option>
                              )}
                            </select>

                            <div className="spacer" /><div className="spacer" />

                            <div className="row-10">
                              <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                            </div>
                          </div>
                        )}

                        {(this.state.showConfirmDelete) && (
                          <div className="padding-40">
                            <p className="heading-text-2">Confirm Deletion</p>
                            <div className="spacer" /><div className="spacer" />

                            {this.renderConfirmDelete(true)}

                          </div>
                        )}

                        {(this.state.showRSVPs) && (
                          <div className="padding-40">
                            <div>
                              <div className="calc-column-offset-25">
                                <p className="heading-text-2">RSVPs & Attendees</p>
                              </div>
                              <div className="fixed-column-25">
                                <button className="background-button pin-right" onClick={() => this.closeModal()}>
                                  <img src={closeIcon} alt="GC" className="image-auto-15 top-margin pin-right" />
                                </button>
                              </div>
                              <div className="clear" />
                            </div>

                            <div className="spacer" /><div className="spacer" />

                            {this.renderRAndA()}
                          </div>
                        )}

                        {(this.state.showProspects) && (
                          <div className="padding-40">
                            <div>
                              <div className="calc-column-offset-25">
                                <p className="heading-text-2">{(this.state.prospects && this.state.prospects.length > 0) && this.state.prospects.length + " "}Prospects</p>
                              </div>
                              <div className="fixed-column-25">
                                <button className="background-button pin-right" onClick={() => this.closeModal()}>
                                  <img src={closeIcon} alt="GC" className="image-auto-15 top-margin pin-right" />
                                </button>
                              </div>
                              <div className="clear" />
                            </div>
                            <p className="top-margin description-text-2">Tag prospects as <label className="bold-text">candidates of interest</label>. Prospects have career assessment results, goals, or other profile items that indicate a good match for this opportunity</p>

                            <div className="spacer" /><div className="spacer" />

                            {this.renderProspects()}

                          </div>
                        )}
                      </Modal>
                    )}
                  </div>
                )}

              </div>
          </div>

      )
    }
}

export default withRouter(EditPosting);
